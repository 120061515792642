import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playerPosition' })
export class PlayerPositionPipe implements PipeTransform {
  transform(inputPlayerPosition: string) {
    if (!inputPlayerPosition) {
      return '';
    }
    if (inputPlayerPosition.toLowerCase().includes('goalkeeper')) {
      return 'G';
    }
    if (
      inputPlayerPosition.toLowerCase().includes('forward') ||
      inputPlayerPosition.toLowerCase().includes('center')
    ) {
      return 'F';
    }
    return 'D';
  }
}
