<h4 mat-dialog-title>Collection Quality</h4>
<mat-dialog-content>
  <button
    mat-button
    (click)="loadData()"
    class="refresh-button"
    [disabled]="!qualityChecks"
    >Refresh</button
  >
  <p
    >{{ game.date | date : 'yyyy-MM-dd' }} {{ game.homeTeam }} -
    {{ game.awayTeam }}</p
  >

  <table *ngIf="qualityChecks" style="margin-top: 24px">
    <tr>
      <th>Unit</th>
      <th>Collector</th>
      <th>Validators</th>
      <th class="number">Effort</th>
      <th class="number">95% Lag</th>
      <th class="number">#Events</th>
      <th class="number">#Updates</th>
      <th class="number">Player</th>
      <th class="number">Outcome</th>
      <th class="number">Position</th>
      <th class="number">Blocker</th>
      <th class="number">Pass Type</th>
      <th class="number">Pass Receiver</th>
      <th class="number">Other</th>
      <th class="number">#Missing/Surplus</th>
    </tr>
    <tr *ngFor="let check of qualityChecks">
      <td>{{ check.groupKey }}</td>
      <td>{{ check.collector }}</td>
      <td>{{ check.validators }}</td>
      <td class="number">{{ check.effortSeconds | hhmmss }}</td>
      <td class="number">{{ check.percentile95Lag | hhmmss }}</td>
      <td class="number">{{ check.numEvents }}</td>
      <td class="number">{{ check.numUpdates }}</td>
      <td class="number">{{ check.player }}</td>
      <td class="number">{{ check.outcome }}</td>
      <td class="number">{{ check.position }}</td>
      <td class="number">{{ check.blocker }}</td>
      <td class="number">{{ check.passType }}</td>
      <td class="number">{{ check.passReceiver }}</td>
      <td class="number">{{ check.other }}</td>
      <td class="number">{{ check.missingSurplusEvents }}</td>
    </tr>
  </table>
</mat-dialog-content>

<mat-progress-bar
  *ngIf="!qualityChecks"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
