import { Component, Input, OnInit } from '@angular/core';
import { ReviewService } from '../../services/review.service';
import { AlertService } from '../../services/alert.service';
import { Review } from '../../domain/review';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  reviews: Review[] = [];
  reviewCount = 0;

  @Input()
  filters = {};

  currentPage;
  itemsPerPage = 10;

  constructor(
    private reviewService: ReviewService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.loadFilter();
    this.loadReviews();
  }

  pageChanged(pageNumber) {
    if (this.currentPage !== pageNumber) {
      this.currentPage = pageNumber;
      this.loadReviews();
      this.saveFilter();
    }
  }

  filterChanged() {
    let path = this.location.path(false);
    path =
      path.indexOf('?') === -1 ? path : path.substring(0, path.indexOf('?'));
    let query = '';
    for (const key in this.filters) {
      if (this.filters.hasOwnProperty(key) && this.filters[key]) {
        query += key + '=' + this.filters[key];
      }
    }

    this.location.replaceState(path, query);
    this.loadReviews();
    this.saveFilter();
  }

  private loadFilter() {
    this.filters = {
      ...this.filters,
      ...this.route.snapshot.queryParams
    };
    this.currentPage = this.route.snapshot.queryParams.page || 1;
    this.itemsPerPage = this.route.snapshot.queryParams.pageSize || 10;
  }

  private saveFilter() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.filters,
        page: this.currentPage,
        pageSize: this.itemsPerPage
      },
      queryParamsHandling: 'merge'
    });
  }

  loadReviews() {
    this.reviewService
      .getReviews(this.filters, this.currentPage - 1, this.itemsPerPage)
      .subscribe(
        (data) => {
          this.reviewCount = data[0];
          this.reviews = data[1];
        },
        (error) => {
          console.log('loading reviews failed', error);
          this.reviews = [];
          this.alertService.showError(
            'Could not load reviews: ' + error.message
          );
        }
      );
  }

  delete(review: Review) {
    if (
      confirm('Are you sure to delete review for game ' + review.gameId + '?')
    ) {
      this.reviewService.deleteReview(review).subscribe(
        () => {
          const i = this.reviews.indexOf(review);
          this.reviews.splice(i, 1);
        },
        (error) => {
          console.log('delete failed');
        }
      );
    }
  }
}
