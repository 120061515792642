<h2 mat-dialog-title>Shot Impact location</h2>
<mat-dialog-content>
  <div><em>Please record where the puck impacted on the net.</em></div>
  <img
    src="/assets/cross_black.png"
    style="position: relative; display: block; width: 15px; height: 12px"
    [style.left.px]="data.netImpactX - 7"
    [style.top.px]="data.netImpactY - 7"
  />
  <img
    #goal
    (click)="onSelectPosition($event)"
    src="/assets/goal.svg"
    style="width: 223px; height: 141px; margin-top: -12px; cursor: crosshair"
  />
  <div>{{ data.netImpactX }} / {{ data.netImpactY }}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button="" color="primary" (click)="apply()">Apply</button>
  <button mat-button [mat-dialog-close]="{}">Cancel</button>
</mat-dialog-actions>
