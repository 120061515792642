<p><b>Event Details</b></p>

<div class="container no-gutters">
  <div class="row">
    <div class="col-4">
      <label>Type</label>
    </div>
    <div class="col-8">{{ event.eventType | titlecase }}</div>
  </div>

  <div [formGroup]="formGroup" class="row">
    <div class="col-4"><label>Player</label></div>
    <div class="col-8">
      <b>
        <mat-select formControlName="playerNumber" placeholder="Player">
          <mat-option></mat-option>
          <mat-option *ngFor="let p of players" [value]="p.playerNumber">{{
            p.playerNumber
          }}</mat-option>
        </mat-select>
      </b>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <label>Period</label>
    </div>
    <div class="col-8">{{ event.period }}</div>
  </div>
  <div class="row">
    <div class="col-4"><label>Game Time</label></div>
    <div class="col-8">{{ event.gameTime | countdown : event.period }}</div>
  </div>
  <div class="row">
    <div class="col-4"><label>Video Time</label></div>
    <div class="col-8"
      ><a href="#" (click)="seekVideo(event.videoTime, $event)">{{
        event.videoTime | hhmmss
      }}</a>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label>Strength State</label>
    </div>
    <div class="col-8">{{ event.strengthState }}</div>
  </div>
  <div class="row">
    <div class="col-4">
      <label>Insert User</label>
    </div>
    <div class="col-8">{{ event.insertUser }}</div>
  </div>
  <div class="row">
    <div class="col-4">
      <label>Insert Time</label>
    </div>
    <div class="col-8">{{ event.insertDate | date : 'HH:mm:ss' }}</div>
  </div>

  <div class="row">
    <div class="col-12">Validations</div>
    <div class="col-12">
      <ul>
        <li *ngFor="let v of event.validations">{{ v.message }}</li>
      </ul>
    </div>
  </div>

  <div class="buttons" style="margin-top: 16px">
    <button mat-button (click)="deleteEvent()" color="warn">Delete</button>
    <button mat-button (click)="cancel()" [disabled]="formGroup.pristine"
      >Reset</button
    >
    <button
      mat-raised-button
      color="primary"
      (click)="saveEvent()"
      [disabled]="formGroup.pristine"
      >Save Game Event</button
    >
  </div>
</div>
