import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getItem(key: string): any {
    const stringValue = localStorage.getItem(key);
    if (stringValue) {
      return JSON.parse(stringValue);
    }
    return null;
  }

  setItem(key: string, value: any): void {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
