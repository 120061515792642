<div class="wall-clock" *ngIf="timeElapsed">
  <div class="time-elapsed"
    >Elapsed: <span class="value">{{ timeElapsed | hhmmss }}</span></div
  >
  <div class="time-lag"
    >Lag:
    <span
      class="value"
      [class.lagging]="videoTimeLag > 30"
      [class.leading]="videoTimeLag < -30"
      >{{ videoTimeLag | hhmmss }}</span
    ></div
  >
</div>
