import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { EMPTY, firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataSet, DataView } from 'vis-data';
import { TimelineItem } from 'vis-timeline';
import { Timeline } from 'vis-timeline/standalone';
import { Game } from '../domain/game';
import { GameEvent, InterruptionType } from '../domain/game-event';
import { Player } from '../domain/player';
import { Shift } from '../domain/shift';
import { QualityCheck } from '../game-quality-dialog/game-quality-dialog.component';
import { AlertService } from '../services/alert.service';
import { EventService } from '../services/event.service';
import { GameTimeService } from '../services/game-time.service';
import { GameService } from '../services/game.service';
import { PuckPossessionStateService } from '../services/puck-possession-state.service';
import { ShiftsService } from '../services/shifts.service';
import { StrengthStateService } from '../services/strength-state.service';
import { Track } from '../services/track';
import { TrackingService } from '../services/tracking.service';
import { PlayerThumbnail } from '../shared/player-track-thumbnails/player-track-thumbnails.component';
import { ShiftDetailsComponent } from '../shift-details/shift-details.component';
import { playerNumberChange } from '../state/actions/game-event.action';
import { videoTimeExternalChange } from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';
import {
  selectGameTime,
  selectVideoTime
} from '../state/reducers/game-event.reducer';
import { VisualTimeOnIceService } from './visual-time-on-ice.service';
import { GameEventInterruptionTypeService } from '../services/game-event-interruption-type.service';

@Component({
  selector: 'app-visual-time-on-ice',
  templateUrl: './visual-time-on-ice.component.html',
  styleUrls: ['./visual-time-on-ice.component.css']
})
export class VisualTimeOnIceComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject();

  options: any;
  dataSet: DataSet<TimelineItem> = new DataSet<TimelineItem>();
  @ViewChild('timeline', { static: true }) timelineContainer: ElementRef;
  timeline: Timeline;

  @ViewChild(ShiftDetailsComponent)
  shiftDetails: ShiftDetailsComponent;

  game: Game;
  private shifts: Shift[];
  private goals: GameEvent[];
  private shots: GameEvent[];
  private passes: GameEvent[];
  selectedShifts: Shift[] = [];
  selectedEvent: GameEvent;
  filter: FormGroup;

  numShortShifts: number;
  totalTimeOnIceWarning: boolean;
  qualityCheck: QualityCheck;
  thumbnails: PlayerThumbnail[] = [];
  selectedShiftTracks: Track[] = [];
  selectedShiftTrack: number;
  frameRate: number;

  // listen to esc key presses
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.hideThumbnails();
    }
  }

  get period() {
    return this.filter.controls.period.value;
  }

  get team() {
    return this.filter.controls.team.value;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private alertService: AlertService,
    private visualTimeOnIceService: VisualTimeOnIceService,
    private strengthStateService: StrengthStateService,
    private shiftsService: ShiftsService,
    private gameTimeService: GameTimeService,
    private puckPossessionStateService: PuckPossessionStateService,
    private store: Store<GlobalState>,
    private gameService: GameService,
    private gameEventInterruptionTypeService: GameEventInterruptionTypeService,
    private trackingService: TrackingService
  ) {}

  async ngOnInit() {
    this.game = this.activatedRoute.snapshot.data['game'];
    this.filter = this.formBuilder.group({
      team: [
        this.activatedRoute.snapshot.queryParamMap.get('team') ??
          this.game.homeTeam,
        Validators.required
      ],
      period: [
        this.activatedRoute.snapshot.queryParamMap.get('period') ?? '1',
        Validators.required
      ],
      strengthStates: [true, Validators.required],
      faceOff: [true, Validators.required],
      shifts: [true, Validators.required],
      toiScores: [false, Validators.required],
      toiScoreThreshold: [82, Validators.required],
      shortShifts: [false, Validators.required],
      shortShiftThreshold: [4, Validators.required],
      goals: [false, Validators.required],
      shots: [false, Validators.required],
      passes: [false, Validators.required],
      videoTags: [false, Validators.required]
    });
    this.filter.valueChanges.subscribe(async () => await this.updateView());
    this.filter.controls.period.valueChanges.subscribe((period) =>
      this.initViewForPeriod(period)
    );
    await this.visualTimeOnIceService.init(this.game);
    await this.updateOptions();
    await this.updateView();

    this.timeline = new Timeline(
      this.timelineContainer.nativeElement,
      new DataView<any>(this.dataSet),
      this.groups(),
      this.options
    );
    this.timeline.addCustomTime(0, 'current_time');
    this.timeline.on('select', (properties: any) =>
      this.selectItems(properties)
    );

    // required for game-context
    this.strengthStateService.init(this.game._id);
    this.gameTimeService.init(this.game._id);
    this.puckPossessionStateService.init(this.game._id);
    setTimeout(() => {
      this.initViewForPeriod(this.filter.value.period);
    }, 0);

    this.store
      .select(selectVideoTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((videoTime) => this.updateVideoTime(videoTime));
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => this.updateGameTime(gameTime));
  }

  private updateVideoTime(videoTime: number) {
    this.timeline.setCustomTime(
      moment(this.game.date).add(videoTime).toDate(),
      'current_time'
    );
    // required for game-context
    this.gameTimeService.updatePeriodAndGameTime(videoTime);
    this.puckPossessionStateService.updatePuckPossessionState(videoTime);
    this.strengthStateService.updateStrengthState(videoTime);
    this.strengthStateService.updateHomeTeamEmptyNet(videoTime);
    this.strengthStateService.updateAwayTeamEmptyNet(videoTime);
  }

  private updateGameTime(gameTime: number): void {
    // required for game-context
    this.strengthStateService.updateActivePenalties(gameTime);
    this.shiftsService.updateActiveShifts(gameTime, this.shifts);
  }

  private async updateOptions() {
    this.options = {
      showTooltips: true,
      format: {
        minorLabels: {
          millisecond: 'mm:SSS',
          second: 'HH:mm:ss',
          minute: 'HH:mm:ss',
          hour: 'HH:mm',
          weekday: 'ddd D',
          day: 'D',
          week: 'w',
          month: 'MMM',
          year: 'YYYY'
        }
      },
      moment(date: moment.MomentInput) {
        return moment(date).utc();
      },
      stack: false,
      start: moment(this.game.date),
      end: moment(this.game.date).add(60 * 20),
      loadingScreenTemplate: () => '<h4>Loading Timeline...</h4>',
      multiselect: true,
      editable: {
        add: true,
        remove: false,
        updateGroup: true,
        updateTime: true,
        overrideItems: false
      },
      snap: (date: Date, scale: string, step: number) => date,
      onMove: async (item, callback) => {
        callback(await this.moveItem(item));
      },
      onAdd: async (item, callback) => {
        callback(await this.createItem(item));
      }
    };
  }

  private async createItem(item: TimelineItem) {
    console.log('createItem', item);
    if (
      ['foint', 'goalkeeper', 'defensemen', 'forwards'].includes(
        item.group as string
      )
    ) {
      // disallow create shift
      return undefined;
    }

    const shift = await this.visualTimeOnIceService.createShift(
      item.start as Date,
      (item.end as Date) ?? moment(item.start).add(10).toDate(),
      this.period,
      this.team,
      item.group as string
    );
    this.shifts.push(shift);

    return {
      id: shift.onEvent._id,
      content: `${shift.duration}s`,
      start: moment(this.game.date).add(shift.onEvent.videoTime),
      end: moment(this.game.date).add(shift.offEvent?.videoTime),
      group: shift.onEvent.playerNumber,
      className: ''
    };
  }

  private async updateView() {
    this.timeline?.setGroups(this.groups());
    this.dataSet.clear();

    if (this.filter.controls['shifts'].value) {
      await this.updateShifts();
    }
    if (this.filter.controls['strengthStates'].value) {
      await this.updateStrengthStates();
    }
    if (this.filter.controls['faceOff'].value) {
      this.updateFaceoffs();
    }
    if (this.filter.controls['goals'].value) {
      await this.updateGoals();
    }
    if (this.filter.controls['shots'].value) {
      await this.updateShots();
    }
    if (this.filter.controls['passes'].value) {
      await this.updatePasses();
    }
    if (this.filter.controls['videoTags'].value) {
      await this.updateVideoTags();
    }
  }

  private initViewForPeriod(period: string) {
    const start = moment(this.game.date)
      .add(this.visualTimeOnIceService.periodStartTime(period) - 60)
      .toDate();
    const end = moment(this.game.date)
      .add(this.visualTimeOnIceService.periodEndTime(period) + 60)
      .toDate();
    if (!start || !end) {
      return;
    }

    this.timeline.setWindow(start, end);
  }

  async updateShifts() {
    const { team, shortShiftThreshold } = this.filter.value;
    this.shifts = await firstValueFrom(this.shiftsService.getShifts(this.game));

    const filteredShifts = this.shifts.filter(
      (s) =>
        s.offEvent &&
        s.onEvent.team === team &&
        s.onEvent.period === this.period
    );

    const timeOnIceReport = await firstValueFrom(
      this.gameService.timeOnIceReport(this.game._id)
    );
    this.qualityCheck = timeOnIceReport.qualityChecks.find(
      (c) => c.period === this.period && c.team === team
    );

    this.numShortShifts = filteredShifts.filter((s) =>
      this.visualTimeOnIceService.isShortShift(
        s,
        shortShiftThreshold,
        this.visualTimeOnIceService.fointEvents
      )
    ).length;

    const playerItems = filteredShifts.map((s) => this.shiftToTimelineItem(s));
    this.dataSet.update(playerItems);
  }

  private shiftToTimelineItem(s: Shift) {
    const { shortShifts, shortShiftThreshold, toiScores, toiScoreThreshold } =
      this.filter.value;

    const duration = Math.round(
      s.offEvent.gameTime - (s.onEvent.gameTime ?? 0)
    );
    let content = `${duration}s `;
    const classes = [];
    if (s.onEvent.confirmed) {
      classes.push('confirmed-shift');
    }
    if (toiScores) {
      if (s.onEvent.score) {
        content += Math.round(s.onEvent.score * 100) + '%';
      }
      classes.push(this.classForScore(s.onEvent.score, toiScoreThreshold));
    }
    if (
      shortShifts &&
      this.visualTimeOnIceService.isShortShift(
        s,
        shortShiftThreshold,
        this.visualTimeOnIceService.fointEvents
      )
    ) {
      classes.push('short-shift');
    }

    return {
      id: s.onEvent._id,
      content,
      start: moment(this.game.date).add(s.onEvent.videoTime).toDate(),
      end: moment(this.game.date).add(s.offEvent?.videoTime).toDate(),
      group: s.onEvent.playerNumber,
      className: classes.join(' ')
    } as TimelineItem;
  }

  private classForScore(score: number, scoreThreshold: number) {
    if (!score) {
      return '';
    } else if (score < scoreThreshold / 100) {
      return 'low-score';
    }
  }

  async updateStrengthStates() {
    const team = this.filter.controls.team.value;
    const strengthStateItems =
      this.visualTimeOnIceService.prepareStrengthStateItems(
        team,
        this.period,
        this.shifts
      );
    this.dataSet.update(strengthStateItems);
  }

  updateFaceoffs() {
    const faceoffEvents = this.visualTimeOnIceService.fointEvents.filter(
      (e) =>
        e.eventType === 'face_off' &&
        e.team === this.team &&
        e.period === this.period
    );

    const interruptionTypes: Set<InterruptionType> =
      this.gameEventInterruptionTypeService.getAllNonPeriodInterruptionTypes();
    const interruptionEvents = this.visualTimeOnIceService.fointEvents.filter(
      (e) =>
        e.eventType === 'interruption' &&
        interruptionTypes.has(e.interruption_type) &&
        e.period === this.period
    );
    const periodEnd = this.visualTimeOnIceService.fointEvents.find(
      (e) =>
        e.period === this.period &&
        (e.interruption_type === 'period_end' ||
          e.interruption_type === ('game_end' as InterruptionType)) // support legacy event
    );

    const fointItems = [];
    faceoffEvents.forEach((faceOff) => {
      const gameClock = this.visualTimeOnIceService.formatGameClock(
        faceOff.gameTime,
        faceOff.period
      );

      const nextFaceoff = faceoffEvents.find(
        (n) => n.videoTime > faceOff.videoTime
      );
      const interruption = nextFaceoff ?? periodEnd;

      const sumTimeOnIce = this.visualTimeOnIceService.summarizeTimeOnIce(
        faceOff,
        interruption,
        this.team,
        this.shifts
      );
      const expectedTimeOnIce =
        this.visualTimeOnIceService.expectedTimeOnIceForSegment(
          faceOff,
          interruption,
          this.visualTimeOnIceService.penaltyEvents,
          this.team === this.game.homeTeam
        );

      const sumTimeOnIceFormatted =
        this.visualTimeOnIceService.formatDuration(sumTimeOnIce);
      const diffTimeOnIce = sumTimeOnIce - expectedTimeOnIce;
      const diffTimeOnIceFormatted =
        this.visualTimeOnIceService.formatDuration(diffTimeOnIce);

      const classes = ['face-off'];
      if (faceOff.validations?.length > 0) {
        classes.push('face_off-validation');
      }
      if (Math.abs(diffTimeOnIce) > 10) {
        classes.push('face_off-time_diff');
      }

      fointItems.push({
        id: faceOff._id,
        content: `FO ${gameClock} (∑${sumTimeOnIceFormatted} Δ${diffTimeOnIceFormatted})`,
        start: moment(this.game.date).add(faceOff.videoTime).toDate(),
        group: 'foint',
        type: 'box',
        className: classes.join(' ')
      } as TimelineItem);
    });
    interruptionEvents.forEach((interruption) => {
      const gameClock = this.visualTimeOnIceService.formatGameClock(
        interruption.gameTime,
        interruption.period
      );

      fointItems.push({
        id: interruption._id,
        content: `INT ${gameClock}`,
        start: moment(this.game.date).add(interruption.videoTime).toDate(),
        group: 'foint',
        type: 'box',
        className: 'interruption'
      } as TimelineItem);
    });
    if (periodEnd) {
      const gameClock = this.visualTimeOnIceService.formatGameClock(
        periodEnd.gameTime,
        periodEnd.period
      );
      fointItems.push({
        id: periodEnd._id,
        content: `END ${gameClock}`,
        start: moment(this.game.date).add(periodEnd.videoTime).toDate(),
        group: 'foint',
        type: 'box',
        className: 'period-end'
      });
    }
    this.dataSet.update(fointItems);
  }

  async updateGoals() {
    const [_, goals] = await firstValueFrom(
      this.eventService.getEvents(
        this.game._id,
        {
          eventType: 'shot',
          shotOutcome: 'goal',
          period: this.period,
          team: this.team
        },
        false
      )
    );
    this.goals = goals;
    const goalItems = goals.map(
      (s) =>
        ({
          id: s._id,
          content: `Goal`,
          start: moment(this.game.date).add(s.videoTime).toDate(),
          group: s.playerNumber,
          type: 'point',
          className: s.validations.length > 0 ? 'event-warning' : ''
        } as TimelineItem)
    );

    this.dataSet.update([...goalItems]);
  }

  async updateShots() {
    const period = this.filter.controls.period.value;
    const team = this.filter.controls.team.value;
    const shotResult = await firstValueFrom(
      this.eventService.getEvents(
        this.game._id,
        { eventType: 'shot', period, team },
        false
      )
    );
    this.shots = shotResult[1].filter((s) => s.shotOutcome !== 'goal');

    const shotItems = this.shots.map(
      (s) =>
        ({
          id: s._id,
          content: `Shot`,
          start: moment(this.game.date).add(s.videoTime).toDate(),
          group: s.playerNumber,
          type: 'point',
          className: s.validations.length > 0 ? 'event-warning' : ''
        } as TimelineItem)
    );
    const blockItems = this.shots
      .filter((s) => s.blocker)
      .map(
        (s) =>
          ({
            id: s._id + '_block',
            content: `Block`,
            start: moment(this.game.date).add(s.videoTime).toDate(),
            group: s.blocker,
            type: 'point',
            className: s.validations.length > 0 ? 'event-warning' : ''
          } as TimelineItem)
      );
    const deflectItems = this.shots
      .filter((s) => s.deflector)
      .map(
        (s) =>
          ({
            id: s._id + '_deflector',
            content: `Deflection`,
            start: moment(this.game.date).add(s.videoTime).toDate(),
            group: s.deflector,
            type: 'point',
            className: s.validations.length > 0 ? 'event-warning' : ''
          } as TimelineItem)
      );
    const screenerItems = this.shots
      .filter((s) => s.screener)
      .map(
        (s) =>
          ({
            id: s._id + '_screen',
            content: `Screen`,
            start: moment(this.game.date).add(s.videoTime).toDate(),
            group: s.screener,
            type: 'point',
            className: s.validations.length > 0 ? 'event-warning' : ''
          } as TimelineItem)
      );
    const netTrafficItems = this.shots
      .filter((s) => s.net_traffic_causer)
      .map(
        (s) =>
          ({
            id: s._id + '_net_traffic',
            content: `Net Traffic`,
            start: moment(this.game.date).add(s.videoTime).toDate(),
            group: s.net_traffic_causer,
            type: 'point',
            className: s.validations.length > 0 ? 'event-warning' : ''
          } as TimelineItem)
      );

    this.dataSet.update([
      ...shotItems,
      ...blockItems,
      ...deflectItems,
      ...screenerItems,
      ...netTrafficItems
    ]);
  }

  async updateVideoTags() {
    const [_, data] = await firstValueFrom(
      this.eventService.getEvents(
        this.game._id,
        { eventType: 'videoTag', period: this.period, team: this.team },
        false
      )
    );

    function mapVideoTag(s: GameEvent) {
      switch (s.videoTag) {
        case 'low_break_out_under_pressure':
          return 'Low BO U.Press.';
        case 'board_sector_break_out_under_pressure':
          return 'Board Sec. BO U.Press.';
        case 'd_offensive_blue_line_puck_management':
          return 'D Off. BL Puck Mgmt';
        case 'controlled_offensive_zone_entry':
          return 'Contr. OZ Entry';
        default:
          return 'Unknown';
      }
    }

    const items = data.map(
      (s) =>
        ({
          id: s._id,
          content: mapVideoTag(s),
          start: moment(this.game.date).add(s.videoTime).toDate(),
          group: s.playerNumber,
          type: 'point',
          className: s.validations.length > 0 ? 'event-warning' : ''
        } as TimelineItem)
    );
    this.dataSet.update([...items]);
  }

  async updatePasses() {
    const period = this.filter.controls.period.value;
    const team = this.filter.controls.team.value;
    const passesResult = await firstValueFrom(
      this.eventService.getEvents(
        this.game._id,
        { eventType: 'pass', period, team },
        false
      )
    );
    this.passes = passesResult[1];
    const passerItems = this.passes.map(
      (s) =>
        ({
          id: s._id,
          content: `Pass`,
          start: moment(this.game.date).add(s.videoTime).toDate(),
          group: s.playerNumber,
          type: 'point',
          className: s.validations.length > 0 ? 'event-warning' : ''
        } as TimelineItem)
    );
    const passReceiverItems = this.passes.map(
      (s) =>
        ({
          id: s._id + '_receiver',
          content: `Received`,
          start: moment(this.game.date).add(s.videoTime).toDate(),
          group: s.pass_receiver,
          type: 'point',
          className: s.validations.length > 0 ? 'event-warning' : ''
        } as TimelineItem)
    );

    this.dataSet.update([...passerItems, ...passReceiverItems]);
  }

  private describePlayer(player: Player) {
    if (player.position === 'goalkeeper') {
      return player.playerNumber;
    }
    if (player.position === 'defender') {
      const keys = ['L', 'R'];
      return `${player.playerNumber} (${player.line}${
        keys[player.positionIndex]
      })`;
    }
    if (player.position === 'forward') {
      const keys = ['L', 'C', 'R'];
      return `${player.playerNumber} (${player.line}${
        keys[player.positionIndex]
      })`;
    }
    return player.playerNumber;
  }

  private groups() {
    const players = this.game
      .getAllPlayersObj()
      .filter((p) => p.team === this.filter.controls.team.value)
      .map((player) => ({
        id: player.playerNumber,
        content: this.describePlayer(player),
        position: player.position
      }));
    /*
      nestedGroups: [11,12,13]
     */
    return [
      { id: 'foint', content: 'FOINT' },
      {
        id: 'goalkeeper',
        content: 'Goalkeeper',
        nestedGroups: players
          .filter((p) => p.position === 'goalkeeper')
          .map((p) => p.id)
      },
      {
        id: 'defensemen',
        content: 'Defensemen',
        nestedGroups: players
          .filter((p) => p.position === 'defender')
          .map((p) => p.id)
      },
      {
        id: 'forwards',
        content: 'Forwards',
        nestedGroups: players
          .filter((p) => p.position === 'forward')
          .map((p) => p.id)
      },
      ...players
    ];
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(EMPTY);
  }

  private selectItems(properties: any) {
    if (!properties.items?.length) {
      this.selectedShifts = [];
      this.selectedEvent = undefined;
      this.hideThumbnails();
      return;
    }
    this.selectedShifts = this.shifts
      ?.filter((s) => properties.items.includes(s.onEvent._id))
      .sort((a, b) => a.onEvent.videoTime - b.onEvent.videoTime);
    if (this.selectedShifts.length === 1) {
      this.store.dispatch(
        playerNumberChange({
          playerNumber: this.selectedShifts[0].player?.playerNumber
        })
      );
      // find tracks for selected player
      const frameRate = this.game.videos[0].frameRate;
      const startFrame = this.selectedShifts[0].onEvent.videoTime * frameRate;
      const endFrame = this.selectedShifts[0].offEvent.videoTime * frameRate;
      const playerNumber = this.selectedShifts[0].player?.playerNumber;
      this.trackingService
        .getTracks(this.game._id, startFrame, endFrame, playerNumber)
        .subscribe((tracks) => {
          this.selectedShiftTracks = tracks;
          this.selectShiftTrack(0);
        });
    }
    if (this.selectedShifts.length > 1) {
      this.selectedShiftTracks = undefined;
      this.thumbnails = [];
    }

    const faceOff = this.visualTimeOnIceService.fointEvents.find(
      (e) => e.eventType === 'face_off' && e._id === properties.items[0]
    );
    if (faceOff) {
      this.seekVideo(faceOff.videoTime);
    }
    this.selectedEvent = [
      ...(this.goals ?? []),
      ...(this.shots ?? []),
      ...(this.visualTimeOnIceService.fointEvents ?? [])
    ].find(
      (e) =>
        ['shot', 'face_off'].includes(e.eventType) &&
        properties.items.includes(e._id)
    );
  }

  selectShiftTrack(index: number) {
    if (this.selectedShiftTracks.length === 0) {
      this.thumbnails = [];
      return;
    }
    this.selectedShiftTrack = index;
    this.trackingService
      .getPlayerThumbnails(
        this.game._id,
        this.period,
        this.selectedShiftTracks[index].trackId,
        13
      )
      .subscribe((thumbnails) => {
        this.thumbnails = thumbnails;
      });
  }

  async confirmShift() {
    this.hideThumbnails();
    if (this.selectedShifts.length === 1) {
      await this.saveShift({
        playerId: this.selectedShifts[0].player.playerId,
        playerNumber: this.selectedShifts[0].player.playerNumber,
        confirmed: true
      });
    }
  }

  hideThumbnails() {
    this.selectedShiftTracks = [];
    this.thumbnails = [];
  }

  seekVideo(videoTime: number) {
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: videoTime,
        random: self.crypto.randomUUID()
      })
    );
  }

  async moveItem(item: any) {
    console.log('moveItem', item);
    const shift = this.shifts?.find((s) => s.onEvent._id === item.id);
    if (shift) {
      return this.handleMovingShift(shift, item);
    }

    const shotId = this.extractShotId(item.id);
    const shot = this.shots?.find((s) => s._id === shotId);
    if (shot) {
      return this.handleMovingShot(shot, item);
    }
    const goal = this.goals?.find((g) => g._id === item.id);
    if (goal) {
      return this.handleMovingShot(goal, item);
    }

    const passId = item.id.split('_receiver')[0];
    const pass = this.passes?.find((p) => p._id === passId);
    if (pass) {
      return this.handleMovingPass(pass, item);
    }
    return;
  }

  extractShotId(id: string) {
    if (id.includes('_block')) {
      return id.split('_block')[0];
    } else if (id.includes('_deflector')) {
      return id.split('_deflector')[0];
    } else if (id.includes('_screen')) {
      return id.split('_screen')[0];
    } else if (id.includes('_net_traffic')) {
      return id.split('_net_traffic')[0];
    } else {
      return id;
    }
  }

  buildShotId(id: string, itemId: string) {
    if (itemId.includes('_block')) {
      return id + '_block';
    } else if (itemId.includes('_deflector')) {
      return id + '_deflector';
    } else if (itemId.includes('_screen')) {
      return id + '_screen';
    } else if (itemId.includes('_net_traffic')) {
      return id + '_net_traffic';
    } else {
      return id;
    }
  }

  buildShotGroup(id: string, event: GameEvent) {
    if (id.includes('_block')) {
      return event.blocker;
    } else if (id.includes('_deflector')) {
      return event.deflector;
    } else if (id.includes('_screen')) {
      return event.screener;
    } else if (id.includes('_net_traffic')) {
      return event.net_traffic_causer;
    } else {
      return event.playerNumber;
    }
  }

  async handleMovingShift(shift: Shift, item: any) {
    const updatedShift = await this.visualTimeOnIceService.moveShift(shift, {
      start: item.start,
      end: item.end,
      group: item.group
    });
    const i = this.shifts.findIndex((s) => s.onEvent._id === shift.onEvent._id);
    if (i > -1) {
      this.shifts.splice(i, 1, updatedShift);
    }
    this.selectedShifts = [updatedShift];
    this.shiftDetails?.reset();
    this.alertService.showInfo('Shift updated');
    this.updateFaceoffs(); // update time-on-ice sums

    const duration = Math.round(
      updatedShift.offEvent.gameTime - updatedShift.onEvent.gameTime
    );
    return {
      id: shift.onEvent._id,
      content: `${duration}s`,
      start: moment(this.game.date)
        .add(updatedShift.onEvent.videoTime)
        .toDate(),
      end: moment(this.game.date)
        .add(updatedShift.offEvent?.videoTime)
        .toDate(),
      group: updatedShift.onEvent.playerNumber,
      className: ''
    };
  }

  async handleMovingShot(event: GameEvent, item: any) {
    let updatedEvent;
    try {
      updatedEvent = await this.visualTimeOnIceService.moveShot(
        event,
        item.content,
        {
          start: item.start,
          end: item.end,
          group: item.group
        }
      );
    } catch (e) {
      console.log('Update event failed', e);
      this.alertService.showError('Update event failed:' + e.message);
      return;
    }

    let i = -1;
    if (item.content === 'Shot') {
      i = this.shots.findIndex((s) => s._id === event._id);
      if (i > -1) {
        this.shots.splice(i, 1, updatedEvent);
      }
    } else if (item.content === 'Goal') {
      i = this.goals.findIndex((g) => g._id === event._id);
      if (i > -1) {
        this.goals.splice(i, 1, updatedEvent);
      }
    }
    this.alertService.showInfo(`${item.content} updated`);

    const id = this.buildShotId(updatedEvent._id, item.id);
    return {
      id,
      content: item.content,
      start: moment(this.game.date).add(updatedEvent.videoTime).toDate(),
      group: this.buildShotGroup(id, updatedEvent),
      type: 'point',
      className: updatedEvent.validations.length > 0 ? 'event-warning' : ''
    };
  }

  async handleMovingPass(event: GameEvent, item: any) {
    let updatedEvent;
    try {
      updatedEvent = await this.visualTimeOnIceService.movePass(
        event,
        item.content,
        {
          start: item.start,
          end: item.end,
          group: item.group
        }
      );
    } catch (e) {
      console.log('Update event failed', e);
      this.alertService.showError('Update event failed:' + e.message);
      return;
    }

    let i = -1;
    i = this.passes.findIndex((s) => s._id === event._id);
    if (i > -1) {
      this.passes.splice(i, 1, updatedEvent);
    }
    this.alertService.showInfo(`${item.content} updated`);

    return {
      id:
        item.content === 'Received'
          ? updatedEvent._id + '_receiver'
          : updatedEvent._id,
      content: item.content,
      start: moment(this.game.date).add(updatedEvent.videoTime).toDate(),
      group:
        item.content === 'Received'
          ? updatedEvent.pass_receiver
          : updatedEvent.playerNumber,
      type: 'point',
      className: updatedEvent.validations.length > 0 ? 'event-warning' : ''
    };
  }

  async saveShift(changes: {
    playerId: string;
    playerNumber: string;
    confirmed: boolean;
  }) {
    if (this.selectedShifts.length !== 1 || !this.selectedShifts[0].offEvent) {
      return;
    }
    const shift = this.selectedShifts[0];
    const updatedShift = await this.visualTimeOnIceService.saveShift(
      shift,
      changes
    );
    this.alertService.showInfo('Shift updated');

    const i = this.shifts.findIndex((s) => s.onEvent._id === shift.onEvent._id);
    if (i > -1) {
      this.shifts.splice(i, 1, updatedShift);
    }
    this.selectedShifts = [updatedShift];
    this.shiftDetails?.reset();
    this.dataSet.update([this.shiftToTimelineItem(updatedShift)]);
  }

  deleteShift(shift: Shift) {
    this.visualTimeOnIceService.deleteShift(shift).subscribe(async () => {
      this.selectedShifts = [];
      this.alertService.showInfo('Shift deleted');
      this.dataSet.remove(shift.onEvent._id);
      this.dataSet.remove(shift.offEvent._id);
      await this.updateShifts();
    });
  }

  mergeShifts(shifts: Shift[]) {
    this.visualTimeOnIceService.mergeShifts(shifts).subscribe(async () => {
      this.selectedShifts = [];
      this.alertService.showInfo('Shifts merged');
      this.dataSet.remove(shifts[1].onEvent._id);
      await this.updateShifts();
      this.timeline.setSelection([shifts[0].onEvent._id]);
    });
  }

  async saveEvent(event: GameEvent) {
    await firstValueFrom(this.eventService.save(event));
    if (event.eventType === 'shot') {
      if (event.shotOutcome === 'goal') {
        await this.updateGoals();
      } else {
        await this.updateShots();
      }
    } else if (event.eventType === 'face_off') {
      // TODO: update opponent face-off
      const opponentFaceOff = this.visualTimeOnIceService.fointEvents.find(
        (e) =>
          e.eventType === 'face_off' &&
          e.period === this.period &&
          e.gameTime === event.gameTime &&
          e.playerNumber === event.faceoff_opponent
      );
      if (opponentFaceOff) {
        opponentFaceOff.faceoff_opponent = event.playerNumber;
        opponentFaceOff.faceoff_opponentId = event.playerId;
        await firstValueFrom(this.eventService.save(opponentFaceOff));
      }
      await this.visualTimeOnIceService.loadFOINT(this.game._id);
      this.updateFaceoffs();
    }
  }

  async deleteEvent(event: GameEvent) {
    await firstValueFrom(this.eventService.delete(this.game._id, event._id));
    this.dataSet.remove(event._id);
    this.alertService.showInfo('Event deleted');
    await this.updateShots();
  }

  async splitShiftsByStrengthState() {
    await firstValueFrom(
      this.eventService.splitShiftsByStrengthState(this.game._id)
    );
    this.alertService.showInfo('Split shifts complete');
    await this.updateShifts();
  }

  frameNumberToVideoTime(frameNumber: number) {
    return frameNumber / (this.game.videos[0]?.frameRate ?? 25);
  }
}
