import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Game } from '../domain/game';
import { GameEvent } from '../domain/game-event';
import { Review } from '../domain/review';
import { ReviewRemark } from '../domain/review-remark';
import { videoTimeExternalChange } from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';

export type Match = [GameEvent, GameEvent, ReviewRemark];

@Injectable({ providedIn: 'root' })
export class ReviewService {
  private url = environment.API_HOST + '/api/reviews';

  constructor(private http: HttpClient, private store: Store<GlobalState>) {}

  getReview(reviewId: string): Observable<Review> {
    return this.http.get(`${this.url}/${reviewId}`).pipe(
      map((review: Review) => {
        review.game = new Game(review.game);
        if (review.masterGame) {
          review.masterGame = new Game(review.masterGame);
        }
        return review;
      })
    );
  }

  getReviews(
    filters: Record<string, any>,
    page: number,
    pageSize: number
  ): Observable<[number, Review[]]> {
    let params = new HttpParams({ fromObject: filters });
    if (page && pageSize) {
      params = params.set('offset', page * pageSize);
    }
    if (pageSize) {
      params = params.set('limit', pageSize);
    }
    return this.http
      .get(`${this.url}`, { params })
      .pipe(
        map(
          (json) =>
            [json['count'], json['data'] as Review[]] as [number, Review[]]
        )
      );
  }

  createReview(review: Review): Observable<Review> {
    if (!review.gameId && review.filename) {
      console.log('deriving gameId from filename');
      const parts = review.filename.split('_');
      if (parts.length > 2) {
        review.gameId = parts[2]; // 2017-09-07_LHC@GSHC_59d4e480a27ac22f5f67128b_.csv
      }
    }

    return this.http.post(this.url, review).pipe(map((r) => r as Review));
  }

  deleteReview(review: Review): Observable<any> {
    return this.http.delete(this.url + '/' + review._id);
  }

  getReviewEvents(
    reviewId: string,
    filters: Record<string, any>,
    page: number,
    pageSize: number
  ): Observable<[number, ReviewRemark[]]> {
    let params = new HttpParams({ fromObject: filters });
    if (page && pageSize) {
      params = params.set('offset', page * pageSize);
    }
    if (pageSize) {
      params = params.set('limit', pageSize);
    }
    return this.http.get(`${this.url}/${reviewId}/events`, { params }).pipe(
      map((json) => {
        const count: number = json['count'] as number;
        const events = json['data'];
        return [count, events] as [number, ReviewRemark[]];
      })
    );
  }

  getMatchedEvents(
    reviewId: string,
    filters: Record<string, any>,
    page: number,
    pageSize: number
  ): Observable<[number, Match[]]> {
    let params = new HttpParams({ fromObject: filters });
    if (page && pageSize) {
      params = params.set('offset', page * pageSize);
    }
    if (pageSize) {
      params = params.set('limit', pageSize);
    }
    return this.http
      .get<any>(`${this.url}/${reviewId}/matches`, { params })
      .pipe(map((res) => [res.count, res.data] as [number, Match[]]));
  }

  updateReviewEvent(reviewEvent: ReviewRemark): Observable<any> {
    return this.http
      .post(`${this.url}/${reviewEvent.reviewId}/events`, reviewEvent)
      .pipe(map((r) => r as ReviewRemark));
  }

  deleteReviewEvent(reviewEvent: ReviewRemark): Observable<any> {
    return this.http.delete(
      `${this.url}/${reviewEvent.reviewId}/events/${reviewEvent._id}`
    );
  }

  seekVideo(videoTime: number) {
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: videoTime,
        random: self.crypto.randomUUID()
      })
    );
  }

  hasDetailDifference(e0: GameEvent, e1: GameEvent) {
    return this.getReviewEventDetail(e0) !== this.getReviewEventDetail(e1);
  }

  getReviewEventDetail(reviewEvent: GameEvent) {
    let detail = '';
    if (reviewEvent.eventType === 'shot') {
      detail = reviewEvent.shotOutcome;
    } else if (reviewEvent.eventType === 'face_off') {
      detail = reviewEvent.teamFaceOffOutcome;
    } else if (reviewEvent.eventType === 'interruption') {
      detail = reviewEvent.interruption_type;
    } else if (reviewEvent.eventType === 'penalty') {
      detail = reviewEvent.penaltyType;
    } else if (reviewEvent.eventType === 'pass') {
      detail = this.passDetail(reviewEvent);
    } else if (reviewEvent.eventType === 'oddMenRush') {
      detail = reviewEvent.oddMenRushDetail;
    }
    return detail;
  }

  passDetail(event: GameEvent) {
    return (
      event.pass_type + ' / ' + event.pass_outcome + ' / ' + event.pass_receiver
    );
  }
}
