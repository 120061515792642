import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { CameraAngle, Game, Video } from '../domain/game';
import { EventService } from '../services/event.service';
import { VideoDownloadOptions } from '../services/game.service';

@Component({
  selector: 'app-video-download-dialog',
  templateUrl: './video-download-dialog.component.html',
  styleUrls: ['./video-download-dialog.component.css']
})
export class VideoDownloadDialogComponent implements OnInit {
  game: Game;
  video: Video;

  downloadFullLength: boolean;
  downloadClipped: boolean;
  downloadHls: boolean;
  reencode: boolean;

  readonly videoTimeMask = [
    /[0-9]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/
  ];
  clipStart: number;
  clipEnd: number;

  private vgApi: VgApiService;

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (this.vgApi?.isPlayerReady) {
      if (event.code === 'ArrowRight') {
        event.preventDefault();
        this.vgApi.seekTime((this.vgApi.time.current + 1000) / 1000);
      } else if (event.code === 'ArrowLeft') {
        event.preventDefault();
        this.vgApi.seekTime((this.vgApi.time.current - 1000) / 1000);
      }
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<VideoDownloadDialogComponent>,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.game = this.data.game;
    this.video = this.data.video;
    this.downloadFullLength = this.video.cameraAngle === CameraAngle.MAIN;
    this.downloadClipped =
      this.video.variant === 'liverecording' && this.video.format === 'hls';
    this.downloadHls =
      [CameraAngle.MAIN, CameraAngle.TV_FEED].includes(
        this.video.cameraAngle
      ) && !this.hlsVideoExists();
    this.calculateClipTime();
  }

  private hlsVideoExists() {
    return (
      this.game.videos.filter(
        (v) => v.cameraAngle === this.video.cameraAngle && v.format === 'hls'
      ).length > 0
    );
  }

  private calculateClipTime() {
    this.eventService
      .getEvents(
        this.game._id,
        {
          eventType: 'FOINT'
        },
        true,
        0,
        10000
      )
      .subscribe((data) => {
        const events = data[1];
        if (events.length === 0) {
          return;
        }
        const periodStart = events.find(
          (e) =>
            e.period === '1' &&
            e.eventType === 'interruption' &&
            e.interruption_type === 'period_start'
        );
        console.log('period start', periodStart);
        this.clipStart = periodStart?.videoTime;

        const periods = Array.from(new Set(events.map((e) => e.period))).sort(
          (a, b) => a.localeCompare(b)
        );
        const lastPeriod = periods.pop();
        const periodEnd = [...events]
          .reverse()
          .find(
            (e) =>
              e.period === lastPeriod &&
              e.eventType === 'interruption' &&
              e.interruption_type === 'period_end'
          );
        console.log('period end', periodEnd);
        this.clipEnd = periodEnd?.videoTime + (this.video.offset ?? 0);
      });
  }

  parseHHMMSS(value: string) {
    const [h, m, s] = value.split(':');
    return +h * 3600 + +m * 60 + +s;
  }

  startDownload() {
    this.dialogRef.close({
      fullLength: this.downloadFullLength,
      clipped: this.downloadClipped,
      hls: this.downloadHls,
      reencode: this.reencode,
      clipStart: this.clipStart,
      clipEnd: this.clipEnd
    } as VideoDownloadOptions);
  }

  onPlayerReady(api: VgApiService) {
    this.vgApi = api;
  }

  get isHLS(): boolean {
    return this.video.url.indexOf('.m3u8') > -1;
  }

  seekTo(videoTime: number) {
    if (videoTime !== undefined && !isNaN(videoTime)) {
      this.vgApi.seekTime(videoTime);
    }
  }

  startAdjusted(value: string) {
    this.clipStart = this.parseHHMMSS(value);
    this.seekTo(this.clipStart);
  }

  endAdjusted(value: string) {
    this.clipEnd = this.parseHHMMSS(value);
    this.seekTo(this.clipEnd);
  }
}
