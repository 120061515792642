<h2 mat-dialog-title>Duplicate Game</h2>
<mat-dialog-content>
  <p>Select parts of the data set to duplicate</p>
  <div [formGroup]="form">
    <mat-checkbox
      (change)="selectAll($event)"
      [checked]="selected"
      class="select-all"
      >Select all</mat-checkbox
    >
    <mat-checkbox formControlName="face_off">Face-Off</mat-checkbox>
    <mat-checkbox formControlName="interruption">Interruption</mat-checkbox>
    <mat-checkbox formControlName="penalty">Penalty</mat-checkbox>
    <mat-checkbox formControlName="time_on_ice">Time-on-Ice</mat-checkbox>
    <mat-checkbox formControlName="shot">Shots</mat-checkbox>
    <mat-checkbox formControlName="pass">Passes</mat-checkbox>
    <mat-checkbox formControlName="videoTag">Video Tags</mat-checkbox>
    <mat-checkbox formControlName="puckPossession"
      >Puck Possession</mat-checkbox
    >
    <mat-checkbox formControlName="oddMenRush">Odd Men Rush</mat-checkbox>
    <mat-checkbox formControlName="highlight">Highlight</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined" class="close-button"
    >Cancel</button
  >
  <button mat-raised-button color="accent" (click)="execute()"
    >Duplicate</button
  >
</mat-dialog-actions>
