import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationEnd,
  Router
} from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = '';
  showToolbar = true;
  clientVersion = environment.clientVersion;

  @HostListener('document:visibilitychange', ['$event'])
  async onVisibilityChange(event: Event) {
    if (document.visibilityState === 'visible') {
      await this.authService.loadAccessToken();
    }
  }

  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowTitle: Title
  ) {
    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof ActivationStart) {
            const data = event.snapshot.data;
            this.showToolbar =
              'showToolbar' in data ? data['showToolbar'] : true;
          }
        })
      )
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        map((event) => {
          let r = this.activatedRoute;
          while (r.firstChild) {
            r = r.firstChild;
          }
          return r.snapshot;
        })
      )
      .subscribe((route) => {
        this.title =
          route.url.length > 0 ? this.capitalize(route.url[0].path) : '';
        this.windowTitle.setTitle(this.title);
      });
  }

  async ngOnInit() {
    if (this.urlPath !== '/login' && this.urlPath !== '/auth0-callback') {
      await this.authService.loadAccessToken();
    }
  }

  capitalize(s: string) {
    if (s.length === 0) {
      return '';
    }
    return s[0].toUpperCase() + s.substring(1);
  }

  private get urlPath() {
    return window.location.pathname;
  }
}
