import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';

export interface CollectionEffort {
  unit: string;
  collector: string;
  userId: string;
  periods: string;
  effortSeconds: number;
  effortSecondsIgnored: number;
  consideredEvents: number;
  ignoredEvents: number;
  totalEvents: number;
}
export interface SeasonCollectionEffort {
  unit: string;
  count: number;
  median: number;
  std: number;
}

export interface GameQualityCheck {
  groupKey: string;
  collector: string;
  validators: string;
  effortSeconds: number;
  unit: string;
  period: string;
  numUpdates: number;
  numEvents: number;
  medianLag: number;
  percentile95Lag: number;
  player: number;
  outcome: number;
  position: number;
  blocker: number;
  passType: number;
  passReceiver: number;
  other: number;
  missingSurplusEvents: number;
}

@Injectable({ providedIn: 'root' })
export class CollectionService {
  private baseUrl = environment.API_HOST + '/api/collection';

  constructor(private http: HttpClient) {}

  getGameQuality(gameId: string) {
    return this.http.get<GameQualityCheck[]>(`${this.baseUrl}/game-quality`, {
      params: new HttpParams().set('gameId', gameId.toString())
    });
  }

  getGameEffort(gameId: string, cutoff?: number) {
    let params = new HttpParams();
    params = params.set('gameId', gameId.toString());
    if (cutoff) {
      params = params.set('cutoff', cutoff.toString());
    }
    return this.http.get<CollectionEffort[]>(`${this.baseUrl}/game-effort`, {
      params
    });
  }

  getSeasonEffort(
    league: string,
    season?: string,
    seasonType?: string,
    cutoff?: number
  ) {
    let params = new HttpParams()
      .set('league', league)
      .set('season', season ?? '2024')
      .set('seasonType', seasonType ?? 'Regular');
    if (cutoff) {
      params = params.set('cutoff', cutoff.toString());
    }
    return this.http.get<SeasonCollectionEffort[]>(
      `${this.baseUrl}/season-effort`,
      {
        params
      }
    );
  }
}
