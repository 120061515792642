import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameEvent } from '../../domain/game-event';
import { Review, ReviewStatus } from '../../domain/review';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { ReviewService } from '../../services/review.service';
import { ReviewRemark } from '../../domain/review-remark';

@Component({
  selector: 'app-review-game-actions',
  templateUrl: './review-game-actions.component.html',
  styleUrls: ['./review-game-actions.component.css']
})
export class ReviewGameActionsComponent implements OnInit {
  reviewId: string;

  review: Review;
  eventCount = 0;
  events: [GameEvent, GameEvent, ReviewRemark][] = [];

  filters: any = {
    diff: null,
    error: null
  };
  currentPage = 0;
  itemsPerPage = 10;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private reviewService: ReviewService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.reviewId = this.route.snapshot.params['reviewId'];
    this.review = this.route.snapshot.data.review;
    this.filters = { ...this.route.snapshot.queryParams };
    this.currentPage = this.route.snapshot.queryParams.page || 1;
    this.loadEvents();
  }

  private loadEvents() {
    this.reviewService
      .getMatchedEvents(this.review._id, this.filters, 0, 10000)
      .subscribe(
        (res) => {
          this.eventCount = res[0];
          this.events = res[1];
        },
        (error) => {
          console.error('Could not find matched events', error);
          this.alertService.showError(
            'Could not find matched events: ' + error.message
          );
        }
      );
  }

  filterChanged() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.filters,
      queryParamsHandling: 'merge'
    });
    this.loadEvents();
  }

  showEventDetails(i: number) {
    const index = (this.currentPage - 1) * this.itemsPerPage + i + 1;
    this.router.navigate(['/reviews', this.review._id, 'game-actions', index], {
      queryParams: this.filters,
      queryParamsHandling: 'merge'
    });
  }

  startReview() {
    this.review.status = ReviewStatus.REVIEWING;
    this.saveReview();
  }

  reviewComplete() {
    this.review.status = ReviewStatus.CHECKING;
    this.saveReview();
  }

  checkingComplete() {
    this.review.status = ReviewStatus.DONE;
    this.saveReview();
  }

  hasDiff(e0, e1) {
    return this.diffNum(e0, e1) > 0;
  }

  timeDiff(e0, e1) {
    if (!e0 || !e1) {
      return 0;
    }
    return e0.gameTime - e1.gameTime;
  }

  timeDiffAbs(e0, e1) {
    return Math.abs(this.timeDiff(e0, e1));
  }

  playerDiff(e0, e1) {
    if (!e0 || !e1) {
      return false;
    }
    return e0.playerNumber !== e1.playerNumber;
  }

  outcomeDiff(e0, e1) {
    if (!e0 || !e1) {
      return false;
    }
    return this.reviewService.hasDetailDifference(e0, e1);
  }

  outcome(e0, e1) {
    let e0Text = '';
    let e1Text = '';
    if (e0) {
      e0Text = this.reviewService.getReviewEventDetail(e0);
    }
    if (e1) {
      e1Text = this.reviewService.getReviewEventDetail(e1);
    }
    return e0Text + ' vs ' + e1Text;
  }

  positionDiff(e0, e1) {
    if (
      !e0 ||
      !e1 ||
      e0.eventType === 'interruption' ||
      e0.eventType === 'penalty'
    ) {
      return 0;
    }
    const dx = Math.abs(e0.xPosition - e1.xPosition);
    const dy = Math.abs(e0.yPosition - e1.yPosition);
    return Math.sqrt(dx * dx + dy * dy);
  }

  diffNum(e0, e1) {
    return (
      (this.timeDiff(e0, e1) > 10 ? 1 : 0) +
      (this.playerDiff(e0, e1) ? 1 : 0) +
      (this.outcomeDiff(e0, e1) ? 1 : 0) +
      (this.positionDiff(e0, e1) > 5 ? 1 : 0)
    );
  }

  private saveReview() {
    this.reviewService.createReview(this.review).subscribe(
      () => {},
      (e) => {
        this.alertService.showError('Could not save review');
      }
    );
  }
}
