<div class="container" style="margin-top: 24px">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
        ><a [routerLink]="['/reviews']">Reviews</a></li
      >
      <li class="breadcrumb-item"
        ><a [routerLink]="['/reviews', review._id]">{{ review._id }}</a></li
      >
      <li class="breadcrumb-item active" aria-current="page">Review Events</li>
    </ol>
  </nav>

  <form>
    <div class="form-row">
      <div class="form-group col-md-1">
        <label for="inputPeriod">Period</label>
        <select
          class="form-control"
          id="inputPeriod"
          name="inputPeriod"
          [(ngModel)]="filters.period"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option [value]="1">1</option>
          <option [value]="2">2</option>
          <option [value]="3">3</option>
          <option [value]="4">4</option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="inputStrengthState">Strength State</label>
        <select
          class="form-control"
          id="inputStrengthState"
          name="inputStrengthState"
          [(ngModel)]="filters.strengthState"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option *ngFor="let s of strengthStates" [value]="s">{{ s }}</option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="inputTeam">Team</label>
        <select
          class="form-control"
          id="inputTeam"
          name="inputTeam"
          [(ngModel)]="filters.team"
          (change)="filterChanged()"
        >
          <option value="" selected>Both</option>
          <option [value]="review.game.homeTeam">{{
            review.game.homeTeam
          }}</option>
          <option [value]="review.game.awayTeam">{{
            review.game.awayTeam
          }}</option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="inputPlayer">Player</label>
        <select
          class="form-control"
          id="inputPlayer"
          name="inputPlayer"
          [(ngModel)]="filters.playerNumber"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option *ngFor="let player of players" [value]="player">{{
            player
          }}</option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="inputEventType">Event Type</label>
        <select
          class="form-control"
          id="inputEventType"
          name="inputEventType"
          [(ngModel)]="filters.eventType"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option *ngFor="let e of eventTypes" [value]="e">{{ e }}</option>
        </select>
      </div>
    </div>
  </form>

  <table class="table shifts">
    <thead>
      <tr>
        <th scope="col" class="">Period</th>
        <th scope="col" class="">Strength State</th>
        <th scope="col" class="">Player</th>
        <th scope="col" class="">Game Time</th>
        <th scope="col" class="">Event Type</th>
        <th scope="col" class="">Detail</th>
        <th scope="col" class=""></th>
      </tr>
    </thead>

    <tbody *ngIf="!filteredEvents">
      <tr>
        <td colspan="10">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="filteredEvents">
      <tr
        *ngFor="
          let e of filteredEvents
            | paginate
              : {
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: eventCount
                }
        "
      >
        <td class="period">{{ e.period }}</td>
        <td class="strength-state">{{ e.strengthState }}</td>
        <td class="player">{{ e.playerNumber }}</td>
        <td class="game-time">{{ e.gameTime | hhmmss }}</td>
        <td class="event-type">{{ e.eventType }}</td>
        <td class="detail">{{ e.timeOnIceType }}</td>
        <td class="actions"
          ><button
            mat-icon-button
            (click)="deleteEvent(e)"
            style="line-height: 20px; height: 20px"
            ><mat-icon>delete</mat-icon></button
          ></td
        >
      </tr>
    </tbody>
  </table>
</div>
