import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrengthState } from '../../domain/game-event';
import { ShiftsService } from '../../services/shifts.service';
import { Review } from '../../domain/review';
import { ErrorType, ReviewRemark } from '../../domain/review-remark';
import { Shift } from '../../domain/shift';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { ReviewService } from '../../services/review.service';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { videoTimeExternalChange } from '../../state/actions/game.action';
import { GlobalState } from '../../state/reducers';
import { selectedTrackIdChange } from '../../state/actions/game-event.action';

interface PlayerSummary {
  team: string;
  playerNumber: string;
  duration0: number;
  duration1: number;
}

@Component({
  selector: 'app-review-shifts',
  templateUrl: './review-shifts.component.html',
  styleUrls: ['./review-shifts.component.css']
})
export class ReviewShiftsComponent implements OnInit {
  readonly strengthStates: StrengthState[] = [
    '5-5',
    '5-4',
    '4-5',
    '4-4',
    '5-3',
    '3-5',
    '4-3',
    '3-4',
    '3-3'
  ];

  currentPage = 1;
  itemsPerPage = 50;

  review: Review;

  matchedShifts: [Shift, Shift, ReviewRemark][];
  filteredShifts: [Shift, Shift, ReviewRemark][];
  playerSummaries: PlayerSummary[];

  sourceTotalToi = 0;
  targetTotalToi = 0;
  sourceNumShifts = 0;
  targetNumShifts = 0;
  rmse = 0;

  players: string[];
  filters = {
    period: null,
    strengthState: null,
    team: '',
    playerNumber: null,
    diff: 0,
    error: ''
  };

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private shiftsService: ShiftsService,
    private reviewService: ReviewService,
    private alertService: AlertService,
    private store: Store<GlobalState>
  ) {}

  async ngOnInit() {
    this.review = this.route.snapshot.data['review'];
    this.players = this.review.game.getAllPlayers().filter((p) => p !== 'n/a');
    this.loadData();
  }

  private loadData() {
    this.matchShifts(
      this.shiftsService.getShifts(this.review.game),
      this.shiftsService.getShifts(this.review.masterGame)
    ).subscribe(
      async (shifts) => {
        this.reviewService
          .getReviewEvents(this.review._id, {}, 0, 10000)
          .subscribe((rE) => {
            const reviewRemarks = rE[1];
            shifts.forEach((m) => {
              const e0 = m[0];
              const e1 = m[1];
              const eventMatched = reviewRemarks.find(
                (re) =>
                  (e0 &&
                    re.sourceEventId &&
                    re.sourceEventId.toString() === e0.onEvent._id) ||
                  (e1 &&
                    re.targetEventId &&
                    re.targetEventId.toString() === e1.onEvent._id)
              );
              if (eventMatched) {
                m[2] = eventMatched;
              }
            });
            this.matchedShifts = shifts;
            this.filterChanged();
          });
      },
      (error) => {
        console.error('Could not match shifts', error);
        this.alertService.showError('Could not match shifts: ' + error.message);
      }
    );
  }

  private matchShifts(
    sourceShifts: Observable<Shift[]>,
    targetShifts: Observable<Shift[]>
  ): Observable<[Shift, Shift, ReviewRemark][]> {
    return zip(sourceShifts, targetShifts).pipe(
      map((combined) => {
        const matches: [Shift, Shift, ReviewRemark][] = [];
        const source = combined[0];
        const target = combined[1];

        source.sort((a, b) => a.onEvent.gameTime - b.onEvent.gameTime);
        target.sort((a, b) => a.onEvent.gameTime - b.onEvent.gameTime);

        // find target shift for each source shift
        source.forEach((s0) => {
          const s1 = target.find(
            (s) =>
              s.player.playerNumber === s0.player.playerNumber &&
              this.hasOverlap(
                s0.onEvent.gameTime,
                s0.offEvent ? s0.offEvent.gameTime : null,
                s.onEvent.gameTime,
                s.offEvent ? s.offEvent.gameTime : null
              )
          );
          matches.push([s0, s1, null]);

          const i = target.indexOf(s1);
          if (i > -1) {
            target.splice(i, 1);
          }
        });

        // add remaining target shifts that have no matching
        if (target.length > 0) {
          target.forEach((s1) => matches.push([null, s1, null]));
        }
        matches.sort((a, b) => {
          const gameTimeA = a[0]
            ? a[0].onEvent.gameTime
            : a[1].onEvent.gameTime;
          const gameTimeB = b[0]
            ? b[0].onEvent.gameTime
            : b[1].onEvent.gameTime;
          return gameTimeA - gameTimeB;
        });
        return matches;
      })
    );
  }

  hasOverlap(aFrom: number, aUntil: number, bFrom: number, bUntil: number) {
    return (
      (aFrom <= bFrom && aUntil >= bUntil) ||
      (aFrom >= bFrom && aUntil <= bUntil) ||
      (aFrom <= bFrom && aUntil <= bUntil && aUntil >= bFrom) ||
      (aFrom >= bFrom && aUntil >= bUntil && bUntil >= aFrom)
    );
  }

  hasDiff(m0: Shift, m1: Shift): boolean {
    if (!m0 || !m1) {
      return false;
    }
    return Math.abs(m0.duration - m1.duration) > 30;
  }

  diff(m0: Shift, m1: Shift): number {
    if (!m0 && !m1) {
      return 0;
    }
    if (!m0) {
      return -m1.duration;
    }
    if (!m1) {
      return m0.duration;
    }
    return m0.duration - m1.duration;
  }

  selectPlayer(player: PlayerSummary) {
    this.filters.playerNumber = player.playerNumber;
    this.filterChanged();
  }

  selectShift(shift: Shift) {
    // TODO: navigate
  }

  filterChanged() {
    console.log('filterChanged', this.filters);
    this.filteredShifts = this.matchedShifts.filter((s) => {
      if (
        this.filters.period &&
        (!s[0] || s[0].onEvent.period !== this.filters.period) &&
        (!s[1] || s[1].onEvent.period !== this.filters.period)
      ) {
        return false;
      }
      if (
        this.filters.strengthState &&
        (!s[0] || s[0].onEvent.strengthState !== this.filters.strengthState) &&
        (!s[1] || s[1].onEvent.strengthState !== this.filters.strengthState)
      ) {
        return false;
      }
      if (
        this.filters.team &&
        (!s[0] || s[0].player.team !== this.filters.team) &&
        (!s[1] || s[1].player.team !== this.filters.team)
      ) {
        return false;
      }
      if (
        this.filters.playerNumber &&
        (!s[0] || s[0].player.playerNumber !== this.filters.playerNumber) &&
        (!s[1] || s[1].player.playerNumber !== this.filters.playerNumber)
      ) {
        return false;
      }
      if (this.filters.diff && this.diff(s[0], s[1]) < this.filters.diff) {
        return false;
      }
      return true;
    });
    console.log('filteredShifts', this.filteredShifts);

    this.sourceTotalToi = this.filteredShifts.reduce(
      (acc, s) => (s[0] ? acc + s[0].duration : acc),
      0
    );
    this.targetTotalToi = this.filteredShifts.reduce(
      (acc, s) => (s[1] ? acc + s[1].duration : acc),
      0
    );
    this.rmse = Math.round(
      Math.sqrt(
        this.filteredShifts.reduce(
          (acc, s) => acc + this.squaredError(s[0], s[1]),
          0
        )
      )
    );

    this.sourceNumShifts = this.filteredShifts.reduce(
      (acc, s) => (s[0] ? acc + 1 : acc),
      0
    );
    this.targetNumShifts = this.filteredShifts.reduce(
      (acc, s) => (s[1] ? acc + 1 : acc),
      0
    );

    this.playerSummaries = this.filteredShifts.reduce(
      (summaries: PlayerSummary[], p: [Shift, Shift, ReviewRemark]) => {
        const playerNumber = p[0]
          ? p[0].player.playerNumber
          : p[1].player.playerNumber;
        const team = p[0] ? p[0].player.team : p[1].player.team;
        let playerSummary = summaries.find(
          (s) => s.playerNumber === playerNumber
        );
        if (!playerSummary) {
          playerSummary = {
            team,
            playerNumber,
            duration0: 0,
            duration1: 0
          };
          summaries.push(playerSummary);
        }
        playerSummary.duration0 += p[0] ? p[0].duration : 0;
        playerSummary.duration1 += p[1] ? p[1].duration : 0;
        return summaries;
      },
      []
    );
    this.playerSummaries.sort((a, b) => b.duration0 - a.duration0);
    this.playerSummaries.sort((a, b) => a.team.localeCompare(b.team));
  }

  squaredError(shift0: Shift, shift1: Shift): number {
    const s0 = shift0 ? shift0.duration : 0;
    const s1 = shift1 ? shift1.duration : 0;
    return Math.pow(s0 - s1, 2);
  }

  pageChanged(pageNumber) {
    if (this.currentPage !== pageNumber) {
      this.currentPage = pageNumber;
    }
  }

  seekTo(videoTime: number, shift: Shift) {
    console.log('videoTime', videoTime);
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: videoTime,
        random: self.crypto.randomUUID()
      })
    );
    this.selectTrack(shift.onEvent.trackId);
  }

  selectTrack(trackId: number) {
    console.log('selectedTrack', trackId);
    this.store.dispatch(selectedTrackIdChange({ selectedTrackId: trackId }));
  }

  saveError(match: [Shift, Shift, ReviewRemark], error: ErrorType) {
    let reviewEvent = match[2];
    if (!reviewEvent) {
      reviewEvent = this.createNewReviewEvent(match);
    }
    reviewEvent.error = error;
    this.reviewService.updateReviewEvent(reviewEvent).subscribe(
      (r) => {
        this.alertService.showInfo('Error saved');
        if (!match[2]) {
          match[2] = r as ReviewRemark;
        } else {
          match[2].error = error;
        }
      },
      (err) => {
        this.alertService.showError('Saving error failed: ' + err.message);
      }
    );
  }

  saveRemark(match: [Shift, Shift, ReviewRemark], remark: string) {
    let reviewEvent = match[2];
    if (!reviewEvent) {
      reviewEvent = this.createNewReviewEvent(match);
    }
    reviewEvent.sourceEventRemarks = remark;
    this.reviewService.updateReviewEvent(reviewEvent).subscribe(
      (r) => {
        this.alertService.showInfo('Remark saved');
        if (!match[2]) {
          match[2] = r as ReviewRemark;
        } else {
          match[2].sourceEventRemarks = remark;
        }
      },
      (error) => {
        this.alertService.showError('Saving remark failed: ' + error.message);
      }
    );
  }

  private createNewReviewEvent(match: [Shift, Shift, ReviewRemark]) {
    const reviewRemark: ReviewRemark = {
      reviewId: this.review._id
    };
    if (match[0]) {
      reviewRemark.sourceEventId = match[0].onEvent._id;
    }
    if (match[1]) {
      reviewRemark.targetEventId = match[1].onEvent._id;
    }
    return reviewRemark;
  }
}
