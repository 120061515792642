import { Injectable } from '@angular/core';
import { Uppy, UppyFile } from '@uppy/core';
import { environment } from '../../environments/environment';
import DragDrop from '@uppy/drag-drop';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import StatusBar from '@uppy/status-bar';
import GoldenRetriever from '@uppy/golden-retriever';
import { AuthService } from '../auth/auth.service';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {
  private uppy: Uppy;
  private _bucketFolder: string;
  public thumbnailImage = new BehaviorSubject<string>(null);
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  public getUppyState() {
    return this.uppy.getState();
  }

  public setOnBeforeFileAdded(func: (file) => UppyFile) {
    this.uppy.setOptions({ onBeforeFileAdded: func.bind(this) });
  }

  public initImageUploader(bucketFolder: string) {
    this.initUppy();
    this.uppy = this.uppy
      .use(DragDrop, {
        target: `.for-DragDrop`
      })
      .use(StatusBar, {
        target: '.statusBar',
        showProgressDetails: false,
        hideAfterFinish: true
      })
      .use(GoldenRetriever, {
        indexedDB: { maxFileSize: Infinity, maxTotalSize: Infinity }
      });
    this.bucketFolder = bucketFolder;
  }

  private addDefaultListeners() {
    this.addListener('thumbnail:generated', (file, preview) => {
      this.thumbnailImage.next(preview);
    });
    this.addListener('pause-all', () => {
      this.snackBar.open('Upload paused');
    });
    this.addListener('upload-retry', (fileID) => {
      this.snackBar.open(`Upload retried: ${fileID}`);
    });
    this.addListener('upload-error', (file, error) => {
      if (error.isNetworkError) {
        this.snackBar.open(error);
      }
    });
    this.addListener('info-visible', () => {
      const info = this.getUppyState().info;

      if (info.type === 'error') {
        this.snackBar.open(`${info.message} ${info.details}`);
      } else {
        const message = info.details
          ? `${info.message} ${info.details}`
          : info.message;
        this.snackBar.open(`${message}`);
      }
    });
  }

  public initUppy() {
    console.log('Init uppy', this.authService.accessToken);
    this.uppy = new Uppy({
      debug: !environment.production,
      autoProceed: true,
      restrictions: {
        maxFileSize: 1024 * 1024 * 10, // 10mb
        maxNumberOfFiles: 1,
        minNumberOfFiles: 0,
        allowedFileTypes: ['image/jpeg', 'image/png']
      }
    })
      .use(AwsS3Multipart, {
        companionUrl: environment.API_HOST + '/api',
        companionHeaders: {
          Authorization: `Bearer ${this.authService.accessToken}`,
          'x-file-type': 'image'
        },
        limit: 0,
        retryDelays: [
          0, 1000, 3000, 5000, 15000, 30000, 60000, 300000, 900000, 1800000
        ]
      })
      .use(ThumbnailGenerator, { thumbnailWidth: 200 });
    this.addDefaultListeners();
  }

  public addListener(event: string, callback: (...args) => void) {
    this.uppy.on(event, callback);
  }

  public setMeta(value) {
    this.uppy.setMeta(value);
  }

  public cancelAll() {
    this.uppy.cancelAll();
  }

  public getS3Key(uuid: string) {
    return `${this.bucketFolder}/${uuid}`;
  }

  set bucketFolder(value: string) {
    this._bucketFolder = value;
  }
  get bucketFolder() {
    return this._bucketFolder;
  }
}
