import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { StrengthState } from '../../domain/game-event';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectStrengthState } from '../../state/reducers/game-event.reducer';
import { GlobalState } from '../../state/reducers';

@Component({
  selector: 'app-strength-state',
  templateUrl: './strength-state.component.html',
  styleUrls: ['./strength-state.component.css']
})
export class StrengthStateComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  strengthState: StrengthState;

  constructor(private store: Store<GlobalState>) {}

  ngOnInit() {
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((strengthState) => (this.strengthState = strengthState));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
