<div
  class="scrubBar"
  tabindex="0"
  role="slider"
  aria-label="scrub bar"
  aria-level="polite"
  [attr.aria-valuenow]="getPercentage()"
  aria-valuemin="0"
  aria-valuemax="100"
  [attr.aria-valuetext]="getPercentage() + '%'"
>
  <ng-content></ng-content>
</div>
