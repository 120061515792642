<form [formGroup]="form">
  <mat-select
    class="league-filter"
    [placeholder]="label"
    formControlName="select"
    multiple
  >
    <mat-select-trigger>
      {{ getSelectTrigger() }}
    </mat-select-trigger>
    <mat-checkbox
      #allSelected
      [indeterminate]="isIndeterminate()"
      [checked]="!isIndeterminate()"
      (click)="$event.preventDefault(); toggleAllSelection()"
      class="mat-option all-checkbox"
      >All</mat-checkbox
    >
    <mat-option
      *ngFor="let item of itemList"
      [value]="item[keyValue]"
      (click)="selectOneItem(item[keyValue])"
      >{{
        item[keyTitle] + (keySubTitle ? ' - ' + item[keySubTitle] : '')
      }}</mat-option
    >
  </mat-select>
</form>
