<div style="width: 100%">
  <div *ngIf="thumbnailUrl" class="thumbnail">
    <mat-icon (click)="removeFile()" class="remove-button">close</mat-icon>
    <img [src]="thumbnailUrl" style="height: 100px" alt="" />
  </div>
  <div *ngIf="imageUrl" class="thumbnail">
    <mat-icon (click)="initUpload()" class="remove-button">close</mat-icon>
    <img [src]="imageUrl" style="height: 100px" alt="" />
  </div>

  <div
    [ngClass]="{
      'my-disable': disabledDragDrop,
      hidden: thumbnailUrl || imageUrl
    }"
    class="for-DragDrop"
  >
    <div class="hint">Image only, 1 file, up to 10 MB</div>
  </div>
  <div class="statusBar"></div>
</div>
