import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalValue'
})
export class DecimalValuePipe implements PipeTransform {
  transform(value: number): string {
    if (value !== undefined && value !== null) {
      return (Math.round(value * 100) / 100).toFixed(0);
    } else {
      return '';
    }
  }
}
