import { Component, Input, ViewChild } from '@angular/core';
import { Game } from '../domain/game';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-custom-player-input',
  templateUrl: './custom-player-input.component.html',
  styleUrls: ['./custom-player-input.component.css']
})
export class CustomPlayerInputComponent {
  @Input() positionName: string;
  @Input() positionKey: string;

  @Input() game: Game;

  @ViewChild('nameValue') nameValue: NgModel;
  @ViewChild('idValue') idValue: NgModel;

  isValid() {
    return this.idValue?.valid && this.nameValue?.valid;
  }
}
