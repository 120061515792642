import { Injectable } from '@angular/core';
import { InterruptionType } from '../domain/game-event';

@Injectable()
export class GameEventInterruptionTypeService {
  // game_start and game_end are considered legacy not used in the codebase
  readonly _allInterruptionTypes: { [key in InterruptionType]: string } = {
    period_start: 'Period start',
    period_end: 'Period end',
    icing: 'Icing',
    offside: 'Offside',
    puck_blocked_by_goalkeeper: 'Puck stopped by goalkeeper',
    puck_out: 'Puck out',
    goal: 'Goal',
    penalty: 'Penalty',
    unspecified: 'Unspecified'
  };

  readonly periodInterruptionTypes: Set<InterruptionType> = new Set([
    'period_start',
    'period_end'
  ]);

  getAllNonPeriodInterruptionTypes(): Set<InterruptionType> {
    return new Set(
      this.allInterruptionTypes.filter(
        (interruptionType) =>
          !this.periodInterruptionTypes.has(interruptionType)
      )
    );
  }

  get allInterruptionTypes(): InterruptionType[] {
    const result = Object.keys(this._allInterruptionTypes).filter((key) =>
      this._allInterruptionTypes.hasOwnProperty(key)
    ) as InterruptionType[];
    return result;
  }

  getInterruptionTypeLabel(interruptionType: InterruptionType) {
    return this._allInterruptionTypes[interruptionType];
  }
}
