<div class="events" *ngIf="_eventType === 'time_on_ice'">
  <table>
    <thead>
      <tr>
        <th>Time</th>
        <th></th>
        <th>Type</th>
        <th>Player</th>
        <th></th>
      </tr>
      <tr>
        <th>
          <mat-form-field style="width: 75px">
            <mat-select
              [(value)]="_period"
              (valueChange)="filterChanged()"
              placeholder="Period"
            >
              <mat-option>Any</mat-option>
              <mat-option value="1">P1</mat-option>
              <mat-option value="2">P2</mat-option>
              <mat-option value="3">P3</mat-option>
              <mat-option value="4">P4</mat-option>
              <mat-option value="5">P5</mat-option>
              <mat-option value="6">P6</mat-option>
              <mat-option value="7">P7</mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <th></th>
        <th></th>
        <th colspan="2">
          <mat-form-field style="width: 90px">
            <mat-select
              [(value)]="_team"
              (valueChange)="filterChanged()"
              placeholder="Team"
            >
              <mat-option>Both</mat-option>
              <mat-option [value]="game?.homeTeam">{{
                game.homeTeam
              }}</mat-option>
              <mat-option [value]="game?.awayTeam">{{
                game.awayTeam
              }}</mat-option>
            </mat-select>
          </mat-form-field>

          <app-player-filter
            style="padding-left: 5px"
            [game]="game"
            [players]="filterablePlayers"
            [team]="_team"
            (playerChange)="playerChanged($event)"
          >
          </app-player-filter>
        </th>
      </tr>
    </thead>
    <tr *ngFor="let e of filteredEvents" [ngClass]="e.timeOnIceType">
      <td>
        P{{ e.period }} {{ e.gameTime | countdown : e.period }}<br />
        <a href="#" (click)="seekEvent(e, $event)">{{
          e.videoTime * 10 | timecode
        }}</a
        ><br />
      </td>
      <td style="min-width: 30px">{{ e.strengthState }}</td>
      <td class="toi-type">{{ e.timeOnIceType }}</td>
      <td style="overflow: hidden">{{ e.team }} {{ e.playerNumber }}</td>
      <td style="width: 50px"
        ><button
          mat-icon-button
          class="delete-button"
          (click)="deleteEvent(e)"
          [disabled]="!e._id"
          ><mat-icon>delete</mat-icon></button
        ></td
      >
    </tr>
  </table>
</div>

<div class="events" *ngIf="_eventType !== 'time_on_ice'">
  <table>
    <thead>
      <tr>
        <th>Time</th>
        <th></th>
        <th>Type</th>
        <th>Player</th>
        <th></th>
      </tr>
    </thead>
    <tr *ngFor="let e of filteredEvents" [ngClass]="e.interruption_type">
      <td>
        P{{ e.period }} {{ e.gameTime | countdown : e.period }}<br />
        <a href="#" (click)="seekEvent(e, $event)">{{
          e.videoTime * 10 | timecode
        }}</a>
      </td>
      <td style="min-width: 30px">{{ e.strengthState }}</td>
      <td>{{ e.eventType }} / {{ e.interruption_type | slice : 0 : 16 }}</td>
      <td style="overflow: hidden">{{ e.team }} {{ e.playerNumber }}</td>
      <td
        ><button
          mat-icon-button
          (click)="deleteInterruptionEvent(e)"
          [disabled]="!e._id"
          ><mat-icon>delete</mat-icon></button
        ></td
      >
    </tr>
  </table>
</div>
