<span
  class="team-info"
  [class.selected]="
    (selectedTeam === homeTeam && !swapSides) ||
    (selectedTeam === awayTeam && swapSides)
  "
>
  <div *ngIf="!swapSides" (click)="selectTeam(homeTeam)">{{ homeTeam }}</div>
  <div *ngIf="swapSides" (click)="selectTeam(awayTeam)">{{ awayTeam }}</div>
</span>
<span class="swap-button" (click)="swapClick()">
  <mat-icon style="width: 20px">swap_horiz</mat-icon>
</span>
<span
  class="team-info"
  [class.selected]="
    (selectedTeam === awayTeam && !swapSides) ||
    (selectedTeam === homeTeam && swapSides)
  "
>
  <div *ngIf="!swapSides" (click)="selectTeam(awayTeam)">{{ awayTeam }}</div>
  <div *ngIf="swapSides" (click)="selectTeam(homeTeam)">{{ homeTeam }}</div>
</span>
