<div
  *ngIf="visible && homePenalties.length > 0"
  [ngClass]="{ 'home-penalties': !swapSide, 'away-penalties': swapSide }"
>
  <app-penalties-details
    [penalties]="homePenalties"
    [gameTime]="gameTime"
    [homeTeam]="game.homeTeam"
  ></app-penalties-details>
</div>

<div
  *ngIf="visible && awayPenalties.length > 0"
  [ngClass]="{ 'away-penalties': !swapSide, 'home-penalties': swapSide }"
>
  <app-penalties-details
    [penalties]="awayPenalties"
    [gameTime]="gameTime"
    [homeTeam]="game.homeTeam"
  ></app-penalties-details>
</div>
