import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Game } from '../../domain/game';
import { Player } from '../../domain/player';
import { Shift } from '../../domain/shift';
import { PlayerService } from '../../services/player.service';
import {
  selectActiveShifts,
  selectIsInterrupted
} from '../../state/reducers/game.reducer';
import { Store } from '@ngrx/store';
import {
  selectGameTime,
  selectPeriod,
  selectPlayerNumber,
  selectStrengthState,
  selectTeam,
  selectVideoTime
} from '../../state/reducers/game-event.reducer';
import {
  playerNumberChange,
  teamChange
} from '../../state/actions/game-event.action';
import { GlobalState } from '../../state/reducers';

@Component({
  selector: 'app-game-context',
  templateUrl: './game-context.component.html',
  styleUrls: ['./game-context.component.css']
})
export class GameContextComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  private readonly positionOrder = ['forward', 'defender'];

  @Input()
  game: Game;

  @Input()
  visible: boolean;

  videoTime: number;
  gameTime: number;
  isInterrupted: boolean;
  period: string;
  strengthState: string;
  shiftsHome: Shift[] = [];
  shiftsAway: Shift[] = [];
  selectedPlayer: string;
  selectedTeam: string;

  @Output()
  swap = new EventEmitter<boolean>();

  @Input() swapSides: boolean;

  private players: Player[];

  constructor(
    private playerService: PlayerService,
    private store: Store<GlobalState>
  ) {}

  async ngOnInit() {
    await this.loadPlayers();
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => {
        this.gameTime = gameTime;
      });
    this.store
      .select(selectVideoTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((videoTime) => {
        this.videoTime = videoTime;
      });
    this.store
      .select(selectIsInterrupted)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isInterrupted) => (this.isInterrupted = isInterrupted));
    this.store
      .select(selectActiveShifts)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((shiftsData) => {
        if (!shiftsData) {
          return;
        }
        const shifts = structuredClone(shiftsData);
        shifts.sort((a, b) =>
          !a.player || !b.player
            ? 0
            : this.positionOrder.indexOf(a.player.position) -
              this.positionOrder.indexOf(b.player.position)
        );
        this.shiftsHome = shifts.filter(
          (s) => s.player && s.player.team === this.game.homeTeam
        );
        this.shiftsHome.forEach((s) => {
          const player = this.findPlayer(s.player.playerId);
          if (player) {
            s.player.shoots = this.getPlayerShoots(player);
            s.player.lastName = player.lastName;
          }
        });
        this.shiftsHome = this.orderPlayers(this.shiftsHome);
        this.shiftsAway = shifts.filter(
          (s) => s.player && s.player.team === this.game.awayTeam
        );
        this.shiftsAway.forEach((s) => {
          const player = this.findPlayer(s.player.playerId);
          if (player) {
            s.player.shoots = this.getPlayerShoots(player);
            s.player.lastName = player.lastName;
          }
        });
        this.shiftsAway = this.orderPlayers(this.shiftsAway);
      });
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((strengthState) => (this.strengthState = strengthState));
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((period) => (this.period = period));
    this.store
      .select(selectTeam)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((team) => (this.selectedTeam = team));
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((player) => (this.selectedPlayer = player));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  private async loadPlayers() {
    if (!this.game) {
      return;
    }
    const homeTeamPlayers = await this.playerService
      .getPlayersByGameLineup(
        this.game._id,
        this.game.homeTeamId,
        this.game.idType
      )
      .toPromise();
    const awayTeamPlayers = await this.playerService
      .getPlayersByGameLineup(
        this.game._id,
        this.game.awayTeamId,
        this.game.idType
      )
      .toPromise();
    this.players = [...homeTeamPlayers, ...awayTeamPlayers];
  }

  private findPlayer(playerId: string) {
    return this.players.find((p) => p.id === playerId);
  }

  private getPlayerShoots(player: any) {
    if (player && player.shoots) {
      return player.shoots[0].toUpperCase();
    }
    return '';
  }

  get homeTeam() {
    return this.game?.homeTeam;
  }

  get awayTeam() {
    return this.game?.awayTeam;
  }

  get periodCount() {
    return Math.max(+this.period, 3);
  }

  shiftDuration(shiftStartTime: number) {
    return shiftStartTime - this.videoTime;
  }

  trackByFn(index: number, item: Shift) {
    return item.player.playerId;
  }

  selectPlayer(player: Player) {
    this.store.dispatch(teamChange({ team: player.team }));

    setTimeout(() => {
      this.store.dispatch(
        playerNumberChange({ playerNumber: player.playerNumber })
      );
    }, 100);
  }

  selectTeam(team: string) {
    this.store.dispatch(teamChange({ team }));
  }

  swappingSide(swap: boolean) {
    this.swapSides = swap;
    this.swap.emit(swap);
  }

  orderPlayers(shifts: Shift[]) {
    return [
      ...shifts.filter((shift) => shift.player.position === 'goalkeeper'),
      ...shifts.filter((shift) => shift.player.position === 'defender'),
      ...shifts.filter((shift) => shift.player.position === 'forward')
    ];
  }
}
