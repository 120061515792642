import { GameEvent } from './game-event';
import { Player } from './player';
import { ValidationCheck } from './validation-check';

export const LONG_SHIFT_THRESHOLD = 2 * 60;
export const SHORT_SHIFT_THRESHOLD = 5;

export interface Shift {
  player?: Player;
  onEvent: GameEvent;
  offEvent?: GameEvent;
  duration?: number;

  validations?: ValidationCheck[];
  editMode?: boolean;
  editPlayerNumber?: string;
}
