<h2 mat-dialog-title>Upload File</h2>
<mat-dialog-content>
  <input #file id="input-file-id" type="file" />
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button="" color="primary" (click)="upload()"
    >Upload</button
  >
  <button mat-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
