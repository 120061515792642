<div class="tracking-controls">
  <mat-slide-toggle
    name="debug-mode"
    [formControl]="debugControl"
    color="primary"
    >CV Debug Mode</mat-slide-toggle
  >

  <mat-button-toggle-group
    [formControl]="teamControl"
    [multiple]="true"
    name="team"
    class="team-selection"
    [(value)]="team"
  >
    <mat-button-toggle [value]="game?.homeTeam">{{
      game?.homeTeam
    }}</mat-button-toggle>
    <mat-button-toggle [value]="game?.awayTeam">{{
      game?.awayTeam
    }}</mat-button-toggle>
    <mat-button-toggle [value]="'none'">Unassigned</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-button-toggle-group
    *ngIf="false"
    [multiple]="true"
    name="detectionMode"
    class="add-detection"
  >
    <mat-button-toggle value="add" (click)="toggleDetectionMode()"
      >Add Detections</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
