<h2 mat-dialog-title>{{ player?.id ? 'Edit' : 'Create' }} Player</h2>
<mat-dialog-content>
  <form [formGroup]="form" style="display: flex; flex-wrap: wrap; gap: 4px">
    <div style="flex: 1 0 100%; display: flex">
      <app-upload-image
        style="flex: 1 0 45%; display: flex"
        bucketFolder="player-images"
        [imageUrl]="form.get('imageUrl').value"
        (fileUploadComplete)="onImageUploadComplete($event)"
      ></app-upload-image>
      <div style="display: flex; flex-direction: column; flex: 1 0 45%">
        <mat-form-field>
          <input
            matInput
            formControlName="firstName"
            placeholder="First Name"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="lastName" placeholder="Last Name" />
        </mat-form-field>
      </div>
    </div>

    <mat-form-field style="flex: 1 0 100%">
      <input matInput formControlName="imageUrl" placeholder="Image Url" />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 100%" class="birthday-select">
      <mat-label>Date of Birth</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <input
        type="number"
        matInput
        formControlName="height"
        placeholder="Height (cm)"
      />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <input
        type="number"
        matInput
        formControlName="weight"
        placeholder="Weight (kg)"
      />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 100%">
      <mat-select placeholder="Nationality" formControlName="countryId">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let country of countries" [value]="country.id">{{
          country.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Gender" formControlName="gender">
        <mat-option [value]="null"></mat-option>
        <mat-option value="MALE">Male</mat-option>
        <mat-option value="FEMALE">Female</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <input
        type="number"
        matInput
        formControlName="jerseyNumber"
        placeholder="Jersey Number"
      />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Position" formControlName="playerPosition">
        <mat-option [value]="null"></mat-option>
        <mat-option value="GOALIE">Goalie</mat-option>
        <mat-option value="DEFENSEMAN">Defenseman</mat-option>
        <mat-option value="FORWARD">Forward</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="
        form.value.playerPosition && form.value.playerPosition !== 'GOALIE'
      "
      style="flex: 1 0 45%"
    >
      <mat-select placeholder="Shoots" formControlName="shoots">
        <mat-option [value]="null"></mat-option>
        <mat-option value="LEFT">Left</mat-option>
        <mat-option value="RIGHT">Right</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      style="flex: 1 0 45%"
      *ngIf="
        form.value.playerPosition && form.value.playerPosition === 'GOALIE'
      "
    >
      <mat-select placeholder="Catches" formControlName="catches">
        <mat-option [value]="null"></mat-option>
        <mat-option value="LEFT">Left</mat-option>
        <mat-option value="RIGHT">Right</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-raised-button
    [disabled]="!form.valid"
    color="accent"
    (click)="applyChanges()"
    >{{ player?.id ? 'Update' : 'Create' }}</button
  >
</mat-dialog-actions>
