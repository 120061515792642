import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Game } from '../domain/game';
import { GameEvent } from '../domain/game-event';
import { Player } from '../domain/player';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnChanges {
  @Input()
  game: Game;

  @Input()
  event: GameEvent;

  @Output()
  seek = new EventEmitter<number>();

  @Output()
  save = new EventEmitter<GameEvent>();

  @Output()
  delete = new EventEmitter<GameEvent>();

  players: Player[] = [];
  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      playerNumber: [undefined],
      playerId: [undefined]
    });
    this.formGroup.controls['playerNumber'].valueChanges.subscribe(
      (playerNumber) => {
        this.formGroup.patchValue({
          playerId: this.players.find((p) => p.playerNumber === playerNumber)
            ?.playerId
        });
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.game && this.event) {
      this.updatePlayers();
      this.reset();
    }
  }

  reset() {
    this.formGroup.reset(
      {
        playerNumber: this.event.playerNumber,
        playerId: this.event.playerId
      },
      { emitEvent: false }
    );
  }

  private updatePlayers() {
    if (!this.game) {
      this.players = [];
    }
    const players = (this.players = this.game?.getAllPlayersObj());
    if (!players) {
      return;
    }
    this.players = players
      .filter((p) => p.team === this.event.team)
      .sort((a, b) => a.jerseyNumber - b.jerseyNumber);
  }
  seekVideo(videoTime: number, event: Event) {
    event.preventDefault();
    this.seek.emit(videoTime);
  }

  saveEvent() {
    this.event.playerNumber = this.formGroup.value.playerNumber;
    this.event.playerId = this.formGroup.value.playerId;
    this.save.emit(this.event);
    this.formGroup.reset();
  }

  deleteEvent() {
    this.delete.emit(this.event);
  }

  cancel() {
    this.formGroup.reset();
  }
}
