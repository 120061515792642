import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GameEvent } from '../domain/game-event';

import {
  gameTimeChange,
  periodChange
} from '../state/actions/game-event.action';
import {
  isInterruptedChange,
  timeElapsedChange,
  videoTimeLagChange
} from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';
import { TimeOnIceService } from '../time-on-ice/time-on-ice.service';
import { findIndexSorted } from './find-index-sorted';

const PERIOD_LENGTH = 1200;

@Injectable()
export class GameTimeService {
  timeEvents: GameEvent[] = [];

  constructor(
    private timeOnIceService: TimeOnIceService,
    private store: Store<GlobalState>
  ) {}

  public init(gameId: string): void {
    this.timeOnIceService
      .loadFaceoffsAndInterruptions(gameId)
      .subscribe((events) => {
        this.timeEvents = events.sort((a, b) => a.videoTime - b.videoTime);
      });
  }

  public deleteTimeEvent(eventId: string, isFaceOff?: boolean) {
    const i = this.timeEvents.findIndex((e) => e._id === eventId);
    const event = this.timeEvents[i];
    if (i > -1) {
      this.timeEvents.splice(i, 1);
    }

    if (isFaceOff) {
      this.handleDeletePairedTimeEvent(event);
    }
  }

  private handleDeletePairedTimeEvent(event: GameEvent) {
    const pairedEvent = this.timeEvents.find(
      (e) =>
        e.faceoffId && e.faceoffId === event.faceoffId && event._id !== e._id
    );
    if (pairedEvent) {
      const j = this.timeEvents.indexOf(pairedEvent);
      this.timeEvents.splice(j, 1);
    }
  }

  public addAndDispatchTimeEvent(timeEvent: GameEvent, videoTime: number) {
    const i = this.timeEvents.findIndex((e) => e._id === timeEvent._id);
    if (i > -1) {
      this.timeEvents[i] = timeEvent;
    } else {
      this.timeEvents.push(timeEvent);
    }

    this.timeEvents.sort((a, b) => a.videoTime - b.videoTime);
    this.updatePeriodAndGameTime(videoTime);
  }

  public updatePeriodAndGameTime(videoTime: number) {
    const clockSyncEvent = this.findLastClockSync(videoTime);
    const gameTime = this.calculateGameTime(videoTime, clockSyncEvent);
    this.store.dispatch(gameTimeChange({ gameTime }));
    if (clockSyncEvent) {
      const interrupted = this.isInterrupted(clockSyncEvent);
      this.store.dispatch(isInterruptedChange({ isInterrupted: interrupted }));
      this.store.dispatch(periodChange({ period: clockSyncEvent.period }));
    }
  }

  public updateElapsedTime(videoTime: number) {
    const periodStartEvent = this.findPeriodStart();
    if (
      periodStartEvent &&
      periodStartEvent.insertDate &&
      periodStartEvent.videoTime
    ) {
      const startTime = new Date(periodStartEvent.insertDate).getTime();
      const timeElapsed = (new Date().getTime() - startTime) / 1000;
      this.store.dispatch(timeElapsedChange({ timeElapsed }));

      const videoTimeLag = periodStartEvent.videoTime + timeElapsed - videoTime;
      this.store.dispatch(videoTimeLagChange({ videoTimeLag }));
    }
  }

  private findPeriodStart(period?: string) {
    let periodStartEvent: GameEvent;
    if (this.timeEvents) {
      let periodStarts = this.timeEvents.filter(
        (e) =>
          e.eventType === 'interruption' &&
          e.interruption_type === 'period_start'
      );
      if (period) {
        periodStarts = periodStarts.filter((e) => e.period === period);
      }
      periodStarts.sort((a, b) => b.videoTime - a.videoTime);
      if (periodStarts.length > 0) {
        periodStartEvent = periodStarts[0];
      }
    }
    return periodStartEvent;
  }

  liveDelay(period: string, videoTime: number, timestamp: Date) {
    const periodStartEvent = this.findPeriodStart(period);
    if (
      periodStartEvent &&
      periodStartEvent.insertDate &&
      periodStartEvent.videoTime
    ) {
      const timeElapsed =
        (timestamp.getTime() -
          new Date(periodStartEvent.insertDate).getTime()) /
        1000;

      const lag = periodStartEvent.videoTime + timeElapsed - videoTime;
      return lag;
    }
    return '';
  }

  public calculateGameTimeForVideoTime(videoTime: number) {
    const e = this.findLastClockSync(videoTime);
    return this.calculateGameTime(videoTime, e);
  }

  public findPeriodForVideoTime(videoTime: number) {
    let result;
    if (this.timeEvents) {
      const periodStarts = this.timeEvents.filter(
        (e) =>
          e.eventType === 'interruption' &&
          e.interruption_type === 'period_start'
      );
      const j = findIndexSorted(periodStarts, (e) => e.videoTime - videoTime);
      if (j > -1) {
        result = periodStarts[j];
      }
    }
    return result?.period;
  }

  calculateGameTime(videoTime: number, timeEvent: GameEvent): number {
    let gameTime = 0;
    if (timeEvent) {
      if (
        timeEvent.eventType !== 'interruption' ||
        (timeEvent.eventType === 'interruption' &&
          timeEvent.interruption_type === 'period_start')
      ) {
        const diff = videoTime - timeEvent.videoTime;
        gameTime = timeEvent.gameTime + diff;
      } else if (timeEvent.eventType === 'interruption') {
        gameTime = timeEvent.gameTime;
      } else {
        throw new Error(
          'Unexpected event type ' + timeEvent.eventType + ': ' + timeEvent._id
        );
      }
    }
    return gameTime;
  }

  private findLastClockSync(videoTime: number): GameEvent {
    let result: GameEvent;
    if (this.timeEvents) {
      const j = findIndexSorted(
        this.timeEvents,
        (e) => e.videoTime - videoTime
      );
      if (j > -1) {
        result = this.timeEvents[j];
      }
    }
    return result;
  }

  private isInterrupted(lastTimeEvent: GameEvent): boolean {
    return lastTimeEvent
      ? lastTimeEvent.eventType === 'interruption' &&
          lastTimeEvent.interruption_type !== 'period_start'
      : false;
  }

  convertGameClockToSeconds(value: string) {
    const [m, s] = value.split(':');
    return +m * 60 + +s;
  }

  convertGameClockToGameTime(value: string, period: number) {
    return (
      PERIOD_LENGTH -
      this.convertGameClockToSeconds(value) +
      (period - 1) * PERIOD_LENGTH
    );
  }
}
