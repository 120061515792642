import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-heads-up-game-score',
  templateUrl: './heads-up-game-score.component.html',
  styleUrls: ['./heads-up-game-score.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadsUpGameScoreComponent {
  @Input()
  homeTeam: string;

  @Input()
  awayTeam: string;

  @Input()
  selectedTeam: string;

  @Output()
  swap = new EventEmitter<boolean>();

  @Output()
  clickTeam = new EventEmitter<string>();

  @Input() swapSides: boolean;

  swapClick() {
    this.swapSides = !this.swapSides;
    this.swap.next(this.swapSides);
  }

  selectTeam(team: string) {
    this.clickTeam.emit(team);
  }
}
