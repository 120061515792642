import { Component, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './callback.html',
  styleUrls: ['./callback.css']
})
@Injectable()
export class CallbackComponent {
  error: Error;

  constructor(public auth: AuthService, private logger: NGXLogger) {
    this.auth
      .handleLoginCallback()
      .then(() => this.logger.info('callback handled'))
      .catch((err) => {
        this.logger.error(`loginCallback: ${err}`);
        this.error = err;
      });
  }
}
