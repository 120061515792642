import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GamePeriod, periods } from '../../domain/game-event';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PeriodComponent,
      multi: true
    }
  ]
})
export class PeriodComponent implements ControlValueAccessor {
  period: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  periods = periods;

  constructor() {}

  onClick(period: GamePeriod) {
    this.period = period;
    this.onChange(this.period);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: GamePeriod): void {
    this.period = obj;
  }
}
