<h2 mat-dialog-title>Update External IDs</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-12" *ngIf="isPlayer">
      {{
        dialogData?.firstName +
          ' ' +
          dialogData?.lastName +
          ' - ' +
          dialogData?.jerseyNumber
      }}
    </div>
    <div class="col-12" *ngIf="!isPlayer">
      {{ dialogData?.shortName + ' ' + dialogData?.name }}
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-4">SIHF</div>
    <div class="col-8">
      <input
        matInput
        class="input-id"
        [(ngModel)]="sihfId"
        placeholder="sihfId"
      />
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-4">EliteProspects</div>
    <div class="col-8">
      <input
        matInput
        class="input-id"
        [(ngModel)]="eliteProspectsId"
        placeholder="eliteProspectsId"
      />
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-4">SportContract</div>
    <div class="col-8">
      <input
        matInput
        class="input-id"
        [(ngModel)]="sportcontractId"
        placeholder="sportcontractId"
      />
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-4">NHL</div>
    <div class="col-8">
      <input
        matInput
        class="input-id"
        [(ngModel)]="nhlId"
        placeholder="nhlId"
      />
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-4">InStat</div>
    <div class="col-8">
      <input
        matInput
        class="input-id"
        [(ngModel)]="instatId"
        placeholder="instatId"
      />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Cancel</button>
  <button
    mat-raised-button
    color="warn"
    (click)="applyChange()"
    [disabled]="!dialogData"
    >Save External IDs</button
  >
</mat-dialog-actions>
