import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';

@Component({
  selector: 'app-video-time-offset-dialog',
  templateUrl: './video-time-offset-dialog.component.html',
  styleUrls: ['./video-time-offset-dialog.component.css']
})
export class VideoTimeOffsetDialogComponent implements OnInit {
  game: Game;
  period: 'all';
  offset = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<VideoTimeOffsetDialogComponent>
  ) {}

  ngOnInit(): void {
    this.game = this.data.game;
  }

  applyChange() {
    this.dialogRef.close({ period: this.period, offset: this.offset });
  }
}
