import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Player } from '../../domain/player';
import { LONG_SHIFT_THRESHOLD, Shift } from '../../domain/shift';
import { Store } from '@ngrx/store';
import { selectGameTime } from '../../state/reducers/game-event.reducer';
import { GlobalState } from '../../state/reducers';

@Component({
  selector: 'app-player-button',
  templateUrl: './player-button.component.html',
  styleUrls: ['./player-button.component.css']
})
export class PlayerButtonComponent implements OnInit, OnDestroy {
  @Input()
  player: Player;

  @Input()
  shift: Shift;

  @Input()
  selected: boolean;

  @Output()
  toggle: EventEmitter<Player> = new EventEmitter();

  duration = 0;
  longShift: boolean;

  private componentDestroyed$: Subject<void> = new Subject();
  private gameTime = 0;

  constructor(private store: Store<GlobalState>) {}

  ngOnInit(): void {
    this.duration = this.shiftDuration(this.gameTime);
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => {
        this.gameTime = gameTime;
        this.duration = this.shiftDuration(gameTime);
        this.longShift = this.isLongShift();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  togglePlayer() {
    this.toggle.emit(this.player);
  }

  private shiftDuration(gameTime: number): number {
    if (!this.player || !this.shift || !this.shift.onEvent) {
      return 0;
    }

    const startTime = this.shift.onEvent.gameTime;
    if (!gameTime || gameTime < startTime) {
      return 0;
    }

    return gameTime - startTime;
  }

  private isLongShift(): boolean {
    if (this.player.position === 'goalkeeper') {
      return false;
    }
    return this.duration > LONG_SHIFT_THRESHOLD;
  }
}
