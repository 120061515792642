import { Component, Input } from '@angular/core';

export interface PlayerThumbnail {
  number: number;
  trackId: string;
  src: string;
  width?: number;
  height?: number;
}

@Component({
  selector: 'app-player-track-thumbnails',
  templateUrl: './player-track-thumbnails.component.html',
  styleUrls: ['./player-track-thumbnails.component.css']
})
export class PlayerTrackThumbnailsComponent {
  @Input()
  scale = 1;

  @Input()
  thumbnails!: PlayerThumbnail[];

  hideMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
  }
}
