import { Player } from './player';

export class Players {
  public static filterGoalkeepers(players: Player[]): Player[] {
    return players.filter(
      (player) => player.position && player.position.toLowerCase() === 'goalie'
    );
  }

  static filterDefensemen(players: Player[]): Player[] {
    return players.filter(
      (player) =>
        player.position && player.position.toLowerCase() === 'defenseman'
    );
  }

  static filterForwards(players: Player[]): Player[] {
    return players.filter(
      (player) => player.position && player.position.toLowerCase() === 'forward'
    );
  }

  static filterDuplicatePlayers(players: Player[]): Player[] {
    const uniquePlayerJerseyNumbers: number[] = [];
    const uniquePlayers: Player[] = [];
    players.forEach(function (player: Player) {
      if (!uniquePlayerJerseyNumbers.includes(player.jerseyNumber)) {
        uniquePlayers.push(player);
        uniquePlayerJerseyNumbers.push(player.jerseyNumber);
      }
    });
    return this.orderByJerseyNumber(uniquePlayers);
  }

  static orderByJerseyNumber(players: Player[]): Player[] {
    const sortedPlayers = players.sort(
      (a, b) => a.jerseyNumber - b.jerseyNumber
    );
    return sortedPlayers;
  }

  static orderPlayerStringByJerseyNumber(players: string[]) {
    if (players) {
      const sortedPlayers = players.sort((a, b) => {
        const aJerseyNumber = this.getJerseyNumberFromPlayer(a);
        const bJerseyNumber = this.getJerseyNumberFromPlayer(b);
        if (
          Number.parseInt(aJerseyNumber, 10) <
          Number.parseInt(bJerseyNumber, 10)
        ) {
          return -1;
        } else if (
          Number.parseInt(aJerseyNumber, 10) >
          Number.parseInt(bJerseyNumber, 10)
        ) {
          return 1;
        }
        const aPlayerName = this.getPlayerNameFromPlayer(a);
        const bPlayerName = this.getPlayerNameFromPlayer(b);
        if (aPlayerName > bPlayerName) {
          return 1;
        }
        return 0;
      });
      return !sortedPlayers.includes('')
        ? ['', ...sortedPlayers]
        : sortedPlayers;
    } else {
      return players;
    }
  }

  static getJerseyNumberFromPlayer(player: string) {
    const jerseyNumber = player.split('-')[0];
    if (jerseyNumber) {
      return jerseyNumber.trim();
    } else {
      return '0';
    }
  }

  static getPlayerNameFromPlayer(player: string) {
    const jerseyNumberNameSeparation = player.split('-');
    if (jerseyNumberNameSeparation.length > 1) {
      return player.split('-')[1].trim();
    } else {
      return 'z';
    }
  }
}
