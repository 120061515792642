import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastAction, BroadcastService } from '../broadcast.service';
import { CharCodes } from '../domain/char-codes';
import { Game } from '../domain/game';
import { GamePeriod } from '../domain/game-event';
import { AlertService } from '../services/alert.service';
import { Detection } from '../services/detection';
import { GameService } from '../services/game.service';
import { PlayerThumbnail, TrackingService } from '../services/tracking.service';
import { Track } from '../services/track';
import { TrackAssignment } from '../services/track-assignment';
import { Store } from '@ngrx/store';

import {
  selectPuckPossessionState,
  selectVideoStatus
} from '../state/reducers/game.reducer';
import {
  selectPeriod,
  selectPlayerNumber,
  selectSelectedTrackId,
  selectTeam
} from '../state/reducers/game-event.reducer';
import {
  detectionsChange,
  playerNumberChange,
  selectedTrackIdChange,
  teamChange
} from '../state/actions/game-event.action';
import { GlobalState } from '../state/reducers';

@Component({
  selector: 'app-video-object-tracks',
  templateUrl: './video-object-tracks.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '(window:keydown)': 'hotKeys($event)' },
  styleUrls: ['./video-object-tracks.component.css']
})
export class VideoObjectTracksComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  private trackAssignments: TrackAssignment[] = [];
  detections: Detection[] = [];
  thumbnails: PlayerThumbnail[] = [];

  highConfidenceThreshold = 0.6;
  mediumConfidenceThreshold = 0.3;

  loading = false;

  videoStatus: string;
  selectedTrack: Detection;
  selectedTrackId: string;
  selectedTeam: string;
  selectedPlayer: string;
  selectedFrom = null;
  puckPossessionPlayer: string;
  period: string;

  private _frameNumber = 0;
  private _filteredTeams: string[];

  _videoHeight = 720;
  _videoWidth = 1280;

  // video scaling factor
  cx = 1;
  cy = 1;

  readonly defaultHomeTeamColor = 'rgba(58, 58, 111, 0.5)';
  readonly defaultAwayTeamColor = 'rgba(75, 158, 114, 0.5)';

  detectionStart = null;
  detectionEnd = null;
  detectionComplete = false;
  detectionScaleX = null;
  detectionScaleY = null;

  @Input()
  set videoWidth(videoWidth: number) {
    this._videoWidth = videoWidth;
    this.cx = 1280 / this._videoWidth;
  }

  @Input()
  set videoHeight(videoHeight: number) {
    this._videoHeight = videoHeight;
    this.cy = 720 / this._videoHeight;
  }

  @Input()
  game: Game;

  @Input()
  onlyNumbers = true;

  @Input()
  frameRate: number;

  @Input()
  detectionMode: boolean;

  @Input()
  set frameNumber(frameNumber: number) {
    this._frameNumber = frameNumber;
    this.updateDetections().catch((e) =>
      this.logger.error('Update detections failed', e)
    );
  }

  get frameNumber() {
    return this._frameNumber;
  }

  @Input()
  set filteredTeams(value: string[]) {
    this._filteredTeams = value;
    this.updateDetections().catch((e) =>
      this.logger.error('Update detections failed', e)
    );
  }

  get filteredTeams() {
    return this._filteredTeams;
  }

  @Output()
  selectTrack = new EventEmitter<string>();

  constructor(
    private gameService: GameService,
    private objectTrackingService: TrackingService,
    private alertService: AlertService,
    private broadcast: BroadcastService,
    private logger: NGXLogger,
    private activatedRoute: ActivatedRoute,
    private store: Store<GlobalState>
  ) {}

  async ngOnInit() {
    if (this.game) {
      this.loading = true;
      this.objectTrackingService.initialize(this.game._id);
      const assignments = await firstValueFrom(
        this.objectTrackingService.getTrackAssignments(this.game._id)
      );
      if (assignments) {
        this.trackAssignments = assignments;
      }
      await this.updateDetections();
      this.loading = false;
    }

    // override default low confidence threshold
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        const threshold = params.get('threshold');
        if (threshold) {
          this.highConfidenceThreshold = +threshold;
        }
      });

    this.store
      .select(selectVideoStatus)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => (this.videoStatus = value));
    this.store
      .select(selectTeam)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => (this.selectedTeam = value));
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((period) => (this.period = period));
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => this.selectPlayer(value));
    this.store
      .select(selectPuckPossessionState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((puckPossessionState) => {
        this.puckPossessionPlayer = puckPossessionState?.player;
      });
    this.store
      .select(selectSelectedTrackId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => (this.selectedTrackId = value ? value.toString() : null)
      );

    this.broadcast
      .listen()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((message) => {
        switch (message.data.type) {
          case BroadcastAction.ResetState:
            this.resetStatus();
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  selectPlayer(playerNumber: string) {
    this.selectedPlayer = playerNumber;
    const track = this.detections.find(
      (t) => t.trackAssignment?.player === playerNumber
    );
    this.trackSelected(track);
  }

  private async updateDetections() {
    const detections = await firstValueFrom(
      this.objectTrackingService.getDetections(
        this.game._id,
        this.frameRate,
        this._frameNumber,
        this.period as GamePeriod,
        this.game.homeTeamStartPosition
      )
    );
    this.detections = detections || [];
    this.updateActiveTrackAssignments();
    this.detections = this.detections.filter(
      (d) =>
        !this.filteredTeams ||
        this.filteredTeams?.includes(d.trackAssignment?.team) ||
        (!d.trackAssignment?.team && this.filteredTeams.includes('none'))
    );
    this.store.dispatch(detectionsChange({ detections: this.detections }));
  }

  trackByFn(index, item) {
    return item.trackId;
  }

  getTrack(trackId: string): Track {
    return this.objectTrackingService.getTrack(trackId);
  }

  getPlayerNumber(detection: Detection): string {
    const assignment = detection.trackAssignment;
    if (assignment && assignment.player) {
      const parts = assignment.player.split(' - ');
      if (parts.length > 0) {
        return parts[0];
      }
    }
    return null;
  }

  getPlayerName(detection: Detection): string {
    const assignment = detection.trackAssignment;
    if (assignment && assignment.player) {
      const parts = assignment.player.split(' - ');
      if (parts.length > 1) {
        return parts[1].trim();
      }
    }
    return '';
  }

  teamColor(detection: Detection) {
    if (this.isHomePlayer(detection)) {
      return this.game.homeTeamColor || this.defaultHomeTeamColor;
    } else if (this.isAwayPlayer(detection)) {
      return this.game.awayTeamColor || this.defaultAwayTeamColor;
    }
  }

  isHomePlayer(detection: Detection) {
    const assignment = detection.trackAssignment;
    if (assignment?.team === this.game.homeTeam) {
      return true;
    }

    return assignment
      ? this.gameService
          .getHomeTeamPlayers(this.game)
          .includes(assignment.player)
      : false;
  }

  isAwayPlayer(detection: Detection) {
    const assignment = detection.trackAssignment;
    if (assignment?.team === this.game.awayTeam) {
      return true;
    }

    return assignment
      ? this.gameService
          .getAwayTeamPlayers(this.game)
          .includes(assignment.player)
      : false;
  }

  containerClicked(event: MouseEvent) {
    const element = document.getElementsByTagName('vg-player')[0];
    const scaledX = (event.offsetX / element.clientWidth) * this._videoWidth;
    const scaledY = (event.offsetY / element.clientHeight) * this._videoHeight;
    const track = this.detections.find(
      (t) =>
        t.bbox[0] < scaledX &&
        t.bbox[2] > scaledX &&
        t.bbox[1] < scaledY &&
        t.bbox[3] > scaledY
    );
    this.trackSelected(track);
  }

  trackSelected(track: Detection) {
    if (track?.trackId === this.selectedTrackId) {
      this.logger.debug('deselected track', this.selectedTrackId);
      this.selectedTrackId = null;
      this.selectedTrack = null;
    } else {
      this.logger.debug('selectedTrackId', track?.trackId);
      this.selectedTrackId = track?.trackId;
      this.selectedTrack = track;
    }

    if (this.selectedTrackId) {
      if (
        this.thumbnails.length === 0 ||
        this.thumbnails[0].trackId !== this.selectedTrackId
      ) {
        this.prepareTrackThumbnails(this.selectedTrackId).catch((err) =>
          this.logger.error('prepareTrackThumbnails failed: ' + err.message)
        );
      }
    } else {
      this.hideThumbnails();
    }
    this.selectTrack.emit(this.selectedTrackId);
  }

  saveDetection() {
    // TODO: move save action to main window
    this.logger.debug('save detection');
    const boundingBox = [
      Math.round(this.detectionStart[0] / this.detectionScaleX),
      Math.round(this.detectionStart[1] / this.detectionScaleY),
      Math.round(this.detectionEnd[0] / this.detectionScaleY),
      Math.round(this.detectionEnd[1] / this.detectionScaleY)
    ];
    this.objectTrackingService
      .saveDetection(this.game._id, this._frameNumber, boundingBox)
      .subscribe((detection) => {
        this.detections.push({
          frame: this._frameNumber,
          bbox: [
            this.detectionStart[0],
            this.detectionStart[1],
            this.detectionEnd[0],
            this.detectionEnd[1]
          ],
          obj: null,
          trackId: null,
          player: null,
          teamId: null
        });
        this.detectionStart = null;
        this.detectionEnd = null;
        this.detectionComplete = false;
      });
  }

  saveTrackAssignment(trackId: string, player: string, team: string): any {
    this.logger.debug('save track assignment', trackId, player, team);
    const track = this.getTrack(trackId);
    const assignment = this.findTrackAssignment(trackId);
    const id = assignment ? assignment._id : undefined;
    let from;
    if (this.selectedFrom) {
      from = this.selectedFrom;
    } else if (assignment) {
      from = assignment.from;
    } else {
      from = track.start;
    }

    // validate player
    if (!player) {
      this.alertService.showError('No player selected');
      return;
    }

    // validate team
    if (!team) {
      this.alertService.showError(
        'No team selected – press "a" or "h" to select home/away team'
      );
      return;
    }
    const playerTeam = this.gameService.getTeamByPlayerName(this.game, player);
    if (playerTeam !== team) {
      this.alertService.showError(
        `Player ${player} does not exist in team ${team}`
      );
      return;
    }

    // TODO: move save action to main window
    this.objectTrackingService
      .saveTrackAssignment(
        this.game._id,
        this._frameNumber,
        id,
        trackId,
        player,
        team,
        from
      )
      .subscribe({
        next: async (updatedAssignment) => {
          if (assignment) {
            const i = this.trackAssignments.indexOf(assignment);
            this.trackAssignments.splice(i, 1);
          }
          this.trackAssignments.push(updatedAssignment);
          this.selectedTrackId = null;
          this.selectedPlayer = null;
          this.selectedFrom = null;
          this.selectNextUnassignedTrack();
          this.broadcast.postMessage({ type: BroadcastAction.ResetState });
          await this.updateDetections();
        },
        error: (e) => {
          const message = e.error.message || e.message;
          this.alertService.showError(
            `Could not save track assignment: ${message}`
          );
        }
      });
  }

  async hotKeys(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.escape_key_code) {
      this.selectedTrackId = null;
    } else if (event.keyCode === CharCodes.enter_key_code) {
      if (this.selectedTrackId) {
        this.saveTrackAssignment(
          this.selectedTrackId,
          this.selectedPlayer,
          this.selectedTeam
        );
      } else if (this.detectionComplete) {
        this.saveDetection();
      }
    } else if (event.keyCode === CharCodes.backspace_key_code) {
      if (this.selectedTrackId) {
        this.deleteTrackAssignment(this.selectedTrackId);
      }
    } else if (event.keyCode === CharCodes.tab_key_code) {
      event.preventDefault();
      this.selectNextUnassignedTrack();
    } else if (event.keyCode === CharCodes.x_key_code) {
      event.preventDefault();
      this.splitTrack();
    }
  }

  deleteTrackAssignment(trackId): any {
    const assignment = this.findTrackAssignment(trackId);
    if (assignment) {
      this.logger.debug('deleted track assignment', trackId, assignment._id);
      this.objectTrackingService
        .deleteTrackAssignment(this.game._id, trackId, assignment._id)
        .subscribe(
          async () => {
            this.trackAssignments.splice(
              this.trackAssignments.indexOf(assignment),
              1
            );
            this.selectedTrackId = null;
            this.selectedFrom = null;
            await this.updateDetections();
          },
          (e) => {
            this.alertService.showError(
              'Could not delete track assignment: ' + e.message
            );
          }
        );
    }
  }

  async prepareTrackThumbnails(trackId: string) {
    this.objectTrackingService
      .getPlayerThumbnails(this.game._id, this.period, trackId, 13)
      .subscribe((thumbnails) => {
        this.thumbnails = thumbnails;
      });
  }

  hideThumbnails() {
    this.thumbnails = [];
  }

  selectNextUnassignedTrack() {
    const unassignedTracks = this.detections.filter(
      (t) => !this.findTrackAssignment(t.trackId)
    );
    let nextTrack;
    if (this.selectedTrackId) {
      nextTrack = unassignedTracks.find(
        (t) => t.trackId > this.selectedTrackId
      );
      if (!nextTrack) {
        nextTrack = unassignedTracks.find(
          (t) => t.trackId < this.selectedTrackId
        );
      }
    } else {
      nextTrack = unassignedTracks.find(
        (t) => t.trackId !== this.selectedTrackId
      );
    }
    if (nextTrack) {
      this.selectedTrackId = nextTrack.trackId;
    }
  }

  private findTrackAssignment(trackId: string): TrackAssignment {
    const candidates = this.trackAssignments.filter(
      (t) => t.trackId === trackId
    );
    candidates.sort((a, b) => {
      if (a.from && !b.from) {
        return -1;
      }
      if (b.from && !a.from) {
        return 1;
      }
      if (a.from && b.from) {
        return b.from - a.from;
      }
      return 0;
    });
    return candidates.length > 0 ? candidates[0] : null;
  }

  splitTrack() {
    if (this.selectedFrom == null) {
      this.selectedFrom = this._frameNumber;
    } else {
      this.selectedFrom = null;
    }
  }

  private updateActiveTrackAssignments() {
    this.detections.forEach((detection) => {
      detection.trackAssignment = this.findTrackAssignment(detection.trackId);
    });
  }

  private resetStatus() {
    this.selectedTrackId = null;
    this.selectedPlayer = null;
    this.selectedFrom = null;
  }

  mouseClick(event: MouseEvent) {
    if (!this.detectionMode) {
      return;
    }

    if (this.detectionComplete) {
      // reset detection
      this.detectionStart = null;
      this.detectionEnd = null;
      this.detectionComplete = false;
      this.detectionScaleX = null;
    }

    if (!this.detectionStart) {
      this.updateScale(event.target as SVGElement);
      this.detectionStart = [
        event.offsetX / this.detectionScaleX,
        event.offsetY / this.detectionScaleY
      ];
    } else {
      this.detectionComplete = true;
    }
  }

  mouseMove(event: MouseEvent) {
    if (!this.detectionMode || !this.detectionStart || this.detectionComplete) {
      return;
    }

    this.updateScale(event.target as SVGElement);
    this.detectionEnd = [
      event.offsetX / this.detectionScaleX,
      event.offsetY / this.detectionScaleY
    ];
  }

  private updateScale(targetElement: SVGElement) {
    this.detectionScaleX = targetElement.clientWidth / this._videoWidth;
    this.detectionScaleY = targetElement.clientHeight / this._videoHeight;
  }

  get detectionWidth() {
    return this.detectionEnd[0] - this.detectionStart[0];
  }

  get detectionHeight() {
    return this.detectionEnd[1] - this.detectionStart[1];
  }

  clickDetection(t: Detection) {
    if (!t.trackAssignment?.player || !t.trackAssignment?.team) {
      return;
    }

    this.store.dispatch(
      playerNumberChange({ playerNumber: t.trackAssignment?.player })
    );

    this.store.dispatch(teamChange({ team: t.trackAssignment?.team }));
  }
}
