import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Game } from '../domain/game';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-game-export-dialog',
  templateUrl: './game-export-dialog.component.html',
  styleUrls: ['./game-export-dialog.component.css']
})
export class GameExportDialogComponent implements OnInit {
  game: Game;
  publish: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gameService: GameService
  ) {}

  ngOnInit() {
    this.game = this.data.game;
    this.publish[this.game.homeTeamId] = '';
    this.publish[this.game.awayTeamId] = '';
  }

  downloadFullGame(teamId: string) {
    this.gameService
      .getGameEventsAsXML(this.game, teamId, true, true)
      .subscribe((data) => {
        const filename =
          'full_export_' + this.game.gameId + '_' + teamId + '_49ing.xml';
        this.downloadFile(data, filename);
      });
  }

  downloadTOI(teamId: string) {
    this.gameService
      .getGameEventsAsXML(this.game, teamId, true, false)
      .subscribe((data) => {
        const filename =
          'time_on_ice_' + this.game.gameId + '_' + teamId + '_49ing.xml';
        this.downloadFile(data, filename);
      });
  }

  downloadGameActions(teamId: string) {
    this.gameService
      .getGameEventsAsXML(this.game, teamId, false, true)
      .subscribe((data) => {
        const filename =
          'game_actions_' + this.game.gameId + '_' + teamId + '_49ing.xml';
        this.downloadFile(data, filename);
      });
  }

  publishFullGame(teamId: string) {
    this.gameService.publishFullGame(this.game, teamId, true, true).subscribe(
      () => {
        this.publish[teamId] = 'success';
        console.log('game published for team', teamId);
      },
      (err) => {
        this.publish[teamId] = 'error';
        console.log(
          `game publishing failed for team ${teamId}: ${err.message}`
        );
      }
    );
  }

  private downloadFile(data: string, filename: string, encoding = 'utf-8') {
    let blob;
    if (encoding === 'utf-8') {
      blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    } else {
      blob = new Blob([data], { type: 'text/csv;charset=' + encoding + ';' });
    }
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
