<h2 mat-dialog-title>Sync Season to Cockpit</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="season">
          <mat-option *ngFor="let season of seasons | async" [value]="season"
            >{{ season - 1 }}/{{ season }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="league">
          <mat-option *ngFor="let l of leagues" [value]="l.sihfId">{{
            l.shortName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="[null, null]" class="close-button"
    >Cancel</button
  >
  <button
    mat-raised-button
    color="warn"
    class="sync-button"
    (click)="syncToCockpit()"
    >Sync to Cockpit</button
  >
</mat-dialog-actions>
