import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';
import { ValidationCheck } from '../domain/validation-check';

@Component({
  selector: 'app-game-validation-dialog',
  templateUrl: './game-validation-dialog.component.html',
  styleUrls: ['./game-validation-dialog.component.css']
})
export class GameValidationDialogComponent implements OnInit {
  game: Game;
  validations: ValidationCheck[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.game = this.data.game;
    this.validations = this.data.validations;
  }
}
