<h2 mat-dialog-title>Sync goals with SIHF</h2>
<mat-dialog-content>
  <p
    >Imports missing goals and overwrites some attributes of existing goals with
    SIHF data</p
  >
  <div [formGroup]="form">
    <mat-checkbox
      (change)="selectAll($event)"
      [checked]="selected"
      class="select-all"
      >Select all</mat-checkbox
    >
    <mat-checkbox formControlName="scorer">Goal Scorer</mat-checkbox>
    <mat-checkbox formControlName="assists">Assist 1 & 2</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined" class="close-button"
    >Cancel</button
  >
  <button mat-raised-button color="accent" (click)="execute()"
    >Sync Goals</button
  >
</mat-dialog-actions>
