import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-import-sihf-goals-dialog',
  templateUrl: './import-sihf-goals-dialog.component.html',
  styleUrls: ['./import-sihf-goals-dialog.component.css']
})
export class ImportSIHFGoalsDialogComponent implements OnInit {
  game: Game;
  form: FormGroup<{
    scorer: FormControl<boolean | null>;
    assists: FormControl<boolean | null>;
  }>;

  selected = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImportSIHFGoalsDialogComponent>
  ) {}

  ngOnInit() {
    this.game = this.data.game;
    this.form = new FormGroup({
      scorer: new FormControl(true),
      assists: new FormControl(true)
    });
  }

  execute() {
    this.dialogRef.close(this.form.value);
  }

  selectAll(e: MatCheckboxChange) {
    this.selected = e.checked;
    Object.keys(this.form.controls).forEach((control) => {
      this.form.controls[control].setValue(this.selected);
    });
  }
}
