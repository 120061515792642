import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-foint-dialog',
  templateUrl: './update-foint.component.html',
  styleUrls: ['./update-foint.component.css']
})
export class UpdateFOINTDialogComponent {
  periods = 'all';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateFOINTDialogComponent>
  ) {}

  confirm() {
    this.dialogRef.close({
      periods: this.periods
    });
  }
}
