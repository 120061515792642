import { Game } from './game';
import { ReviewRemark } from './review-remark';

export enum ReviewStatus {
  TO_REVIEW = 'to_review',
  REVIEWING = 'reviewing',
  CHECKING = 'checking',
  DONE = 'done'
}

export interface Review {
  _id: string;

  type: '49ing';
  eventType: 'shot' | 'foint';
  gameId: string; // 49ing gameId
  sihfGameId: string; // SIHF gameId
  masterGameId: string;

  filename: string;
  person: string;
  status: ReviewStatus;
  lastExport: string;

  insertDate: string;
  modificationDate: string;

  // joined by backend

  game: Game;
  masterGame: Game;
  events: ReviewRemark[];
}
