<div class="container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
        ><a [routerLink]="['/reviews']">Reviews</a></li
      >
      <li class="breadcrumb-item"
        >{{ review?.game.date | date : 'yyyy-MM-dd' }}
        {{ review?.game?.homeTeam }} - {{ review?.game?.awayTeam }}</li
      >
      <li class="breadcrumb-item active" aria-current="page">Game Actions</li>
    </ol>
  </nav>

  <div class="title-bar">
    <div class="action-button-right">
      <button
        mat-raised-button
        color="accent"
        *ngIf="review?.status === 'to_review'"
        (click)="startReview()"
      >
        <i class="material-icons icon-button">play_arrow</i> Start Review
      </button>
      <button
        mat-raised-button
        color="accent"
        *ngIf="review?.status === 'reviewing'"
        (click)="reviewComplete()"
      >
        <i class="material-icons icon-button">stop</i> Complete Review
      </button>
      <button
        mat-raised-button
        color="accent"
        *ngIf="review?.status === 'checking'"
        (click)="checkingComplete()"
      >
        <i class="material-icons icon-button">check</i> Complete Checking
      </button>
      <button
        mat-raised-button
        color="accent"
        *ngIf="review?.status === 'done'"
        [disabled]="true"
      >
        <i class="material-icons icon-button">check</i> Done
      </button>
    </div>
  </div>

  <div class="events">
    <div class="events-table">
      <table class="mdl-data-table mdl-js-data-table" style="width: 100%">
        <thead>
          <tr>
            <th class="mdl-data-table__cell--non-numeric">Period</th>
            <th class="mdl-data-table__cell--non-numeric">Video Time</th>
            <th class="mdl-data-table__cell--non-numeric">Game Clock</th>
            <th class="mdl-data-table__cell--non-numeric">Time Diff</th>
            <th class="mdl-data-table__cell--non-numeric">Player</th>
            <th class="mdl-data-table__cell--non-numeric">Event Type</th>
            <th class="mdl-data-table__cell--non-numeric">Detail</th>
            <th class="mdl-data-table__cell--non-numeric">Position</th>

            <th class="mdl-data-table__cell--non-numeric">
              <label>Diff:</label>
              <mat-select
                [(value)]="filters['diff']"
                (valueChange)="filterChanged()"
                style="width: 100px"
              >
                <mat-option>All</mat-option>
                <mat-option value="0">0</mat-option>
                <mat-option value="1">&gt; 1</mat-option>
              </mat-select>
            </th>
            <th class="mdl-data-table__cell--non-numeric">
              <label>Error:</label>
              <mat-select
                [(value)]="filters['error']"
                (valueChange)="filterChanged()"
                style="width: 100px"
              >
                <mat-option>All</mat-option>
                <mat-option value="reviewed_game">Reviewed</mat-option>
                <mat-option value="master_game">Master</mat-option>
                <mat-option value="both_wrong">Both wrong</mat-option>
                <mat-option value="both_ok">Both OK</mat-option>
                <mat-option value="not_sure">Not Sure</mat-option>
                <mat-option value="none">No Error</mat-option>
              </mat-select>
            </th>
            <th class="mdl-data-table__cell--non-numeric"
              >Remarks Reviewed Game</th
            >
            <th class="mdl-data-table__cell--non-numeric"
              >Remarks Master Game</th
            >
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let match of events
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage };
              index as i
            "
            [class.diff]="hasDiff(match[0], match[1])"
            [class.source-only]="!match[0]"
            [class.target-only]="!match[1]"
            (click)="showEventDetails(i)"
          >
            <td class="mdl-data-table__cell">
              {{ match[0]?.period }} / {{ match[1]?.period }}
            </td>

            <td
              class="mdl-data-table__cell"
              [class.diff]="timeDiffAbs(match[0], match[1]) > 5"
            >
              {{ match[0]?.videoTime | hhmmss }} /
              {{ match[1]?.videoTime | hhmmss }}
            </td>

            <td
              class="mdl-data-table__cell"
              [class.diff]="timeDiffAbs(match[0], match[1]) > 5"
            >
              {{ match[0]?.gameTime | countdown : match[0]?.period }} /
              {{ match[1]?.gameTime | countdown : match[1]?.period }}
            </td>

            <td> {{ timeDiff(match[0], match[1]) | number : '0.1-1' }}s </td>

            <td
              class="mdl-data-table__cell--non-numeric"
              [class.diff]="playerDiff(match[0], match[1])"
            >
              {{ match[0]?.playerNumber }} / {{ match[1]?.playerNumber }}
            </td>

            <td class="mdl-data-table__cell--non-numeric">
              {{ match[0]?.eventType }} / {{ match[1]?.eventType }}
            </td>

            <td
              class="mdl-data-table__cell--non-numeric"
              [class.diff]="outcomeDiff(match[0], match[1])"
            >
              {{ outcome(match[0], match[1]) }}
            </td>

            <td
              class="mdl-data-table__cell--non-numeric"
              [class.diff]="positionDiff(match[0], match[1]) > 5"
            >
              {{ positionDiff(match[0], match[1]) | number : '0.1-1' }}m
            </td>

            <td class="mdl-data-table__cell">
              {{ match[0] && match[1] ? diffNum(match[0], match[1]) : '-' }}
            </td>
            <td class="mdl-data-table__cell--non-numeric">{{
              match[2]?.error
            }}</td>
            <td class="mdl-data-table__cell--non-numeric">{{
              match[2]?.sourceEventRemarks
            }}</td>
            <td class="mdl-data-table__cell--non-numeric">{{
              match[2]?.targetEventRemarks
            }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="12">
              <pagination-controls
                (pageChange)="currentPage = $event"
              ></pagination-controls>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
