import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AlertService {
  constructor(private snackbar: MatSnackBar) {}

  public showError(message: string) {
    this.snackbar.open(message, null, {
      duration: 10000,
      panelClass: 'error'
    });
  }

  public showWarning(message: string) {
    this.snackbar.open(message, null, {
      duration: 5000,
      panelClass: 'warning'
    });
  }

  public showInfo(message: string, action?: string) {
    return this.snackbar
      .open(message, action, { duration: 2000 })
      .afterDismissed()
      .pipe(map((res) => res.dismissedByAction));
  }

  public showErrorWithAction(
    message: string,
    duration = 2000,
    action?: string
  ): Observable<boolean> {
    return this.snackbar
      .open(message, action, { duration, panelClass: 'error' })
      .afterDismissed()
      .pipe(map((res) => res.dismissedByAction));
  }
}
