<div class="container-fluid">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
        ><a [routerLink]="['/reviews']">Reviews</a></li
      >
      <li class="breadcrumb-item"
        >{{ review?.game.date | date : 'yyyy-MM-dd' }}
        {{ review?.game?.homeTeam }} - {{ review?.game?.awayTeam }}</li
      >
      <li class="breadcrumb-item"
        ><a
          [routerLink]="['/reviews', review._id, 'game-actions']"
          [queryParams]="route.snapshot.queryParams"
          >Game Actions</a
        ></li
      >
      <li class="breadcrumb-item active" aria-current="page">{{
        index + 1
      }}</li>
    </ol>
  </nav>

  <a
    mat-raised-button
    color="primary"
    [disabled]="(this.game.videos ?? []).length === 0"
    [routerLink]="['/games', game._id, 'video-player']"
    target="_blank"
    style="margin-bottom: 24px; float: right"
  >
    <mat-icon>open_in_new</mat-icon>
    Video Player
  </a>

  <div class="row">
    <div class="col-9 details-table">
      <table class="mdl-data-table mdl-js-data-table" style="width: 100%">
        <thead>
          <tr>
            <th style="width: 20%"> </th>
            <th style="width: 40%" class="mdl-data-table__cell--non-numeric"
              >Reviewed</th
            >
            <th style="width: 40%" class="mdl-data-table__cell--non-numeric"
              >Master</th
            >
          </tr>
        </thead>
        <tbody [class.diff]="hasDiff(sourceEvent, targetEvent)">
          <tr>
            <td>ID</td>
            <td
              class="mdl-data-table__cell"
              [class.target-only]="!sourceEvent"
              >{{ sourceEvent?._id }}</td
            >
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?._id }}</td
            >
          </tr>
          <tr>
            <td>Period</td>
            <td
              class="mdl-data-table__cell"
              [class.target-only]="!sourceEvent"
              >{{ sourceEvent?.period }}</td
            >
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?.period }}</td
            >
          </tr>
          <tr
            [class.diff]="timeDiffAbs(sourceEvent, targetEvent) > 10"
            title="Time Diff: {{
              timeDiffAbs(sourceEvent, targetEvent) | safeNumber : '.1-1'
            }}s"
          >
            <td>Game Clock</td>
            <td
              class="mdl-data-table__cell"
              [class.target-only]="!sourceEvent"
              >{{ sourceEvent?.gameTime | countdown : sourceEvent?.period }}</td
            >
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?.gameTime | countdown : targetEvent?.period }}</td
            >
          </tr>
          <tr>
            <td>Video Time</td>
            <td class="mdl-data-table__cell" [class.target-only]="!sourceEvent">
              <button
                mat-button
                class="action-button"
                (click)="updateVideoTime()"
                [disabled]="!sourceEvent"
                >Adjust</button
              >
              <a [routerLink]="[]" (click)="seekVideo(sourceEvent.videoTime)">{{
                sourceEvent?.videoTime | hhmmss
              }}</a>
            </td>
            <td class="mdl-data-table__cell" [class.source-only]="!targetEvent"
              >-</td
            >
          </tr>
          <tr>
            <td>Team</td>
            <td
              class="mdl-data-table__cell"
              [class.target-only]="!sourceEvent"
              >{{ sourceEvent?.team }}</td
            >
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?.team }}</td
            >
          </tr>
          <tr [class.diff]="playerDiff(sourceEvent, targetEvent)">
            <td>Player</td>
            <td class="mdl-data-table__cell" [class.target-only]="!sourceEvent">
              <mat-form-field *ngIf="sourceEvent" [formGroup]="playerFormGroup">
                <input
                  matInput
                  [matAutocomplete]="auto"
                  formControlName="playerNumber"
                  placeholder="Number - Lastname"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [autoActiveFirstOption]="true"
                  (optionSelected)="playerSelected($event.option.viewValue)"
                >
                  <mat-option *ngFor="let p of playerSuggestions">{{
                    p
                  }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?.playerNumber }}</td
            >
          </tr>
          <tr
            [class.diff]="positionDiff(sourceEvent, targetEvent) > 5"
            title="Position Diff: {{
              positionDiff(sourceEvent, targetEvent) | safeNumber : '.1-1'
            }}m"
          >
            <td>Position</td>
            <td class="mdl-data-table__cell" [class.target-only]="!sourceEvent">
              <img
                *ngIf="sourceEvent?.xPosition"
                align="left"
                src="/assets/cross_green.png"
              />
              {{ sourceEvent?.xPosition | safeNumber : '.1-1' }}/{{
                sourceEvent?.yPosition | safeNumber : '.1-1'
              }}
            </td>
            <td class="mdl-data-table__cell" [class.source-only]="!targetEvent">
              <img
                *ngIf="targetEvent?.xPosition"
                align="left"
                src="/assets/cross_red.png"
              />
              {{ targetEvent?.xPosition | safeNumber : '.1-1' }}/{{
                targetEvent?.yPosition | safeNumber : '.1-1'
              }}
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td
              class="mdl-data-table__cell"
              [class.target-only]="!sourceEvent"
              >{{ sourceEvent?.eventType }}</td
            >
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              >{{ targetEvent?.eventType }}</td
            >
          </tr>
          <tr [class.diff]="outcomeDiff(sourceEvent, targetEvent)">
            <td>Detail</td>
            <td class="mdl-data-table__cell" [class.target-only]="!sourceEvent">
              <mat-form-field *ngIf="sourceEvent" [formGroup]="detailFormGroup">
                <input
                  matInput
                  [matAutocomplete]="autoDetail"
                  formControlName="detail"
                  placeholder="Detail"
                />
                <mat-autocomplete
                  #autoDetail="matAutocomplete"
                  [autoActiveFirstOption]="true"
                  (optionSelected)="outcomeSelected($event.option.viewValue)"
                >
                  <mat-option *ngFor="let p of details">{{ p }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
            <td
              class="mdl-data-table__cell"
              [class.source-only]="!targetEvent"
              style="min-width: 150px"
              >{{ getDetail(targetEvent) }}</td
            >
          </tr>
          <tr>
            <td>Error</td>
            <td class="mdl-data-table__cell">
              <button
                mat-button
                class="action-button"
                (click)="deleteSourceEvent(sourceEvent)"
                *ngIf="error === 'reviewed_game' || error === 'both_wrong'"
                >Delete 49ing Event</button
              >
            </td>
            <td class="mdl-data-table__cell">
              <mat-form-field>
                <mat-select
                  [(value)]="error"
                  (selectionChange)="
                    saveError(sourceEvent, targetEvent, $event.value)
                  "
                >
                  <mat-option value=""></mat-option>
                  <mat-option value="reviewed_game">Reviewed</mat-option>
                  <mat-option value="master_game">Master</mat-option>
                  <mat-option value="both_wrong">Both</mat-option>
                  <mat-option value="both_ok">Both OK</mat-option>
                  <mat-option value="not_sure">Not Sure</mat-option>
                  <mat-option value="none">No Error</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>Remarks</td>
            <td class="mdl-data-table__cell" [class.target-only]="!sourceEvent">
              <mat-form-field *ngIf="sourceEvent">
                <textarea
                  matInput
                  placeholder=""
                  [(ngModel)]="sourceEventRemarks"
                  rows="4"
                  (blur)="saveSourceEvent()"
                  (keydown)="remarksFieldKeyDown($event)"
                ></textarea>
              </mat-form-field>
            </td>
            <td class="mdl-data-table__cell">
              <mat-form-field
                *ngIf="targetEvent"
                [class.source-only]="!targetEvent"
              >
                <textarea
                  matInput
                  placeholder=""
                  [(ngModel)]="targetEventRemarks"
                  rows="4"
                  (blur)="saveTargetEvent()"
                  (keydown)="remarksFieldKeyDown($event)"
                ></textarea>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" style="text-align: center">
              <button
                mat-button
                color="primary"
                class="action-button"
                (click)="previousEvent()"
                [disabled]="index === 0"
                ><mat-icon>skip_previous</mat-icon></button
              >
              {{ index + 1 }} / {{ count() }}
              <button
                mat-button
                color="primary"
                class="action-button"
                (click)="nextEvent()"
                [disabled]="index + 1 >= eventCount"
                ><mat-icon>skip_next</mat-icon></button
              >
            </th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="col-3" style="min-width: 340px">
      <div style="position: absolute; margin-left: 15px">
        <app-ice-rink
          [game]="review?.game"
          (edit)="updateCoordinates($event)"
        ></app-ice-rink>
        <div class="puck">
          <img src="/assets/cross_red.png" />
          <p class="hint" style="display: inline">Master Position</p>
          <img src="/assets/cross_green.png" />
          <p class="hint" style="display: inline">Reviewed Position</p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grid">
    <div class="col-0" style="padding-left: 25px"> </div>
  </div>
</div>
