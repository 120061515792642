import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
/**
 * This component already exists in data-cockpit
 */
@Component({
  selector: 'app-video-player-trim-controls',
  templateUrl: './video-player-trim-controls.component.html',
  styleUrls: ['./video-player-trim-controls.component.scss']
})
export class VideoPlayerTrimControlsComponent {
  @ViewChild('startTrim') startTrimRef: ElementRef;
  @ViewChild('endTrim') endTrimRef: ElementRef;
  @ViewChild('customSeekBar') customSeekBarRef: ElementRef;

  @Input() leftTime: number;
  @Input() rightTime: number;

  @Input() progressBarCSSWidth: string;
  @Input() progressBarCSSLeft: string;
  @Input() progressBarCSSRight: string;

  @Input() seekableBarCSSLeft: string;
  @Input() seekableBarCSSRight: string;

  @Input() leftTitle: number;
  @Input() rightTitle: number;

  @Input() startTrimCSSLeft: string;
  @Input() endTrimCSSLeft: string;

  @Output() draggingLeftTrim: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() draggingRightTrim: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() extendTrimTime: EventEmitter<'START' | 'END'> = new EventEmitter<
    'START' | 'END'
  >();

  onDragStartLeftTrim(event: MouseEvent) {
    this.draggingLeftTrim.emit(true);
  }

  onDragEndLeftTrim(event: MouseEvent) {
    this.draggingLeftTrim.emit(false);
  }

  onDragStartRightTrim(event: MouseEvent) {
    this.draggingRightTrim.emit(true);
  }

  onDragEndRightTrim(event: MouseEvent) {
    this.draggingRightTrim.emit(false);
  }

  get startTrimPosition() {
    return this.startTrimRef.nativeElement.getBoundingClientRect();
  }

  get endTrimPosition() {
    return this.endTrimRef.nativeElement.getBoundingClientRect();
  }

  get seekBarPosition() {
    return this.customSeekBarRef.nativeElement.getBoundingClientRect();
  }
}
