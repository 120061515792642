import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Game } from '../domain/game';
import {
  CollectionService,
  GameQualityCheck
} from '../services/collection.service';

@Component({
  selector: 'app-game-quality-report-dialog',
  templateUrl: './game-quality-report-dialog.component.html',
  styleUrls: ['./game-quality-report-dialog.component.css']
})
export class GameQualityReportDialogComponent implements OnInit {
  game: Game;
  qualityChecks: GameQualityCheck[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private collectionService: CollectionService
  ) {}

  ngOnInit(): void {
    this.game = this.data.game as Game;
    this.loadData();
  }

  loadData() {
    this.qualityChecks = null;
    this.collectionService
      .getGameQuality(this.game?._id)
      .subscribe((collectionEffort) => {
        this.qualityChecks = collectionEffort;
      });
  }
}
