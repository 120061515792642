<div class="puck-possession-toggle">
  <div
    [class.active]="game?.homeTeam === puckPossessionState?.team"
    class="puck-possession-state"
    [title]="'{{ game?.homeTeam }} Puck Possession'"
  ></div>
  <div
    [class.active]="'neutral' === puckPossessionState?.team"
    class="puck-possession-state"
    title="Neutral Puck Possession"
  ></div>
  <div
    [class.active]="game?.awayTeam === puckPossessionState?.team"
    class="puck-possession-state"
    [title]="'{{ game?.awayTeam }} Puck Possession'"
  ></div>
</div>
