<div class="content container-fluid">
  <div class="row">
    <div class="col-9">
      <app-game-navigation [game]="game"></app-game-navigation>

      <div class="loading" *ngIf="!homePlayers">
        <img src="assets/loading.svg" alt="loading" />
        <h3>Loading events...</h3>
      </div>

      <mat-tab-group mat-stretch-tabs (selectedTabChange)="changeTab($event)">
        <mat-tab [label]="game.homeTeam">
          <div class="team" *ngIf="homePlayers" style="margin-top: 16px">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="strictPositions[0]"
              title="Replace player on same position. e.g. Left defender for left defender."
              >Strict positions</mat-slide-toggle
            >

            <app-warning
              *ngIf="homeWarnings"
              [messages]="homeWarnings"
            ></app-warning>

            <div class="row no-gutters" *ngFor="let line of [1, 2, 3, 4, 5]">
              <div class="line" (click)="toggleDefenseLine(line, game.homeTeam)"
                >{{ line }}D</div
              >
              <div
                class="col-2"
                *ngFor="
                  let player of homePlayers.defenders[line];
                  let odd = odd;
                  let even = even
                "
              >
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  [ngClass]="{ odd: odd, even: even }"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>

              <div class="line" (click)="toggleForwardLine(line, game.homeTeam)"
                >{{ line }}F</div
              >
              <div
                class="col-2"
                *ngFor="
                  let player of homePlayers.forwards[line];
                  let odd = odd;
                  let even = even
                "
              >
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  [ngClass]="{ odd: odd, even: even }"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="line">GK</div>
              <div class="col-2" *ngFor="let player of homePlayers.goalkeepers">
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="game.awayTeam">
          <div class="team" *ngIf="awayPlayers" style="margin-top: 16px">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="strictPositions[1]"
              title="Replace player on same position. e.g. Left defender for left defender."
              >Strict positions</mat-slide-toggle
            >

            <app-warning
              *ngIf="awayWarnings"
              [messages]="awayWarnings"
            ></app-warning>

            <div class="row no-gutters" *ngFor="let line of [1, 2, 3, 4, 5]">
              <div class="line" (click)="toggleDefenseLine(line, game.awayTeam)"
                >{{ line }}D</div
              >
              <div
                class="col-2"
                *ngFor="
                  let player of awayPlayers.defenders[line];
                  let odd = odd;
                  let even = even
                "
              >
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  [ngClass]="{ odd: odd, even: even }"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>

              <div class="line" (click)="toggleForwardLine(line, game.awayTeam)"
                >{{ line }}F</div
              >
              <div
                class="col-2 player forward"
                *ngFor="
                  let player of awayPlayers.forwards[line];
                  let odd = odd;
                  let even = even
                "
              >
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  [ngClass]="{ odd: odd, even: even }"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="line">GK</div>
              <div class="col-2" *ngFor="let player of awayPlayers.goalkeepers">
                <app-player-button
                  [player]="player"
                  [shift]="activeShifts[player.playerNumber]"
                  [selected]="player.playerNumber === selectedPlayerNumber"
                  (toggle)="togglePlayer($event)"
                >
                </app-player-button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="col-3 right">
      Video Time:
      <span class="video-time">{{ videoTime * 10 | timecode }}</span>

      <hr />

      <mat-select
        id="homeTeamEmptyNet"
        class="empty-net"
        [(ngModel)]="isHomeTeamEmptyNet"
        (valueChange)="emptyNetChanged(game.homeTeam)"
        placeholder="Home Empty Net"
        [disabled]="playing"
      >
        <mat-option [value]="false">Home Empty Net: No</mat-option>
        <mat-option [value]="true">Home Empty Net: Yes</mat-option>
      </mat-select>

      <mat-select
        id="awayTeamEmptyNet"
        class="empty-net"
        [(ngModel)]="isAwayTeamEmptyNet"
        (valueChange)="emptyNetChanged(game.awayTeam)"
        placeholder="Away Empty Net"
        [disabled]="playing"
      >
        <mat-option [value]="false">Away Empty Net: No</mat-option>
        <mat-option [value]="true">Away Empty Net: Yes</mat-option>
      </mat-select>

      <hr />

      <div style="display: flex">
        <mat-select
          id="period"
          class="period"
          [(ngModel)]="period"
          placeholder="Period"
          [disabled]="playing"
          (valueChange)="periodChanged($event)"
          style="margin-right: 10px"
        >
          <mat-option value="1">P1</mat-option>
          <mat-option value="2">P2</mat-option>
          <mat-option value="3">P3</mat-option>
          <mat-option value="4">P4</mat-option>
          <mat-option value="5">P5</mat-option>
          <mat-option value="6">P6</mat-option>
          <mat-option value="7">P7</mat-option>
        </mat-select>

        <app-game-clock style="margin-right: 10px"></app-game-clock>
        <app-strength-state></app-strength-state>
      </div>

      <hr />

      <app-events-overview
        [game]="game"
        [eventType]="'time_on_ice'"
        [events]="events"
        (seek)="seekEvent($event)"
        (delete)="deleteEvent($event)"
      >
      </app-events-overview>
    </div>
  </div>
</div>
