import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { GameService } from '../services/game.service';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Game } from '../domain/game';

@Injectable()
export class GameResolver implements Resolve<Game> {
  constructor(private gameService: GameService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Game> {
    const gameId = route.params['gameId'];
    if (gameId === 'new') {
      return from([new Game({ progress: 'before_start' })]);
    }
    return this.gameService.getGame(gameId);
  }
}
