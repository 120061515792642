<h4 mat-dialog-title
  >Collection Effort {{ game.homeTeam }} - {{ game.awayTeam }}</h4
>
<mat-dialog-content>
  <div class="row">
    <div class="col-md-3">
      <label for="cutoff">Pause Cut-off (sec)</label>
    </div>
    <div class="col-md-2">
      <input
        type="number"
        id="cutoff"
        class="form-control"
        [(ngModel)]="cutoff"
        (change)="updateEffort()"
      />
    </div>
  </div>

  <table *ngIf="collectionEffort" style="margin-top: 24px">
    <tr>
      <th>Unit</th>
      <th>Period</th>
      <th>Collector</th>
      <th class="number">#Events</th>
      <th class="number">Pauses</th>
      <th class="number">Effort</th>
    </tr>
    <tr *ngFor="let effort of collectionEffort">
      <td>{{ effort.unit }}</td>
      <td>{{ effort.periods }}</td>
      <td>{{ effort.collector }}</td>
      <td class="number"
        >{{ effort.consideredEvents }} / {{ effort.totalEvents }}</td
      >
      <td class="number">{{ effort.effortSecondsIgnored | hhmmss }}</td>
      <td class="number">{{ effort.effortSeconds | hhmmss }}</td>
    </tr>
    <tr>
      <td class="total">Total</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="total number">{{ totalEffort | hhmmss }}</td>
    </tr>
  </table>
</mat-dialog-content>

<mat-progress-bar
  *ngIf="!collectionEffort"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
