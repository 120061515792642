<h2 mat-dialog-title>SIHF Export</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col"
      ><span class="team">{{ game?.homeTeam }}</span></div
    >
    <div class="col">
      <button
        style="height: 50px"
        mat-flat-button
        (click)="downloadFullGame(game.homeTeamId)"
        ><mat-icon>file_download</mat-icon>TOI + Game Actions</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="downloadTOI(game.homeTeamId)"
        ><mat-icon>file_download</mat-icon>TOI</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="downloadGameActions(game.homeTeamId)"
        ><mat-icon>file_download</mat-icon>Game Actions</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="publishFullGame(game.homeTeamId)"
        ><mat-icon>cloud</mat-icon>Publish</button
      >
      <mat-icon *ngIf="publish[game.homeTeamId] === 'success'" class="success"
        >check_circle</mat-icon
      >
      <mat-icon *ngIf="publish[game.homeTeamId] === 'error'" class="error"
        >cancel</mat-icon
      >
    </div>
  </div>

  <div class="row">
    <div class="col"
      ><span class="team">{{ game?.awayTeam }}</span></div
    >
    <div class="col">
      <button
        style="height: 50px"
        mat-flat-button
        (click)="downloadFullGame(game.awayTeamId)"
        ><mat-icon>file_download</mat-icon>TOI + Game Actions</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="downloadTOI(game.awayTeamId)"
        ><mat-icon>file_download</mat-icon>TOI</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="downloadGameActions(game.awayTeamId)"
        ><mat-icon>file_download</mat-icon>Game Actions</button
      >
    </div>
    <div class="col">
      <button mat-flat-button (click)="publishFullGame(game.awayTeamId)"
        ><mat-icon>cloud</mat-icon>Publish</button
      >
      <mat-icon *ngIf="publish[game.awayTeamId] === 'success'" class="success"
        >check_circle</mat-icon
      >
      <mat-icon *ngIf="publish[game.awayTeamId] === 'error'" class="error"
        >cancel</mat-icon
      >
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" class="close-button"
    >Close</button
  >
</mat-dialog-actions>
