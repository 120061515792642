import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Official } from '../domain/official';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
import { isObject, toLower, trim } from 'lodash/fp';
import { OfficialService } from '../services/official.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-official-input',
  templateUrl: './official-input.component.html',
  styleUrls: ['./official-input.component.css']
})
export class OfficialInputComponent implements OnInit {
  @Input() officialLabel: string;

  #official?: Official;
  @Input() set official(official: Official) {
    this.#official = official;
    this.officialFormControl.setValue(official, {
      emitEvent: false
    });
  }

  get official(): Official {
    return this.#official;
  }

  @Input() unknownOfficialInfo!: string | undefined;

  @Output() officialIdChange = new EventEmitter<number | null>();

  officialFormControl = new FormControl<Official>(undefined, []);
  filteredOfficials$: Observable<Official[]>;

  constructor(
    private officialService: OfficialService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.filteredOfficials$ = this.officialFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      filter((value) => isObject(value) === false),
      map(toLower),
      map(trim),
      switchMap((value: string) => {
        if (value.length === 0) {
          return of([]);
        } else if (this.containsWhitespace(value)) {
          this.alertService.showWarning('Spaces are not allowed');
          return of([]);
        } else {
          return this.officialService.searchOfficialsByName(value);
        }
      })
    );
  }

  private containsWhitespace(value: string) {
    const regexp: RegExp = /\s/;
    return regexp.test(value);
  }

  clear() {
    this.official = null;
    this.officialFormControl.setValue(null);
    this.officialIdChange.emit(undefined);
  }

  clearIfNotSet() {
    if (!this.isOfficialSet()) {
      this.clear();
    }
  }

  private isOfficialSet() {
    return !!this.official;
  }

  isGameOfficialUnknown() {
    return !this.isOfficialSet() && this.unknownOfficialInfo;
  }

  gameOfficialIsUnknown() {
    if (this.isGameOfficialUnknown()) {
      return `'${this.unknownOfficialInfo}' does not exist`;
    } else {
      return undefined;
    }
  }

  selectionChange(official: Official) {
    this.officialFormControl.setValue(official, {
      emitEvent: false
    });
    this.official = official;
    this.officialIdChange.emit(official?.id ?? undefined);
  }

  officialFullName(official: Official): string {
    if (!official) {
      return undefined;
    }
    return `${official.lastName} ${official.firstName}`;
  }

  tooltip() {
    if (!this.official) {
      return undefined;
    }
    let tooltip = `Internal ID: ${this.official.id}`;
    if (this.official.sihfId) {
      tooltip = `SIHF-ID: ${this.official.sihfId} (${tooltip})`;
    }
    return tooltip;
  }
}
