import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  okButtonCaption: string;
  cancelButtonCaption: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.title = data?.title || 'Confirm action';
    this.message = data?.message || 'Are you sure want to proceed?';
    this.okButtonCaption = data?.okButtonCaption || 'Ok';
    this.cancelButtonCaption = data?.cancelButtonCaption || 'Cancel';
  }

  close(success: boolean) {
    this.dialogRef.close(success);
  }
}
