import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { League } from '../domain/league';
import { Observable } from 'rxjs';

export interface LeagueSummary {
  _id: string;
  league: League;
  byStatus: any;
}

@Injectable()
export class LeagueService {
  private baseUrl = environment.API_HOST + '/api/leagues';

  constructor(private http: HttpClient) {}

  getLeagues() {
    return this.http.get<League[]>(this.baseUrl);
  }

  getSummary(
    season: string,
    seasonType: string,
    collector: string,
    hideWithoutGames: boolean
  ) {
    let params = new HttpParams()
      .append('season', season)
      .append('seasonType', seasonType)
      .append('hideWithoutGames', hideWithoutGames);
    if (collector) {
      params = params.append('collector', collector);
    }
    return this.http.get<LeagueSummary[]>(`${this.baseUrl}/summary`, {
      params
    });
  }

  createOrUpdateLeague(league): Observable<any[]> {
    if (league.id) {
      return this.http.put<any[]>(`${this.baseUrl}/${league.id}`, league);
    }
    return this.http.post<any[]>(this.baseUrl, league);
  }

  deleteLeague(leagueId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.baseUrl}/${leagueId}`);
  }
}
