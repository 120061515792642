<ng-container *ngFor="let mediaId of mediaIds">
  <vg-player
    (onPlayerReady)="onPlayerReady($event, mediaId)"
    *ngIf="mediaId === activeMediaId"
  >
    <video
      *ngIf="mediaId && mediaId.startsWith('video-vod-') && isMP4"
      [vgMedia]="mediaVOD"
      #mediaVOD
      type="video/mp4"
      [id]="mediaId"
      preload="auto"
      [src]="videoUrl"
    ></video>
    <video
      *ngIf="mediaId && mediaId.startsWith('video-dash-') && isDASH"
      [vgMedia]="mediaDASH"
      #mediaDASH
      [id]="mediaId"
      preload="auto"
      [vgDash]="videoUrl"
    ></video>
    <video
      *ngIf="mediaId && mediaId.startsWith('video-hls-') && isHLS"
      [vgMedia]="mediaHLS"
      #mediaHLS
      [id]="mediaId"
      preload="auto"
      autoplay
      [vgHls]="videoUrl"
    ></video>
    <video
      *ngIf="mediaId && mediaId.startsWith('video-webrtc-') && isWebRTC"
      [vgMedia]="mediaWebRTC"
      #mediaWebRTC
      [id]="mediaId"
      preload="auto"
      autoplay
      muted
      [appWebRTC]="videoUrl"
    ></video>
  </vg-player>
</ng-container>
