<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    matInput
    [placeholder]="'Type to filter'"
    [formControl]="teamFormControl"
    [matAutocomplete]="auto"
    (focus)="showSuggestions()"
  />
  <button
    *ngIf="teamFormControl.value && !disabled"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="$event.stopPropagation(); clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="changeTeam($event.option.value)"
    [displayWith]="displayTeamFn"
  >
    <mat-option *ngFor="let t of filteredTeams$ | async" [value]="t">
      <div class="team-name"
        >{{ t.fullName }} <span class="short-name">{{ t.shortName }}</span>
        <span class="league" *ngIf="t.league">/ {{ t.league }}</span>
      </div>
      <div class="team-id">{{ t.id }}</div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div class="team-id-selected">{{ teamId }}</div>
