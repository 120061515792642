<div class="container-fluid" style="margin-top: 24px">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
        ><a [routerLink]="['/reviews']">Reviews</a></li
      >
      <li class="breadcrumb-item"
        >{{ review?.game.date | date : 'yyyy-MM-dd' }}
        {{ review?.game?.homeTeam }} - {{ review?.game?.awayTeam }}</li
      >
      <li class="breadcrumb-item active" aria-current="page">Shifts</li>
    </ol>
  </nav>

  <a
    class="video-player-button"
    mat-stroked-button
    color="primary"
    [routerLink]="['/', 'games', review.game._id, 'video-player']"
    target="_blank"
  >
    <mat-icon style="vertical-align: middle">videocam</mat-icon>
    Video Player
  </a>

  <a
    class="toi-button"
    mat-stroked-button
    color="primary"
    [routerLink]="['/', 'games', review.game._id, 'time-on-ice']"
    target="_blank"
  >
    <mat-icon style="vertical-align: middle">swap_vert</mat-icon>
    Time on Ice
  </a>

  <form>
    <div class="form-row">
      <div class="form-group col-md-1">
        <label for="inputPeriod">Period</label>
        <select
          class="form-control"
          id="inputPeriod"
          name="inputPeriod"
          [(ngModel)]="filters.period"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option [value]="1">1</option>
          <option [value]="2">2</option>
          <option [value]="3">3</option>
          <option [value]="4">4</option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="inputStrengthState">Strength State</label>
        <select
          class="form-control"
          id="inputStrengthState"
          name="inputStrengthState"
          [(ngModel)]="filters.strengthState"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option *ngFor="let s of strengthStates" [value]="s">{{ s }}</option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="inputTeam">Team</label>
        <select
          class="form-control"
          id="inputTeam"
          name="inputTeam"
          [(ngModel)]="filters.team"
          (change)="filterChanged()"
        >
          <option value="" selected>Both</option>
          <option [value]="review.game.homeTeam">{{
            review.game.homeTeam
          }}</option>
          <option [value]="review.game.awayTeam">{{
            review.game.awayTeam
          }}</option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="inputPlayer">Player</label>
        <select
          class="form-control"
          id="inputPlayer"
          name="inputPlayer"
          [(ngModel)]="filters.playerNumber"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option *ngFor="let player of players" [value]="player">{{
            player
          }}</option>
        </select>
      </div>

      <div class="form-group col-md-1">
        <label for="inputDiff">Difference</label>
        <select
          class="form-control"
          id="inputDiff"
          name="inputDiff"
          [(ngModel)]="filters.diff"
          (change)="filterChanged()"
        >
          <option value="" selected>Any</option>
          <option value="0">0</option>
          <option value="1">&gt; 1s</option>
          <option value="10">&gt; 10s</option>
          <option value="30">&gt; 30s</option>
        </select>
      </div>

      <!--
      <div class="form-group col-md-6">
        <label for="inputError">Error</label>
        <select class="form-control" id="inputError" name="inputError" [(ngModel)]="filters.error" (change)="filterChanged()">
          <option value="">All</option>
          <option value="49ing">49ing</option>
          <option value="livestats">Livestats</option>
          <option value="both_wrong">Both Wrong</option>
          <option value="both_ok">Both OK</option>
          <option value="not_sure">Not Sure</option>
          <option value="none">No Error</option>
        </select>
      </div>
      -->
    </div>
  </form>

  <table class="table">
    <thead class="thead-light">
      <tr>
        <th></th>
        <th scope="col">Reviewed Game</th>
        <th scope="col">Master Game</th>
        <th scope="col">Diff</th>
        <th scope="col">RMSE</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Total TOI</td>
        <td>{{ sourceTotalToi | hhmmss }}</td>
        <td>{{ targetTotalToi | hhmmss }}</td>
        <td>{{ sourceTotalToi - targetTotalToi | hhmmss }}</td>
        <td>{{ rmse }}</td>
      </tr>
      <tr>
        <td>#Shifts</td>
        <td>{{ sourceNumShifts }}</td>
        <td>{{ targetNumShifts }}</td>
        <td>{{ sourceNumShifts - targetNumShifts }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <table
    class="table table-hover players"
    *ngIf="playerSummaries && !filters.playerNumber"
  >
    <thead class="thead-light">
      <tr>
        <th scope="col" class="">Team</th>
        <th scope="col" class="">Player</th>
        <th scope="col" class="" colspan="2">Duration</th>
        <th scope="col" class="">Time Diff</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>Reviewed Game</th>
        <th>Master Game</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let player of playerSummaries"
        [class.diff]="
          player.duration0 - player.duration1 > 30 ||
          player.duration0 - player.duration1 < -30
        "
        (click)="selectPlayer(player)"
      >
        <td class="team">{{ player.team }}</td>
        <td class="player">{{ player.playerNumber }}</td>
        <td class="duration">{{ player.duration0 | hhmmss }}</td>
        <td class="duration">{{ player.duration1 | hhmmss }}</td>
        <td>{{ player.duration0 - player.duration1 | hhmmss }}</td>
      </tr>
    </tbody>
  </table>

  <table class="table shifts" *ngIf="filters.playerNumber">
    <thead class="thead-light">
      <tr>
        <th scope="col" class="">Period</th>
        <th scope="col" class="">Strength State</th>
        <th scope="col" class="" colspan="2">Video Time</th>
        <th scope="col" class="" colspan="2">Game Time</th>
        <th scope="col" class="" colspan="2">Duration</th>
        <th scope="col" class="">Time Diff</th>
        <th scope="col" class="">Cause on/off</th>
        <th scope="col" class="">Error</th>
        <th scope="col" class="">Remark</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>Reviewed Game</th>
        <th>Master Game</th>
        <th>Reviewed Game</th>
        <th>Master Game</th>
        <th>Reviewed Game</th>
        <th>Master Game</th>
        <th>Master Game</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="!filteredShifts">
      <tr>
        <td colspan="10">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="filteredShifts">
      <tr
        *ngFor="
          let match of filteredShifts
            | paginate
              : {
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: filteredShifts.length
                }
        "
        [class.diff]="hasDiff(match[0], match[1])"
        [class.source-only]="!match[0]"
        [class.target-only]="!match[1]"
        (click)="selectShift(match)"
      >
        <td class="period">{{
          match[0]?.onEvent.period || match[1]?.onEvent.period
        }}</td>
        <td class="strength-state">{{
          match[0]?.onEvent.strengthState || match[1]?.onEvent.strengthState
        }}</td>

        <td class="video-time">
          <a
            [routerLink]="[]"
            (click)="seekTo(match[0]?.onEvent.videoTime, match[0])"
            >{{ match[0]?.onEvent.videoTime | hhmmss }}</a
          >
          -
          <a
            [routerLink]="[]"
            (click)="seekTo(match[0]?.offEvent?.videoTime, match[0])"
            >{{ match[0]?.offEvent?.videoTime | hhmmss }}</a
          >
        </td>

        <td class="video-time">
          <a
            [routerLink]="[]"
            (click)="seekTo(match[1]?.onEvent.videoTime, match[1])"
            >{{ match[1]?.onEvent.videoTime | hhmmss }}</a
          >
          -
          <a
            [routerLink]="[]"
            (click)="seekTo(match[1]?.offEvent?.videoTime, match[1])"
            >{{ match[1]?.offEvent?.videoTime | hhmmss }}</a
          >
        </td>

        <td class="game-time">
          <span *ngIf="match[0]"
            >{{
              match[0]?.onEvent.gameTime | countdown : match[0]?.onEvent.period
            }}
            -
            {{
              match[0]?.offEvent?.gameTime
                | countdown : match[0]?.onEvent.period
            }}</span
          >
        </td>
        <td class="game-time">
          <span *ngIf="match[1]">
            {{
              match[1]?.onEvent.gameTime | countdown : match[1]?.onEvent.period
            }}
            -
            <span
              title="OFF event missing"
              [class.off-event-missing]="!match[1]?.offEvent?._id"
              >{{
                match[1]?.offEvent?.gameTime
                  | countdown : match[1]?.onEvent.period
              }}</span
            >
          </span>
        </td>

        <td class="duration" [class.short-shift]="match[0]?.duration < 10">{{
          match[0]?.duration | hhmmss
        }}</td>
        <td class="duration" [class.short-shift]="match[1]?.duration < 10">{{
          match[1]?.duration | hhmmss
        }}</td>

        <td [class.diff]="hasDiff(match[0], match[1])">{{
          diff(match[0], match[1]) | hhmmss
        }}</td>

        <td>
          {{ match[0]?.onEvent?.timeOnIceCause }} (<a
            [routerLink]="[]"
            (click)="selectTrack(match[0]?.onEvent.trackId)"
            >{{ match[0]?.onEvent.trackId }}</a
          >) - {{ match[0]?.offEvent?.timeOnIceCause }}
        </td>

        <td class="error">
          <select
            *ngIf="match[2]"
            [(ngModel)]="match[2].error"
            (change)="saveError(match, $event.target.value)"
          >
            <option value=""></option>
            <option value="reviewed_game">Reviewed Game</option>
            <option value="master_game">Master Game</option>
            <option value="both_wrong">Both Wrong</option>
            <option value="both_ok">Both OK</option>
            <option value="not_sure">Not Sure</option>
            <option value="none">No Error</option>
          </select>

          <select
            *ngIf="!match[2]"
            (change)="saveError(match, $event.target.value)"
          >
            <option value=""></option>
            <option value="reviewed_game">Reviewed Game</option>
            <option value="master_game">Master Game</option>
            <option value="both_wrong">Both Wrong</option>
            <option value="both_ok">Both OK</option>
            <option value="not_sure">Not Sure</option>
            <option value="none">No Error</option>
          </select>
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            *ngIf="!match[2]"
            (blur)="saveRemark(match, $event.target.value)"
          />
          <input
            type="text"
            class="form-control"
            *ngIf="match[2]"
            [(ngModel)]="match[2].sourceEventRemarks"
            (blur)="saveRemark(match, $event.target.value)"
          />
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="10">
          <pagination-template
            #p="paginationApi"
            (pageChange)="pageChanged($event)"
          >
            <div class="pagination-page-size">
              Items Per Page:
              <select [(ngModel)]="itemsPerPage" (change)="pageChanged($event)">
                <option value="50">50</option>
                <option value="500">500</option>
                <option value="5000">5000</option>
              </select>
            </div>

            <div
              class="pagination-previous pagination-label"
              [class.disabled]="p.isFirstPage()"
            >
              <a *ngIf="!p.isFirstPage()" (click)="p.previous()"> < </a>
            </div>

            <div
              *ngFor="let page of p.pages"
              [class.current]="p.getCurrent() === page.value"
              class="pagination-label"
            >
              <a
                (click)="p.setCurrent(page.value)"
                *ngIf="p.getCurrent() !== page.value"
              >
                <span>{{ page.label }}</span>
              </a>
              <div *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>

            <div
              class="pagination-next pagination-label"
              [class.disabled]="p.isLastPage()"
            >
              <a *ngIf="!p.isLastPage()" (click)="p.next()"> > </a>
            </div>
          </pagination-template>
        </th>
      </tr>
    </tfoot>
  </table>
</div>
