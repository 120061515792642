import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hhmmss' })
export class TimePipe implements PipeTransform {
  transform(inputTime: string | number) {
    let seconds;
    if (typeof inputTime === 'number') {
      seconds = inputTime;
    } else if (Number(inputTime)) {
      seconds = Number.parseInt(inputTime, 10);
    } else {
      return inputTime;
    }

    const negative = seconds < 0;
    if (negative) {
      seconds *= -1;
    }

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    return (
      (negative ? '-' : '') +
      ('0' + h).slice(-2) +
      ':' +
      ('0' + m).slice(-2) +
      ':' +
      ('0' + s).slice(-2)
    );
  }
}
