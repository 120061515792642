<div class="games-container" style="min-height: 500px">
  <table class="mdl-data-table mdl-js-data-table" style="width: 100%">
    <thead>
      <tr>
        <th class="mdl-data-table__cell--non-numeric">Game Date</th>
        <th class="mdl-data-table__cell--non-numeric">Game</th>
        <th class="mdl-data-table__cell--non-numeric">Reviewed</th>
        <th class="mdl-data-table__cell--non-numeric">Reviewer</th>
        <th class="mdl-data-table__cell--non-numeric">Status</th>
        <th class="mdl-data-table__cell--non-numeric">Review Created</th>
        <th class="mdl-data-table__cell--non-numeric">Last Export</th>
        <th style="padding-bottom: 2px"> </th>
      </tr>
      <tr>
        <th class="mdl-data-table__cell--non-numeric">
          <input
            placeholder="Filter Game Date"
            [(ngModel)]="filters['game_date']"
            (ngModelChange)="filterChanged()"
            style="width: 150px"
          />
        </th>
        <th class="mdl-data-table__cell--non-numeric">
          <input
            placeholder="Filter Game Team"
            [(ngModel)]="filters['game_team']"
            (ngModelChange)="filterChanged()"
            style="width: 150px"
          />
        </th>
        <th></th>
        <th class="mdl-data-table__cell--non-numeric">
          <input
            placeholder="Filter Reviewer"
            [(ngModel)]="filters['person']"
            (ngModelChange)="filterChanged()"
            style="width: 150px"
          />
        </th>
        <th class="mdl-data-table__cell--non-numeric">
          <mat-select
            [(value)]="filters['status']"
            (valueChange)="filterChanged()"
            style="width: 100px"
          >
            <mat-option value="">ALL</mat-option>
            <mat-option value="to_review">TO REVIEW</mat-option>
            <mat-option value="checking">CHECKING</mat-option>
            <mat-option value="reviewing">REVIEWING</mat-option>
            <mat-option value="done">DONE</mat-option>
          </mat-select>
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let review of reviews
            | paginate
              : {
                  itemsPerPage: itemsPerPage,
                  currentPage: currentPage,
                  totalItems: reviewCount
                }
        "
      >
        <td class="mdl-data-table__cell--non-numeric">{{
          review.game?.date | date : 'yyyy-MM-dd'
        }}</td>
        <td class="mdl-data-table__cell--non-numeric"
          ><a [routerLink]="['/games', review.gameId]"
            >{{ review.game?.homeTeam }} – {{ review.game?.awayTeam }}</a
          ></td
        >
        <td class="mdl-data-table__cell--non-numeric">
          <a
            *ngIf="review.game?.eventCollectorName"
            [routerLink]="['/users', review.game.eventCollectorName]"
            >{{ review.game.eventCollectorName }}</a
          >
        </td>
        <td class="mdl-data-table__cell--non-numeric"
          ><a [routerLink]="['/users', review.person]">{{
            review.person
          }}</a></td
        >
        <td class="mdl-data-table__cell--non-numeric">{{ review.status }}</td>
        <td class="mdl-data-table__cell--non-numeric">{{
          review.insertDate | date : 'yyyy-MM-dd'
        }}</td>
        <td class="mdl-data-table__cell--non-numeric">{{
          review.lastExport | date : 'yyyy-MM-dd'
        }}</td>
        <td>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              [routerLink]="['/reviews', review._id, 'edit']"
              ><i class="material-icons icon-button" title="Edit Review"
                >edit</i
              >
              Edit</button
            >
            <button mat-menu-item (click)="delete(review)"
              ><i class="material-icons icon-button" title="Delete Review"
                >delete</i
              >
              Delete</button
            >
          </mat-menu>

          <button
            mat-icon-button
            [routerLink]="['/reviews', review._id, 'game-actions']"
            title="Game Action Review"
          >
            <mat-icon>view_list</mat-icon>
          </button>
          <button
            mat-icon-button
            [routerLink]="['/reviews', review._id, 'shifts']"
            title="Shifts Review"
          >
            <mat-icon>swap_vert</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="9" style="text-align: center">
          <pagination-template
            #p="paginationApi"
            (pageChange)="pageChanged($event)"
          >
            <div class="pagination-page-size">
              Items Per Page:
              <select [(ngModel)]="itemsPerPage" (change)="pageChanged($event)">
                <option value="10">10</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
            </div>

            <div
              class="pagination-previous pagination-label"
              [class.disabled]="p.isFirstPage()"
            >
              <a *ngIf="!p.isFirstPage()" (click)="p.previous()"> < </a>
            </div>

            <div
              *ngFor="let page of p.pages"
              [class.current]="p.getCurrent() === page.value"
              class="pagination-label"
            >
              <a
                (click)="p.setCurrent(page.value)"
                *ngIf="p.getCurrent() !== page.value"
              >
                <span>{{ page.label }}</span>
              </a>
              <div *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>

            <div
              class="pagination-next pagination-label"
              [class.disabled]="p.isLastPage()"
            >
              <a *ngIf="!p.isLastPage()" (click)="p.next()"> > </a>
            </div>
          </pagination-template>
        </th>
      </tr>
    </tfoot>
  </table>

  <button
    mat-raised-button
    color="primary"
    class="save-button"
    *ngIf="authService.hasPermission('write:reviews') | async"
    [routerLink]="['/reviews', 'new', 'edit']"
  >
    New Review
  </button>
</div>
