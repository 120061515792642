<h2 mat-dialog-title
  >VideoTime Offset {{ game.homeTeam }} - {{ game.awayTeam }}</h2
>
<mat-dialog-content>
  <span
    >Adjust the videoTime of each game event by a positive or negative
    offset.</span
  >
  <div class="row" style="margin-top: 24px">
    <div class="col-2 mt-3"><label for="period">Period</label></div>
    <div class="col-10 mt-3">
      <mat-select [(value)]="period" id="period">
        <mat-option value="all">All Periods</mat-option>
        <mat-option value="1">Period 1</mat-option>
        <mat-option value="2">Period 2</mat-option>
        <mat-option value="3">Period 3</mat-option>
        <mat-option value="4">Period 4</mat-option>
        <mat-option value="5">Period 5</mat-option>
        <mat-option value="6">Period 6</mat-option>
        <mat-option value="7">Period 7</mat-option>
      </mat-select>
    </div>

    <div class="col-2 mt-3"><label for="offset">Offset</label></div>
    <div class="col-10 mt-3">
      <input type="number" id="offset" [(ngModel)]="offset" />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" (click)="applyChange()"
    >Apply VideoTime Offset</button
  >
</mat-dialog-actions>
