import { Injectable } from '@angular/core';
import { GameEvent } from '../domain/game-event';
import { DataSet, Game } from '../domain/game';
import { GameTimeService } from './game-time.service';
import { GameService } from './game.service';
import { GameIncidentService } from './game-incident.service';

@Injectable()
export class EventValidationService {
  static readonly SHOT_POSITION_OUTSIDE_OFFENSIVE_ZONE =
    'Shot from outside offensive zone recorded. Click "Save Event Manually" if this is correct.';
  static readonly ON_GOAL_SHOT_WITHOUT_IMPACT_RECORDED =
    'Shot on goal or iron without impact information recorded. Add impact information.';
  static readonly FACEOFF_PLAYER_NON_FORWARD =
    'Face-Off player is not a forward.';
  static readonly FACEOFF_OPPONENT_NON_FORWARD =
    'Face-Off opponent is not a forward.';

  constructor(
    private gameService: GameService,
    private gameTimeService: GameTimeService,
    private gameIncidentService: GameIncidentService
  ) {}

  validate(event: GameEvent, game: Game): string {
    if (!event || !game) {
      // prevent endless loop at application startup
      return 'undefined event or game';
    }

    if (!event.eventType) {
      return 'eventType is missing';
    }

    if (!event.period) {
      return 'period is missing';
    }

    if (event.eventType === 'shot') {
      if (!event.shotOutcome) {
        return 'shot outcome is missing';
      }
    }

    if (event.eventType === 'face_off') {
      if (!event.teamFaceOffOutcome) {
        return 'Team Face-Off Outcome must be defined';
      }
      if (!event.faceoff_opponent) {
        return 'faceoff opponent is missing';
      }
    }

    if (event.eventType === 'pass') {
      if (!event.pass_type) {
        return 'pass_type is missing';
      }
      if (this.shouldCheckPassReceiver(event, game)) {
        return 'pass_receiver is missing';
      }
      if (
        (event.pass_type === 'pass' || event.pass_type === 'shot_pass') &&
        !event.pass_outcome
      ) {
        return 'pass_outcome is missing';
      }
    }

    if (
      event.eventType !== 'interruption' ||
      event.interruption_type !== 'period_start'
    ) {
      const expectedPeriod = this.gameTimeService.findPeriodForVideoTime(
        event.videoTime
      );
      if (expectedPeriod && event.period !== expectedPeriod) {
        return `Invalid period – expected: ${expectedPeriod}, actual: ${event.period}`;
      }
    }

    if (event.eventType !== 'interruption') {
      if (!event.team) {
        if (this.shouldCheckTeam(event)) {
          return 'team is missing';
        }
      }
      if (event.team === 'neutral') {
        if (event.playerNumber) {
          return 'no player allowed with team neutral. press esc.';
        }
        if (event.eventType !== 'puckPossession') {
          return 'team neutral is only allowed for events of type puck possession.';
        }
      } else if (!event.teamId) {
        if (this.shouldCheckTeam(event)) {
          return 'teamId is missing';
        }
      }
      if (event.eventType === 'penalty') {
        if (!event.strengthState) {
          return 'strength state is missing';
        }
        if (!event.playerNumber && game.isLiveDraftEvents) {
          return 'player is missing';
        }
      } else {
        if (
          event.eventType !== 'time_on_ice' &&
          event.eventType !== 'highlight' &&
          event.eventType !== 'game_incident'
        ) {
          if (
            event.xPosition === null ||
            event.xPosition === undefined ||
            event.yPosition === null ||
            event.yPosition === undefined
          ) {
            return 'position is missing';
          }
        }
        if (
          !(event.eventType === 'puckPossession' && event.team === 'neutral')
        ) {
          if (!event.playerNumber) {
            if (this.shouldCheckPlayer(event, game)) {
              return 'player is missing';
            }
          } else {
            let players = null;
            if (game.homeTeam === event.team) {
              players = this.gameService.getHomeTeamPlayers(game);
            } else {
              players = this.gameService.getAwayTeamPlayers(game);
            }
            if (!players.includes(event.playerNumber)) {
              return `Player-Team mismatch: player '${event.playerNumber}' does not play for team '${event.team}'.`;
            }
          }
        } else {
          if (event.playerNumber) {
            return `No player should be recorded for eventType 'Puck Possession' and Team 'neutral'. Press 'ESC' to remove player info.`;
          }
        }
        if (event.eventType === 'oddMenRush') {
          if (!event.oddMenRushDetail) {
            return 'Odd Men Rush Detail Is missing. Add detail (e.g. 2-1)';
          }
        }
        if (event.eventType === 'videoTag') {
          if (!event.videoTag) {
            return 'Video Tag Events must have a Video Tag';
          } else if (
            event.videoTag === 'board_sector_break_out_under_pressure'
          ) {
            if (event.xPosition > 30 && event.xPosition < 250) {
              return `invalid board sector x position: ${event.xPosition}`;
            }
          } else if (event.videoTag === 'low_break_out_under_pressure') {
            if (event.yPosition > 100 && event.yPosition < 500) {
              return `invalid low y position: ${event.yPosition}`;
            }
          }
        }
        if (event.eventType === 'highlight') {
          if (!event.highlightType) {
            return 'Highlight Type Is missing.';
          }
          if (!event.highlightPlayback) {
            if (this.shouldCheckHighlightPlayback(event)) {
              return 'Highlight Playback Is missing.';
            }
          }
        }
      }
    }

    if (event.eventType === 'interruption') {
      if (!event.interruption_type) {
        return 'Interruption reason is missing';
      }
    }

    if (event.eventType === 'game_incident') {
      if (!event.gameIncidentReason) {
        return `"Game Incident Reason" is missing`;
      }
      if (!event.officialsCallAction) {
        return `"Official's Call Action" is missing`;
      }
      if (!event.officialsCallAssessment) {
        return `"Official's Assessment" is missing`;
      }
      if (!this.gameIncidentService.isValidUncalledPenaltyEvent(event)) {
        return `"Wrong Non-Call" requires a "Uncalled Penalty Severity"`;
      }
    }

    return undefined;
  }

  getWarnings(event: GameEvent, game: Game): string {
    if (event.eventType === 'shot') {
      if (event.yPosition) {
        if (this.isLongDistanceShot(event, game)) {
          return EventValidationService.SHOT_POSITION_OUTSIDE_OFFENSIVE_ZONE;
        }
      }
      if (
        event.shotOutcome &&
        ['on_goal', 'iron', 'goal'].includes(event.shotOutcome)
      ) {
        if (
          !game.isSihfLive &&
          !game.isLiveCollection &&
          game.dataSet === DataSet.FULL &&
          (!event.netImpactX || !event.netImpactY)
        ) {
          return EventValidationService.ON_GOAL_SHOT_WITHOUT_IMPACT_RECORDED;
        }
      }
    }
    if (event.eventType === 'face_off' && !event.draft) {
      if (!game.getForwards(event.team).includes(event.playerNumber)) {
        return EventValidationService.FACEOFF_PLAYER_NON_FORWARD;
      }
      const opponentTeam =
        event.team === game.homeTeam ? game.awayTeam : game.homeTeam;
      if (!game.getForwards(opponentTeam).includes(event.faceoff_opponent)) {
        return EventValidationService.FACEOFF_OPPONENT_NON_FORWARD;
      }
    }
    return null;
  }

  private isLongDistanceShot(event: GameEvent, game: Game) {
    if (event.team === game.homeTeam) {
      if (event.yPosition > 30) {
        return true;
      }
    } else {
      if (event.yPosition < 30) {
        return true;
      }
    }
    return false;
  }

  private shouldCheckPassReceiver(event: GameEvent, game: Game): boolean {
    return (
      !event.pass_receiver &&
      (event.pass_type === 'pass' ||
        (event.pass_type === 'shot_pass' &&
          event.pass_outcome === 'complete')) &&
      (!game.autoPuckPossessionEvents ||
        !(game.autoPuckPossessionEvents && event.pass_outcome === 'incomplete'))
    );
  }

  private shouldCheckPlayer(event: GameEvent, game: Game): boolean {
    return (
      !(
        (event.eventType === 'puckPossession' ||
          this.isSpecificPassWithoutPlayer(event)) &&
        game.autoPuckPossessionEvents
      ) &&
      event.eventType !== 'highlight' &&
      event.eventType !== 'game_incident'
    );
  }

  private shouldCheckTeam(event: GameEvent): boolean {
    return !(
      event.eventType === 'highlight' || event.eventType === 'game_incident'
    );
  }

  private shouldCheckHighlightPlayback(event: GameEvent): boolean {
    return !(
      event.highlightType === 'goal' ||
      event.highlightType === 'save' ||
      event.highlightType === 'penalty_shot'
    );
  }

  private isPassTypeIncomplete(event: GameEvent): boolean {
    return event.pass_type === 'pass' && event.pass_outcome === 'incomplete';
  }

  private isSpecificPassWithoutPlayer(event: GameEvent) {
    return (
      event.eventType === 'pass' &&
      (this.isPassTypeIncomplete(event) ||
        event.pass_type === 'rim' ||
        event.pass_type === 'chip')
    );
  }
}
