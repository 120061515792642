import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vg-time-display',
  encapsulation: ViewEncapsulation.None,
  template: `
    <span *ngIf="(target?.isLive || isWebRTC || isHLS) && !ignoreLive"
      >LIVE</span
    >
    <span *ngIf="!target?.isLive && !isWebRTC && !isHLS">{{
      getTime() | vgUtc : vgFormat
    }}</span>
    <span *ngIf="(isWebRTC || isHLS) && ignoreLive">{{
      getTime() | vgUtc : vgFormat
    }}</span>
  `,
  styles: [
    `
      app-vg-time-display {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        justify-content: center;
        height: 50px;
        width: 60px;
        cursor: pointer;
        color: white;
        line-height: 50px;
        pointer-events: none;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      }
    `
  ]
})
export class AppVgTimeDisplayComponent implements OnInit, OnDestroy {
  @Input() vgFor: string;
  @Input() vgProperty = 'current';
  @Input() vgFormat = 'mm:ss';

  /**
   * We want to ignore live in WebRTC/HLS when we want to display current time
   */
  @Input() ignoreLive: boolean = false;
  @Input() isWebRTC: boolean = false;
  @Input() isHLS: boolean = false;
  @Input() duration: number = 0; // timestamp

  elem: HTMLElement;
  target: any;

  subscriptions: Subscription[] = [];

  constructor(ref: ElementRef, public API: VgApiService) {
    this.elem = ref.nativeElement;
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);
  }

  /**
   * In case of WebRTC add duration that we get from HLS video
   *
   */
  getTime() {
    let t = 0;

    if (this.target) {
      t = Math.round(this.target.time[this.vgProperty]);
      t = isNaN(t) ? 0 : this.isWebRTC ? this.duration + t : t;
    }

    return t;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
