import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EventsOverviewComponent } from './events-overview/events-overview.component';
import { GameClockDialogComponent } from './game-clock-dialog/game-clock-dialog.component';
import { PlayerButtonComponent } from './player-button/player-button.component';
import { TimeOnIceComponent } from './time-on-ice.component';
import { TimeOnIceService } from './time-on-ice.service';
import { WarningComponent } from './warning/warning.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatSlideToggleModule
  ],
  declarations: [
    TimeOnIceComponent,
    PlayerButtonComponent,
    GameClockDialogComponent,
    EventsOverviewComponent,
    WarningComponent
  ],
  providers: [TimeOnIceService],
  exports: [TimeOnIceComponent]
})
export class TimeOnIceModule {}
