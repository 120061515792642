import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BitrateOptions, VgApiService } from '@videogular/ngx-videogular/core';
import { first } from 'rxjs/operators';
import { Game, Video } from '../domain/game';
import { VideoService } from '../services/video.service';
import { VgHlsDirective } from '@videogular/ngx-videogular/streaming';
import { WebrtcDirective } from '../webrtc.directive';

@Component({
  selector: 'app-video-player-preload',
  templateUrl: './video-player-preload.component.html',
  styleUrls: ['./video-player-preload.component.scss']
})
export class VideoPlayerPreloadComponent {
  videoUrl: string;
  activeMediaId: string;
  _video: Video;

  @Input() game: Game;
  @Input() set video(video: Video) {
    this.videoUrl = video.urlSigned;
    this.activeMediaId = video.mediaId;
    this._video = video;
  }
  get video(): Video {
    return this._video;
  }

  @Output() duration = new EventEmitter<number>();
  @Output() error = new EventEmitter<string>();

  mediaPlayerApi: VgApiService;
  @ViewChild(VgHlsDirective)
  vgHls: VgHlsDirective;
  bitrates: BitrateOptions[];

  @ViewChild(WebrtcDirective)
  vgWebRtc: WebrtcDirective;

  constructor(private videoService: VideoService) {}

  get isHLS(): boolean {
    return this.videoUrl && this.videoUrl.indexOf('.m3u8') > -1;
  }

  get isDASH(): boolean {
    return this.videoUrl && this.videoUrl.indexOf('.mpd') > -1;
  }

  get isWebRTC(): boolean {
    return (
      this.videoUrl &&
      (this.videoUrl.startsWith('ws://') || this.videoUrl.startsWith('wss://'))
    );
  }

  get isMP4(): boolean {
    return !this.isHLS && !this.isDASH && !this.isWebRTC;
  }

  get hls() {
    return this.vgHls?.hls;
  }

  get mediaIds(): string[] {
    return this.game.videos && this.game.videos.length > 0
      ? this.game.videos.map((v: any) => v.mediaId)
      : ['video-dash-0', 'video-vod-0', 'video-webrtc-0', 'video-hls-0'];
  }

  onPlayerReady(api: VgApiService, mediaId: string) {
    console.log('onPlayerReady');
    this.mediaPlayerApi = api;
    // We can't catch some errors so throw error if not loadedMetadata after some time
    const timer = setTimeout(() => {
      this.error.emit('video loaded error');
    }, 5000);
    const subscriptions =
      this.mediaPlayerApi.getMediaById(mediaId).subscriptions;
    subscriptions.error.subscribe((e) => {
      clearTimeout(timer);
      const errorMessage = e.target?.error?.message;
      const errorCode = e.target?.error?.code;
      this.error.emit(
        `video loaded error: code=${errorCode}, message=${errorMessage}`
      );
    });
    subscriptions.loadedMetadata.pipe(first()).subscribe(async (e) => {
      const duration = await this.getDuration();
      clearTimeout(timer);
      this.duration.emit(duration);
    });
  }

  async getDuration() {
    if (this.isWebRTC) {
      const response = await this.videoService
        .getWebRTCStreamDetails(this.game._id, this.video.id)
        .toPromise();
      return new Date().getTime() - new Date(response.startTime).getTime();
    } else if (this.mediaPlayerApi.isLive && this.hls) {
      return this.hls.liveSyncPosition;
    } else {
      return this.mediaPlayerApi.getDefaultMedia().duration;
    }
  }
}
