import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlayerService } from '../../services/player.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { League } from '../../domain/league';

@Component({
  selector: 'app-league-team-dialog',
  templateUrl: './edit-league-dialog.component.html',
  styleUrls: ['./edit-league-dialog.component.css']
})
export class EditLeagueDialogComponent implements OnInit {
  league: League;
  form: UntypedFormGroup;
  countries: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditLeagueDialogComponent>,
    private playerService: PlayerService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      shortName: [null, Validators.required],
      fullName: null,
      countryId: null,
      imageUrl: null,
      teamType: null,
      teamClass: null,
      gameType: null,
      playerLevel: null
    });
  }

  ngOnInit() {
    this.playerService
      .countries()
      .subscribe((countries) => (this.countries = countries));
    this.league = this.data.league;
    if (this.league) {
      this.form.patchValue(this.data.league);
    }
  }

  applyChanges() {
    this.dialogRef.close({ ...this.form.value, id: this.league?.id });
  }

  onImageUploadComplete(url: string) {
    this.form.patchValue({ imageUrl: url });
  }
}
