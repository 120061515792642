import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ReviewService } from '../../services/review.service';
import { AlertService } from '../../services/alert.service';
import { Review } from '../../domain/review';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.css']
})
export class ReviewDetailsComponent implements OnInit {
  review: Review;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reviewService: ReviewService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.review = this.route.snapshot.data['review'] as Review;
    if (!this.review.type) {
      this.review.type = '49ing';
    }
  }

  save() {
    this.reviewService.createReview(this.review).subscribe(
      (_) => {
        this.router.navigateByUrl('reviews');
      },
      (error) => {
        console.error('save review failed: ', error);
        this.alertService.showError('Could not save review: ' + error.message);
      }
    );
  }

  delete() {
    this.reviewService.deleteReview(this.review).subscribe(
      (_) => {
        this.router.navigateByUrl('reviews');
      },
      (error) => {
        console.error('delete review failed: ', error);
        this.alertService.showError(
          'Could not delete review: ' + error.message
        );
      }
    );
  }
}
