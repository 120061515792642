<h2 mat-dialog-title>Archive Video "{{ video.cameraAngle }}"</h2>
<mat-dialog-content> </mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <input
        type="text"
        name="url"
        placeholder="URL"
        class="form-control col-sm-12"
        readonly
        [(ngModel)]="video.url"
      />
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <input
          type="checkbox"
          id="full-length-check"
          name="full-length-check"
          [(ngModel)]="downloadFullLength"
        />
        <label
          class="form-check-label"
          for="full-length-check"
          style="margin-left: 8px"
          >Full Length Video (MP4)</label
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <input
          type="checkbox"
          id="clipped-check"
          name="clipped-check"
          [(ngModel)]="downloadClipped"
        />
        <label
          class="form-check-label"
          for="clipped-check"
          style="margin-left: 8px"
          >Clipped Video (MP4)</label
        >
      </div>
      <div class="col-2">
        <input
          type="text"
          name="clipped-start"
          class="form-control"
          placeholder="clip start"
          [ngModel]="clipStart | hhmmss"
          (ngModelChange)="startAdjusted($event)"
          (focus)="seekTo(clipStart)"
          [maskito]="{ mask: videoTimeMask, overwriteMode: 'replace' }"
          [disabled]="!downloadClipped"
          [required]="downloadClipped"
        />
      </div>
      <div class="col-2">
        <input
          type="text"
          name="clipped-start"
          placeholder="clip end"
          class="form-control"
          [ngModel]="clipEnd | hhmmss"
          (ngModelChange)="endAdjusted($event)"
          (focus)="seekTo(clipEnd)"
          [maskito]="{ mask: videoTimeMask, overwriteMode: 'replace' }"
          [disabled]="!downloadClipped"
          [required]="downloadClipped"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="downloadClipped">
      <vg-player (onPlayerReady)="onPlayerReady($event)">
        <vg-controls>
          <vg-play-pause></vg-play-pause>

          <vg-time-display
            vgProperty="current"
            vgFormat="hh:mm:ss"
          ></vg-time-display>

          <app-video-live-scrub-bar [dvr]="true">
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </app-video-live-scrub-bar>

          <vg-time-display
            vgProperty="left"
            vgFormat="hh:mm:ss"
          ></vg-time-display>
          <vg-mute></vg-mute>
        </vg-controls>

        <video
          *ngIf="!isHLS"
          [vgMedia]="mediaVOD"
          #mediaVOD
          type="video/mp4"
          id="vod-video"
          preload="auto"
          [src]="video.urlSigned"
        ></video>
        <video
          *ngIf="isHLS"
          [vgMedia]="mediaHLS"
          #mediaHLS
          id="hls-stream"
          preload="auto"
          [vgHls]="video.urlSigned"
        ></video>
      </vg-player>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <input
          type="checkbox"
          id="hls-check"
          name="hls-check"
          [(ngModel)]="downloadHls"
        />
        <label class="form-check-label" for="hls-check" style="margin-left: 8px"
          >Package as HLS</label
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <input
          type="checkbox"
          id="reencode-check"
          name="reencode-check"
          [(ngModel)]="reencode"
        />
        <label
          class="form-check-label"
          for="reencode-check"
          style="margin-left: 8px"
          >Rencode Video</label
        >
      </div>
    </div>
    <div class="row mt-3">
      <button mat-button [mat-dialog-close]="false" class="col-sm-5"
        >Cancel</button
      >
      <button
        mat-raised-button
        color="primary"
        [disabled]="!downloadClipped && !downloadFullLength && !downloadHls"
        (click)="startDownload()"
        class="col-sm-6"
        >Start Archiving</button
      >
    </div>
  </div>
</mat-dialog-actions>
