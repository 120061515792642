import { createActionGroup, props } from '@ngrx/store';
import {
  EventType,
  GameIncidentReason,
  GamePeriod,
  HighlightPlayback,
  HighlightRating,
  HighlightType,
  InterruptionType,
  NetImpactPositionCoordinate,
  OddMenRushDetail,
  PassOutcome,
  PassType,
  PenaltyDuration,
  PenaltyType,
  PositionCoordinate,
  ShotOutcome,
  ShotScenario,
  ShotType,
  StrengthState,
  TeamFaceOffOutcome,
  VideoTagType
} from '../../domain/game-event';
import { Detection } from '../../services/detection';
import ObjectId from 'bson-objectid';

const eventActionGroup = createActionGroup({
  source: 'Event Actions',
  events: {
    'Event Id Change': props<{ _id: string }>(),
    'Game Time Change': props<{ gameTime: number }>(),
    // Update videoTime after seeking. Should only be called from video player.
    'Video Time Change': props<{ videoTime: number }>(),
    'Period Change': props<{ period: GamePeriod }>(),
    'Strength State Change': props<{ strengthState: StrengthState }>(),
    'Event Type Change': props<{ eventType: EventType }>(),
    'Team Change': props<{ team: string }>(),
    'Is Home Team Empty Net Change': props<{ isHomeTeamEmptyNet: boolean }>(),
    'Is Away Team Empty Net Change': props<{ isAwayTeamEmptyNet: boolean }>(),
    'Odd Men Rush Detail Change': props<{
      oddMenRushDetail: OddMenRushDetail;
    }>(),

    'Position Change': props<{ position: PositionCoordinate }>(),
    'Pass Receiver Position Change': props<{
      passReceiverPosition: PositionCoordinate;
    }>(),
    'Shot Blocker Position Change': props<{
      shotBlockerPosition: PositionCoordinate;
    }>(),
    'Faceoff Outcome Position Change': props<{
      faceoffOutcomePosition: PositionCoordinate;
    }>(),

    'Pass Type Change': props<{ passType: PassType }>(),
    'Pass Outcome Change': props<{ passOutcome: PassOutcome }>(),

    'Shot Outcome Change': props<{ shotOutcome: ShotOutcome }>(),
    'Shot Scenario Change': props<{ shotScenario: ShotScenario }>(),
    'Shot Type Change': props<{ shotType: ShotType }>(),

    'Is Blocked Change': props<{ is_blocked: string }>(),
    'Has Net Traffic Change': props<{ has_net_traffic: string }>(),
    'Has Screen Change': props<{ has_screen: string }>(),

    'Team FaceOff Outcome Change': props<{
      teamFaceOffOutcome: TeamFaceOffOutcome;
    }>(),

    'Interruption Type Change': props<{
      interruption_type: InterruptionType;
    }>(),

    'Video Tag Change': props<{ videoTag: VideoTagType }>(),

    'Player Number Change': props<{ playerNumber: string }>(),
    'Pass Receiver Player Number Change': props<{
      passReceiverPlayerNumber: string;
    }>(),
    'Assist 1 Player Number Change': props<{
      assist1PlayerNumber: string;
    }>(),
    'Assist 2 Player Number Change': props<{
      assist2PlayerNumber: string;
    }>(),
    'Shot Blocker Player Number Change': props<{
      shotBlockerPlayerNumber: string;
    }>(),
    'Deflector Player Number Change': props<{
      deflectorPlayerNumber: string;
    }>(),
    'Screener Player Number Change': props<{ screenerPlayerNumber: string }>(),
    'Net Traffic Causer Player Number Change': props<{
      netTrafficCauserPlayerNumber: string;
    }>(),
    'FaceOff Opponent Player Number Change': props<{
      faceOffOpponentPlayerNumber: string;
    }>(),
    'Fouled Player Number Change': props<{ fouledPlayerNumber: string }>(),

    'Selected Track Id Change': props<{ selectedTrackId: number }>(),

    'Detections Change': props<{ detections: Detection[] }>(),

    'Highlight Type Change': props<{ highlightType: HighlightType }>(),
    'Highlight Playback Change': props<{
      highlightPlayback: HighlightPlayback;
    }>(),
    'Highlight Rating Change': props<{ highlightRating: HighlightRating }>(),

    'Net Impact Change': props<{
      netImpact: NetImpactPositionCoordinate;
    }>(),

    'Penalty Type Change': props<{
      penaltyType: PenaltyType;
    }>(),
    'Penalty Id Change': props<{
      penaltyId: ObjectId;
    }>(),
    'Penalty Duration Change': props<{
      penaltyDuration: PenaltyDuration;
    }>(),

    'Game Incident Reason Change': props<{
      gameIncidentReason: GameIncidentReason;
    }>(),

    'Reset State': props<{ team: string; period: GamePeriod }>(),
    'Reset Hard State': props<any>()
  }
});

export const {
  eventIdChange,
  gameTimeChange,
  videoTimeChange,
  periodChange,
  strengthStateChange,
  eventTypeChange,
  teamChange,
  isHomeTeamEmptyNetChange,
  isAwayTeamEmptyNetChange,
  oddMenRushDetailChange,
  positionChange,
  passReceiverPositionChange,
  shotBlockerPositionChange,
  faceoffOutcomePositionChange,
  passTypeChange,
  passOutcomeChange,
  shotOutcomeChange,
  shotScenarioChange,
  shotTypeChange,
  isBlockedChange,
  hasNetTrafficChange,
  hasScreenChange,
  teamFaceoffOutcomeChange,
  interruptionTypeChange,
  videoTagChange,
  playerNumberChange,
  passReceiverPlayerNumberChange,
  assist1PlayerNumberChange,
  assist2PlayerNumberChange,
  shotBlockerPlayerNumberChange,
  deflectorPlayerNumberChange,
  screenerPlayerNumberChange,
  netTrafficCauserPlayerNumberChange,
  faceoffOpponentPlayerNumberChange,
  fouledPlayerNumberChange,
  selectedTrackIdChange,
  detectionsChange,
  highlightTypeChange,
  highlightPlaybackChange,
  highlightRatingChange,
  netImpactChange,
  penaltyTypeChange,
  penaltyIdChange,
  penaltyDurationChange,
  gameIncidentReasonChange,
  resetState,
  resetHardState
} = eventActionGroup;
