export class CharCodes {
  public static backspace_key_code = 8;
  public static tab_key_code = 9;
  public static enter_key_code = 13;
  public static escape_key_code = 27;
  public static space_key_code = 32;
  public static left_arrow_key_code = 37;
  public static up_arrow_key_code = 38;
  public static right_arrow_key_code = 39;
  public static down_arrow_key_code = 40;
  public static digit_zero = 48;
  public static digit_one = 49;
  public static digit_two = 50;
  public static digit_three = 51;
  public static digit_four = 52;
  public static digit_five = 53;
  public static digit_six = 54;
  public static digit_seven = 55;
  public static digit_eight = 56;
  public static a_key_code = 65;
  public static b_key_code = 66;
  public static c_key_code = 67;
  public static d_key_code = 68;
  public static e_key_code = 69;
  public static f_key_code = 70;
  public static g_key_code = 71;
  public static h_key_code = 72;
  public static i_key_code = 73;
  public static k_key_code = 75;
  public static l_key_code = 76;
  public static m_key_code = 77;
  public static n_key_code = 78;
  public static o_key_code = 79;
  public static p_key_code = 80;
  public static q_key_code = 81;
  public static r_key_code = 82;
  public static s_key_code = 83;
  public static t_key_code = 84;
  public static u_key_code = 85;
  public static v_key_code = 86;
  public static w_key_code = 87;
  public static x_key_code = 88;
  public static y_key_code = 89;
  public static z_key_code = 90;
}
