import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GameEvent } from '../../domain/game-event';
import { Shift } from '../../domain/shift';

const videoPlayerActionGroup = createActionGroup({
  source: 'Game Actions',
  events: {
    'Game Id Change': props<{ gameId: string }>(),
    // Seeks video. Can be called from any component.
    'Video Time External Change': props<{
      videoTimeExternal: number;
      random?: string;
    }>(),
    'Video Status Change': props<{ videoStatus: 'play' | 'pause' }>(),
    'Is Interrupted Change': props<{ isInterrupted: boolean }>(),
    'Time Elapsed Change': props<{ timeElapsed: number }>(),
    'Penalties Change': props<{ penalties: GameEvent[] }>(),
    'Video Time Lag Change': props<{ videoTimeLag: number }>(),
    'Puck Possession State Change': props<{
      puckPossessionState: { team: string; player: string };
    }>(),
    'Active Shifts Change': props<{ activeShifts: Shift[] }>(),

    'Reset State': props<{ gameId: string }>(),
    'Reset Hard State': props<{ gameId: string }>(),
    'Ignore Action': emptyProps(),
    'Random Change': props<{ random: string }>()
  }
});

export const {
  gameIdChange,
  videoTimeExternalChange,
  videoStatusChange,
  isInterruptedChange,
  timeElapsedChange,
  penaltiesChange,
  videoTimeLagChange,
  puckPossessionStateChange,
  activeShiftsChange,
  randomChange,
  resetState,
  resetHardState
} = videoPlayerActionGroup;
