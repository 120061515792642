import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Official } from '../domain/official';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfficialService {
  private baseUrl = environment.API_HOST + '/api/officials';

  constructor(private http: HttpClient) {}

  searchOfficialsByName(query: string): Observable<Official[]> {
    const params = new HttpParams().set('query', query);
    return this.http.get<Official[]>(`${this.baseUrl}/search`, {
      params
    });
  }

  fetchByInternalIds(officialIds: number[]): Observable<Official[]> {
    const params = new HttpParams().set('ids', officialIds.join(','));
    return this.http.get<Official[]>(`${this.baseUrl}/by-ids`, {
      params
    });
  }
}
