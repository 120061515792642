import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Game } from '../domain/game';

@Component({
  selector: 'app-video-tracking-controls',
  templateUrl: './video-tracking-controls.component.html',
  styleUrls: ['./video-tracking-controls.component.css']
})
export class VideoTrackingControlsComponent implements OnInit {
  _frameRate = 25;
  _frameOffset = 0;
  _durationFilter = 0;

  debugMode = true;
  detectionMode = false;

  teamControl = new UntypedFormControl();
  debugControl = new UntypedFormControl();

  @Input()
  game: Game;

  @Input()
  initialFrameRate: number;

  set frameRate(value: number) {
    this._frameRate = value;
    this.frameRateChange.emit(value);
  }

  get frameRate() {
    return this._frameRate;
  }

  @Input()
  currentFrame = 0;

  @Input()
  set durationFilter(value: number) {
    this._durationFilter = value;
    this.durationFilterChange.emit(value);
  }

  get durationFilter() {
    return this._durationFilter;
  }

  @Output()
  statusChange = new EventEmitter<'loaded' | 'unloaded'>();

  @Output()
  frameRateChange = new EventEmitter<number>();

  @Output()
  frameOffsetChange = new EventEmitter<number>();

  @Output()
  durationFilterChange = new EventEmitter<number>();

  @Output()
  debugModeChange = new EventEmitter<boolean>();

  @Output()
  detectionModeChange = new EventEmitter<boolean>();

  @Output()
  teamChange = new EventEmitter<string[]>();

  team: any;

  set frameOffset(value: number) {
    this._frameOffset = value;
    this.frameOffsetChange.emit(value);
  }

  get frameOffset() {
    return this._frameOffset;
  }

  ngOnInit() {
    this._frameRate = this.initialFrameRate;
    this.teamControl.setValue([
      this.game?.homeTeam,
      this.game?.awayTeam,
      'none'
    ]);
    this.teamControl.valueChanges.subscribe((value) => {
      this.teamChange.emit(value);
    });
    this.debugControl.valueChanges.subscribe((value) => {
      this.debugModeChange.emit(!value);
    });
    this.debugControl.setValue(true);
  }

  debugModeChanged() {
    this.debugModeChange.emit(this.debugMode);
  }

  toggleDetectionMode() {
    this.detectionMode = !this.detectionMode;
    this.detectionModeChange.emit(this.detectionMode);
  }
}
