import { Injectable } from '@angular/core';
import { GameEvent } from '../domain/game-event';
import { EventService } from '../services/event.service';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

@Injectable()
export class TimeOnIceService {
  constructor(private eventService: EventService) {}

  loadFaceoffsAndInterruptions(gameId: string): Observable<GameEvent[]> {
    return this.eventService
      .getEvents(gameId, { eventType: 'FOINT' }, true, 0, 10000)
      .pipe(
        map((res) => res[1]),
        map((events: GameEvent[]) => {
          events = events.filter((e) =>
            ['face_off', 'interruption'].includes(e.eventType)
          );
          return events;
        })
      );
  }

  loadPenalties(gameId): Observable<GameEvent[]> {
    return this.eventService
      .getEvents(gameId, { eventType: 'penalty' }, true)
      .pipe(
        map((penalties) => [...penalties[1]]),
        map((events: GameEvent[]) => {
          events.sort((a, b) => b.videoTime - a.videoTime);
          return events;
        })
      );
  }

  loadTimeOnIce(gameId): Observable<GameEvent[]> {
    return this.eventService
      .getEvents(gameId, { eventType: 'time_on_ice' }, true)
      .pipe(
        map((timeOnIce) => [...timeOnIce[1]]),
        map((events: GameEvent[]) => {
          events.sort((a, b) => b.videoTime - a.videoTime);
          return events;
        })
      );
  }
}
