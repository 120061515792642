import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlayerService } from '../../services/player.service';
import { Player } from '../../domain/player';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-edit-player-dialog',
  templateUrl: './edit-player-dialog.component.html',
  styleUrls: ['./edit-player-dialog.component.css']
})
export class EditPlayerDialogComponent implements OnInit {
  player: Player;
  form: UntypedFormGroup;
  countries: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditPlayerDialogComponent>,
    private playerService: PlayerService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      jerseyNumber: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      dateOfBirth: null,
      countryId: null,
      gender: null,
      playerPosition: null,
      shoots: null,
      catches: null,
      height: null,
      weight: null,
      imageUrl: null
    });
  }

  ngOnInit() {
    this.playerService
      .countries()
      .subscribe((countries) => (this.countries = countries));
    this.player = this.data.player;
    if (this.player) {
      this.form.patchValue(this.data.player);
      this.form
        .get('playerPosition')
        .setValue(this.mapPosition(this.data.player.position ?? null));
    }
    this.form.get('playerPosition').valueChanges.subscribe((value) => {
      if (value === 'GOALIE') {
        this.form.get('shoots').setValue(null);
      } else {
        this.form.get('catches').setValue(null);
      }
    });
  }

  applyChanges() {
    this.dialogRef.close({ ...this.form.value, id: this.player?.id });
  }

  mapPosition(playerPosition) {
    switch (playerPosition) {
      case 'goalkeeper':
        return 'GOALIE';
      case 'defender':
        return 'DEFENSEMAN';
      case 'forward':
        return 'FORWARD';
      default:
        return null;
    }
  }

  onImageUploadComplete(url: string) {
    this.form.patchValue({ imageUrl: url });
  }
}
