<mat-form-field style="width: 120px">
  <mat-select
    [(value)]="selectedPlayerId"
    (valueChange)="filterChanged()"
    placeholder="Player"
  >
    <mat-option>All</mat-option>
    <mat-optgroup
      *ngIf="!team || game?.homeTeam === team"
      [label]="game?.homeTeam"
    >
      <mat-option *ngFor="let p of homeTeamPlayers" [value]="p.playerId"
        >{{ p.jerseyNumber }} - {{ p.name }}</mat-option
      >
    </mat-optgroup>
    <mat-optgroup
      *ngIf="!team || game?.awayTeam === team"
      [label]="game?.awayTeam"
    >
      <mat-option *ngFor="let p of awayTeamPlayers" [value]="p.playerId"
        >{{ p.jerseyNumber }} - {{ p.name }}</mat-option
      >
    </mat-optgroup>
  </mat-select>
</mat-form-field>
