<h2 mat-dialog-title>{{ team?.id ? 'Edit' : 'Create' }} Team</h2>
<mat-dialog-content>
  <form [formGroup]="form" style="display: flex; flex-wrap: wrap; gap: 4px">
    <div style="flex: 1 0 100%; display: flex">
      <app-upload-image
        style="flex: 1 0 45%; display: flex"
        bucketFolder="team-logos"
        [imageUrl]="form.get('imageUrl').value"
        (fileUploadComplete)="onImageUploadComplete($event)"
      ></app-upload-image>
      <div style="display: flex; flex-direction: column; flex: 1 0 45%">
        <mat-form-field>
          <input
            matInput
            formControlName="shortName"
            placeholder="Short Name"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="fullName" placeholder="Full Name" />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field style="flex: 1 0 100%">
      <input matInput formControlName="imageUrl" placeholder="Image Url" />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 100%">
      <mat-select placeholder="Country" formControlName="countryId">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let country of countries" [value]="country.id">{{
          country.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Team Class" formControlName="teamClass">
        <mat-option [value]="null"></mat-option>
        <mat-option value="MEN">MEN</mat-option>
        <mat-option value="WOMEN">WOMEN</mat-option>
        <mat-option value="MIXED">MIXED</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Team Type" formControlName="teamType">
        <mat-option [value]="null"></mat-option>
        <mat-option value="CLUB">CLUB</mat-option>
        <mat-option value="NATIONAL">NATIONAL</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 100%">
      <input
        matInput
        placeholder="Default League"
        [disabled]="true"
        [value]="defaultLeague?.fullName"
      />
    </mat-form-field>
    <div style="flex: 1" formArrayName="additionalLeagues">
      <div
        *ngFor="
          let additionalLeague of additionalLeaguesControls.controls;
          let i = index
        "
      >
        <div style="display: flex; gap: 4px" [formGroupName]="i">
          <mat-form-field style="flex: 1 0 20%">
            <mat-label>League</mat-label>
            <mat-select formControlName="sihfId">
              <mat-option [value]="null"></mat-option>
              <mat-option
                *ngFor="let league of availableLeagues"
                [value]="league.sihfId"
                >{{ league.shortName }} - {{ league.fullName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field style="flex: 1 0 20%">
            <mat-label>From Season</mat-label>
            <mat-select formControlName="fromSeason">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let year of seasons" [value]="year">{{
                year
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="flex: 1 0 20%">
            <mat-label>Until Season</mat-label>
            <mat-select formControlName="untilSeason">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let year of seasons" [value]="year">{{
                year
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-icon-button
            color="accent"
            (click)="removeAdditionalLeague(i)"
            ><mat-icon>clear</mat-icon></button
          >
        </div>
      </div>
      <button
        style="flex-basis: 200px"
        mat-raised-button
        color="primary"
        (click)="addAdditionalLeague()"
        >Add League</button
      >
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-raised-button
    [disabled]="!form.valid"
    color="accent"
    (click)="applyChanges()"
    >{{ team?.id ? 'Update' : 'Create' }}</button
  >
</mat-dialog-actions>
