<div>
  <button
    mat-icon-button
    [disabled]="disabled"
    [color]="color"
    *ngFor="let ratingId of ratingArr; index as i"
    [id]="'star_' + i"
    (click)="onClick(i + 1); $event.stopPropagation()"
    class="small-ratting-icon"
  >
    <mat-icon>
      {{ showIcon(i) }}
    </mat-icon>
  </button>
</div>
