import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-heads-up-game-time-state',
  templateUrl: './heads-up-game-time-state.component.html',
  styleUrls: ['./heads-up-game-time-state.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadsUpGameTimeStateComponent {
  @Input()
  currentPeriod: string;

  @Input()
  periodCount: number;

  @Input()
  gameTime: number;

  @Input()
  strengthState: string;
}
