<div class="controls-row-video">
  <button
    mat-button
    class="trim-arrow"
    matTooltip="+10s"
    (click)="extendTrimTime.emit('START')"
    ><mat-icon style="transform: rotate(180deg)">keyboard_tab</mat-icon>
  </button>
  <div class="time-info">{{ leftTime * 10 | timecode }}</div>

  <div #customSeekBar class="custom-seekbar playback">
    <div
      [style.width]="progressBarCSSWidth"
      [style.left]="progressBarCSSLeft"
      [style.right]="progressBarCSSRight"
      class="progression-bar"
    ></div>
    <div
      [style.left]="seekableBarCSSLeft"
      [style.right]="seekableBarCSSRight"
      class="seekable-bar"
    ></div>

    <div
      #startTrim
      mat-icon-button
      class="grabber start"
      (mousedown)="onDragStartLeftTrim($event)"
      (mouseup)="onDragEndLeftTrim($event)"
      [title]="leftTitle * 10 | timecode"
      [style.left]="startTrimCSSLeft"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="10"
        height="14"
        viewBox="0 0 10 14"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: rgba(0, 0, 0, 0.2);
          }
        </style>
        <path
          class="st0"
          d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
        />
      </svg>
    </div>
    <div
      #endTrim
      mat-icon-button
      class="grabber end"
      (mousedown)="onDragStartRightTrim($event)"
      (mouseup)="onDragEndRightTrim($event)"
      [title]="rightTitle * 10 | timecode"
      [style.left]="endTrimCSSLeft"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="10"
        height="14"
        viewBox="0 0 10 14"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: rgba(0, 0, 0, 0.2);
          }
        </style>
        <path
          class="st0"
          d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
        />
      </svg>
    </div>
  </div>

  <div class="time-info">{{ rightTime * 10 | timecode }}</div>
  <button
    mat-button
    class="trim-arrow"
    matTooltip="+10s"
    (click)="extendTrimTime.emit('END')"
    ><mat-icon>keyboard_tab</mat-icon>
  </button>
</div>
