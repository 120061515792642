<h2 mat-dialog-title>
  {{ title }}
</h2>

<mat-dialog-content>
  {{ message }}
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close(false)">{{ cancelButtonCaption }}</button>
  <button mat-flat-button color="accent" (click)="close(true)">{{
    okButtonCaption
  }}</button>
</mat-dialog-actions>
