import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-heads-up-player-info',
  templateUrl: './heads-up-player-info.component.html',
  styleUrls: ['./heads-up-player-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadsUpPlayerInfoComponent {
  @Input()
  position: string;

  @Input()
  player: string;

  @Input()
  handedness: string;

  @Input()
  duration: number;

  @Output()
  click = new EventEmitter<void>();

  getPositionColor() {
    switch (this.position) {
      case 'G':
        return '#0000ff60';
      case 'D':
        return '#00ff0060';
      case 'F':
        return '#ff000060';
      default:
        return 'none';
    }
  }
}
