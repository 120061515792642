import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-tag-form-field',
  templateUrl: './tag-form-field.component.html',
  styleUrls: ['./tag-form-field.component.css']
})
export class TagFormFieldComponent implements OnInit {
  selectedTags: string[] = [];
  addTagCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTags$: Observable<string[]>;
  filteredTags: Observable<string[]>;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  @Input()
  set tags(value: string[]) {
    const tags = (value ?? []).filter((t) => t);
    tags.forEach((t) => {
      if (!this.selectedTags.includes(t)) {
        this.selectedTags.push(t);
      }
    });
  }

  @Output()
  tagChange = new EventEmitter<string[]>();

  constructor(private gameService: GameService) {}

  ngOnInit(): void {
    this.allTags$ = this.gameService.allTags();
    this.filteredTags = combineLatest([
      this.allTags$,
      this.addTagCtrl.valueChanges.pipe(startWith(null))
    ]).pipe(
      map((pair) => this.matchingTags(pair[0], pair[1])),
      map((tags) => tags.filter((t) => !this.selectedTags.includes(t)))
    );
  }

  private matchingTags(allTags: string[], tag: string): string[] {
    if (!tag) {
      return allTags.slice();
    }
    return allTags.filter((t) => t.toLowerCase().includes(tag.toLowerCase()));
  }

  selectTagFromList(event: MatAutocompleteSelectedEvent) {
    this.selectedTags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.addTagCtrl.setValue(null);
    this.tagChange.emit(this.selectedTags);
  }

  enterTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLowerCase();
    if (!value) {
      return;
    }
    this.selectedTags.push(value);
    event.chipInput.clear();
    this.addTagCtrl.setValue(null);
    this.tagChange.emit(this.selectedTags);
  }

  removeTagFilter(fruit: string): void {
    const index = this.selectedTags.indexOf(fruit);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
    this.tagChange.emit(this.selectedTags);
  }
}
