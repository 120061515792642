import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlayerService } from '../../services/player.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Team } from '../../domain/team';
import { League } from '../../domain/league';
import { LeagueService } from '../../services/league.service';
import { SeasonService } from '../../services/season.service';

@Component({
  selector: 'app-edit-team-dialog',
  templateUrl: './edit-team-dialog.component.html',
  styleUrls: ['./edit-team-dialog.component.css']
})
export class EditTeamDialogComponent implements OnInit {
  team: Team;
  defaultLeague: League;
  availableLeagues: League[] = [];
  form: UntypedFormGroup;
  countries: any[];
  additionalLeagues: any[] = [];
  seasons = [];
  additionalLeaguesForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditTeamDialogComponent>,
    private playerService: PlayerService,
    private formBuilder: UntypedFormBuilder,
    private leagueService: LeagueService,
    private seasonService: SeasonService
  ) {
    this.additionalLeaguesForm = this.formBuilder.group({});
    this.form = this.formBuilder.group({
      shortName: [null, Validators.required],
      fullName: [null, Validators.required],
      countryId: [null, Validators.required],
      imageUrl: null,
      teamType: null,
      teamClass: null,
      additionalLeagues: this.formBuilder.array([])
    });
  }

  get additionalLeaguesControls(): UntypedFormArray {
    return this.form.get('additionalLeagues') as UntypedFormArray;
  }
  ngOnInit() {
    this.seasonService
      .getSeasons()
      .subscribe((result) => (this.seasons = result));
    this.playerService
      .countries()
      .subscribe((countries) => (this.countries = countries));
    this.team = this.data.team;
    this.leagueService.getLeagues().subscribe((leagues) => {
      this.availableLeagues = leagues;
      this.defaultLeague = this.availableLeagues.find(
        (league) => league.sihfId === this.data.leagueId
      );
      this.availableLeagues = this.availableLeagues.filter(
        (league) => league.sihfId !== this.defaultLeague.sihfId
      );
    });
    if (this.team) {
      this.team.additionalLeagues.forEach(() => this.addAdditionalLeague());
      this.form.patchValue(this.data.team);
    }
  }

  applyChanges() {
    this.dialogRef.close({
      ...this.form.value,
      id: this.team?.id
    });
  }

  newAdditionalLeague(): UntypedFormGroup {
    return this.formBuilder.group({
      sihfId: null,
      fromSeason: null,
      untilSeason: null
    });
  }

  addAdditionalLeague() {
    this.additionalLeaguesControls.push(this.newAdditionalLeague());
  }

  removeAdditionalLeague(i) {
    this.additionalLeaguesControls.removeAt(i);
  }

  onImageUploadComplete(url: string) {
    this.form.patchValue({ imageUrl: url });
  }
}
