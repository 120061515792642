import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-camera-angles-select-dialog',
  templateUrl: './camera-angles-select-dialog.component.html',
  styleUrls: ['./camera-angles-select-dialog.component.scss']
})
export class CameraAnglesSelectDialogComponent implements OnInit {
  cameraIndex = 0;
  videos: any[];
  message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CameraAnglesSelectDialogComponent>
  ) {}

  ngOnInit() {
    this.message = this.data?.message;
    this.videos = this.data?.videos;
  }

  switchCamera(cameraIndex: number) {
    const video = this.videos[cameraIndex];
    this.dialogRef.close({ cameraAngle: video.cameraAngle });
  }
}
