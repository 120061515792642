import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Game } from '../../domain/game';
import { EventService } from '../../services/event.service';
import { ShiftsService } from '../../services/shifts.service';
import { PlayerSummaryService, TeamSummary } from './player-summary.service';

@Component({
  selector: 'app-player-summary',
  templateUrl: './player-summary.component.html',
  styleUrls: ['./player-summary.component.css']
})
export class PlayerSummaryComponent implements OnInit {
  game: Game;
  homeTeam: string;
  awayTeam: string;
  homeTeamSummary: TeamSummary;
  awayTeamSummary: TeamSummary;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private shiftSummaryService: PlayerSummaryService,
    private shiftsService: ShiftsService,
    private eventService: EventService
  ) {}

  async ngOnInit() {
    this.game = this.data.game as Game;
    const shifts = await firstValueFrom(
      this.shiftsService.getShifts(this.game)
    );
    const penalties = await firstValueFrom(
      this.eventService
        .getEvents(this.game._id, { eventType: 'penalty' }, true, 0, 100)
        .pipe(
          map((res) => {
            res[1].sort((a, b) => b.videoTime - a.videoTime);
            return res;
          })
        )
    );
    const goals = await firstValueFrom(
      this.eventService.getEvents(
        this.game._id,
        { eventType: 'shot', shotOutcome: 'goal' },
        false,
        0,
        100
      )
    );

    this.homeTeam = this.game.homeTeam;
    this.awayTeam = this.game.awayTeam;
    this.homeTeamSummary = this.shiftSummaryService.summary(
      this.homeTeam,
      this.game,
      shifts,
      penalties[1],
      goals[1]
    );
    this.awayTeamSummary = this.shiftSummaryService.summary(
      this.awayTeam,
      this.game,
      shifts,
      penalties[1],
      goals[1]
    );
  }
}
