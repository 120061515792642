<ng-container *ngFor="let p of periods">
  <label
    class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
    [for]="p"
  >
    <input
      type="radio"
      [id]="p"
      class="mdl-radio__button"
      name="p-options"
      [checked]="p === period"
      (change)="onClick($event.target.value)"
      [value]="p"
    />
    <span class="mdl-radio__label"> {{ p }} </span>
  </label>
</ng-container>
