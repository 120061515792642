import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { League } from '../domain/league';
import { SeasonService } from '../services/season.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-season-sync-dialog',
  templateUrl: './season-sync-dialog.component.html',
  styleUrls: ['./season-sync-dialog.component.css']
})
export class SeasonSyncDialogComponent implements OnInit {
  season: number;
  league = '1';

  leagues: League[];
  seasons = this.seasonService.getSeasons();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private seasonService: SeasonService,
    private dialogRef: MatDialogRef<SeasonSyncDialogComponent>
  ) {}

  async ngOnInit() {
    this.leagues = this.data.leagues;
    this.season = await firstValueFrom(this.seasonService.getCurrentSeason());
  }

  syncToCockpit() {
    this.dialogRef.close([this.season, this.league]);
  }
}
