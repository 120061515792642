import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor/js/webrtc_adaptor.js';
import { IPlayable, VgApiService } from '@videogular/ngx-videogular/core';
import { BitrateOptions } from '@videogular/ngx-videogular/core/lib/interfaces/bitrate-options.interface';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { AlertService } from './services/alert.service';

/** Values are -1 if not set */
export interface WebrtcConnectionStats {
  streamId: string;

  firstByteSentCount: number;
  firstBytesReceivedCount: number;
  lastBytesSent: number;
  lastBytesReceived: number;
  totalBytesSentCount: -1;
  totalBytesReceivedCount: number;
  startTime: number;
  currentTimestamp: number;
  lastTime: number;
  timerId: number;

  videoPacketsLost: number;
  fractionLost: null;
  framesReceived: number;
  framesDropped: number;
  framesDecoded: number;

  lastFramesEncoded: number;
  totalFramesEncodedCount: number;

  audioLevel: number;
  qualityLimitationReason: string;

  resWidth: number;
  resHeight: number;
  srcFps: number;
  frameWidth: number;
  frameHeight: number;

  videoRoundTripTime: number;
  videoJitter: number;
  audioRoundTripTime: number;
  audioJitter: number;
  audioPacketsLost: number;
  audioJitterAverageDelay: number;
  videoJitterAverageDelay: number;
}

export interface WebrtcBitrateMeasurements {
  streamId: string;
  command: 'notification';
  definition: 'bitrateMeasurement';
  audioBitrate: number;
  videoBitrate: number;
  targetBitrate: number;
}

@Directive({
  selector: '[appWebRTC]'
})
export class WebrtcDirective implements OnInit, OnChanges, OnDestroy {
  // https://github.com/ant-media/StreamApp/blob/master/src/main/webapp/player_with_timestamp.html
  // https://github.com/ant-media/StreamApp/tree/master/src/main/webapp

  @Input() appWebRTC: string;

  @Output() availableBitrates: EventEmitter<BitrateOptions[]> =
    new EventEmitter();
  @Output() connectionStats: EventEmitter<WebrtcConnectionStats> =
    new EventEmitter();
  @Output() bitrateMeasurement: EventEmitter<WebrtcBitrateMeasurements> =
    new EventEmitter();

  vgFor: string;
  target: IPlayable;
  preload: boolean;
  config: any;
  crossorigin: string;
  streamId: string;
  subscriptions: Subscription[] = [];

  private webRTCAdaptor?: WebRTCAdaptor;
  private iceConnected: boolean;

  constructor(
    private ref: ElementRef,
    public API: VgApiService,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vgHls?.currentValue) {
      this.createPlayer();
    } else if (changes.vgHlsHeaders && changes.vgHlsHeaders.currentValue) {
      // Do nothing. We don't want to create a or destroy a player if the headers change.
    } else {
      this.destroyPlayer();
    }
  }

  onPlayerReady() {
    console.log('[webrtc] onPlayerReady');
    this.crossorigin = this.ref.nativeElement.getAttribute('crossorigin');
    this.preload = this.ref.nativeElement.getAttribute('preload') !== 'none';
    this.vgFor = this.ref.nativeElement.getAttribute('vgFor');

    if (this.vgFor) {
      this.target = this.API.getMediaById(this.vgFor);
    } else {
      this.target = this.API.getDefaultMedia();
    }

    this.config = {
      autoStartLoad: this.preload
    };

    this.createPlayer();

    if (!this.preload) {
      this.subscriptions.push(
        this.API.subscriptions.play.subscribe(() => {
          console.log('[WebRTC] play');
          this.webRTCAdaptor.play(this.streamId, undefined, '', []);
        })
      );
    }
  }

  createPlayer() {
    console.log('[WebRTC] createPlayer', this.appWebRTC);
    if (this.webRTCAdaptor) {
      // already initialized
      return;
    }

    if (!this.appWebRTC?.startsWith('wss://') || !this.API.isPlayerReady) {
      return;
    }

    const [webSocketUrl, _] = this.appWebRTC.split('?');
    console.log('[WebRTC] WebSocketURL', webSocketUrl);
    this.streamId = new URL(this.appWebRTC).searchParams.get('streamId');
    console.log('[WebRTC] streamId', this.streamId);
    this.iceConnected = false;
    console.log('[WebRTC] targetId', this.target.id);

    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: webSocketUrl,
      mediaConstraints: {
        video: true,
        audio: true
      },
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }]
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
      },
      remoteVideoId: this.target.id,
      isPlayMode: true,
      debug: false,
      dataChannelEnabled: false,
      candidateTypes: ['tcp', 'udp'],
      callback: (info, obj) => this.playerEvent(info, obj),
      callbackError: (err, message) => this.playerError(err, message),
      viewerInfo: 'data-collector_49ing_' + this.authService.userProfile?.sub
    });
    (window as any).webRTCAdaptor = this.webRTCAdaptor;
  }

  private async playerEvent(info: string, description: any) {
    if (info === 'initialized') {
      console.log('[WebRTC] initialized');
      this.iceConnected = false;
      this.webRTCAdaptor.getStreamInfo(this.streamId);
    } else if (info === 'streamInformation') {
      console.log('[WebRTC] stream information', description.streamInfo);

      const videoList = [];
      videoList.push({
        qualityIndex: 0,
        width: 0,
        height: 0,
        bitrate: 0,
        mediaType: 'video',
        label: 'AUTO'
      });
      description['streamInfo'].forEach((item, index) => {
        videoList.push({
          qualityIndex: ++index,
          width: item.streamWidth,
          height: item.streamHeight,
          bitrate: item.bitrate,
          mediaType: 'video',
          label: item.name
        });
      });
      // It contains both VP8 and H264. So there are duplicates
      this.availableBitrates.emit(videoList);

      if (this.preload) {
        console.log('[WebRTC] preload');
        this.webRTCAdaptor.play(this.streamId, undefined, '', []);
      }
    } else if (info === 'ice_connection_state_changed') {
      console.log(
        '[WebRTC] ice connection state changed to ' + description.state
      );
      if (
        description.state === 'connected' ||
        description.state === 'completed'
      ) {
        this.iceConnected = true;
      }
    } else if (info === 'play_started') {
      console.log('[WebRTC] play started');
      this.webRTCAdaptor.enableStats(description.streamId);
      this.API.play();
    } else if (info === 'updated_stats') {
      this.connectionStats.emit(description);
    } else if (info === 'play_finished') {
      console.log('[WebRTC] play finished');

      // if play_finished event is received, it has two meanings
      // 1. stream is really finished
      // 2. ice connection cannot be established and server reports play_finished event
      // check that publish may start again
      if (this.iceConnected) {
        console.log('[WebRTC] Retrying ICE connection in 3s...');
        // webrtc connection was successful and try to play again with webrtc
        setTimeout(() => {
          this.webRTCAdaptor.getStreamInfo(this.streamId);
        }, 3000);
      } else {
        console.log('[WebRTC] connection was not successful');
      }
    } else if (info === 'closed') {
      console.log('[WebRTC] connection closed');
      if (typeof description != 'undefined') {
        console.log(
          '[WebRTC] Connection closed: ' + JSON.stringify(description)
        );
      }
    } else if (info === 'bitrateMeasurement') {
      this.bitrateMeasurement.emit(description);
      if (
        description.audioBitrate + description.videoBitrate >
        description.targetBitrate
      ) {
        // TODO: show network stalling warning
        console.log('[WebRTC] network bandwidth not sufficient');
        // TODO: reset stalling again
        this.target.isWaiting = true;
      }
    } else if (info === 'resolutionChangeInfo') {
      console.log(
        '[WebRTC] Resolution is changed to ' + description['streamHeight']
      );
    }
  }

  private playerError(error: string, message: string) {
    console.log('[WebRTC] error', JSON.stringify(error), message);
    if (error === 'no_stream_exist') {
      this.alertService.showError(
        `WebRTC stream with id "${this.streamId}" does not exist. Please check the video URL and make sure the stream has started.`
      );
    } else {
      this.alertService.showError(`WebRTC error ${error}: ${message}`);
    }
  }

  private destroyPlayer() {
    if (this.webRTCAdaptor) {
      this.webRTCAdaptor.disableStats(this.streamId);
      this.webRTCAdaptor.closeWebSocket();
      this.webRTCAdaptor = null;
    }
  }

  setBitrate(bitrate: BitrateOptions) {
    this.webRTCAdaptor.forceStreamQuality(this.streamId, bitrate.height);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.destroyPlayer();
    delete this.webRTCAdaptor;
  }
}
