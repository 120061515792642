<div
  *ngIf="shiftsHome.length > 0 && visible"
  [ngClass]="{ 'home-players': !swapSides, 'away-players': swapSides }"
>
  <ng-container *ngFor="let s of shiftsHome; trackBy: trackByFn">
    <app-heads-up-player-info
      [position]="s.player?.key | playerPosition"
      [player]="s.player?.playerNumber"
      [handedness]="s.player?.shoots"
      [duration]="shiftDuration(s.onEvent.videoTime)"
      [class.selected]="selectedPlayer === s.player?.playerNumber"
      (click)="selectPlayer(s.player)"
    >
    </app-heads-up-player-info>
  </ng-container>
</div>

<div *ngIf="gameTime && visible" class="game-time">
  <app-heads-up-game-score
    class="game-score"
    [homeTeam]="homeTeam"
    [awayTeam]="awayTeam"
    [selectedTeam]="selectedTeam"
    [swapSides]="swapSides"
    (swap)="swappingSide($event)"
    (clickTeam)="selectTeam($event)"
  >
  </app-heads-up-game-score>
  <app-heads-up-game-time-state
    class="game-time-state"
    [currentPeriod]="period"
    [periodCount]="periodCount"
    [gameTime]="gameTime"
    [strengthState]="strengthState"
  >
  </app-heads-up-game-time-state>
  <app-heads-up-interruption-state
    [interrupted]="isInterrupted"
  ></app-heads-up-interruption-state>
</div>

<div
  *ngIf="shiftsAway.length > 0 && visible"
  class="away-players"
  [ngClass]="{ 'away-players': !swapSides, 'home-players': swapSides }"
>
  <ng-container *ngFor="let s of shiftsAway; trackBy: trackByFn">
    <app-heads-up-player-info
      [position]="s.player?.key | playerPosition"
      [player]="s.player?.playerNumber"
      [handedness]="s.player?.shoots"
      [class.selected]="selectedPlayer === s.player?.playerNumber"
      (click)="selectPlayer(s.player)"
    >
    </app-heads-up-player-info>
  </ng-container>
</div>
