<h4 mat-dialog-title>Player Summary</h4>
<mat-dialog-content>
  <a
    *ngIf="game.gameId"
    [href]="'https://www.sihf.ch/de/game-center/game/#/' + game.gameId"
    target="_blank"
    >SIHF Game Center</a
  >

  <mat-tab-group mat-stretch-tabs>
    <mat-tab [label]="homeTeam" style="padding-top: 24px">
      <table>
        <thead>
          <tr>
            <th colspan="2">Player</th>
            <th>Pos</th>
            <th>Goals</th>
            <th class="number">+/-</th>
            <th class="number">TOI Total</th>
            <th class="number">TOI PP</th>
            <th class="number">TOI PK</th>
          </tr>
        </thead>
        <tr *ngFor="let s of homeTeamSummary?.players">
          <td class="number">{{ s.player.jerseyNumber }}</td>
          <td>{{ s.player.name }}</td>
          <td>{{ s.player.position | titlecase }}</td>
          <td class="number">{{ s.goals }}</td>
          <td class="number">{{ s.plusMinus }}</td>
          <td class="number">{{ s.total | mmss }}</td>
          <td class="number">{{ s.totalPP | mmss }}</td>
          <td class="number">{{ s.totalPK | mmss }}</td>
          <td></td>
          <td></td>
        </tr>
        <tfoot>
          <td></td>
          <td></td>
          <td></td>
          <td class="number">{{ homeTeamSummary?.goals }}</td>
          <td class="number">{{ homeTeamSummary?.plusMinus }}</td>
          <td class="number">{{ homeTeamSummary?.total | hhmmss }}</td>
          <td class="number">{{ homeTeamSummary?.totalPP | hhmmss }}</td>
          <td class="number">{{ homeTeamSummary?.totalPK | hhmmss }}</td>
        </tfoot>
      </table>
      <mat-progress-bar
        *ngIf="!homeTeamSummary"
        mode="indeterminate"
        style="width: 100%"
      ></mat-progress-bar>
    </mat-tab>

    <mat-tab [label]="awayTeam" style="padding-top: 24px">
      <table>
        <thead>
          <tr>
            <th colspan="2">Player</th>
            <th>Pos</th>
            <th>Goals</th>
            <th>+/-</th>
            <th class="number">Total</th>
            <th class="number">PP</th>
            <th class="number">PK</th>
          </tr>
        </thead>
        <tr *ngFor="let s of awayTeamSummary?.players">
          <td class="number">{{ s.player.jerseyNumber }}</td>
          <td>{{ s.player.name }}</td>
          <td>{{ s.player.position | titlecase }}</td>
          <td class="number">{{ s.goals }}</td>
          <td class="number">{{ s.plusMinus }}</td>
          <td class="number">{{ s.total | mmss }}</td>
          <td class="number">{{ s.totalPP | mmss }}</td>
          <td class="number">{{ s.totalPK | mmss }}</td>
        </tr>
        <tfoot>
          <td></td>
          <td></td>
          <td></td>
          <td class="number">{{ awayTeamSummary?.goals }}</td>
          <td class="number">{{ awayTeamSummary?.plusMinus }}</td>
          <td class="number">{{ awayTeamSummary?.total | hhmmss }}</td>
          <td class="number">{{ awayTeamSummary?.totalPP | hhmmss }}</td>
          <td class="number">{{ awayTeamSummary?.totalPK | hhmmss }}</td>
        </tfoot>
      </table>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
