<span class="player-info">
  <span class="player-number">{{ player | playerTrim }}</span>
  <span
    [ngStyle]="{ 'background-color': getPositionColor() }"
    class="player-position"
    >{{ position }}</span
  >
  <span class="handedness" [title]="'shoots ' + handedness">{{
    handedness?.substr(0, 1)?.toUpperCase()
  }}</span>
</span>
