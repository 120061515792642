import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timecode' })
export class TimeCodePipe implements PipeTransform {
  transform(timeMs: number) {
    if (typeof timeMs === 'undefined' || timeMs === null) {
      return '';
    }

    const hours = Math.floor(timeMs / (3600 * 10));
    const minutes = Math.floor((timeMs % (3600 * 10)) / (60 * 10));
    const seconds = Math.floor(((timeMs % (3600 * 10)) % (60 * 10)) / 10);

    return (
      this.formatWithTwoDigits(hours) +
      ':' +
      this.formatWithTwoDigits(minutes) +
      ':' +
      this.formatWithTwoDigits(seconds)
    );
  }

  private formatWithTwoDigits(value: number) {
    return value < 10 ? '0' + value : '' + value;
  }
}
