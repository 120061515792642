import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-multi-select-all',
  templateUrl: './multi-select-all.component.html',
  styleUrls: ['./multi-select-all.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectAllComponent,
      multi: true
    }
  ]
})
export class MultiSelectAllComponent implements OnInit, ControlValueAccessor {
  @Input() itemList: any[];
  @Input() keyValue: string;
  @Input() keyTitle: string;
  @Input() keySubTitle: string;
  @Input() label: string;
  @ViewChild('allSelected') private allSelected: MatCheckbox;
  form: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      select: ''
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((changes) => {
      if (this.onChange) {
        this.onChange(changes.select);
      }
    });
  }

  selectOneItem(value: any) {
    if (this.allSelected.checked) {
      this.allSelected.checked = false;
      this.form.controls['select'].patchValue([value]);
      return;
    }
    if (this.formLength === this.itemList.length || this.formLength === 0) {
      this.allSelected.checked = true;
    }
  }

  toggleAllSelection() {
    if (!this.allSelected.checked) {
      this.form.controls['select'].patchValue([]);
      this.allSelected.checked = true;
    }
  }

  getSelectTrigger(): string {
    if (this.formLength === this.itemList.length) {
      return 'All';
    }
    const firstItems = this.itemList
      .filter((item) => this.formValue?.includes(item[this.keyValue]))
      .map((item) => item[this.keyTitle])
      .slice(0, 3)
      .join(', ');
    return this.formValue?.length < 4
      ? firstItems
      : `${firstItems} + ${this.formValue?.length - 3}`;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.form.controls['select'].setValue(obj);
  }

  isIndeterminate() {
    return this.formLength !== this.itemList.length && this.formLength !== 0;
  }

  private get formValue() {
    return this.form.controls['select']?.value;
  }

  private get formLength() {
    return this.formValue?.length ?? 0;
  }
}
