import { Player } from './player';

export enum PlayerType {
  KEEPER = 'keeper',
  DEFENDER = 'defender',
  FORWARD = 'forward'
}

export enum GameStatus {
  NEW = 'new',
  IN_COLLECTION = 'in_collection',
  IN_EXTENDED_COLLECTION = 'in_extended_collection',
  QUALITY_CONTROL = 'quality_control',
  COMPLETE = 'complete',
  FAILED = 'failed',
  ABANDONED = 'abandoned'
}

export enum AggregationStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  FAILED = 'failed'
}

export enum FinalizationStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  FAILED = 'failed'
}

export enum SeasonType {
  REGULAR = 'Regular',
  PLAYOFF = 'Playoff',
  PLAYOUT = 'Playout',
  EXHIBITION = 'Exhibition',
  OTHER = 'Other'
}

export enum IdType {
  MASTER = 'Master',
  SIHF = 'SIHF',
  ELITE_PROSPECTS = 'EliteProspects',
  INSTAT = 'InStat',
  NHL = 'NHL'
}

export enum DataSet {
  LITE = 'LITE',
  LITE_PLUS = 'LITE_PLUS',
  FULL = 'FULL'
}

export enum SihfGameStatus {
  BEFORE_GAME = 0,
  PERIOD_1_RUNNING = 1,
  BREAK_1_2 = 2,
  PERIOD_2_RUNNING = 3,
  BREAK_2_3 = 4,
  PERIOD_3_RUNNING = 5,
  PERIOD_3_FINISHED = 6,
  OVERTIME = 7,
  SHOOTOUT = 8,
  END = 9,
  END_AFTER_OVERTIME = 10,
  END_AFTER_SHOOTOUT = 11,
  GAME_COMPLETE = 12,
  GAME_POSTPONED = 13,
  GAME_CANCELLED = 14,
  FORFEIT_HOME = 15,
  FORFEIT_AWAY = 16,
  ZERO_POINTS = 17
}

export enum GameProgress {
  BEFORE_START = 'before_start',
  IN_PROGRESS = 'in_progress',
  ENDED = 'ended'
}

export enum CameraAngle {
  MAIN = 'main',
  TV_FEED = 'tvfeed',
  MULTIVIEW = 'multiview',
  BEHIND_GOAL_1 = 'behindgoal1',
  BEHIND_GOAL_2 = 'behindgoal2',
  OVER_GOAL_1 = 'overgoal1',
  OVER_GOAL_2 = 'overgoal2',
  CORNER_1 = 'corner1',
  CORNER_2 = 'corner2',
  CLOSE_UP = 'closeup',
  GAME_CLOCK = 'gameclock',
  PANORAMIC = 'panoramic',
  FALLBACK = 'fallback'
}

export enum VideoVariantEnum {
  LIVERECORDING = 'liverecording',
  CLIPPED = 'clipped'
}

export enum GoalClipsStatus {
  OPEN = 'open',
  CREATING_VIDEO = 'creating_video',
  COMPLETED = 'completed',
  ERROR = 'error'
}

export type VideoVariantType = `${VideoVariantEnum}`;
export type VideoFormat = 'mp4' | 'hls' | 'dash' | 'webm' | 'webrtc';

export interface Video {
  id: string;
  cameraAngle?: CameraAngle;
  variant?: VideoVariantType;
  format?: VideoFormat;

  url?: string;
  urlSigned?: string;
  downloadStatus?: 'new' | 'in_progress' | 'complete' | 'error';
  downloadError?: string;
  frameRate?: number;
  trackOffset?: number; // cv tracking video offset

  /** number of seconds the video is delayed compared to the first video */
  offset?: number;

  /** media server stream identifier */
  streamId?: string;

  /** temporary attributes */
  mediaId?: string;
  index?: number;
}

export interface AuditLogEntry {
  action: string;
  date: string;
  user: string;
}

export interface EventStats {
  _id: {
    eventType: string;
    gameId: string;
  };
  start: number;
  end: number;
  count: number;
}

export interface CVPipelineStatus {
  taskArn: string;
  jobId: string;
  period: string;
  phase: string;
  liveMode: boolean;
  createdAt?: Date;
  startedAt?: Date;
  stoppedAt?: Date;
  start: Date;
  stop?: Date;
  createdBy?: string;
  lastStatus?:
    | 'SUBMITTED'
    | 'PENDING'
    | 'RUNNABLE'
    | 'STARTING'
    | 'RUNNING'
    | 'SUCCEEDED'
    | 'FAILED';
  exitCode?: number;
  version?: string;
}

export class GameOfficial {
  public officialId?: number;
  public unknownOfficialInfo?: string;
  public officialRoleInGame: OfficialRoleInGame;
  public sequenceNumber: number;
}

export interface GameOfficialInputField {
  label: string;
  role: OfficialRoleInGame;
  sequenceNumber: number;
}

export type OfficialRoleInGame = 'head' | 'linesperson';

export class Game {
  public _id: string;
  public gameId: string; // SIHF game ID filled by Reporter API
  public league: string;
  public date: string;
  public venueTimezoneId?: string;
  public venueUTCOffset?: string;
  public videos?: Video[] = [];
  public season: string;
  public seasonType: SeasonType;
  public homeTeam: string;
  public homeTeamId: string;
  public awayTeam: string;
  public awayTeamId: string;
  public homeGoals: number;
  public awayGoals: number;
  public overtime: boolean;
  public shootout: boolean;

  public isCVProcessed: boolean; // whether cv-pipeline has been run
  public homeTeamStartPosition?: 'left' | 'right' = 'left';
  public cvPipeline?: CVPipelineStatus[] = [];
  public topScorerIds?: string[] = [];

  public homeTeamColor?: string; // hex code
  public awayTeamColor?: string; // hex code

  public eventCollectorName: string;
  public tags: string[];

  // status fields
  public progress: GameProgress;
  public status: GameStatus = GameStatus.NEW;
  public aggregationStatus?: AggregationStatus;
  public aggregationTaskArn?: string;
  public aggregationStartDate?: string;
  public aggregationEndDate?: string;
  public finalization: {
    start?: Date;
    status: FinalizationStatus;
    commandId?: string;
    instanceId?: string;
  };
  public goalClipsStatus?: GoalClipsStatus;
  public isLiveCollection: boolean;
  public isTrainingGame: boolean;
  public isSihfLive: boolean;
  public lagClock: boolean;
  public autoPuckPossessionEvents: boolean;
  public disableUpdates: boolean;
  public auditLog: AuditLogEntry[] = [];
  public lastSihfExportId: number;
  public idType: IdType;
  public dataSet: DataSet;
  public sihfStatus: SihfGameStatus;
  public isHighlightCollection: boolean;
  public isGameIncidentCollection?: boolean;
  public goalClips: boolean;
  public isLiveDraftEvents: boolean;
  public isCameraTimeSynchronized?: boolean; // whether the timing of all cameras has been synced
  public isAssistsCollection: boolean;

  // home team
  // goalkeepers
  public homeGoalkeeper: string;
  public homeGoalkeeperID: string;
  public homeBackupGoalkeeper: string;
  public homeBackupGoalkeeperID: string;
  // first line
  public homeFirstLineLeftDefence: string;
  public homeFirstLineLeftDefenceID: string;
  public homeFirstLineRightDefence: string;
  public homeFirstLineRightDefenceID: string;
  public homeFirstLineCenter: string;
  public homeFirstLineCenterID: string;
  public homeFirstLineLeftForward: string;
  public homeFirstLineLeftForwardID: string;
  public homeFirstLineRightForward: string;
  public homeFirstLineRightForwardID: string;
  // second line
  public homeSecondLineLeftDefence: string;
  public homeSecondLineLeftDefenceID: string;
  public homeSecondLineRightDefence: string;
  public homeSecondLineRightDefenceID: string;
  public homeSecondLineCenter: string;
  public homeSecondLineCenterID: string;
  public homeSecondLineLeftForward: string;
  public homeSecondLineLeftForwardID: string;
  public homeSecondLineRightForward: string;
  public homeSecondLineRightForwardID: string;
  // third line
  public homeThirdLineLeftDefence: string;
  public homeThirdLineLeftDefenceID: string;
  public homeThirdLineRightDefence: string;
  public homeThirdLineRightDefenceID: string;
  public homeThirdLineCenter: string;
  public homeThirdLineCenterID: string;
  public homeThirdLineLeftForward: string;
  public homeThirdLineLeftForwardID: string;
  public homeThirdLineRightForward: string;
  public homeThirdLineRightForwardID: string;
  // fourth line
  public homeFourthLineLeftDefence: string;
  public homeFourthLineLeftDefenceID: string;
  public homeFourthLineRightDefence: string;
  public homeFourthLineRightDefenceID: string;
  public homeFourthLineCenter: string;
  public homeFourthLineCenterID: string;
  public homeFourthLineLeftForward: string;
  public homeFourthLineLeftForwardID: string;
  public homeFourthLineRightForward: string;
  public homeFourthLineRightForwardID: string;
  // fifth line - filled automatically
  public homeFifthLineLeftDefence: string;
  public homeFifthLineLeftDefenceID: string;
  public homeFifthLineRightDefence: string;
  public homeFifthLineRightDefenceID: string;
  public homeFifthLineCenter: string;
  public homeFifthLineCenterID: string;
  public homeFifthLineLeftForward: string;
  public homeFifthLineLeftForwardID: string;
  public homeFifthLineRightForward: string;
  public homeFifthLineRightForwardID: string;
  // extra line - only filled manually
  public homeExtraLineDefence: string;
  public homeExtraLineDefenceID: string;
  public homeExtraLineForward: string;
  public homeExtraLineForwardID: string;

  // away team
  // goalkeepers
  public awayGoalkeeper: string;
  public awayGoalkeeperID: string;
  public awayBackupGoalkeeper: string;
  public awayBackupGoalkeeperID: string;
  // first line
  public awayFirstLineLeftDefence: string;
  public awayFirstLineLeftDefenceID: string;
  public awayFirstLineRightDefence: string;
  public awayFirstLineRightDefenceID: string;
  public awayFirstLineCenter: string;
  public awayFirstLineCenterID: string;
  public awayFirstLineLeftForward: string;
  public awayFirstLineLeftForwardID: string;
  public awayFirstLineRightForward: string;
  public awayFirstLineRightForwardID: string;
  // second line
  public awaySecondLineLeftDefence: string;
  public awaySecondLineLeftDefenceID: string;
  public awaySecondLineRightDefence: string;
  public awaySecondLineRightDefenceID: string;
  public awaySecondLineCenter: string;
  public awaySecondLineCenterID: string;
  public awaySecondLineLeftForward: string;
  public awaySecondLineLeftForwardID: string;
  public awaySecondLineRightForward: string;
  public awaySecondLineRightForwardID: string;
  // third line
  public awayThirdLineLeftDefence: string;
  public awayThirdLineLeftDefenceID: string;
  public awayThirdLineRightDefence: string;
  public awayThirdLineRightDefenceID: string;
  public awayThirdLineCenter: string;
  public awayThirdLineCenterID: string;
  public awayThirdLineLeftForward: string;
  public awayThirdLineLeftForwardID: string;
  public awayThirdLineRightForward: string;
  public awayThirdLineRightForwardID: string;
  // fourth line
  public awayFourthLineLeftDefence: string;
  public awayFourthLineLeftDefenceID: string;
  public awayFourthLineRightDefence: string;
  public awayFourthLineRightDefenceID: string;
  public awayFourthLineCenter: string;
  public awayFourthLineCenterID: string;
  public awayFourthLineLeftForward: string;
  public awayFourthLineLeftForwardID: string;
  public awayFourthLineRightForward: string;
  public awayFourthLineRightForwardID: string;
  // fifth line - filled automatically
  public awayFifthLineLeftDefence: string;
  public awayFifthLineLeftDefenceID: string;
  public awayFifthLineRightDefence: string;
  public awayFifthLineRightDefenceID: string;
  public awayFifthLineCenter: string;
  public awayFifthLineCenterID: string;
  public awayFifthLineLeftForward: string;
  public awayFifthLineLeftForwardID: string;
  public awayFifthLineRightForward: string;
  public awayFifthLineRightForwardID: string;
  // extra line - only filled manually
  public awayExtraLineDefence: string;
  public awayExtraLineDefenceID: string;
  public awayExtraLineForward: string;
  public awayExtraLineForwardID: string;

  public officials: GameOfficial[] = [];

  public eventStats?: EventStats[];

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  getTeams() {
    return [
      { id: this.homeTeamId, shortName: this.homeTeam },
      { id: this.awayTeamId, shortName: this.awayTeam }
    ];
  }

  getGoalkeepers(team: string): string[] {
    if (team === this.homeTeam) {
      return this.getHomeTeamGoalkeepers();
    } else if (team === this.awayTeam) {
      return this.getAwayTeamGoalkeepers();
    } else {
      throw new Error('Unknown team: ' + team);
    }
  }

  getDefensemen(team: string): string[] {
    if (team === this.homeTeam) {
      return this.getHomeTeamDefensemen();
    } else if (team === this.awayTeam) {
      return this.getAwayTeamDefensemen();
    } else {
      throw new Error('Unknown team: ' + team);
    }
  }

  getForwards(team: string): string[] {
    if (team === this.homeTeam) {
      return this.getHomeTeamForwards();
    } else if (team === this.awayTeam) {
      return this.getAwayTeamForwards();
    } else {
      console.log('Unknown team: ' + team);
      return [];
    }
  }

  getHomeTeamGoalkeepers(): string[] {
    return [this.homeGoalkeeper, this.homeBackupGoalkeeper];
  }

  getAwayTeamGoalkeepers(): string[] {
    return [this.awayGoalkeeper, this.awayBackupGoalkeeper];
  }

  getHomeTeamDefensemen(): string[] {
    return [
      this.homeFirstLineLeftDefence,
      this.homeFirstLineRightDefence,
      this.homeSecondLineLeftDefence,
      this.homeSecondLineRightDefence,
      this.homeThirdLineLeftDefence,
      this.homeThirdLineRightDefence,
      this.homeFourthLineLeftDefence,
      this.homeFourthLineRightDefence,
      this.homeFifthLineLeftDefence,
      this.homeFifthLineRightDefence,
      this.homeExtraLineDefence
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamForwards(): string[] {
    return [
      this.homeFirstLineLeftForward,
      this.homeFirstLineCenter,
      this.homeFirstLineRightForward,
      this.homeSecondLineLeftForward,
      this.homeSecondLineCenter,
      this.homeSecondLineRightForward,
      this.homeThirdLineLeftForward,
      this.homeThirdLineCenter,
      this.homeThirdLineRightForward,
      this.homeFourthLineLeftForward,
      this.homeFourthLineCenter,
      this.homeFourthLineRightForward,
      this.homeFifthLineLeftForward,
      this.homeFifthLineCenter,
      this.homeFifthLineRightForward,
      this.homeExtraLineForward
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAwayTeamDefensemen(): string[] {
    return [
      this.awayFirstLineLeftDefence,
      this.awayFirstLineRightDefence,
      this.awaySecondLineLeftDefence,
      this.awaySecondLineRightDefence,
      this.awayThirdLineLeftDefence,
      this.awayThirdLineRightDefence,
      this.awayFourthLineLeftDefence,
      this.awayFourthLineRightDefence,
      this.awayFifthLineLeftDefence,
      this.awayFifthLineRightDefence,
      this.awayExtraLineDefence
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAwayTeamForwards(): string[] {
    return [
      this.awayFirstLineLeftForward,
      this.awayFirstLineCenter,
      this.awayFirstLineRightForward,
      this.awaySecondLineLeftForward,
      this.awaySecondLineCenter,
      this.awaySecondLineRightForward,
      this.awayThirdLineLeftForward,
      this.awayThirdLineCenter,
      this.awayThirdLineRightForward,
      this.awayFourthLineLeftForward,
      this.awayFourthLineCenter,
      this.awayFourthLineRightForward,
      this.awayFifthLineLeftForward,
      this.awayFifthLineCenter,
      this.awayFifthLineRightForward,
      this.awayExtraLineForward
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamPlayers(): string[] {
    let homeTeamPlayers = [this.homeGoalkeeper, this.homeBackupGoalkeeper];
    homeTeamPlayers = homeTeamPlayers.concat(this.getHomeTeamDefensemen());
    homeTeamPlayers = homeTeamPlayers.concat(this.getHomeTeamForwards());
    return homeTeamPlayers.filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAwayTeamPlayers(): string[] {
    let awayTeamPlayers = [this.awayGoalkeeper, this.awayBackupGoalkeeper];
    awayTeamPlayers = awayTeamPlayers.concat(this.getAwayTeamDefensemen());
    awayTeamPlayers = awayTeamPlayers.concat(this.getAwayTeamForwards());
    return awayTeamPlayers.filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAllPlayers(): string[] {
    return [...this.getHomeTeamPlayers(), ...this.getAwayTeamPlayers()];
  }

  getPlayersByTeam(team: string): string[] {
    if (team === this.homeTeam) {
      return this.getHomeTeamPlayers();
    } else if (team === this.awayTeam) {
      return this.getAwayTeamPlayers();
    } else {
      throw new Error('Unknown team: ' + team);
    }
  }

  getAllPlayersObj(): Player[] {
    return [
      ...this.getPlayersAsObj(this.homeTeam),
      ...this.getPlayersAsObj(this.awayTeam)
    ];
  }

  getPlayersAsObj(team: string): Player[] {
    const players =
      team === this.homeTeam
        ? this.getHomeTeamPlayers()
        : this.getAwayTeamPlayers();
    return players
      .filter((p) => p && p !== 'n/a')
      .map((p) => {
        try {
          return this.getPlayerObj(p, team);
        } catch (err) {
          console.error('Failed to getPlayerObj: ' + err.message);
          return null;
        }
      });
  }

  getPlayerObj(playerNumber: string, team: string): Player {
    const teamId = team === this.homeTeam ? this.homeTeamId : this.awayTeamId;

    if (
      !playerNumber ||
      playerNumber === 'n/a' ||
      playerNumber.indexOf('-') === -1
    ) {
      return {
        team,
        teamId
      } as Player;
    }
    const homeTeamOrAwayTeam = team === this.homeTeam;
    const jerseyNumber = parseInt(playerNumber.split('-')[0].trim(), 10);
    const playerId = this.getPlayerIdByJerseyNumber(
      homeTeamOrAwayTeam,
      playerNumber
    );
    const name = playerNumber.split('-')[1].trim();
    const position = this.getPlayerPosition(playerNumber, team);
    const positionIndex = this.getPositionIndex(playerNumber, team);
    const line = this.getLine(playerNumber, team);
    const key = Object.keys(this).find((k) => this[k] === playerNumber);

    return {
      playerId,
      jerseyNumber,
      key,
      name,
      team,
      teamId,
      position,
      positionIndex,
      line,
      playerNumber
    } as Player;
  }

  private getLine(playerNumber: string, team: string) {
    const playerType = this.getPlayerType(playerNumber, team);
    if (playerType === PlayerType.DEFENDER) {
      return 1 + Math.floor(this.getDefensemen(team).indexOf(playerNumber) / 2);
    } else if (playerType === PlayerType.FORWARD) {
      return 1 + Math.floor(this.getForwards(team).indexOf(playerNumber) / 3);
    } else if (playerType === PlayerType.KEEPER) {
      return this.getGoalkeepers(team).indexOf(playerNumber) + 1;
    } else {
      console.warn('Cannot find line index for player type: ' + playerType);
      return -1;
    }
  }

  private getPositionIndex(playerNumber: string, team: string) {
    const playerType = this.getPlayerType(playerNumber, team);
    if (playerType === PlayerType.DEFENDER) {
      return this.getDefensemen(team).indexOf(playerNumber) % 2;
    } else if (playerType === PlayerType.FORWARD) {
      return this.getForwards(team).indexOf(playerNumber) % 3;
    } else if (playerType === PlayerType.KEEPER) {
      return 0;
    } else {
      console.warn('Cannot find line index for player type: ' + playerType);
      return -1;
    }
  }

  getPlayerType(playerNumber: string, team: string): PlayerType {
    if (team === this.homeTeam) {
      if (this.getHomeTeamDefensemen().indexOf(playerNumber) > -1) {
        return PlayerType.DEFENDER;
      } else if (this.getHomeTeamForwards().indexOf(playerNumber) > -1) {
        return PlayerType.FORWARD;
      } else if (
        this.homeGoalkeeper === playerNumber ||
        this.homeBackupGoalkeeper === playerNumber
      ) {
        return PlayerType.KEEPER;
      } else {
        console.warn(
          'Could not find type for player ' + playerNumber + ' in team ' + team
        );
        return null;
      }
    } else {
      if (this.getAwayTeamDefensemen().indexOf(playerNumber) > -1) {
        return PlayerType.DEFENDER;
      } else if (this.getAwayTeamForwards().indexOf(playerNumber) > -1) {
        return PlayerType.FORWARD;
      } else if (
        this.awayGoalkeeper === playerNumber ||
        this.awayBackupGoalkeeper === playerNumber
      ) {
        return PlayerType.KEEPER;
      } else {
        console.warn(
          'Could not find type for player ' + playerNumber + ' in team ' + team
        );
        return null;
      }
    }
  }

  getPlayerPosition(playerNumber: string, team: string): string {
    const g = this.getGoalkeepers(team).indexOf(playerNumber);
    if (g > -1) {
      return 'goalkeeper';
    }
    const d = this.getDefensemen(team).indexOf(playerNumber);
    if (d > -1) {
      return 'defender';
    }
    const f = this.getForwards(team).indexOf(playerNumber);
    if (f > -1) {
      return 'forward';
    }
    throw new Error('Could not find position for player: ' + playerNumber);
  }

  getPlayerPositionNameAndIndex(playerNumber: string, team: string): string {
    const g = this.getGoalkeepers(team).indexOf(playerNumber);
    if (g > -1) {
      return 'goalkeeper';
    }
    const d = this.getDefensemen(team).indexOf(playerNumber);
    if (d > -1) {
      const pos = ['left', 'right'];
      return 'defender-' + pos[d % 2];
    }
    const f = this.getForwards(team).indexOf(playerNumber);
    if (f > -1) {
      const pos = ['left', 'center', 'right'];
      return 'forward-' + pos[f % 3];
    }
    throw new Error('Could not find position for player: ' + playerNumber);
  }

  getPlayerIdByJerseyNumber(
    homeOrWayTeam: boolean,
    playerNumber: string
  ): string {
    const players = homeOrWayTeam
      ? this.getHomeTeamPlayersById()
      : this.getAwayTeamPlayersById();
    const result = players
      .filter((t) => t[0] != null && t[1] != null)
      .filter((player) => player[1] === playerNumber);
    if (result.length === 1) {
      return result[0][0];
    } else {
      return null;
    }
  }

  hasLineup() {
    return (
      this.getAllPlayers().length > 0 &&
      this.homeGoalkeeperID &&
      this.awayGoalkeeperID
    );
  }

  private getHomeTeamPlayersById(): [string, string][] {
    return [
      [this.homeGoalkeeperID, this.homeGoalkeeper],
      [this.homeBackupGoalkeeperID, this.homeBackupGoalkeeper],
      [this.homeFirstLineLeftDefenceID, this.homeFirstLineLeftDefence],
      [this.homeFirstLineRightDefenceID, this.homeFirstLineRightDefence],
      [this.homeSecondLineLeftDefenceID, this.homeSecondLineLeftDefence],
      [this.homeSecondLineRightDefenceID, this.homeSecondLineRightDefence],
      [this.homeThirdLineLeftDefenceID, this.homeThirdLineLeftDefence],
      [this.homeThirdLineRightDefenceID, this.homeThirdLineRightDefence],
      [this.homeFourthLineLeftDefenceID, this.homeFourthLineLeftDefence],
      [this.homeFourthLineRightDefenceID, this.homeFourthLineRightDefence],
      [this.homeFifthLineLeftDefenceID, this.homeFifthLineLeftDefence],
      [this.homeFifthLineRightDefenceID, this.homeFifthLineRightDefence],
      [this.homeExtraLineDefenceID, this.homeExtraLineDefence],

      [this.homeFirstLineLeftForwardID, this.homeFirstLineLeftForward],
      [this.homeFirstLineCenterID, this.homeFirstLineCenter],
      [this.homeFirstLineRightForwardID, this.homeFirstLineRightForward],
      [this.homeSecondLineLeftForwardID, this.homeSecondLineLeftForward],
      [this.homeSecondLineCenterID, this.homeSecondLineCenter],
      [this.homeSecondLineRightForwardID, this.homeSecondLineRightForward],
      [this.homeThirdLineLeftForwardID, this.homeThirdLineLeftForward],
      [this.homeThirdLineCenterID, this.homeThirdLineCenter],
      [this.homeThirdLineRightForwardID, this.homeThirdLineRightForward],
      [this.homeFourthLineLeftForwardID, this.homeFourthLineLeftForward],
      [this.homeFourthLineCenterID, this.homeFourthLineCenter],
      [this.homeFourthLineRightForwardID, this.homeFourthLineRightForward],
      [this.homeFifthLineLeftForwardID, this.homeFifthLineLeftForward],
      [this.homeFifthLineCenterID, this.homeFifthLineCenter],
      [this.homeFifthLineRightForwardID, this.homeFifthLineRightForward],
      [this.homeExtraLineForwardID, this.homeExtraLineForward]
    ];
  }

  private getAwayTeamPlayersById(): [string, string][] {
    return [
      [this.awayGoalkeeperID, this.awayGoalkeeper],
      [this.awayBackupGoalkeeperID, this.awayBackupGoalkeeper],
      [this.awayFirstLineLeftDefenceID, this.awayFirstLineLeftDefence],
      [this.awayFirstLineRightDefenceID, this.awayFirstLineRightDefence],
      [this.awaySecondLineLeftDefenceID, this.awaySecondLineLeftDefence],
      [this.awaySecondLineRightDefenceID, this.awaySecondLineRightDefence],
      [this.awayThirdLineLeftDefenceID, this.awayThirdLineLeftDefence],
      [this.awayThirdLineRightDefenceID, this.awayThirdLineRightDefence],
      [this.awayFourthLineLeftDefenceID, this.awayFourthLineLeftDefence],
      [this.awayFourthLineRightDefenceID, this.awayFourthLineRightDefence],
      [this.awayFifthLineLeftDefenceID, this.awayFifthLineLeftDefence],
      [this.awayFifthLineRightDefenceID, this.awayFifthLineRightDefence],
      [this.awayExtraLineDefenceID, this.awayExtraLineDefence],

      [this.awayFirstLineLeftForwardID, this.awayFirstLineLeftForward],
      [this.awayFirstLineCenterID, this.awayFirstLineCenter],
      [this.awayFirstLineRightForwardID, this.awayFirstLineRightForward],
      [this.awaySecondLineLeftForwardID, this.awaySecondLineLeftForward],
      [this.awaySecondLineCenterID, this.awaySecondLineCenter],
      [this.awaySecondLineRightForwardID, this.awaySecondLineRightForward],
      [this.awayThirdLineLeftForwardID, this.awayThirdLineLeftForward],
      [this.awayThirdLineCenterID, this.awayThirdLineCenter],
      [this.awayThirdLineRightForwardID, this.awayThirdLineRightForward],
      [this.awayFourthLineLeftForwardID, this.awayFourthLineLeftForward],
      [this.awayFourthLineCenterID, this.awayFourthLineCenter],
      [this.awayFourthLineRightForwardID, this.awayFourthLineRightForward],
      [this.awayFifthLineLeftForwardID, this.awayFifthLineLeftForward],
      [this.awayFifthLineCenterID, this.awayFifthLineCenter],
      [this.awayFifthLineRightForwardID, this.awayFifthLineRightForward],
      [this.awayExtraLineForwardID, this.awayExtraLineForward]
    ];
  }

  getOfficialIds() {
    return this.officials
      .map((official) => official.officialId)
      .filter((id) => !!id);
  }
}
