/**
 * Findes the index of the first value that fulfills the predicate using binary search
 *
 * @param values
 * @param predicate
 */
export function findIndexSorted<T>(
  values: T[],
  predicate: (T) => number
): number {
  if (values.length === 0 || predicate(values[0]) > 0) {
    return -1;
  }

  // initial values for start, middle and end
  let start = 0;
  let stop = values.length - 1;
  let middle = Math.floor((start + stop) / 2);

  // While the middle is not what we're looking for and the list does not have a single item
  while (predicate(values[middle]) !== 0 && start < stop) {
    if (predicate(values[middle]) > 0) {
      stop = middle - 1;
    } else {
      start = middle + 1;
    }

    // recalculate middle on every iteration
    middle = Math.floor((start + stop) / 2);
  }

  if (predicate(values[middle]) <= 0) {
    return middle;
  }

  return middle - 1;
}
