import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Review, ReviewStatus } from '../domain/review';
import { ReviewService } from '../services/review.service';

@Injectable()
export class ReviewResolver implements Resolve<Review> {
  constructor(private reviewService: ReviewService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Review> {
    const reviewId = route.params['reviewId'];
    if (reviewId === 'new') {
      const review = {
        status: ReviewStatus.TO_REVIEW
      } as Review;
      return of(review);
    }
    return this.reviewService.getReview(reviewId);
  }
}
