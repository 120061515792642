import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

const PLAYER_ID_REGEXP = /^[0-9]{1,9}$/;

export function validatePlayerId(formControl: UntypedFormControl): any {
  return !formControl.value || PLAYER_ID_REGEXP.test(formControl.value)
    ? null
    : {
        validatePlayerId: {
          valid: false
        }
      };
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[validatePlayerId][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useValue: validatePlayerId, multi: true }
  ]
})
export class PlayerIdValidatorDirective {}
