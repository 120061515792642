import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shift } from '../domain/shift';
import { activeShiftsChange } from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { Game } from '../domain/game';
import { Player } from '../domain/player';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {
  private url = environment.API_HOST + '/api/shifts';

  constructor(
    private store: Store<GlobalState>,
    private http: HttpClient,
    private logger: NGXLogger
  ) {}

  public updateActiveShifts(gameTime: number, shifts: Shift[]) {
    const activeShifts = shifts.filter(
      (s) =>
        s.onEvent.gameTime <= gameTime &&
        s.offEvent &&
        gameTime < s.offEvent.gameTime
    );
    this.store.dispatch(activeShiftsChange({ activeShifts }));
  }

  getShifts(game: Game): Observable<Shift[]> {
    return this.http.get<any>(`${this.url}/${game._id}`).pipe(
      map((events) =>
        events.map((s) => {
          let player: Player;
          try {
            player = game.getPlayerObj(s.onEvent.playerNumber, s.team);
          } catch (err) {
            this.logger.warn(
              `Could not getPlayerObj: playerNumber=${s.onEvent.playerNumber}, team=${s.team}, error=${err.message}`
            );
          }
          return { ...s, player } as Shift;
        })
      )
    );
  }

  createShift(shift: Shift) {
    return this.http.post<Shift>(`${this.url}/${shift.onEvent.gameId}`, shift);
  }

  updateShift(shift: Shift) {
    return this.http.put<Shift>(`${this.url}/${shift.onEvent.gameId}`, shift);
  }

  deleteShift(shift: Shift) {
    return this.http.post<void>(
      `${this.url}/${shift.onEvent.gameId}/delete`,
      shift
    );
  }

  mergeShifts(shift0: Shift, shift1: Shift) {
    return this.http.post<Shift>(`${this.url}/${shift0.onEvent.gameId}/merge`, {
      shift0,
      shift1
    });
  }
}
