import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';

@Component({
  selector: 'app-game-sync-dialog',
  templateUrl: './game-sync-dialog.component.html',
  styleUrls: ['./game-sync-dialog.component.css']
})
export class GameSyncDialogComponent implements OnInit {
  game: Game;
  syncPeriod = 'all';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GameSyncDialogComponent>
  ) {}

  ngOnInit() {
    this.game = this.data.game;
  }

  syncToCockpit() {
    this.dialogRef.close(this.syncPeriod);
  }
}
