import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameEvent, StrengthState } from '../../domain/game-event';
import { Review } from '../../domain/review';
import { AlertService } from '../../services/alert.service';
import { ReviewService } from '../../services/review.service';

@Component({
  selector: 'app-review-events',
  templateUrl: './review-events.component.html',
  styleUrls: ['./review-events.component.css']
})
export class ReviewEventsComponent implements OnInit {
  readonly strengthStates: StrengthState[] = [
    '5-5',
    '5-4',
    '4-5',
    '4-4',
    '5-3',
    '3-5',
    '4-3',
    '3-4',
    '3-3'
  ];
  readonly eventTypes = [
    'time_on_ice',
    'face_off',
    'shot',
    'penalty',
    'pass',
    'interruption',
    'puckPossession',
    'videoTag',
    'oddMenRush'
  ];

  review: Review;
  events: GameEvent[];
  filteredEvents: GameEvent[];

  players: string[];
  filters = {
    period: '',
    strengthState: null,
    team: '',
    playerNumber: null,
    eventType: null
  };

  constructor(
    private route: ActivatedRoute,
    private reviewService: ReviewService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.review = this.route.snapshot.data['review'];
    this.players = this.review.game.getAllPlayers();
    this.loadData();
  }

  private loadData() {
    this.reviewService.getReviewEvents(this.review._id, {}, 0, 10000).subscribe(
      (data) => {
        // @ts-ignore
        this.events = data[1] as GameEvent[];
        this.filterChanged();
      },
      (error) => {
        this.alertService.showError('Could not find events: ' + error.message);
      }
    );
  }

  filterChanged() {
    console.log('filterChanged', this.filters);
    this.filteredEvents = this.events.filter((e) => {
      if (this.filters.period && e.period !== this.filters.period) {
        return false;
      }
      if (
        this.filters.strengthState &&
        e.strengthState !== this.filters.strengthState
      ) {
        return false;
      }
      if (this.filters.team && e.team !== this.filters.team) {
        return false;
      }
      if (
        this.filters.playerNumber &&
        e.playerNumber !== this.filters.playerNumber
      ) {
        return false;
      }
      if (this.filters.eventType && e.eventType !== this.filters.eventType) {
        return false;
      }
      return true;
    });
  }

  deleteEvent(e) {
    this.reviewService.deleteReviewEvent(e).subscribe(() => {
      this.events.splice(this.events.indexOf(e), 1);
      this.filterChanged();
    });
  }
}
