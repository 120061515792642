<div
  class="video-player"
  #videoPlayerTrim
  (mouseup)="onDragEndLeftTrim($event); onDragEndRightTrim($event)"
  (mousemove)="startGrabberMove($event); endGrabberMove($event)"
>
  <vg-player (onPlayerReady)="onPlayerReady($event)">
    <vg-buffering></vg-buffering>

    <app-video-player-trim-controls
      [leftTime]="startTrimTime"
      [rightTime]="endTrimTime"
      [seekableBarCSSLeft]="seekableBarCSSLeft"
      [seekableBarCSSRight]="seekableBarCSSRight"
      [startTrimCSSLeft]="startTrimCSSLeft"
      [endTrimCSSLeft]="endTrimCSSLeft"
      [progressBarCSSWidth]="progressBarCSSWidth"
      [progressBarCSSLeft]="progressBarCSSLeft"
      [progressBarCSSRight]="progressBarCSSRight"
      [leftTitle]="startTrimTime - startOfVideoClip"
      [rightTitle]="endTrimTime - startOfVideoClip"
      (draggingLeftTrim)="onDragLeftTrim($event)"
      (draggingRightTrim)="onDragRightTrim($event)"
      (extendTrimTime)="extendTrimTime($event)"
    ></app-video-player-trim-controls>

    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <div class="switch-camera">
        <mat-select
          [(value)]="cameraIndex"
          *ngIf="game.videos"
          (selectionChange)="switchCamera($event.value)"
          style="width: 200px; padding: 16px; color: white"
        >
          <mat-option
            *ngFor="let video of game.videos; let i = index"
            [value]="i"
          >
            <span class="video-number">{{ i + 1 }} </span>
            <span>{{ video.cameraAngle | titlecase }} </span>
            <span class="video-variant" *ngIf="video.variant">{{
              video.variant
            }}</span>
            <span class="video-format" *ngIf="video.format">{{
              video.format?.toUpperCase()
            }}</span>
            <span class="video-offset" *ngIf="video.offset"
              >+{{ video.offset }}s</span
            >
          </mat-option>
        </mat-select>
      </div>
    </vg-controls>
    <video
      [vgMedia]="mediaTrim"
      #mediaTrim
      [src]="src"
      [vgHls]="src"
      #vgHls="vgHls"
      preload="auto"
      playsinline
      [volume]="0"
    ></video>
  </vg-player>
</div>

<div class="spinner-div" *ngIf="!isVideoMetadataLoaded">
  <div class="spinner">
    <mat-progress-spinner
      *ngIf="!isVideoMetadataLoaded"
      color="primary"
      diameter="50"
      mode="indeterminate"
      title="Preparing video..."
    ></mat-progress-spinner>
  </div>
</div>
