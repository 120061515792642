<button
  style="float: right; margin: 4px"
  mat-raised-button
  color="accent"
  (click)="showCreateTeamDialog(null)"
  >Add Team</button
>
<table class="mat-elevation-z0" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="shortName">
    <th *matHeaderCellDef mat-header-cell>Short Name</th>
    <td mat-cell *matCellDef="let team">
      {{ team?.shortName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell>Name</th>
    <td mat-cell *matCellDef="let team">
      {{ team?.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="imageUrl">
    <th *matHeaderCellDef mat-header-cell>Logo</th>
    <td mat-cell *matCellDef="let team">
      <img
        [src]="logoUrl(team.imageUrl)"
        style="height: 20px"
        *ngIf="team.imageUrl"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td mat-cell *matCellDef="let team">
      <button
        mat-button
        color="primary"
        (click)="updateExternalIDs($event, team)"
      >
        Update External IDs
      </button>
      <a
        mat-icon-button
        [routerLink]="['./', team.id, 'players']"
        title="List Players"
      >
        <mat-icon>list</mat-icon>
      </a>
      <button
        mat-icon-button
        (click)="$event.stopPropagation(); deleteTeam(team)"
        ><mat-icon>delete</mat-icon></button
      >
      <button
        mat-icon-button
        (click)="$event.stopPropagation(); showCreateTeamDialog(team)"
        ><mat-icon>edit</mat-icon></button
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let team; columns: columns"
    [routerLink]="['./', team.id, 'players']"
  ></tr>
</table>

<mat-progress-bar
  *ngIf="loading"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
