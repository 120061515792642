<mat-dialog-content>
  <div style="display: inline">{{ message }}</div>
  <div class="camera-angle-select" style="display: inline">
    <mat-select
      [(value)]="cameraIndex"
      *ngIf="videos"
      (selectionChange)="switchCamera($event.value)"
      style="width: 200px; padding: 16px"
    >
      <mat-option *ngFor="let video of videos; let i = index" [value]="i">
        <span class="video-data-number">{{ i + 1 }} </span>
        <span>{{ video.cameraAngle | titlecase }} </span>
        <span class="video-data-variant" *ngIf="video.variant">{{
          video.variant
        }}</span>
        <span class="video-data-format" *ngIf="video.format">{{
          video.format?.toUpperCase()
        }}</span>
        <span class="video-data-offset" *ngIf="video.offset"
          >+{{ video.offset }}s</span
        >
      </mat-option>
    </mat-select>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" class="close-button"
    >Cancel</button
  >
</mat-dialog-actions>
