import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'playerTrim' })
export class PlayerNumberTrimPipe implements PipeTransform {
  transform(inputPlayerNumber: string) {
    if (!inputPlayerNumber || inputPlayerNumber.split(' ').length < 3) {
      return inputPlayerNumber;
    }
    const elements = inputPlayerNumber.split(' ');
    let result = elements[0] + ' ' + elements[2];
    if (elements.length > 4) {
      result += ' ' + elements[3];
    }
    return result;
  }
}
