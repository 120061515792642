import { Injectable } from '@angular/core';
import { Game } from '../../domain/game';
import { GameEvent } from '../../domain/game-event';
import { Player } from '../../domain/player';
import { Shift } from '../../domain/shift';

export interface TeamSummary {
  goals: number;
  plusMinus: number;
  count: number;
  total: number;
  totalPP: number;
  totalPK: number;
  mean: number;
  players: PlayerSummary[];
}

export interface PlayerSummary {
  team: string;
  player: Player;
  goals: number;
  plusMinus: number;
  count: number;
  total: number;
  totalPP: number;
  totalPK: number;
}

const EQ_STRENGTH_STATES = ['5-5', '4-4', '3-3'];

@Injectable({
  providedIn: 'root'
})
export class PlayerSummaryService {
  summary(
    team: string,
    game: Game,
    shifts: Shift[],
    penalties: GameEvent[],
    goals: GameEvent[]
  ): TeamSummary {
    const teamShifts = shifts.filter((s) => s.player && s.player.team === team);
    const playerSummaries = game
      .getPlayersAsObj(team)
      .sort((a, b) => a.jerseyNumber - b.jerseyNumber)
      .map((player) =>
        this.playerSummary(
          shifts,
          penalties,
          goals,
          player,
          game.homeTeam === team
        )
      );
    const totals = playerSummaries.reduce(
      (toiSummary, playerSummary) => ({
        goals: toiSummary.goals + playerSummary.goals,
        plusMinus: toiSummary.plusMinus + playerSummary.plusMinus,
        total: toiSummary.total + playerSummary.total,
        totalPP: toiSummary.totalPP + playerSummary.totalPP,
        totalPK: toiSummary.totalPK + playerSummary.totalPK
      }),
      { goals: 0, plusMinus: 0, total: 0, totalPP: 0, totalPK: 0 }
    );

    return {
      count: teamShifts.length,
      ...totals,
      mean: totals.total / teamShifts.length,
      players: playerSummaries
    };
  }

  playerSummary(
    shifts: Shift[],
    penalties: GameEvent[],
    goals: GameEvent[],
    player: Player,
    homeTeam: boolean
  ): PlayerSummary {
    const playerShifts = shifts.filter(
      (s) =>
        s.player &&
        s.player.playerNumber === player.playerNumber &&
        s.offEvent &&
        s.duration
    );
    const toiPerShift = playerShifts.map((s) =>
      this.shiftSummaryForStrengthStates(s, penalties, homeTeam)
    );
    const toi = toiPerShift.reduce(
      (sum: any, t) => ({
        all: sum.all + t.all,
        pp: sum.pp + t.pp,
        pk: sum.pk + t.pk
      }),
      { all: 0, pp: 0, pk: 0 }
    );
    const goalCount = this.countGoals(player, goals);
    const playerPlusMinus = this.countPlusMinus(player, goals, homeTeam);

    return {
      team: null,
      player,
      goals: goalCount,
      plusMinus: playerPlusMinus,
      count: playerShifts.length,
      total: toi.all,
      totalPP: toi.pp,
      totalPK: toi.pk
    };
  }

  shiftSummaryForStrengthStates(
    shift: Shift,
    penaltyEvents: GameEvent[],
    isHomeTeam: boolean
  ) {
    const splitShifts = this.splitShiftOnStrengthStateChange(
      [shift],
      penaltyEvents
    );
    return splitShifts.reduce(
      (sum: any, s: Shift) => ({
        all: sum.all + s.duration,
        pp: sum.pp + (this.isPP(s, isHomeTeam) ? s.duration : 0),
        pk: sum.pk + (this.isPK(s, isHomeTeam) ? s.duration : 0)
      }),
      { all: 0, pp: 0, pk: 0 }
    );
  }

  intersects(event, shifts) {
    return shifts.filter(
      (shift) =>
        shift.onEvent.gameTime <= event.gameTime &&
        shift.offEvent &&
        event.gameTime <= shift.offEvent.gameTime
    );
  }

  splitShiftOnStrengthStateChange(shifts: Shift[], penaltyEvents: GameEvent[]) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < penaltyEvents.length; i++) {
      const penaltyEvent = penaltyEvents[i];
      const intersectedShifts = this.intersects(penaltyEvent, shifts);

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let j = 0; j < intersectedShifts.length; j++) {
        const shift = intersectedShifts[j];

        if (
          penaltyEvent.gameTime - shift.onEvent.gameTime <= 1 &&
          shift.onEvent.strengthState !== shift.offEvent.strengthState
        ) {
          // only update strength state - no additional off/on required
          shift.onEvent.strengthState = penaltyEvent.strengthState;
          shift.onEvent.gameTime = penaltyEvent.gameTime;
          continue;
        }

        if (
          shift.offEvent.gameTime - penaltyEvent.gameTime <= 1 &&
          shift.offEvent.strengthState === penaltyEvent.strengthState &&
          shift.offEvent.strengthState !== shift.onEvent.strengthState
        ) {
          // only update strength state - no additional off/on required
          shift.offEvent.strengthState = shift.onEvent.strengthState;
          shift.offEvent.gameTime = penaltyEvent.gameTime - 0.1;
          continue;
        }

        if (
          shift.onEvent.strengthState === penaltyEvent.strengthState &&
          shift.offEvent.strengthState === penaltyEvent.strengthState
        ) {
          // not adjusting shift with correct strength states
          continue;
        }

        shifts.splice(shifts.indexOf(shift), 1);
        shifts.push({
          onEvent: shift.onEvent,
          offEvent: {
            ...shift.onEvent,
            strengthState: shift.onEvent.strengthState,
            gameTime: penaltyEvent.gameTime - 0.1
          },
          duration: penaltyEvent.gameTime - shift.onEvent.gameTime
        } as Shift);
        shifts.push({
          onEvent: {
            ...shift.offEvent,
            strengthState: penaltyEvent.strengthState,
            gameTime: penaltyEvent.gameTime
          },
          offEvent: shift.offEvent,
          duration: shift.offEvent.gameTime - penaltyEvent.gameTime
        } as Shift);
      }
    }

    return shifts;
  }

  isPP(shift: Shift, isHome: boolean) {
    const [numHome, numAway] = (shift.onEvent.strengthState ?? '5-5')
      .split('-')
      .map((t) => parseInt(t, 10));
    return (isHome && numHome > numAway) || (!isHome && numAway > numHome);
  }

  isPK(shift: Shift, isHome: boolean) {
    const [numHome, numAway] = (shift.onEvent.strengthState ?? '5-5')
      .split('-')
      .map((t) => parseInt(t, 10));
    return (isHome && numHome < numAway) || (!isHome && numAway < numHome);
  }

  private countPlusMinus(
    player: Player,
    goals: GameEvent[],
    isHomeTeam: boolean
  ) {
    const plus = goals.filter(
      (g) =>
        g.plus.includes(player.playerNumber) && !this.isPowerPlay(g, isHomeTeam)
    );
    const minus = goals.filter(
      (g) =>
        g.minus.includes(player.playerNumber) &&
        !this.isPenaltyKilling(g, isHomeTeam)
    );
    return plus.length - minus.length;
  }

  private countGoals(player: Player, allGoals: GameEvent[]) {
    return allGoals.filter((g) => g.playerNumber === player.playerNumber)
      .length;
  }

  private isPowerPlay(goal: GameEvent, isHomeTeam: boolean) {
    if (this.isUnknownStrengthState(goal)) {
      return false;
    }
    const [h, a] = this.parseStrengthState(goal.strengthState);
    return isHomeTeam ? h > a : a > h;
  }

  private isPenaltyKilling(goal: GameEvent, isHomeTeam: boolean) {
    if (this.isUnknownStrengthState(goal)) {
      return false;
    }
    const [h, a] = this.parseStrengthState(goal.strengthState);
    return isHomeTeam ? a > h : h > a;
  }

  private isUnknownStrengthState(goal: GameEvent) {
    return !goal.strengthState || goal.strengthState.indexOf('-') === -1;
  }

  private parseStrengthState(strengthState: string) {
    const [h, a] = strengthState.split('-');
    const homeCount = parseInt(h, 10);
    const awayCount = parseInt(a, 10);
    return [homeCount, awayCount];
  }
}
