import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { VideoPlayerTrimControlsComponent } from './video-player-trim-controls/video-player-trim-controls.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import { VideoPlayerTrimService } from './video-player-trim.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { first } from 'rxjs/operators';
import { Game, Video } from '../domain/game';
/**
 * This component already exists in data-cockpit with some difference
 */
@Component({
  selector: 'app-video-player-trim',
  templateUrl: './video-player-trim.component.html',
  styleUrls: ['./video-player-trim.component.scss'],
  providers: [VideoPlayerTrimService]
})
export class VideoPlayerTrimComponent {
  mediaPlayerApi: VgApiService;

  @Input() cameraIndex = 0;

  @Input() game: Game;
  @Input() offset: number = 0;

  @Input() src: string | SafeResourceUrl;
  @Input() startOfVideoClip: number;

  @Input() durationVideoClip: number;
  @Input() startOfOriginalVideoClip: number;
  @Input() durationOfOriginalVideoClip: number;
  @Input() durationOfFullVideo: number;

  @Output() updateTrimVideo = new EventEmitter<{
    startTrimTime: number;
    endTrimTime: number;
  }>();

  @ViewChild(VideoPlayerTrimControlsComponent)
  trimControls: VideoPlayerTrimControlsComponent;

  startTrimTime: number;
  endTrimTime: number;
  durationTrimTime: number;

  startTrimCSSLeft: string;
  endTrimCSSLeft: string;

  seekableBarCSSLeft: string;
  seekableBarCSSRight: string;

  progressBarCSSLeft: string;
  progressBarCSSRight: string;
  progressBarCSSWidth: string;

  frontTrimmedRatio: number = 0;
  endTrimmedRatio: number = 1;

  seekRatio: number;
  initRatio: boolean = true;

  draggingLeftTrim: boolean = false;
  draggingRightTrim: boolean = false;

  isVideoMetadataLoaded: boolean = false;

  @Output() changeCameraAngle = new EventEmitter<Video>();

  constructor(
    private ref: ChangeDetectorRef,
    private videoPlayerTrimService: VideoPlayerTrimService
  ) {}

  onPlayerReady(mediaPlayerApi: VgApiService) {
    this.mediaPlayerApi = mediaPlayerApi;
    if (this.mediaPlayerApi.fsAPI.isAvailable) {
      this.mediaPlayerApi.fsAPI.nativeFullscreen = false;
    }

    const media = this.mediaPlayerApi.getDefaultMedia().subscriptions;

    media.loadedMetadata.pipe(first()).subscribe((e) => {
      this.startTrimTime = this.startOfOriginalVideoClip;
      this.durationTrimTime = this.durationOfOriginalVideoClip;

      this.isVideoMetadataLoaded = true;
      this.endTrimTime = this.startTrimTime + this.durationTrimTime;
      // emit init data
      this.confirmTrim();

      this.calculateInitPositions();
      this.mediaPlayerApi.seekTime(this.startTrimTime);
    });

    media.timeUpdate.subscribe((e) => {
      this.seekRatio =
        (this.currentTime - this.startOfVideoClip) / this.durationVideoClip;
      if (!this.initRatio) {
        this.updateProgressBarWidth();
      } else {
        this.initRatio = false;
      }
      if (this.currentTime >= this.endTrimTime) {
        this.pause();
      }
    });

    media.play.subscribe(() => {
      if (this.currentTime >= this.endTrimTime) {
        this.mediaPlayerApi.seekTime(this.startTrimTime);
      }
    });
  }

  pause() {
    if (this.mediaPlayerApi != null) {
      this.mediaPlayerApi.pause();
    }
  }

  private calculateInitPositions() {
    const leftStartTrim =
      ((this.startTrimTime - this.startOfVideoClip) / this.durationVideoClip) *
        100 +
      '%';
    const leftEndTrim =
      ((this.startTrimTime + this.durationTrimTime - this.startOfVideoClip) /
        this.durationVideoClip) *
        100 +
      '%';
    this.startTrimCSSLeft = parseInt(leftStartTrim, 10) + '%';
    this.endTrimCSSLeft = parseInt(leftEndTrim, 10) + '%';

    this.seekableBarCSSLeft = leftStartTrim;
    this.seekableBarCSSRight = 100 - parseInt(leftEndTrim, 10) + '%';

    this.progressBarCSSLeft = leftStartTrim;

    this.ref.detectChanges();

    const startTrimPos = this.trimControls.startTrimPosition;
    const customSeekBarPos = this.trimControls.seekBarPosition;
    this.frontTrimmedRatio =
      (startTrimPos.left - customSeekBarPos.left) / customSeekBarPos.width;

    const endTrimPos = this.trimControls.endTrimPosition;
    this.endTrimmedRatio =
      (endTrimPos.left - customSeekBarPos.left) / customSeekBarPos.width;
  }

  onDragEndLeftTrim(event: MouseEvent) {
    this.draggingLeftTrim = false;
  }

  onDragLeftTrim(event: boolean) {
    this.draggingLeftTrim = event;
  }

  onDragRightTrim(event: boolean) {
    this.draggingRightTrim = event;
  }

  onDragEndRightTrim(event: MouseEvent) {
    this.draggingRightTrim = false;
  }

  startGrabberMove(event) {
    if (this.draggingLeftTrim) {
      const customSeekBarPos = this.trimControls.seekBarPosition;
      const ratio =
        (event.clientX - customSeekBarPos.left) / customSeekBarPos.width;
      if (ratio <= 0) {
        this.frontTrimmedRatio = 0;
      } else if (ratio >= this.endTrimmedRatio) {
        this.frontTrimmedRatio = this.endTrimmedRatio;
      } else {
        this.frontTrimmedRatio = ratio;
      }

      this.updateSeekableStart();
    }
  }

  endGrabberMove(event) {
    if (this.draggingRightTrim) {
      const customSeekBarPos = this.trimControls.seekBarPosition;
      const ratio =
        (event.clientX - customSeekBarPos.left) / customSeekBarPos.width;
      if (ratio >= 1) {
        this.endTrimmedRatio = 1;
      } else if (ratio <= this.frontTrimmedRatio) {
        this.endTrimmedRatio = this.frontTrimmedRatio;
      } else {
        this.endTrimmedRatio = ratio;
      }
      this.updateSeekableEnd();
    }
  }

  updateSeekableStart() {
    const newStartTrimTime =
      this.startOfVideoClip + this.durationVideoClip * this.frontTrimmedRatio;
    if (this.endTrimTime - newStartTrimTime > 1) {
      this.startTrimTime = newStartTrimTime;
      this.seekableBarCSSLeft = this.frontTrimmedRatio * 100 + '%';
      this.startTrimCSSLeft = this.frontTrimmedRatio * 100 + '%';
      this.updateProgressBarWidth();
      this.updateProgressBarPosition();
      this.seekVideo(this.frontTrimmedRatio);
    }
  }

  updateSeekableEnd() {
    const newEndTrimTime =
      this.startOfVideoClip + this.durationVideoClip * this.endTrimmedRatio;
    if (newEndTrimTime - this.startTrimTime > 1) {
      this.endTrimTime = newEndTrimTime;
      this.seekableBarCSSRight = (1 - this.endTrimmedRatio) * 100 + '%';
      this.endTrimCSSLeft = this.endTrimmedRatio * 100 + '%';
      this.seekVideo(this.endTrimmedRatio);
    }
  }

  updateProgressBarWidth() {
    this.progressBarCSSWidth =
      (this.seekRatio - this.frontTrimmedRatio) * 100 + '%';
  }

  updateProgressBarPosition() {
    this.progressBarCSSLeft = this.frontTrimmedRatio * 100 + '%';
    this.progressBarCSSRight = (1 - this.endTrimmedRatio) * 100 + '%';
  }

  seekProgressBar(event: MouseEvent) {
    const customSeekBarPos = this.trimControls.seekBarPosition;
    const seekAmount =
      (event.clientX - customSeekBarPos.left) / customSeekBarPos.width;
    this.seekVideo(seekAmount);
  }

  seekVideo(seekAmount: number) {
    this.mediaPlayerApi.seekTime(
      this.startOfVideoClip + this.durationVideoClip * seekAmount
    );
  }

  confirmTrim() {
    if (this.endTrimTime - this.startTrimTime < 1) {
      return;
    }

    this.updateTrimVideo.emit({
      startTrimTime: this.startTrimTime - (this.offset ?? 0),
      endTrimTime: this.endTrimTime - (this.offset ?? 0)
    });
  }

  get currentTime(): number {
    return this.mediaPlayerApi.currentTime;
  }

  extendTrimTime(action: string) {
    const modifiedTrimTime = this.videoPlayerTrimService.extendTrimTime(
      action,
      this.startOfVideoClip,
      this.durationVideoClip,
      this.durationOfFullVideo
    );
    this.startOfVideoClip = modifiedTrimTime.startOfVideoClip;
    this.durationVideoClip = modifiedTrimTime.durationVideoClip;

    this.calculateInitPositions();
  }

  switchCamera(cameraIndex: number) {
    const video = this.game.videos[cameraIndex];
    this.changeCameraAngle.emit(video);
  }
}
