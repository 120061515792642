import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, IdToken, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { User } from '@auth0/auth0-spa-js/src/global';
import jwt_decode from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage.service';

export type Role =
  | 'supervisor'
  | 'chief_data_officer'
  | 'process_manager'
  | 'team_manager'
  | 'quality_manager'
  | 'game_operator'
  | 'validator'
  | 'collector';

@Injectable({ providedIn: 'root' })
export class AuthService {
  idToken: IdToken;
  accessToken: any;
  userProfile: User;
  roles: string[];
  scope: string[];
  authenticated$ = new ReplaySubject<boolean>(1);

  readonly collectorRoles = [
    'supervisor',
    'chief_data_officer',
    'process_manager',
    'team_manager',
    'quality_manager',
    'game_operator',
    'validator',
    'collector'
  ];

  constructor(
    private auth0Client: Auth0Client,
    public router: Router,
    private storageService: StorageService,
    private logger: NGXLogger,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  public isAuthenticated(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  public login(): void {
    this.auth0Client.loginWithRedirect().catch((err) => {
      this.logger.error('error while redirecting', err);
    });
  }

  logout() {
    this.logger.info('logout');

    this.idToken = undefined;
    this.accessToken = undefined;
    this.userProfile = undefined;
    this.roles = undefined;
    this.scope = undefined;

    this.auth0Client.logout({ returnTo: this.doc.location.origin });

    // Remove auth data and update login status
    this.authenticated$.next(false);
  }

  async handleLoginCallback() {
    this.logger.info('handleLoginCallback');
    return this.auth0Client
      .handleRedirectCallback()
      .then((result) => this.handleAuthResult(result));
  }

  async handleAuthResult(result: RedirectLoginResult) {
    this.logger.info('handleAuthResult', result);
    await this.loadAccessToken();

    // TODO: read from appState in redirectLoginResult
    const nextUrl = this.storageService.getItem('next_url');
    if (nextUrl && nextUrl !== '/auth0-callback') {
      this.logger.info('navigate to location after login', nextUrl);
      this.storageService.removeItem('next_url');
      return this.router.navigateByUrl(nextUrl);
    } else {
      return this.router.navigate(['/games']);
    }
  }

  async loadAccessToken() {
    try {
      this.logger.info('loadAccessToken...');
      this.accessToken = await this.auth0Client.getTokenSilently({
        timeoutInSeconds: 10
      });
      const decodedAccessToken = jwt_decode(this.accessToken) as any;

      const idToken = await this.auth0Client.getIdTokenClaims();
      this.saveSession(idToken, decodedAccessToken.scope);

      const user = await this.auth0Client.getUser();
      this.saveUserProfile(user, idToken);

      this.logger.info('Access token is valid');
      this.authenticated$.next(true);
    } catch (err) {
      this.logger.info('failed to load access token', err.message);
      this.authenticated$.next(false);
    }
  }

  private saveSession(idToken: IdToken, scope: string) {
    this.verifyIssuer(idToken);
    this.idToken = idToken;
    this.roles = idToken['http://49ing.ch/roles'];
    this.scope = scope?.split(' ') ?? [];
  }

  private verifyIssuer(idToken: IdToken) {
    if (idToken.iss !== this.getRequiredIssuer()) {
      throw new Error(
        `Issuer (iss) claim mismatch in the ID token; expected "${this.getRequiredIssuer()}", found "${
          idToken.iss
        }"`
      );
    }
  }

  private getRequiredIssuer() {
    const client = this.auth0Client as any;
    return client.tokenIssuer;
  }

  private saveUserProfile(profile: User, idToken: IdToken) {
    const userMetadata = idToken['http://49ing.ch/user_metadata'];

    profile.nickname =
      userMetadata && userMetadata.name ? userMetadata.name : profile.nickname;
    this.userProfile = profile;
  }

  hasPermission(permission: string): Observable<boolean> {
    return this.authenticated$.pipe(
      map((authenticated) => {
        if (!authenticated) {
          return false;
        }
        return this.scope && this.scope.indexOf(permission) > -1;
      })
    );
  }

  hasRoleSync(role: Role): boolean {
    return this.roles && this.roles.indexOf(role) > -1;
  }

  hasAnyRole(roles: Role[]) {
    return roles.some((role) => this.hasRoleSync(role));
  }

  redirectToLogin() {
    // const url = this.window.location.pathname;
    // this.storageService.setItem('next_url', url);
    this.router.navigate(['login']);
  }

  get currentRolesFormatted() {
    return this.roles
      ?.filter((r) => this.collectorRoles.includes(r))
      .map((r) => r.replace('_', ' '));
  }
}
