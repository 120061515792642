<table class="mdl-data-table mdl-js-data-table">
  <thead>
    <tr>
      <th>Period</th>
      <th>VideoTime</th>
      <th>Game Clock</th>
      <th>Duration</th>
      <th>Strength State</th>
      <th class="mdl-data-table__cell--non-numeric">Team</th>
      <th class="mdl-data-table__cell--non-numeric">Player</th>
      <th>
        <input
          type="checkbox"
          id="validations-check"
          name="validations-check"
          [(ngModel)]="validationIssues"
          (ngModelChange)="filterChanged()"
        />
        <label class="form-check-label" for="validations-check"
          >Only Validation issues</label
        >
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="
        let s of filteredShifts
          | paginate
            : { itemsPerPage: 15, currentPage: page, id: 'shifts_pagination' }
      "
      [class.validation]="s.validations && s.validations.length > 0"
      [class.edit]="s.editMode"
    >
      <td [class.period-overlapping]="periodOverlappingShift(s)"
        >{{ s.onEvent.period }}/{{ s.offEvent?.period }}</td
      >
      <td>
        <a href="#" (click)="seekEvent(s.onEvent, $event)">{{
          s.onEvent.videoTime | hhmmss
        }}</a>
        <ng-container *ngIf="s.offEvent">
          -
          <a href="#" (click)="seekEvent(s.offEvent, $event)">{{
            s.offEvent.videoTime | hhmmss
          }}</a>
        </ng-container>
      </td>
      <td>
        {{ s.onEvent.gameTime | countdown : s.onEvent.period }}
        <ng-container *ngIf="s.offEvent">
          -
          {{ s.offEvent.gameTime | countdown : s.offEvent.period }}
        </ng-container>
      </td>
      <td class="duration" [class.long-shift]="longShift(s)"
        >{{ duration(s) * 1000 | date : 'mm:ss' }}<br
      /></td>
      <td [class.different-strength-state]="differentStrengthState(s)">
        {{ s.onEvent.strengthState }}
        <ng-container *ngIf="s.offEvent"
          >/{{ s.offEvent?.strengthState }}</ng-container
        >
      </td>
      <td class="mdl-data-table__cell--non-numeric"
        >{{ s.onEvent.team }}<br
      /></td>
      <td class="mdl-data-table__cell--non-numeric">
        <ng-container *ngIf="!s.editMode">{{
          s.onEvent.playerNumber
        }}</ng-container>

        <mat-select
          *ngIf="s.editMode"
          [(value)]="s.editPlayerNumber"
          placeholder="Player"
        >
          <mat-option></mat-option>
          <mat-option
            *ngFor="let player of players[s.onEvent.team]"
            [value]="player.playerNumber"
            >{{ player.playerNumber }}</mat-option
          >
        </mat-select>
      </td>
      <td class="mdl-data-table__cell--non-numeric">
        <ng-container *ngFor="let validation of s.validations">
          <mat-icon
            *ngIf="validation.status === 'success'"
            class="success"
            [title]="validation.message"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="validation.status === 'warning'"
            class="warning"
            [title]="validation.message"
            >warning</mat-icon
          >
          <mat-icon
            *ngIf="validation.status === 'error'"
            class="error"
            [title]="validation.message"
            >error</mat-icon
          >
        </ng-container>
      </td>
      <td>
        <button mat-icon-button *ngIf="!s.editMode" (click)="editShift(s)"
          ><mat-icon>edit</mat-icon></button
        >
        <button mat-icon-button *ngIf="s.editMode" (click)="saveShift(s)"
          ><mat-icon>check</mat-icon></button
        >
        <button mat-icon-button *ngIf="s.editMode" (click)="cancelEdit(s)"
          ><mat-icon>cancel</mat-icon></button
        >
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="12" style="text-align: center">
        <pagination-controls
          (pageChange)="page = $event"
          id="shifts_pagination"
        ></pagination-controls>
      </th>
    </tr>
  </tfoot>
</table>
