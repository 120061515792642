import { Injectable } from '@angular/core';
import { GameEvent } from '../domain/game-event';

@Injectable({
  providedIn: 'root'
})
export class GameIncidentService {
  constructor() {}

  isWrongUncalledPenaltyEvent(event: GameEvent) {
    return (
      event?.eventType === 'game_incident' &&
      event?.officialsCallAssessment === 'wrong' &&
      event?.officialsCallAction === 'non-call'
    );
  }

  isCalledPenaltyEvent(event: GameEvent) {
    return (
      event?.eventType === 'game_incident' &&
      event?.officialsCallAction === 'call'
    );
  }

  isWithReason(event?: GameEvent) {
    return event?.eventType === 'game_incident' && !!event?.gameIncidentReason;
  }

  updateUncalledPenaltySeverity(event?: GameEvent) {
    if (!this.isWrongUncalledPenaltyEvent(event)) {
      event.officialsUncalledPenaltySeverity = null;
    }
  }

  isValidUncalledPenaltyEvent(event?: GameEvent) {
    return !(
      this.isWrongUncalledPenaltyEvent(event) &&
      !event?.officialsUncalledPenaltySeverity
    );
  }

  updateCalledPenaltySeverity(event?: GameEvent) {
    if (!this.isCalledPenaltyEvent(event)) {
      event.officialsPenaltyCallSeverity = null;
    }
  }

  applyDefaultValues(event?: GameEvent) {
    if (event?.eventType !== 'game_incident') {
      return;
    }
    if (!event.officialsCallAssessment) {
      event.officialsCallAssessment = 'unspecified';
    }
    if (
      !event.officialsPenaltyCallSeverity &&
      this.isCalledPenaltyEvent(event)
    ) {
      event.officialsPenaltyCallSeverity = 'unspecified';
    }
    if (!event.officialsCallAction) {
      event.officialsCallAction = 'call';
    }
  }
}
