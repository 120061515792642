<mat-form-field>
  <mat-label>{{ positionName }}</mat-label>
  <input
    type="text"
    matInput
    [attr.aria-label]="positionName"
    [placeholder]="'Type to search entire database'"
    [formControl]="playerFormControl"
    [matAutocomplete]="auto"
    [ngClass]="{
      warning: isClassWarning(
        playerId$ | async,
        playerPosition$ | async,
        isTeamPlayer$ | async
      ),
      danger: isClassDanger(
        playerId$ | async,
        playerPosition$ | async,
        isTeamPlayer$ | async
      )
    }"
  />

  <button
    *ngIf="playerFormControl.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="$event.stopPropagation(); clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-hint
    *ngIf="
      player?.teamShortName &&
      (playerId$ | async) &&
      (playerPosition$ | async) !== 'missing' &&
      (isTeamPlayer$ | async) === false
    "
    class="warning-text"
    >From {{ player?.teamShortName }}
  </mat-hint>
  <mat-hint
    *ngIf="(playerPosition$ | async) === 'non-default'"
    align="end"
    class="warning-text"
    >Non Default Position</mat-hint
  >
  <mat-hint
    *ngIf="
      isShowPlayerMissingInRegister(
        playerPosition$ | async,
        playerName$ | async
      )
    "
    align="end"
    class="danger-text"
    >Player is missing in the register "{{ idType }}"</mat-hint
  >
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selectionChange($event.option.value)"
    [displayWith]="displayPlayerFn"
  >
    <mat-option
      *ngFor="let p of filteredPlayers$ | async"
      [value]="p"
      [hidden]="(playerId$ | async) === p.id"
    >
      <ng-container *ngIf="p.jerseyNumber">
        {{ p.jerseyNumber }} -
      </ng-container>
      {{ p.lastName }} {{ p.firstName }}
      <ng-container *ngIf="p.position">{{
        p.position[0].toUpperCase()
      }}</ng-container
      >/<ng-container *ngIf="p.shoots">{{
        p.shoots[0].toUpperCase()
      }}</ng-container>

      <i style="flex: 1 1 auto"></i>
      <ng-container *ngIf="p.teamShortName">
        <span class="ing-team-name">
          {{ p.teamShortName }}
        </span>
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
