<mat-form-field>
  <mat-label>{{ officialLabel }}</mat-label>

  <input
    type="text"
    matInput
    [matTooltip]="tooltip()"
    [matTooltipShowDelay]="1000"
    [matTooltipPosition]="'below'"
    [attr.aria-label]="officialLabel"
    [placeholder]="'Type to search entire official\'s database'"
    [formControl]="officialFormControl"
    [matAutocomplete]="officialAutoComplete"
    required="false"
    (blur)="clearIfNotSet()"
  />

  <button
    *ngIf="officialFormControl.value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="$event.stopPropagation(); clear()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-hint *ngIf="isGameOfficialUnknown()" align="start" class="danger-text"
    >{{ gameOfficialIsUnknown() }}
  </mat-hint>

  <mat-autocomplete
    #officialAutoComplete="matAutocomplete"
    (optionSelected)="selectionChange($event.option.value)"
    [displayWith]="officialFullName"
  >
    <mat-option *ngFor="let o of filteredOfficials$ | async" [value]="o">
      {{ officialFullName(o) }}
      <ng-container *ngIf="o.officialType || o.sihfId">
        (
        <span *ngIf="o.sihfId">SIHF-ID: {{ o.sihfId }}</span>
        <span *ngIf="o.officialType" class="official-type">
          , {{ o.officialType }}
        </span>
        )
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
