import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Game } from '../domain/game';
import { GameEvent } from '../domain/game-event';
import { Shift } from '../domain/shift';
import { AlertService } from '../services/alert.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-shifts-overview',
  templateUrl: './shifts-overview.component.html',
  styleUrls: ['./shifts-overview.component.css']
})
export class ShiftsOverviewComponent implements OnInit {
  private static readonly LONG_SHIFT_THRESHOLD = 2 * 60;

  _playerId: string;
  validationIssues = false;

  allShifts: Shift[] = [];
  filteredShifts: Shift[] = [];
  page = 1;
  players: any = {};

  @Input()
  game: Game;

  @Input()
  set shifts(shifts: Shift[]) {
    this.allShifts = shifts;
    this.filterChanged();
  }

  @Input()
  set playerId(playerId: string) {
    this._playerId = playerId;
    this.filterChanged();
  }

  @Output()
  seek = new EventEmitter<GameEvent>();

  constructor(
    private eventService: EventService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (!this.game) {
      return; // workaround for test
    }
    this.players[this.game.homeTeam] = this.game
      .getAllPlayersObj()
      .filter((player) => player.team === this.game.homeTeam)
      .sort((a, b) => a.jerseyNumber - b.jerseyNumber);
    this.players[this.game.awayTeam] = this.game
      .getAllPlayersObj()
      .filter((player) => player.team === this.game.awayTeam)
      .sort((a, b) => a.jerseyNumber - b.jerseyNumber);
  }

  filterChanged() {
    this.filteredShifts = this.allShifts;
    if (this._playerId) {
      this.filteredShifts = this.filteredShifts.filter(
        (s) => s.player && s.player.playerId === this._playerId
      );
    }
    if (this.validationIssues) {
      this.filteredShifts = this.filteredShifts.filter(
        (s) => s.validations && s.validations.length > 0
      );
    }
  }

  seekEvent(onEvent: GameEvent, $event: MouseEvent): void {
    $event.preventDefault();
    this.seek.emit(onEvent);
  }

  periodOverlappingShift(shift: Shift): boolean {
    if (shift.offEvent) {
      return shift.onEvent.period !== shift.offEvent.period;
    }
    return false;
  }

  duration(shift: Shift): number {
    if (shift.offEvent) {
      return shift.offEvent.gameTime - shift.onEvent.gameTime;
    } else {
      return null;
    }
  }

  longShift(shift: Shift): boolean {
    if (shift.player && shift.player.position === 'goalkeeper') {
      return false;
    }
    const d = this.duration(shift);
    return d && d > ShiftsOverviewComponent.LONG_SHIFT_THRESHOLD;
  }

  differentStrengthState(shift: Shift): boolean {
    if (shift.offEvent) {
      return shift.offEvent.strengthState !== shift.onEvent.strengthState;
    } else {
      return false;
    }
  }

  editShift(shift: Shift) {
    shift.editMode = true;
    shift.editPlayerNumber = shift.onEvent.playerNumber;
  }

  saveShift(shift: Shift) {
    const playerObj = this.game.getPlayerObj(
      shift.editPlayerNumber,
      shift.onEvent.team
    );
    shift.onEvent.playerNumber = playerObj.playerNumber;
    shift.onEvent.playerId = playerObj.playerId;
    if (shift.offEvent) {
      shift.offEvent.playerNumber = playerObj.playerNumber;
      shift.offEvent.playerId = playerObj.playerId;
    }

    this.eventService
      .save(shift.onEvent)
      .pipe(
        mergeMap(() => {
          if (shift.offEvent) {
            return this.eventService.save(shift.offEvent);
          } else {
            return of({});
          }
        })
      )
      .subscribe(
        () => {
          delete shift.editMode;
          this.alertService.showInfo('Shift updated');
        },
        (error) => alert('Save shift failed: ' + error.message)
      );
  }

  cancelEdit(shift: Shift) {
    delete shift.editMode;
  }
}
