import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class IceRinkService {
  private static RINK_WIDTH = 30;
  private static RINK_LENGTH = 60;
  protected static IMAGE_WIDTH = 300;
  protected static IMAGE_HEIGHT = 600;

  constructor(private logger: NGXLogger) {}

  public relativeToImage(xPosition: number, yPosition: number) {
    const x = Math.round(xPosition * IceRinkService.IMAGE_WIDTH);
    const y = Math.round(yPosition * IceRinkService.IMAGE_HEIGHT);
    return { x, y };
  }

  public coordinateToImage(xPosition: number, yPosition: number) {
    const x =
      Math.round(
        ((xPosition * IceRinkService.IMAGE_WIDTH) / IceRinkService.RINK_WIDTH) *
          100
      ) / 100;
    const y =
      Math.round(
        ((yPosition * IceRinkService.IMAGE_HEIGHT) /
          IceRinkService.RINK_LENGTH) *
          100
      ) / 100;
    return { x, y };
  }

  public imageToCoordinate(x: number, y: number) {
    const xPosition =
      Math.round(
        ((x * IceRinkService.RINK_WIDTH) / IceRinkService.IMAGE_WIDTH) * 100
      ) / 100;
    const yPosition =
      Math.round(
        ((y * IceRinkService.RINK_LENGTH) / IceRinkService.IMAGE_HEIGHT) * 100
      ) / 100;
    this.logger.info('position coordinates: ' + xPosition + '/' + yPosition);
    return { x: xPosition, y: yPosition };
  }
}
