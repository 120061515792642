import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Player } from '../domain/player';
import { PlayerService } from '../services/player.service';
import { ExternalIdsDialogComponent } from '../shared/external-ids-dialog/external-ids-dialog.component';
import { EditPlayerDialogComponent } from './edit-player-dialog/edit-player-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  teamId: string;

  columns = [
    'imageUrl',
    'position',
    'jerseyNumber',
    'name',
    'shoots',
    'sihfId',
    'eliteProspectsId',
    'nhlId',
    'sportcontractId',
    'instatId',
    'actions'
  ];

  constructor(
    private playerService: PlayerService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource<any>([]);
    this.teamId = this.route.snapshot.params['teamId'];

    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.dataSource.data = await this.playerService.getPlayersByTeam(
      this.teamId,
      null
    );
  }

  updateExternalIDs(player: Player): void {
    const dialogRef = this.dialog.open(ExternalIdsDialogComponent, {
      width: '350px',
      data: { player }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.updated) {
        await this.loadData();
      }
    });
  }

  public showCreatePlayerDialog(player) {
    const dialogRef = this.dialog.open(EditPlayerDialogComponent, {
      width: '600px',
      data: { player }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.playerService
          .createOrUpdatePlayer({ ...result, teamId: this.teamId })
          .subscribe((_player) => {
            const index = this.dataSource.data.findIndex(
              (item) => item.id === _player[0].id
            );
            if (index >= 0) {
              this.dataSource.data[index] = _player[0];
              this.dataSource.data = [...this.dataSource.data];
            } else {
              this.dataSource.data = [...this.dataSource.data, _player[0]];
            }
          });
      }
    });
  }

  deletePlayer(player) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Confirm deletion of ${
            player.firstName + ' ' + player.lastName
          }`,
          message: `This will hide the player and it's data, but not delete any actual data.`,
          okButtonCaption: 'Delete Player'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.playerService.deletePlayer(player.id).subscribe(() => {
            const index = this.dataSource.data.findIndex(
              (item) => item.id === player.id
            );
            this.dataSource.data.splice(index, 1);
            this.dataSource._updateChangeSubscription();
          });
        }
      });
  }
}
