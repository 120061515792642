<ng-container *ngIf="penalties?.length > 0">
  <table class="penalty">
    <tr *ngFor="let p of penalties" [class.penalty-expired]="isExpired(p)">
      <td class="duration">{{ p.penaltyDuration }}'</td>
      <td class="player">{{ transformPlayerInfo(p.playerNumber) }}</td>
      <td class="remaining">{{ timeRemaining(p) | mmss }}</td>
      <td class="expired" *ngIf="isExpired(p)"
        ><button mat-icon-button (click)="expirePenalty(p)"
          ><mat-icon>done</mat-icon></button
        ></td
      >
    </tr>
  </table>
</ng-container>
