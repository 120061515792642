import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';
import { Player } from '../domain/player';
import { GameService } from '../services/game.service';

@Component({
  selector: 'app-lineup-change-dialog',
  templateUrl: './lineup-change-dialog.component.html',
  styleUrls: ['./lineup-change-dialog.component.css']
})
export class LineupChangeDialogComponent implements OnInit {
  game: Game;
  players: Player[];

  existingPlayer: Player;
  newPlayerId: string;
  newPlayerName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gameService: GameService,
    private dialogRef: MatDialogRef<LineupChangeDialogComponent>
  ) {}

  ngOnInit() {
    this.game = new Game(this.data.game);
    this.players = this.game.getAllPlayersObj();
    this.players.sort((a, b) =>
      `${a.team} ${a.playerNumber}`.localeCompare(`${b.team} ${b.playerNumber}`)
    );
  }

  applyChange() {
    this.gameService
      .adjustLineup(
        this.game._id,
        this.existingPlayer.playerNumber,
        this.existingPlayer.playerId,
        this.newPlayerName,
        this.newPlayerId
      )
      .subscribe((res) => {
        this.dialogRef.close();
        alert(res['message']);
      });
  }
}
