import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthGuardService as AuthGuard } from '../auth/auth.guard.service';
import { SharedModule } from '../shared/shared.module';
import { ReviewDetailsComponent } from './review-details/review-details.component';
import { ReviewGameActionDetailsComponent } from './review-game-action-details/review-game-action-details.component';
import { ReviewGameActionsComponent } from './review-game-actions/review-game-actions.component';
import { ReviewResolver } from './review-resolver';
import { ReviewShiftsComponent } from './review-shifts/review-shifts.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewEventsComponent } from './review-events/review-events.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RolesGuard } from '../auth/roles.guard';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatOptionModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: 'reviews',
        canActivate: [AuthGuard, RolesGuard],
        data: {
          roles: [
            'supervisor',
            'chief_data_officer',
            'process_manager',
            'team_manager',
            'quality_manager'
          ]
        },
        children: [
          { path: '', component: ReviewsComponent },
          {
            path: ':reviewId',
            resolve: {
              review: ReviewResolver
            },
            children: [
              { path: 'edit', component: ReviewDetailsComponent },
              { path: 'events', component: ReviewEventsComponent },
              { path: 'game-actions', component: ReviewGameActionsComponent },
              {
                path: 'game-actions/:index',
                component: ReviewGameActionDetailsComponent
              },
              { path: 'shifts', component: ReviewShiftsComponent }
            ]
          }
        ]
      }
    ]),
    MatAutocompleteModule
  ],
  declarations: [
    ReviewsComponent,
    ReviewDetailsComponent,
    ReviewGameActionsComponent,
    ReviewGameActionDetailsComponent,
    ReviewShiftsComponent,
    ReviewEventsComponent
  ],
  exports: [ReviewGameActionDetailsComponent],
  providers: [ReviewResolver]
})
export class ReviewsModule {}
