<h2 mat-dialog-title>Time-on-Ice Quality</h2>
<p style="padding-left: 25px"
  >{{ game.date | date : 'yyyy-MM-dd' }} {{ game.homeTeam }} -
  {{ game.awayTeam }}</p
>
<mat-dialog-content>
  <div style="text-align: center" *ngIf="!qualityChecks">
    <img src="assets/loading.svg" alt="loading" style="height: 24px" />
    <span style="padding-left: 8px">Loading data...</span>
  </div>

  <table style="width: 100%" *ngIf="qualityChecks">
    <thead>
      <tr>
        <th>Period</th>
        <th>Team</th>
        <th class="number">Expected</th>
        <th class="number">Actual</th>
        <th class="number" matTooltip="Mean Square Error">MS-Error</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let check of qualityChecks">
        <td>{{ check.period }}</td>
        <td>{{ check.team }}</td>
        <td class="number">{{ check.expected | hhmmss }}</td>
        <td class="number">{{ check.actual | hhmmss }}</td>
        <td class="number" [class.warn]="check.error > 60"
          ><a
            [routerLink]="[]"
            (click)="navigateToTimeline(check.period, check.team)"
            >{{ check.error | hhmmss }}</a
          ></td
        >
      </tr>
      <tr class="total" *ngIf="total">
        <td>{{ total.period }}</td>
        <td>{{ total.team }}</td>
        <td class="number">{{ total.expected | hhmmss }}</td>
        <td class="number">{{ total.actual | hhmmss }}</td>
        <td class="number" [class.warn]="total.error > 180">{{
          total.error | hhmmss
        }}</td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" style="width: 100%"
    >Close</button
  >
</mat-dialog-actions>
