import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CollectionService,
  SeasonCollectionEffort
} from '../../services/collection.service';

@Component({
  selector: 'app-season-collection-effort',
  templateUrl: './season-collection-effort.component.html',
  styleUrls: ['./season-collection-effort.component.css']
})
export class SeasonCollectionEffortComponent implements OnInit {
  collectionEffort: SeasonCollectionEffort[];
  cutoff = 120;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private collectionService: CollectionService
  ) {}

  async ngOnInit() {
    await this.updateEffort();
  }

  async updateEffort() {
    this.collectionEffort = null;
    this.collectionService
      .getSeasonEffort(
        this.data.league,
        this.data.season,
        this.data.seasonType,
        this.cutoff
      )
      .subscribe((collectionEffort) => {
        this.collectionEffort = collectionEffort;
      });
  }
}
