<div class="right-buttons">
  <ng-content></ng-content>
  <a
    class="video-player-button"
    mat-stroked-button
    color="primary"
    *ngIf="(game.videos ?? []).length > 0"
    [routerLink]="['/games', game._id, 'video-player']"
    style="vertical-align: top"
    target="_blank"
  >
    <mat-icon style="vertical-align: middle">videocam</mat-icon>
    Video Player
  </a>
  <a
    class="video-player-button"
    mat-stroked-button
    color="primary"
    [disabled]="(this.game.videos ?? []).length === 0"
    (click)="openVideoPlayerWindow()"
    style="vertical-align: top"
  >
    <mat-icon>desktop_windows</mat-icon>
  </a>
</div>

<ol class="breadcrumb">
  <li class="breadcrumb-item"><a [routerLink]="['/games']">Games</a></li>
  <li class="breadcrumb-item" *ngIf="leagueName">{{ leagueName }}</li>
  <li class="breadcrumb-item"
    >{{ game.date | date : 'yyyy-MM-dd' }} {{ game.homeTeam }} -
    {{ game.awayTeam }}</li
  >
  <li class="breadcrumb-item">
    <select name="view" (change)="viewChange($event.target.value)">
      <option value="setup" [selected]="currentView === 'setup'">Setup</option>
      <option value="events" [selected]="currentView === 'events'"
        >Game Actions</option
      >
      <option value="time_on_ice" [selected]="currentView === 'time-on-ice'"
        >Time-on-Ice</option
      >
      <option value="timeline" [selected]="currentView === 'timeline'"
        >Timeline</option
      >
    </select>
  </li>
</ol>
