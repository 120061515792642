import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlayerService } from '../../services/player.service';
import { Player } from '../../domain/player';
import { TeamService } from '../../services/team.service';
import { Team } from '../../domain/team';

@Component({
  selector: 'app-external-ids-dialog',
  templateUrl: './external-ids-dialog.component.html',
  styleUrls: ['./external-ids-dialog.component.css']
})
export class ExternalIdsDialogComponent implements OnInit {
  sihfId: string;
  eliteProspectsId: string;
  sportcontractId: string;
  nhlId: string;
  instatId: string;

  dialogData: Player | Team;
  isPlayer: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ExternalIdsDialogComponent>,
    private playerService: PlayerService,
    private teamService: TeamService
  ) {}

  ngOnInit() {
    this.isPlayer = !!this.data.player;
    this.dialogData = this.data.player ?? this.data.team;
    if (this.dialogData) {
      this.sihfId = this.dialogData.sihfId;
      this.eliteProspectsId = this.dialogData.eliteProspectsId;
      this.sportcontractId = this.dialogData.sportcontractId;
      this.nhlId = this.dialogData.nhlId;
      this.instatId = this.dialogData.instatId;
    }
  }

  applyChange() {
    if (this.data.player) {
      this.playerService
        .updatePlayerExternalIDs(
          this.dialogData.id,
          this.sihfId,
          this.eliteProspectsId,
          this.sportcontractId,
          this.nhlId,
          this.instatId
        )
        .subscribe((res) => {
          this.dialogRef.close({
            updated: true
          });
          alert(res['message']);
        });
    } else if (this.data.team) {
      this.teamService
        .updateTeamExternalIDs(
          this.dialogData.id,
          this.sihfId,
          this.eliteProspectsId,
          this.sportcontractId,
          this.nhlId,
          this.instatId
        )
        .subscribe((res) => {
          this.dialogRef.close({
            updated: true
          });
          alert(res['message']);
        });
    }
  }
}
