import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Game } from '../domain/game';
import { Player } from '../domain/player';
import { Shift } from '../domain/shift';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.css']
})
export class ShiftDetailsComponent implements OnChanges {
  players: Player[] = [];
  formGroup: FormGroup;

  @Input()
  game: Game;

  @Input()
  shift: Shift;

  @Output()
  seek = new EventEmitter<number>();

  @Output()
  saveShift = new EventEmitter<{
    playerId: string;
    playerNumber: string;
    confirmed: boolean;
  }>();

  @Output()
  deleteShift = new EventEmitter<Shift>();

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      playerNumber: [undefined],
      playerId: [undefined]
    });
    this.formGroup.controls['playerNumber'].valueChanges.subscribe(
      (playerNumber) => {
        this.formGroup.patchValue({
          playerId: this.players.find((p) => p.playerNumber === playerNumber)
            ?.playerId
        });
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.game && this.shift) {
      this.updatePlayers();
      this.reset();
    }
  }

  reset() {
    this.formGroup.reset(
      {
        playerNumber: this.shift.onEvent.playerNumber,
        playerId: this.shift.onEvent.playerId
      },
      { emitEvent: false }
    );
  }

  private updatePlayers() {
    if (!this.game) {
      this.players = [];
    }
    const players = (this.players = this.game?.getAllPlayersObj());
    if (!players) {
      return;
    }
    this.players = players
      .filter((p) => p.team === this.shift.onEvent.team)
      .sort((a, b) => a.jerseyNumber - b.jerseyNumber);
  }

  seekVideo(videoTime: number, event: Event) {
    event.preventDefault();
    this.seek.emit(videoTime);
  }

  save() {
    this.saveShift.emit(this.formGroup.value);
  }

  delete() {
    this.deleteShift.emit(this.shift);
  }

  cancel() {
    this.formGroup.reset();
  }
}
