import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  PenaltyDuration,
  GameEvent,
  StrengthState
} from '../../domain/game-event';
import {
  eventTypeChange,
  penaltyIdChange,
  penaltyTypeChange,
  playerNumberChange,
  strengthStateChange,
  teamChange
} from '../../state/actions/game-event.action';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../state/reducers';
import { StrengthStateService } from '../../services/strength-state.service';
import { selectStrengthState } from '../../state/reducers/game-event.reducer';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-penalties-details',
  templateUrl: './penalties-details.component.html',
  styleUrls: ['./penalties-details.component.css']
})
export class PenaltiesDetailsComponent implements OnInit, OnDestroy {
  @Input() penalties: GameEvent[] = [];
  @Input() gameTime: number;
  @Input() homeTeam: string;

  strengthState: StrengthState;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private store: Store<GlobalState>,
    private strengthStateService: StrengthStateService
  ) {}

  ngOnInit() {
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((strengthState) => (this.strengthState = strengthState));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(null);
  }

  timeElapsed(penalty: GameEvent): number {
    const startTime = penalty.gameTime;
    return this.gameTime - startTime;
  }

  timeRemaining(penalty: GameEvent): number {
    const elapsed = this.timeElapsed(penalty);
    const duration = this.getPenaltyDurationSec(penalty.penaltyDuration);
    return Math.max(Math.round(duration - elapsed), 0);
  }

  getPenaltyDurationSec(duration: PenaltyDuration) {
    switch (duration) {
      case '2':
        return 2 * 60;
      case '2+2':
        return 4 * 60;
      case '5':
        return 5 * 60;
      case '10':
        return 10 * 60;
      case '20':
        return 20 * 60;
    }
  }

  isExpired(penalty: GameEvent): boolean {
    return this.timeRemaining(penalty) <= 0;
  }

  expirePenalty(penalty: GameEvent) {
    this.store.dispatch(eventTypeChange({ eventType: 'penalty' }));
    this.store.dispatch(teamChange({ team: penalty.team }));
    this.store.dispatch(
      playerNumberChange({ playerNumber: penalty.playerNumber })
    );
    this.store.dispatch(penaltyIdChange({ penaltyId: penalty.penaltyId }));

    this.handlePenaltyStrengthState(penalty.team);

    this.store.dispatch(penaltyTypeChange({ penaltyType: 'expiration' }));
  }

  transformPlayerInfo(playerNumber) {
    if (!playerNumber) {
      return;
    }
    const i = playerNumber.indexOf(' - ');
    const jerseyNumber = playerNumber.substr(0, i);
    const fullName = playerNumber.substr(jerseyNumber.length + 3);
    const k = fullName.lastIndexOf(' ');
    const lastName = fullName.substr(0, k).trim();
    return `${jerseyNumber} - ${lastName}`;
  }

  handlePenaltyStrengthState(team: string) {
    const result = this.strengthStateService.changeStrengthState(
      this.strengthState,
      this.homeTeam === team,
      1
    );

    // Only emit if valid strength state
    if (result) {
      this.strengthState = result;
      this.store.dispatch(strengthStateChange({ strengthState: result }));
    }
  }
}
