import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Game } from '../../domain/game';
import { selectPuckPossessionState } from '../../state/reducers/game.reducer';
import { GlobalState } from '../../state/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-puck-possession-state',
  templateUrl: './puck-possession-state.component.html',
  styleUrls: ['./puck-possession-state.component.css']
})
export class PuckPossessionStateComponent implements OnInit, OnDestroy {
  @Input()
  game: Game;

  private componentDestroyed$: Subject<void> = new Subject();

  puckPossessionState: { team: string; player: string };

  constructor(private store: Store<GlobalState>) {}

  ngOnInit() {
    this.store
      .select(selectPuckPossessionState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (puckPossessionState) =>
          (this.puckPossessionState = puckPossessionState)
      );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
