<button
  style="float: right; margin: 4px"
  mat-raised-button
  color="accent"
  (click)="showCreatePlayerDialog(null)"
  >Add Player</button
>
<table class="mat-elevation-z0" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="imageUrl">
    <th *matHeaderCellDef mat-header-cell>Image</th>
    <td mat-cell *matCellDef="let player">
      <img
        [src]="player.imageUrl"
        style="height: 20px"
        *ngIf="player.imageUrl"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="jerseyNumber">
    <th *matHeaderCellDef mat-header-cell>#</th>
    <td mat-cell *matCellDef="let player">
      {{ player.jerseyNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell>Name</th>
    <td mat-cell *matCellDef="let player">
      {{ player.lastName }} {{ player.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="position">
    <th *matHeaderCellDef mat-header-cell>Position</th>
    <td mat-cell *matCellDef="let player">
      {{ player.position | titlecase }}
    </td>
  </ng-container>

  <ng-container matColumnDef="shoots">
    <th *matHeaderCellDef mat-header-cell>Shoots</th>
    <td mat-cell *matCellDef="let player">
      {{ player.shoots | titlecase }}
    </td>
  </ng-container>

  <ng-container matColumnDef="sihfId">
    <th *matHeaderCellDef mat-header-cell>SIHF ID</th>
    <td mat-cell *matCellDef="let player">
      {{ player.sihfId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="eliteProspectsId">
    <th *matHeaderCellDef mat-header-cell>EliteProspects ID</th>
    <td mat-cell *matCellDef="let player">
      <a
        [href]="
          'https://www.eliteprospects.com/player/' +
          player.eliteProspectsId +
          '/' +
          player.lastName
        "
        target="_blank"
        >{{ player.eliteProspectsId }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="nhlId">
    <th *matHeaderCellDef mat-header-cell>NHL ID</th>
    <td mat-cell *matCellDef="let player">
      <a
        [href]="
          'https://www.nhl.com/player/' +
          player.lastName +
          '-' +
          player.firstName +
          '-' +
          player.nhlId
        "
        target="_blank"
        >{{ player.nhlId }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="sportcontractId">
    <th *matHeaderCellDef mat-header-cell>SportContract ID</th>
    <td mat-cell *matCellDef="let player">
      {{ player.sportcontractId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="instatId">
    <th *matHeaderCellDef mat-header-cell>InStat ID</th>
    <td mat-cell *matCellDef="let player">
      {{ player.instatId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let player">
      <button mat-button color="primary" (click)="updateExternalIDs(player)">
        Update External IDs
      </button>
      <button mat-icon-button (click)="deletePlayer(player)"
        ><mat-icon>delete</mat-icon></button
      >
      <button mat-icon-button (click)="showCreatePlayerDialog(player)"
        ><mat-icon>edit</mat-icon></button
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let team; columns: columns"></tr>
</table>

<mat-progress-bar
  *ngIf="loading"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
