import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

export function validatePlayer(formControl: UntypedFormControl): any {
  const PLAYER_REGEXP = /^[0-9]+ - [^ ](.*)+$/;

  if (!formControl.value) {
    return null;
  }

  // workaround for missing lookahead regex support
  if (formControl.value.endsWith(' ')) {
    return {
      validatePlayer: {
        valid: false
      }
    };
  }

  return PLAYER_REGEXP.test(formControl.value)
    ? null
    : {
        validatePlayer: {
          valid: false
        }
      };
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[validatePlayer][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useValue: validatePlayer, multi: true }]
})
export class PlayerValidatorDirective {}
