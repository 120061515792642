import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Game } from '../../domain/game';
import {
  CollectionEffort,
  CollectionService
} from '../../services/collection.service';

@Component({
  selector: 'app-collection-stats',
  templateUrl: './collection-effort.component.html',
  styleUrls: ['./collection-effort.component.css']
})
export class CollectionEffortComponent implements OnInit {
  game: Game;
  collectionEffort: CollectionEffort[];
  totalEffort: number;
  cutoff = 120;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private collectionService: CollectionService
  ) {}

  async ngOnInit() {
    this.game = this.data.game as Game;
    await this.updateEffort();
  }

  async updateEffort() {
    this.collectionEffort = null;
    this.collectionService
      .getGameEffort(this.game?._id, this.cutoff)
      .subscribe((collectionEffort) => {
        this.collectionEffort = collectionEffort;
        this.totalEffort = this.collectionEffort.reduce(
          (acc, val) => acc + val.effortSeconds,
          0
        );
      });
  }
}
