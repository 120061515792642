import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IceRinkService } from '../../shared/ice-rink/ice-rink.service';
import { Game } from '../../domain/game';
import { GameEvent, ShotOutcome } from '../../domain/game-event';
import { Review } from '../../domain/review';
import { ErrorType, ReviewRemark } from '../../domain/review-remark';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { GameService } from '../../services/game.service';
import { ReviewService } from '../../services/review.service';
import { IceRinkComponent } from '../../shared/ice-rink/ice-rink.component';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { selectVideoTime } from '../../state/reducers/game-event.reducer';
import { GlobalState } from '../../state/reducers';
import { GameEventInterruptionTypeService } from '../../services/game-event-interruption-type.service';

@Component({
  selector: 'app-review-game-details',
  templateUrl: './review-game-action-details.component.html',
  styleUrls: ['./review-game-action-details.component.css']
})
export class ReviewGameActionDetailsComponent implements OnInit {
  public index: number;
  public sourceEvent: GameEvent;
  public targetEvent: GameEvent;
  public reviewRemark: ReviewRemark;

  error: ErrorType;
  sourceEventRemarks: string;
  targetEventRemarks: string;

  public eventCount = 0;
  public allEvents: [GameEvent, GameEvent, ReviewRemark][] = [];

  public review: Review;
  public game: Game;
  public players: string[] = [];
  public playerSuggestions: string[] = [];
  public details: any[];
  public detailsSuggestions: any[];

  @ViewChild(IceRinkComponent, { static: true })
  private iceRink: IceRinkComponent;

  playerFormGroup: UntypedFormGroup;
  detailFormGroup: UntypedFormGroup;

  constructor(
    private reviewService: ReviewService,
    private eventService: EventService,
    private gameService: GameService,
    private alertService: AlertService,
    private iceRinkService: IceRinkService,
    public route: ActivatedRoute,
    formBuilder: UntypedFormBuilder,
    private store: Store<GlobalState>,
    private gameEventInterruptionTypeService: GameEventInterruptionTypeService
  ) {
    this.playerFormGroup = formBuilder.group({
      playerNumber: []
    });
    this.detailFormGroup = formBuilder.group({
      detail: []
    });
  }

  ngOnInit() {
    this.playerFormGroup.valueChanges.subscribe((value) => {
      this.updatePlayerSuggestions(value.playerNumber);
    });
    this.detailFormGroup.valueChanges.subscribe((value) => {
      this.updateDetails(value.detail);
    });
    this.review = this.route.snapshot.data['review'];
    this.index = parseInt(this.route.snapshot.params.index, 10) - 1;
    this.game = this.review.game;
    this.loadEvents();
    if (this.iceRink) {
      this.iceRink.homeTeam = this.game.homeTeam;
      this.iceRink.awayTeam = this.game.awayTeam;
      this.iceRink.alwaysShowTargetPosition = true;
      this.iceRink.showTargetArrow = false;
    }
  }

  private loadEvents() {
    return this.reviewService
      .getMatchedEvents(
        this.review._id,
        this.route.snapshot.queryParams,
        0,
        10000
      )
      .subscribe(
        (res) => {
          this.eventCount = res[0];
          this.allEvents = res[1];
          this.selectEvent(this.index, this.allEvents[this.index]);
        },
        (error) => {
          console.error('Could not match events', error);
          this.alertService.showError(
            'Could not match events: ' + error.message
          );
        }
      );
  }

  private updatePlayerList() {
    const isHomeTeamEvent =
      (this.sourceEvent && this.sourceEvent.team === this.game.homeTeam) ||
      (this.targetEvent && this.targetEvent.team === this.game.homeTeam);
    this.players = isHomeTeamEvent
      ? this.gameService.getHomeTeamPlayers(this.game)
      : this.gameService.getAwayTeamPlayers(this.game);
  }

  private selectEvent(i: number, e: [GameEvent, GameEvent, ReviewRemark]) {
    this.index = i;
    this.sourceEvent = e[0];
    this.targetEvent = e[1];
    this.reviewRemark = e[2];
    this.updatePlayerList();
    if (e[0]) {
      this.reviewService.seekVideo(e[0].videoTime - 10);
    } else if (e[1]) {
      this.reviewService.seekVideo(e[1].videoTime - 10);
    }
    if (this.iceRink) {
      if (e[0]) {
        this.iceRink.position = this.iceRinkService.coordinateToImage(
          e[0].xPosition,
          e[0].yPosition
        );
      } else {
        this.iceRink.position = { x: null, y: null };
      }
      if (e[1]) {
        this.iceRink.targetPosition = this.iceRinkService.coordinateToImage(
          e[1].xPosition,
          e[1].yPosition
        );
      } else {
        this.iceRink.targetPosition = { x: null, y: null };
      }
    }
    if (this.sourceEvent) {
      this.playerFormGroup.patchValue({
        playerNumber: this.sourceEvent.playerNumber
      });
      this.detailFormGroup.patchValue({
        detail: this.reviewService.getReviewEventDetail(this.sourceEvent)
      });
    }
    const event = this.sourceEvent ? this.sourceEvent : this.targetEvent;
    if (event.eventType === 'shot') {
      this.details = ['blocked', 'miss', 'on_goal', 'iron', 'goal'];
    } else if (event.eventType === 'face_off') {
      this.details = ['win', 'lost', 'no_winner'];
    } else if (event.eventType === 'interruption') {
      this.details = this.gameEventInterruptionTypeService.allInterruptionTypes;
    } else if (event.eventType === 'penalty') {
      this.details = ['start', 'expiration'];
    }
    if (this.reviewRemark) {
      this.sourceEventRemarks = this.reviewRemark.sourceEventRemarks;
      this.targetEventRemarks = this.reviewRemark.targetEventRemarks;
      this.error = this.reviewRemark.error;
    } else {
      this.sourceEventRemarks = null;
      this.targetEventRemarks = null;
      this.error = null;
    }
  }

  getDetail(event: GameEvent) {
    if (!event) {
      return '';
    }
    return this.reviewService.getReviewEventDetail(event);
  }

  saveSourceEvent() {
    let reviewEvent = this.reviewRemark;
    if (!reviewEvent) {
      reviewEvent = this.createNewReviewEvent();
    }
    if (reviewEvent.sourceEventRemarks !== this.sourceEventRemarks) {
      console.log('save source event remark', this.sourceEventRemarks);
      reviewEvent.sourceEventRemarks = this.sourceEventRemarks;
      // @ts-ignore
      this.reviewService.updateReviewEvent(reviewEvent).subscribe(
        (r) => {
          this.alertService.showInfo('Event saved');
          if (!this.reviewRemark) {
            this.reviewRemark = r as ReviewRemark;
          } else {
            this.reviewRemark.sourceEventRemarks = this.sourceEventRemarks;
          }
          this.allEvents[this.index] = [
            this.sourceEvent,
            this.targetEvent,
            this.reviewRemark
          ];
        },
        (error) => {
          this.alertService.showError('Saving event failed: ' + error.message);
        }
      );
    }
  }

  saveTargetEvent() {
    let reviewEvent = this.reviewRemark;
    if (!reviewEvent) {
      reviewEvent = this.createNewReviewEvent();
    }
    if (reviewEvent.targetEventRemarks !== this.targetEventRemarks) {
      console.log('save target event remark', this.targetEventRemarks);
      reviewEvent.targetEventRemarks = this.targetEventRemarks;
      // @ts-ignore
      this.reviewService.updateReviewEvent(reviewEvent).subscribe(
        (r) => {
          this.alertService.showInfo('Event saved');
          if (!this.reviewRemark) {
            this.reviewRemark = r as ReviewRemark;
          } else {
            this.reviewRemark.targetEventRemarks = this.targetEventRemarks;
          }
          this.allEvents[this.index] = [
            this.sourceEvent,
            this.targetEvent,
            this.reviewRemark
          ];
        },
        (error) => {
          this.alertService.showError('Saving event failed: ' + error.message);
        }
      );
    }
  }

  saveError(sourceEvent, targetEvent, error: ErrorType) {
    let reviewEvent = this.reviewRemark;
    if (!reviewEvent) {
      reviewEvent = this.createNewReviewEvent();
    }
    reviewEvent.error = error;
    this.reviewService.updateReviewEvent(reviewEvent).subscribe(
      (r) => {
        this.alertService.showInfo('Event saved');
        if (!this.reviewRemark) {
          this.reviewRemark = r as ReviewRemark;
        } else {
          this.reviewRemark.error = error;
        }
        this.allEvents[this.index] = [
          this.sourceEvent,
          this.targetEvent,
          this.reviewRemark
        ];
      },
      (err) => {
        this.alertService.showError('Saving event failed: ' + err.message);
      }
    );
  }

  private createNewReviewEvent() {
    const reviewRemark: ReviewRemark = {
      reviewId: this.review._id
    };
    if (this.sourceEvent) {
      reviewRemark.sourceEventId = this.sourceEvent._id;
    }
    if (this.targetEvent) {
      reviewRemark.targetEventId = this.targetEvent._id;
    }
    return reviewRemark;
  }

  updateCoordinates(event) {
    if (!event || event.x === 0) {
      return;
    }
    this.iceRink.position = { x: event.x, y: event.y };
    this.sourceEvent.xPosition = event.x;
    this.sourceEvent.yPosition = event.y;
    this.updateSourceEvent(true);
  }

  remarksFieldKeyDown(event: KeyboardEvent) {
    // avoid playing of video when pressing space bar
    event.stopPropagation();
  }

  updatePlayerSuggestions(filterValue: string) {
    if (!filterValue) {
      this.playerSuggestions = this.players;
    } else {
      this.playerSuggestions = this.players.filter(
        (s) => s.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
      );
    }
  }

  updateDetails(filterValue: string) {
    if (!filterValue) {
      this.detailsSuggestions = this.details;
    } else {
      if (this.details) {
        this.detailsSuggestions = this.details.filter(
          (s) => s.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        );
      }
    }
  }

  playerSelected(value: string) {
    if (this.sourceEvent && this.sourceEvent.playerNumber === value) {
      return;
    }
    const updatedEvent = { ...this.sourceEvent };
    updatedEvent.playerNumber = value;
    const isHomeTeamEvent = updatedEvent.team === this.game.homeTeam;
    updatedEvent.playerId = this.gameService.getPlayerIdByJerseyNumber(
      this.game,
      isHomeTeamEvent,
      updatedEvent.playerNumber
    );
    this.eventService.save(updatedEvent).subscribe(
      () => {
        this.alertService.showInfo('49ing event updated');
        this.playerFormGroup.patchValue({
          playerNumber: updatedEvent.playerNumber
        });
        this.sourceEvent.playerNumber = updatedEvent.playerNumber;
      },
      (e) => {
        console.log('Update event failed', e);
        this.alertService.showError('Update 49ing event failed:' + e.message);
        this.playerFormGroup.patchValue({
          playerNumber: this.sourceEvent.playerNumber
        });
      }
    );
  }

  outcomeSelected(value: string) {
    if (this.sourceEvent && this.sourceEvent.shotOutcome === value) {
      return;
    }
    const updatedEvent = { ...this.sourceEvent };
    updatedEvent.shotOutcome = value as ShotOutcome;
    this.eventService.save(updatedEvent).subscribe(
      () => {
        this.alertService.showInfo('49ing event updated');
        this.detailFormGroup.patchValue({
          shotOutcome: updatedEvent.shotOutcome
        });
        this.sourceEvent.shotOutcome = updatedEvent.shotOutcome;
      },
      (e) => {
        console.log('Update event failed', e);
        this.alertService.showError('Update 49ing event failed:' + e.message);
        this.detailFormGroup.patchValue({
          shotOutcome: this.sourceEvent.shotOutcome
        });
      }
    );
  }

  updateSourceEvent(updatePosition: boolean) {
    if (!this.sourceEvent) {
      this.alertService.showInfo(
        'Cannot update source event. No matching game event found.'
      );
      return;
    }
    if (updatePosition) {
      const position = this.iceRinkService.imageToCoordinate(
        this.iceRink.position.x,
        this.iceRink.position.y
      );
      this.sourceEvent.xPosition = position.x;
      this.sourceEvent.yPosition = position.y;
    }
    this.eventService.save(this.sourceEvent).subscribe(
      () => {
        this.alertService.showInfo('49ing event updated');
        this.saveTargetEvent();
      },
      (e) => {
        console.log('Update event failed', e);
        this.alertService.showError('Update 49ing event failed:' + e.message);
        this.selectEvent(this.index, this.allEvents[this.index]);
      }
    );
  }

  count() {
    return this.allEvents.length;
  }

  previousEvent() {
    const i = this.index;
    if (i > 0) {
      const event = this.allEvents[i - 1];
      this.selectEvent(i - 1, event);
    }
  }

  nextEvent() {
    const i = this.index;
    if (i < this.allEvents.length - 1) {
      const event = this.allEvents[i + 1];
      this.selectEvent(i + 1, event);
    }
  }

  deleteSourceEvent(sourceEvent: GameEvent) {
    if (confirm('Are you sure to delete the 49ing source event?')) {
      this.eventService.delete(sourceEvent.gameId, sourceEvent._id).subscribe(
        () => {
          this.alertService.showInfo('49ing event deleted');
          this.loadEvents();
        },
        (error) => {
          this.alertService.showError(
            'Deletion of 49ing event failed: ' + error.message
          );
        }
      );
    }
  }

  async updateVideoTime() {
    if (this.sourceEvent) {
      this.sourceEvent.videoTime = await this.store
        .select(selectVideoTime)
        .pipe(first())
        .toPromise();
      this.eventService
        .interpolateGameTime(this.game._id, this.sourceEvent.videoTime)
        .subscribe((gameTime) => {
          console.log(
            'interpolated gameTime',
            this.sourceEvent.videoTime,
            gameTime
          );
          this.sourceEvent.gameTime = gameTime;
          this.updateSourceEvent(false);
        });
    }
  }

  private nextOrPreviousEvent(i) {
    if (i > this.allEvents.length) {
      this.nextEvent();
    } else {
      this.previousEvent();
    }
  }

  hasDiff(e0, e1) {
    return this.diffNum(e0, e1) > 0;
  }

  diffNum(e0, e1) {
    return (
      (this.timeDiffAbs(e0, e1) > 10 ? 1 : 0) +
      (this.playerDiff(e0, e1) ? 1 : 0) +
      (this.outcomeDiff(e0, e1) ? 1 : 0) +
      (this.positionDiff(e0, e1) > 5 ? 1 : 0)
    );
  }

  timeDiff(e0, e1) {
    if (!e0 || !e1) {
      return 0;
    }
    return e0.gameTime - e1.gameTime;
  }

  timeDiffAbs(e0, e1) {
    return Math.abs(this.timeDiff(e0, e1));
  }

  playerDiff(e0, e1) {
    if (!e0 || !e1) {
      return false;
    }
    return e0.playerNumber !== e1.playerNumber;
  }

  outcomeDiff(e0, e1) {
    if (!e0 || !e1) {
      return false;
    }
    return this.reviewService.hasDetailDifference(e0, e1);
  }

  positionDiff(e0, e1) {
    if (
      !e0 ||
      !e1 ||
      e0.eventType === 'interruption' ||
      e0.eventType === 'penalty'
    ) {
      return false;
    }
    const dx = Math.abs(e0.xPosition - e1.xPosition);
    const dy = Math.abs(e0.yPosition - e1.yPosition);
    return Math.sqrt(dx * dx + dy * dy);
  }

  seekVideo(videoTime: number) {
    this.reviewService.seekVideo(videoTime);
  }
}
