import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-shot-impact',
  templateUrl: './shot-impact-dialog.component.html',
  styleUrls: ['./shot-impact-dialog.component.css']
})
export class ShotImpactDialogComponent {
  @ViewChild('goal', { static: true }) goal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShotImpactDialogComponent>
  ) {}

  onSelectPosition(event: any): void {
    this.data.netImpactX = event.layerX - this.goal.nativeElement.offsetLeft;
    this.data.netImpactY = event.layerY - this.goal.nativeElement.offsetTop;
  }

  apply() {
    this.dialogRef.close({
      netImpactX: this.data.netImpactX,
      netImpactY: this.data.netImpactY
    });
  }
}
