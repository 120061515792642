import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class SeasonService {
  private baseUrl = environment.API_HOST + '/api/seasons';

  private readonly seasons: Observable<number[]>;
  private readonly currentSeason: Observable<number>;

  constructor(private http: HttpClient) {
    this.seasons = this.http.get<number[]>(this.baseUrl).pipe(shareReplay());
    this.currentSeason = this.http
      .get<number>(`${this.baseUrl}/current`)
      .pipe(shareReplay());
  }

  getSeasons() {
    return this.seasons;
  }

  getCurrentSeason() {
    return this.currentSeason;
  }
}
