import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable } from 'rxjs';
import { filter, map, mergeMap, toArray } from 'rxjs/operators';
import { GameEvent } from '../domain/game-event';
import { EventService } from './event.service';
import { findIndexSorted } from './find-index-sorted';
import { puckPossessionStateChange } from '../state/actions/game.action';
import { Store } from '@ngrx/store';
import { GlobalState } from '../state/reducers';

@Injectable()
export class PuckPossessionStateService {
  puckPossessionEvents: GameEvent[] = [];

  constructor(
    private eventService: EventService,
    private logger: NGXLogger,
    private store: Store<GlobalState>
  ) {}

  public init(gameId: string): void {
    this.loadPuckPossessionEvents(gameId).subscribe((events) => {
      this.puckPossessionEvents = events;
    });
  }

  public deleteAndDispatchPuckPossessionEvent(
    event: GameEvent,
    videoTime: number
  ) {
    const i = this.puckPossessionEvents.findIndex((e) => e._id === event._id);
    if (i > -1) {
      this.puckPossessionEvents.splice(i, 1);
      this.puckPossessionEvents.sort((a, b) => a.videoTime - b.videoTime);
      this.updatePuckPossessionState(videoTime);
    }
  }

  public addAndDispatchPuckPossessionEvent(
    event: GameEvent,
    videoTime: number
  ) {
    const i = this.puckPossessionEvents.findIndex((e) => e._id === event._id);
    if (i > -1) {
      this.puckPossessionEvents[i] = event;
    } else {
      this.puckPossessionEvents.push(event);
    }

    this.puckPossessionEvents.sort((a, b) => a.videoTime - b.videoTime);
    this.updatePuckPossessionState(videoTime);
  }

  public updatePuckPossessionState(videoTime: number) {
    const lastPuckPossessionEvent = this.findLastPuckPossessionEvent(videoTime);
    const puckPossessionState = this.calculatePuckPossessionState(
      videoTime,
      lastPuckPossessionEvent
    );
    this.store.dispatch(puckPossessionStateChange({ puckPossessionState }));
  }

  calculatePuckPossessionState(
    videoTime: number,
    puckPossessionEvent: GameEvent
  ): { team: string; player: string } {
    if (!puckPossessionEvent) {
      return { team: 'neutral', player: null };
    } else {
      const team = puckPossessionEvent.team;
      let player;
      if (puckPossessionEvent.eventType === 'puckPossession') {
        this.logger.debug('last event is puckPossession', puckPossessionEvent);
        player = puckPossessionEvent.playerNumber;
      } else if (puckPossessionEvent.eventType === 'pass') {
        this.logger.info('last event is pass', puckPossessionEvent);
        player = puckPossessionEvent.pass_receiver;
      }
      this.logger.debug('calculated puck possession state: ', team, player);
      return {
        team,
        player
      };
    }
  }

  private findLastPuckPossessionEvent(videoTime: number): GameEvent {
    let result: GameEvent;
    if (this.puckPossessionEvents) {
      const j = findIndexSorted(
        this.puckPossessionEvents,
        (e) => e.videoTime - videoTime
      );
      if (j > -1) {
        result = this.puckPossessionEvents[j];
      }
    }
    return result;
  }

  private loadPuckPossessionEvents(gameId): Observable<GameEvent[]> {
    const possessionEvents = this.eventService
      .getEvents(gameId, { eventType: 'puckPossession' }, true)
      .pipe(
        map((puckPossessionEvents) => [...puckPossessionEvents[1]]),
        mergeMap((events) => events)
      );

    const completePasses = this.eventService
      .getEvents(gameId, { eventType: 'pass' }, true)
      .pipe(
        map((events) => [...events[1]]),
        mergeMap((events) => events),
        filter((e) => e.pass_type === 'pass' && e.pass_outcome === 'complete')
      );

    return concat(possessionEvents, completePasses).pipe(
      toArray(),
      map((events) => events.sort((a, b) => a.videoTime - b.videoTime))
    );
  }
}
