import { has, forOwn, isEqual, reduce, isPlainObject } from 'lodash/fp';

/**
 * Deep diff between two objects - i.e. an object with the new value of new & changed fields.
 * Removed fields will be set as undefined on the result.
 * Only plain objects will be deeply compared (@see _.isPlainObject)
 *
 * Inspired by: https://gist.github.com/Yimiprod/7ee176597fef230d1451#gistcomment-2565071
 * This fork: https://gist.github.com/TeNNoX/5125ab5770ba287012316dd62231b764/
 *
 * @param  {Object} base   Object to compare with (if falsy we return object)
 * @param  {Object} object Object compared
 * @return {Object}        Return a new object who represent the changed & new values
 */
export function deepDiffObj(base: any, object: any) {
  if (!object) {
    throw new Error(`The object compared should be an object: ${object}`);
  }
  if (!base) {
    return object;
  }
  const diffResult = reduce(
    (result, key) => {
      const value = object[key];
      if (!has(key, base)) {
        // fix edge case: not defined to explicitly defined as undefined
        result[key] = value;
      }
      if (!isEqual(base[key], value)) {
        result[key] =
          isPlainObject(value) && isPlainObject(base[key])
            ? deepDiffObj(base[key], value)
            : value;
      }
      return result;
    },
    {},
    Object.keys(object)
  );
  // map removed fields to undefined
  /*
  forOwn((value, key) => {
    if (!has(key, object)) {
      diffResult[key] = undefined;
    }
  }, base);
   */
  return diffResult;
}
