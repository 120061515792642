import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { HeadsUpInterruptionStateComponent } from './heads-up-game-interruption-state/heads-up-interruption-state.component';
import { HeadsUpGameScoreComponent } from './heads-up-game-score/heads-up-game-score.component';
import { HeadsUpGameTimeStateComponent } from './heads-up-game-time-state/heads-up-game-time-state.component';
import { HeadsUpPlayerInfoComponent } from './heads-up-player-info/heads-up-player-info.component';
import { GameContextComponent } from './game-context/game-context.component';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    SharedModule,
    MatIconModule,
    MatTooltipModule
  ],
  declarations: [
    GameContextComponent,
    HeadsUpGameScoreComponent,
    HeadsUpGameTimeStateComponent,
    HeadsUpPlayerInfoComponent,
    HeadsUpInterruptionStateComponent
  ],
  exports: [GameContextComponent]
})
export class VideoModule {}
