import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mmss' })
export class MMSSTimePipe implements PipeTransform {
  transform(seconds: number) {
    const negative = seconds < 0;
    if (negative) {
      seconds *= -1;
    }
    seconds = Math.round(seconds);

    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    return (
      (negative ? '-' : '') + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2)
    );
  }
}
