import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GamePeriod } from '../../domain/game-event';
import {
  selectIsInterrupted,
  selectTimeElapsed,
  selectVideoTimeLag
} from '../../state/reducers/game.reducer';
import {
  selectGameTime,
  selectPeriod
} from '../../state/reducers/game-event.reducer';
import { GlobalState } from '../../state/reducers';

@Component({
  selector: 'app-game-clock',
  templateUrl: './game-clock.component.html',
  styleUrls: ['./game-clock.component.css']
})
export class GameClockComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  gameTime: number;
  period: GamePeriod;
  isInterrupted: boolean;

  timeElapsed;
  videoTimeLag;

  constructor(private store: Store<GlobalState>) {}

  ngOnInit() {
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => (this.gameTime = gameTime));
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((period) => (this.period = period));
    this.store
      .select(selectIsInterrupted)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isInterrupted) => (this.isInterrupted = isInterrupted));
    this.store
      .select(selectTimeElapsed)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((timeElapsed) => (this.timeElapsed = timeElapsed));
    this.store
      .select(selectVideoTimeLag)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((videoTimeLag) => (this.videoTimeLag = videoTimeLag));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
