import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum StarRatingColor {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn'
}

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StarRatingComponent,
      multi: true
    }
  ]
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {
  private rating: number;
  @Input() private starCount: number;
  @Input() disabled: boolean = false;
  @Input() color: StarRatingColor = StarRatingColor.accent;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  ratingArr = [];

  constructor() {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  onClick(rating: number) {
    if (this.disabled) {
      return;
    }
    if (rating === this.rating) {
      this.rating = 0;
    } else {
      this.rating = rating;
    }
    this.onChange(this.rating);
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.rating = obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
