import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, from as observableFrom, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Team } from '../domain/team';
import { Player } from '../domain/player';

@Injectable()
export class PlayerService {
  private baseUrl = environment.API_HOST + '/api/players';

  constructor(private http: HttpClient) {}

  getPlayersByGameLineup(gameId: string, teamId: string, idType: string) {
    if (!teamId) {
      return observableFrom([]);
    }
    return this.http.get<Team[]>(
      `${this.baseUrl}/lineup?gameId=${gameId}&teamId=${teamId}&idType=${idType}`
    );
  }

  searchPlayersByName(
    searchQuery: string,
    idType: string = 'id',
    params: any = {}
  ): Observable<Player[]> {
    return this.http.get<Player[]>(`${this.baseUrl}/search`, {
      params: new HttpParams({
        fromObject: {
          query: searchQuery,
          idType,
          ...params
        }
      })
    });
  }

  async getPlayersByTeam(teamId: string, idType: string) {
    if (!teamId) {
      return [];
    }
    return firstValueFrom(
      this.http.get<Player[]>(
        `${this.baseUrl}/team?teamId=${teamId}&idType=${idType}`
      )
    );
  }

  updatePlayerExternalIDs(
    playerId: string,
    sihfId: string,
    eliteProspectsId: string,
    sportcontractId: string,
    nhlId: string,
    instatId: string
  ) {
    if (!playerId) {
      return observableFrom([]);
    }
    return this.http.post(`${this.baseUrl}/external-ids`, {
      playerId,
      sihfId,
      eliteProspectsId,
      sportcontractId,
      nhlId,
      instatId
    });
  }

  countries(): Observable<any[]> {
    const url = `${this.baseUrl}/countries`;
    return this.http.get<any[]>(url);
  }

  createOrUpdatePlayer(player): Observable<any[]> {
    if (player.id) {
      return this.http.put<any[]>(`${this.baseUrl}/${player.id}`, player);
    }
    return this.http.post<any[]>(this.baseUrl, player);
  }

  deletePlayer(playerId): Observable<any[]> {
    return this.http.delete<any[]>(`${this.baseUrl}/${playerId}`);
  }
}
