import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Shift } from '../domain/shift';

@Component({
  selector: 'app-multiple-shift-actions',
  templateUrl: './multiple-shift-actions.component.html',
  styleUrls: ['./multiple-shift-actions.component.css']
})
export class MultipleShiftActionsComponent {
  @Input()
  shifts: Shift[];

  @Output()
  merge = new EventEmitter<Shift[]>();

  mergeShifts() {
    this.merge.emit(this.shifts);
  }
}
