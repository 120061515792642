<h2 mat-dialog-title>{{ league?.id ? 'Edit' : 'Create' }} League</h2>
<mat-dialog-content>
  <form [formGroup]="form" style="display: flex; flex-wrap: wrap; gap: 4px">
    <div style="flex: 1 0 100%; display: flex">
      <app-upload-image
        style="flex: 1 0 45%; display: flex"
        bucketFolder="league-logos"
        [imageUrl]="form.get('imageUrl').value"
        (fileUploadComplete)="onImageUploadComplete($event)"
      ></app-upload-image>
      <div style="display: flex; flex-direction: column; flex: 1 0 45%">
        <mat-form-field>
          <input
            matInput
            formControlName="shortName"
            placeholder="Short Name"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="fullName" placeholder="Full Name" />
        </mat-form-field>
      </div>
    </div>
    <mat-form-field style="flex: 1 0 100%">
      <input matInput formControlName="imageUrl" placeholder="Image Url" />
    </mat-form-field>
    <mat-form-field style="flex: 1 0 100%">
      <mat-select placeholder="Country" formControlName="countryId">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let country of countries" [value]="country.id">{{
          country.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Team Class" formControlName="teamClass">
        <mat-option [value]="null"></mat-option>
        <mat-option value="MEN">MEN</mat-option>
        <mat-option value="WOMEN">WOMEN</mat-option>
        <mat-option value="MIXED">MIXED</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Team Type" formControlName="teamType">
        <mat-option [value]="null"></mat-option>
        <mat-option value="CLUB">CLUB</mat-option>
        <mat-option value="NATIONAL">NATIONAL</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Player Level" formControlName="playerLevel">
        <mat-option [value]="null"></mat-option>
        <mat-option value="AMATEUR">AMATEUR</mat-option>
        <mat-option value="PROFESSIONAL">PROFESSIONAL</mat-option>
        <mat-option value="YOUTH">YOUTH</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="flex: 1 0 45%">
      <mat-select placeholder="Game Type" formControlName="gameType">
        <mat-option [value]="null"></mat-option>
        <mat-option value="CUP">CUP</mat-option>
        <mat-option value="REGULAR_SEASON">REGULAR_SEASON</mat-option>
        <mat-option value="QUALIFYING">QUALIFYING</mat-option>
        <mat-option value="TOURNAMENT">TOURNAMENT</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="float: right">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    [disabled]="!form.valid"
    (click)="applyChanges()"
    >{{ league?.id ? 'Update' : 'Create' }}</button
  >
</mat-dialog-actions>
