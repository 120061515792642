<h4 mat-dialog-title>Season Collection Effort </h4>
<mat-dialog-content>
  <div class="row">
    <div class="col-md-3">
      <label for="cutoff">Pause Cut-off (sec)</label>
    </div>
    <div class="col-md-2">
      <input
        type="number"
        id="cutoff"
        class="form-control"
        [(ngModel)]="cutoff"
        (change)="updateEffort()"
      />
    </div>
  </div>

  <table *ngIf="collectionEffort" style="margin-top: 24px">
    <tr>
      <th>Unit</th>
      <th class="number"># Game Periods</th>
      <th class="number">Median</th>
      <th class="number">Std</th>
    </tr>
    <tr *ngFor="let effort of collectionEffort">
      <td>{{ effort.unit }}</td>
      <td class="number">{{ effort.count }}</td>
      <td class="number">{{ effort.median | hhmmss }}</td>
      <td class="number">{{ effort.std | hhmmss }}</td>
    </tr>
  </table>
</mat-dialog-content>

<mat-progress-bar
  *ngIf="!collectionEffort"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
