<p
  ><b>{{ shifts.length }} Shifts selected</b></p
>

<div class="container no-gutters">
  <div class="row">
    <div class="col">
      <button mat-raised-button color="primary" (click)="mergeShifts()"
        >Merge Shifts</button
      >
    </div>
  </div>
</div>
