import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-duplicate-game-dialog',
  templateUrl: './duplicate-game-dialog.component.html',
  styleUrls: ['./duplicate-game-dialog.component.css']
})
export class DuplicateGameDialogComponent implements OnInit {
  game: Game;
  form: FormGroup<{
    face_off: FormControl<boolean | null>;
    interruption: FormControl<boolean | null>;
    penalty: FormControl<boolean | null>;
    time_on_ice: FormControl<boolean | null>;
    shot: FormControl<boolean | null>;
    pass: FormControl<boolean | null>;
    highlight: FormControl<boolean | null>;
    videoTag: FormControl<boolean | null>;
    puckPossession: FormControl<boolean | null>;
    oddMenRush: FormControl<boolean | null>;
  }>;

  selected = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DuplicateGameDialogComponent>
  ) {}

  ngOnInit() {
    this.game = this.data.game;
    this.form = new FormGroup({
      face_off: new FormControl(false),
      interruption: new FormControl(false),
      penalty: new FormControl(false),
      time_on_ice: new FormControl(false),
      shot: new FormControl(false),
      pass: new FormControl(false),
      videoTag: new FormControl(false),
      puckPossession: new FormControl(false),
      oddMenRush: new FormControl(false),
      highlight: new FormControl(false)
    });
  }

  execute() {
    const eventTypes = Object.entries(this.form.value)
      .filter((kv) => kv[1])
      .map((kv) => kv[0]);
    this.dialogRef.close(eventTypes);
  }

  selectAll(e: MatCheckboxChange) {
    this.selected = e.checked;
    Object.keys(this.form.controls).forEach((control) => {
      this.form.controls[control].setValue(this.selected);
    });
  }
}
