import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from '../services/alert.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private alertService: AlertService) {}

  handleError(error) {
    if (this.ignoreError(error)) {
      console.warn('Ignore error', error);
      return;
    }

    if (error instanceof HttpErrorResponse) {
      // Server Error
      this.alertService.showError(error.message);
      return;
    }

    console.log('Uncaught error', error);
    this.alertService.showError(
      `An unexpected error occurred: ${error.message}`
    );
  }

  protected ignoreError(error) {
    // Ignore EventSource disconnection errors
    return (
      error.message === 'network error' || error.message === 'Failed to fetch'
    );
  }
}
