import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { AlertService } from '../services/alert.service';
import { GlobalErrorHandler } from './global-error-handler';

export const SENTRY_CONFIG = {
  dsn: 'https://1ea43169f41d4c4c9a69c58ff078ea8e@o102496.ingest.sentry.io/277916',
  maxBreadcrumbs: 50,
  debug: false
};

export class SentryErrorHandler extends GlobalErrorHandler {
  constructor(alertService: AlertService) {
    super(alertService);
  }

  handleError(error: any): void {
    try {
      if (!this.ignoreError(error)) {
        Sentry.captureException(error);
      }
    } catch (e) {
      console.error('sentry error capture failed', e);
    }
    super.handleError(error);
  }
}

export const createErrorHandler = (alertService: AlertService) => {
  console.log('Intializing Global Handler');
  return new GlobalErrorHandler(alertService);
};
