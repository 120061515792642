import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  gameIdChange,
  isInterruptedChange,
  penaltiesChange,
  randomChange,
  resetState,
  resetHardState,
  timeElapsedChange,
  videoTimeLagChange,
  videoStatusChange,
  videoTimeExternalChange,
  puckPossessionStateChange,
  activeShiftsChange
} from '../actions/game.action';
import { GameEvent } from '../../domain/game-event';
import { Shift } from '../../domain/shift';

export const gameFeatureKey = 'game';

export interface GameState {
  gameId: string;
  videoTimeExternal: number; // seek video time
  videoStatus: 'play' | 'pause';
  isInterrupted: boolean;
  /**
   * Time elapsed since game start
   */
  timeElapsed: number;
  penalties: GameEvent[];
  /**
   * Lag or lead of current video time relative to time elapsed
   */
  videoTimeLag: number;

  puckPossessionState: { team: string; player: string };
  activeShifts: Shift[];

  random: string; // use it when you need to create selector for sending same value
}

export const INITIAL_GAME_STATE: GameState = {
  gameId: null,
  videoTimeExternal: undefined,
  videoStatus: 'pause',
  isInterrupted: false,
  timeElapsed: undefined,
  penalties: undefined,
  videoTimeLag: undefined,
  puckPossessionState: undefined,
  activeShifts: undefined,

  random: undefined
};

const gameReducer = createReducer(
  INITIAL_GAME_STATE,
  on(gameIdChange, (state, { gameId }) => ({
    ...state,
    gameId
  })),
  on(videoTimeExternalChange, (state, { videoTimeExternal, random }) => ({
    ...state,
    videoTimeExternal,
    random: random ?? state.random
  })),
  on(videoStatusChange, (state, { videoStatus }) => ({
    ...state,
    videoStatus
  })),
  on(isInterruptedChange, (state, { isInterrupted }) => ({
    ...state,
    isInterrupted
  })),
  on(timeElapsedChange, (state, { timeElapsed }) => ({
    ...state,
    timeElapsed
  })),
  on(penaltiesChange, (state, { penalties }) => ({
    ...state,
    penalties
  })),
  on(videoTimeLagChange, (state, { videoTimeLag }) => ({
    ...state,
    videoTimeLag
  })),
  on(puckPossessionStateChange, (state, { puckPossessionState }) => ({
    ...state,
    puckPossessionState
  })),
  on(activeShiftsChange, (state, { activeShifts }) => ({
    ...state,
    activeShifts
  })),
  on(randomChange, (state, { random }) => ({
    ...state,
    random
  })),
  on(resetHardState, (state, payload) => {
    let result = Object.assign({}, INITIAL_GAME_STATE);
    if (payload.gameId) {
      result = Object.assign({}, result, { gameId: payload.gameId });
    }
    return result;
  }),
  on(resetState, (state, payload) => {
    let result = Object.assign({}, INITIAL_GAME_STATE, {
      penalties: state.penalties,
      videoStatus: state.videoStatus,
      isInterrupted: state.isInterrupted
    });

    if (payload.gameId) {
      result = Object.assign({}, result, { gameId: payload.gameId });
    }
    return result;
  })
);

const gameFeature = createFeature({
  name: gameFeatureKey,
  reducer: gameReducer
});

export const {
  name,
  reducer,
  selectGameId,
  selectVideoTimeExternal,
  selectVideoStatus,
  selectIsInterrupted,
  selectTimeElapsed,
  selectPenalties,
  selectVideoTimeLag,
  selectPuckPossessionState,
  selectActiveShifts,
  selectGameState,
  selectRandom
} = gameFeature;

/*
  Library doesn't trigger selector if you emit the same value because by library rules
  selector is memorized.
  In order to seeking from events table work properly and always seek to time
  we need to change random every time we emit action
*/
export const selectVideoTimeExternalReplay = createSelector(
  selectVideoTimeExternal,
  selectRandom,
  (videoTime: number, random: string) => ({ videoTime, random })
);
