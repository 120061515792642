import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

export const CHANNEL_GAME_EVENTS = 'game-events';

export enum BroadcastAction {
  Save = 'save',
  SaveComplete = 'save_complete',
  SaveFailed = 'save_failed',
  ResetState = 'reset_state'
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private broadcast: BroadcastChannel;

  constructor() {
    this.broadcast = new BroadcastChannel(CHANNEL_GAME_EVENTS);
  }

  listen(): Observable<any> {
    return fromEvent(this.broadcast, 'message');
  }

  postMessage(message: any) {
    this.broadcast.postMessage(message);
  }
}
