import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GameEvent } from '../domain/game-event';
import { takeUntil } from 'rxjs/operators';
import { Game } from '../domain/game';
import { Store } from '@ngrx/store';

import { selectGameTime } from '../state/reducers/game-event.reducer';
import { selectPenalties } from '../state/reducers/game.reducer';
import { GlobalState } from '../state/reducers';

@Component({
  selector: 'app-penalties',
  templateUrl: './penalties.component.html',
  styleUrls: ['./penalties.component.css']
})
export class PenaltiesComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();

  @Input() swapSide: boolean;
  @Input() game: Game;
  @Input() visible: boolean;

  homePenalties: GameEvent[] = [];
  awayPenalties: GameEvent[] = [];
  gameTime: number;

  constructor(private store: Store<GlobalState>) {}

  ngOnInit() {
    this.store
      .select(selectPenalties)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((penalties) => {
        if (penalties) {
          this.homePenalties = penalties.filter(
            (p) => p.team && p.team === this.game.homeTeam
          );
          this.awayPenalties = penalties.filter(
            (p) => p.team && p.team === this.game.awayTeam
          );
        }
      });

    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => {
        this.gameTime = gameTime;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
