import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastAction, BroadcastService } from '../../broadcast.service';
import { Game } from '../../domain/game';
import { Detection } from '../../services/detection';
import { IceRinkService } from './ice-rink.service';
import {
  selectDetections,
  selectEventType,
  selectFaceoffOutcomePosition,
  selectPassReceiverPosition,
  selectPlayerNumber,
  selectPosition,
  selectShotBlockerPosition,
  selectVideoTime
} from '../../state/reducers/game-event.reducer';
import { Store } from '@ngrx/store';
import { PositionCoordinate } from '../../domain/game-event';
import { GlobalState } from '../../state/reducers';
import {
  positionChange,
  passReceiverPositionChange,
  shotBlockerPositionChange,
  faceoffOutcomePositionChange,
  teamChange,
  playerNumberChange
} from '../../state/actions/game-event.action';
import { selectPuckPossessionState } from '../../state/reducers/game.reducer';

interface PlayerCoordinate {
  x: number;
  y: number;
  player?: string;
  team?: string;
}

@Component({
  selector: 'app-ice-rink',
  templateUrl: './ice-rink.component.html',
  styleUrls: ['./ice-rink.component.css']
})
export class IceRinkComponent implements OnDestroy {
  @Output()
  edit = new EventEmitter<PositionCoordinate>();

  @Input()
  game: Game;

  @Input() showPlayerPosition: boolean = true;

  homeTeam: string;
  awayTeam: string;
  eventType: string;

  position: PositionCoordinate;
  targetPosition: PositionCoordinate;

  // to allow reuse in review
  alwaysShowTargetPosition: boolean = false;
  showTargetArrow: boolean = true;

  positionClickCounter: number = 0;
  selectedPlayer: string;
  puckPossessionPlayer: string;

  readonly defaultHomeTeamColor = 'rgba(58, 58, 111, 0.5)';
  readonly defaultAwayTeamColor = 'rgba(75, 158, 114, 0.5)';
  readonly defaultNeutralColor = 'rgba(75, 75, 75, 0.5)';

  playerCoordinates: PlayerCoordinate[];
  private videoTime: number;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private iceRinkService: IceRinkService,
    private broadcast: BroadcastService,
    private store: Store<GlobalState>
  ) {
    this.store
      .select(selectVideoTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((videoTime) => {
        this.videoTime = videoTime;
      });
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((player) => {
        this.selectedPlayer = player;
        this.determinePlayerPosition(player);
      });
    this.store
      .select(selectPuckPossessionState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((puckPossessionState) => {
        this.puckPossessionPlayer = puckPossessionState?.player;
      });
    this.store
      .select(selectPosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (position?.x && position?.y) {
          this.position = this.iceRinkService.coordinateToImage(
            position.x,
            position.y
          );
        } else {
          this.position = { x: null, y: null };
        }
      });
    this.store
      .select(selectPassReceiverPosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (this.eventType === 'pass') {
          this.setTargetPosition(position);
        }
      });
    this.store.select(selectShotBlockerPosition).subscribe((position) => {
      if (this.eventType === 'shot') {
        this.setTargetPosition(position);
      }
    });
    this.store
      .select(selectFaceoffOutcomePosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (this.eventType === 'face_off') {
          this.setTargetPosition(position);
        }
      });
    this.store
      .select(selectEventType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newEventType) => (this.eventType = newEventType));

    this.store
      .select(selectDetections)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((detections) => {
        this.playerCoordinates = this.transformPlayerPositions(detections);
      });

    this.broadcast
      .listen()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((message) => {
        switch (message.data.type) {
          case BroadcastAction.ResetState:
            this.positionClickCounter = 0;
            break;
          case BroadcastAction.SaveComplete:
            this.positionClickCounter = 0;
            break;
        }
      });
  }

  private transformPlayerPositions(detections: Detection[]) {
    return detections?.map((d) => {
      let x;
      let y;
      if (d.iceRinkCoordinates) {
        ({ x, y } = this.iceRinkService.relativeToImage(
          d.iceRinkCoordinates[0],
          d.iceRinkCoordinates[1]
        ));
      }
      return {
        x,
        y,
        player: d.trackAssignment?.player,
        team: d.trackAssignment?.team
      };
    });
  }

  private determinePlayerPosition(player: string) {
    if (!player) {
      return;
    }

    const playerCoordinate = this.playerCoordinates?.find(
      (p) => p.player === player
    );
    if (!playerCoordinate || !playerCoordinate.x || !playerCoordinate.y) {
      return;
    }

    const iceRinkCoordinate = this.iceRinkService.imageToCoordinate(
      playerCoordinate.x,
      playerCoordinate.y
    );

    this.store.dispatch(
      positionChange({
        position: { x: iceRinkCoordinate.x, y: iceRinkCoordinate.y }
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  setEventPosition(event: MouseEvent) {
    const coordinate = this.iceRinkService.imageToCoordinate(
      event.offsetX,
      event.offsetY
    );

    if (
      this.positionClickCounter === 1 &&
      this.eventType != null &&
      this.hasSourceAndTargetPosition()
    ) {
      if (this.eventType === 'pass') {
        this.store.dispatch(
          passReceiverPositionChange({
            passReceiverPosition: { x: coordinate.x, y: coordinate.y }
          })
        );
      } else if (this.eventType === 'shot') {
        this.store.dispatch(
          shotBlockerPositionChange({
            shotBlockerPosition: { x: coordinate.x, y: coordinate.y }
          })
        );
      } else if (this.eventType === 'face_off') {
        this.store.dispatch(
          faceoffOutcomePositionChange({
            faceoffOutcomePosition: { x: coordinate.x, y: coordinate.y }
          })
        );
      }
    } else {
      this.store.dispatch(
        positionChange({ position: { x: coordinate.x, y: coordinate.y } })
      );
      this.resetTarget();
    }
    this.positionClickCounter = (this.positionClickCounter + 1) % 2;
    this.edit.next(this.position);
  }

  hasSourceAndTargetPosition() {
    return (
      ['pass', 'shot', 'face_off'].includes(this.eventType) ||
      this.alwaysShowTargetPosition
    );
  }

  jerseyNumber(playerName: string) {
    if (!playerName) {
      return '';
    }
    const parts = playerName.split(' - ');
    return parts[0];
  }

  private resetTarget() {
    this.store.dispatch(
      faceoffOutcomePositionChange({
        faceoffOutcomePosition: { x: null, y: null }
      })
    );
    this.store.dispatch(
      shotBlockerPositionChange({ shotBlockerPosition: { x: null, y: null } })
    );
    this.store.dispatch(
      passReceiverPositionChange({ passReceiverPosition: { x: null, y: null } })
    );
  }

  teamColor(team: string) {
    if (team === this.game?.homeTeam) {
      return this.game.homeTeamColor || this.defaultHomeTeamColor;
    } else if (team === this.game?.awayTeam) {
      return this.game.awayTeamColor || this.defaultAwayTeamColor;
    } else {
      return this.defaultNeutralColor;
    }
  }

  round(value: number) {
    return Math.round(value);
  }

  selectPlayer(p: PlayerCoordinate) {
    this.selectedPlayer = p.player;
    this.store.dispatch(teamChange({ team: p.team }));
    this.store.dispatch(playerNumberChange({ playerNumber: p.player }));

    const coordinate = this.iceRinkService.imageToCoordinate(p.x, p.y);
    this.store.dispatch(
      positionChange({ position: { x: coordinate.x, y: coordinate.y } })
    );
  }

  setTargetPosition(position) {
    if (position?.x && position?.y) {
      this.targetPosition = this.iceRinkService.coordinateToImage(
        position.x,
        position.y
      );
    } else {
      this.targetPosition = { x: null, y: null };
    }
  }
}
