<div
  class="player"
  [class.empty]="player.playerNumber === 'n/a'"
  [class.on-ice]="shift"
  [class.long-shift]="longShift"
  [class.selected]="selected"
  [class.disabled]="!player.playerId"
  (click)="togglePlayer()"
>
  <span class="number">{{ player.jerseyNumber }}</span>
  <span class="name" *ngIf="player.playerNumber !== 'n/a'">{{
    player.name
  }}</span>
  <span class="shift-duration" *ngIf="duration">{{ duration | hhmmss }}</span>
</div>
