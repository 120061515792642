import { Injectable } from '@angular/core';

const DEFAULT_TIME_EXTENSION = 10;

@Injectable()
export class VideoPlayerTrimService {
  extendTrimTime(
    action: string,
    startOfVideoClip: number,
    durationVideoClip: number,
    durationOfFullVideo: number
  ) {
    if (action === 'START') {
      const previousStartOfVideoClip = startOfVideoClip;
      startOfVideoClip = Math.max(
        0,
        previousStartOfVideoClip - DEFAULT_TIME_EXTENSION
      );

      durationVideoClip =
        durationVideoClip + (previousStartOfVideoClip - startOfVideoClip);
    } else if (action === 'END') {
      const previousEndOfVideoClip = Math.min(
        durationOfFullVideo,
        startOfVideoClip + durationVideoClip
      );

      const newEndOfVideoClip = Math.min(
        durationOfFullVideo,
        previousEndOfVideoClip + DEFAULT_TIME_EXTENSION
      );

      durationVideoClip =
        durationVideoClip + (newEndOfVideoClip - previousEndOfVideoClip);
    }
    return {
      startOfVideoClip,
      durationVideoClip
    };
  }
}
