<table class="mdl-data-table mdl-js-data-table app-events" style="width: 100%">
  <thead>
    <tr>
      <th>Period</th>
      <th>Video Time</th>
      <th>Game Clock</th>
      <th>Insert Time</th>
      <th *ngIf="game.lagClock">Live Delay</th>
      <th class="mdl-data-table__cell--non-numeric">Event Type</th>
      <th
        *ngIf="
          game.isHighlightCollection &&
          (filter.eventType === '' || filter.eventType === 'highlight')
        "
        class="mdl-data-table__cell--non-numeric"
        >Rating</th
      >
      <th class="mdl-data-table__cell--non-numeric">Strength State</th>
      <th class="mdl-data-table__cell--non-numeric">Team</th>
      <th class="mdl-data-table__cell--non-numeric">Player Number</th>
      <th
        *ngIf="game.isLiveDraftEvents"
        class="mdl-data-table__cell--non-numeric"
        >Draft</th
      >
      <th class="mdl-data-table__cell--non-numeric">Validation</th>
      <th class="mdl-data-table__cell--non-numeric">x/y</th>
      <th></th>
    </tr>
    <tr class="search-bar">
      <th>
        <select
          name="period"
          [(ngModel)]="filter.period"
          (ngModelChange)="filterChanged()"
        >
          <option value="">Any</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
        </select>
      </th>
      <th></th>
      <th
        ><input
          placeholder="From"
          type="text"
          [maskito]="{ mask: gameTimeMask, overwriteMode: 'replace' }"
          [disabled]="!filter.period"
          [title]="!filter.period ? 'Requires period filter' : ''"
          [ngModel]="filter.gameTimeFrom"
          (ngModelChange)="gameTimeChanged($event, 'gameTimeFrom')"
          style="width: 40px; text-align: center" />
        <span>-</span>
        <input
          type="text"
          placeholder="To"
          [maskito]="{ mask: gameTimeMask, overwriteMode: 'replace' }"
          [disabled]="!filter.period"
          [title]="!filter.period ? 'Requires period filter' : ''"
          [ngModel]="filter.gameTimeTo"
          (ngModelChange)="gameTimeChanged($event, 'gameTimeTo')"
          style="width: 40px; text-align: center"
      /></th>
      <th></th>
      <th *ngIf="game.lagClock"></th>
      <th class="mdl-data-table__cell--non-numeric">
        <select
          name="team"
          [(ngModel)]="filter.eventType"
          (ngModelChange)="filterChanged()"
        >
          <option value="">Any</option>
          <option *ngFor="let t of eventTypes">{{ t }}</option>
        </select>

        <select
          name="shotOutcome"
          [(ngModel)]="filter.shotOutcome"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'shot'"
        >
          <option value="">Any</option>
          <option *ngFor="let o of shotOutcomes">{{ o }}</option>
        </select>

        <select
          name="teamFaceOffOutcome"
          [(ngModel)]="filter.teamFaceOffOutcome"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'face_off'"
        >
          <option value="">Any</option>
          <option *ngFor="let o of teamFaceOffOutcomes">{{ o }}</option>
        </select>

        <select
          name="passType"
          [(ngModel)]="filter.pass_type"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'pass'"
        >
          <option value="">Any</option>
          <option *ngFor="let p of passTypes">{{ p }}</option>
        </select>

        <select
          name="interruptionType"
          [(ngModel)]="filter.interruptionType"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'interruption'"
        >
          <option value="">Any</option>
          <option *ngFor="let t of interruptionTypes">{{ t }}</option>
        </select>

        <select
          name="videoTagType"
          [(ngModel)]="filter.videoTagType"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'videoTag'"
        >
          <option value="">Any</option>
          <option *ngFor="let t of videoTagTypes | keyvalue" [value]="t.key">{{
            t.value
          }}</option>
        </select>

        <select
          name="highlightType"
          [(ngModel)]="filter.highlightType"
          (ngModelChange)="filterChanged()"
          *ngIf="filter.eventType === 'highlight'"
        >
          <option value="">Any</option>
          <option *ngFor="let t of highlightTypes">{{ t }}</option>
        </select>
      </th>
      <th
        *ngIf="
          game.isHighlightCollection &&
          (filter.eventType === '' || filter.eventType === 'highlight')
        "
        class="mdl-data-table__cell--non-numeric"
      >
        <app-star-rating
          [starCount]="5"
          [color]="'primary'"
          [ngModel]="filter.highlightRating"
          (ngModelChange)="ratingChange($event); filterChanged()"
        ></app-star-rating>
      </th>
      <th class="mdl-data-table__cell--non-numeric">
        <select
          name="team"
          [(ngModel)]="filter.strengthState"
          (ngModelChange)="filterChanged()"
        >
          <option value="">Any</option>
          <option *ngFor="let s of strengthStates">{{ s }}</option>
        </select>
        <select
          name="team"
          [(ngModel)]="filter.emptyNet"
          (ngModelChange)="filterChanged()"
        >
          <option value="">Any</option>
          <option value="home">Empty Net Home</option>
          <option value="away">Empty Net Away</option>
        </select>
      </th>
      <th class="mdl-data-table__cell--non-numeric">
        <select
          name="team"
          [(ngModel)]="filter.team"
          (ngModelChange)="filterChanged()"
        >
          <option value="">Any</option>
          <option value="{{ game.homeTeam }}">{{ game.homeTeam }}</option>
          <option value="{{ game.awayTeam }}">{{ game.awayTeam }}</option>
        </select>
      </th>
      <th class="mdl-data-table__cell--non-numeric">
        <input
          placeholder="Player"
          [(ngModel)]="filter.playerNumber"
          (ngModelChange)="filterChanged()"
          style="width: 100px"
        />
      </th>
      <th
        *ngIf="game.isLiveDraftEvents"
        class="mdl-data-table__cell--non-numeric"
      >
        <input
          type="checkbox"
          id="errors-check"
          name="errors-check"
          [(ngModel)]="filter.draft"
          (ngModelChange)="filterChanged()"
        />
        <label
          class="form-check-label"
          for="errors-check"
          style="margin-left: 8px"
          >Drafts</label
        >
      </th>
      <th colspan="2" class="mdl-data-table__cell--non-numeric">
        <input
          type="checkbox"
          id="errors-check"
          name="errors-check"
          [(ngModel)]="filter.errors"
          (ngModelChange)="filterChanged()"
        />
        <label
          class="form-check-label"
          for="errors-check"
          style="margin-left: 8px"
          >Errors</label
        >
        <input
          type="checkbox"
          id="warnings-check"
          name="warnings-check"
          [(ngModel)]="filter.warnings"
          (ngModelChange)="filterChanged()"
        />
        <label
          class="form-check-label"
          for="warnings-check"
          style="margin-left: 8px"
          >Warnings</label
        >
      </th>
      <th>
        <input
          type="checkbox"
          id="changes-check"
          name="changes-check"
          [(ngModel)]="filter.changed"
          (ngModelChange)="filterChanged()"
        />
        <label class="form-check-label" for="changes-check">Show Changes</label>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td colspan="12">
        <mat-progress-bar
          mode="indeterminate"
          color="primary"
          style="width: 100%"
        >
        </mat-progress-bar>
      </td>
    </tr>
    <tr
      *ngFor="
        let event of events
          | paginate
            : {
                itemsPerPage: itemsPerPage,
                currentPage: currentPage,
                totalItems: eventCount,
                id: 'events-pagination'
              };
        trackBy: trackByFn
      "
      [class.selected]="event._id === selectedEventId"
    >
      <td>{{ event.period }}</td>
      <td>
        <a href="#" (click)="seekEvent(event, $event)">{{
          event.videoTime | hhmmss
        }}</a>
      </td>
      <td>{{ event.gameTime | countdown : event.period }}</td>
      <td>{{ event.insertDate | date : 'HH:mm:ss' }}</td>
      <td *ngIf="game.lagClock">{{
        liveDelay(event) | safeNumber : '.1-1'
      }}</td>
      <td class="mdl-data-table__cell--non-numeric">
        {{ event.eventType }}
        <ng-container *ngIf="event.eventType === 'interruption'"
          >/ {{ event.interruption_type }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'penalty'"
          >/ {{ event.penaltyType }} {{ event.penaltyDuration }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'shot'"
          >/ {{ event.shotOutcome }}</ng-container
        >
        <ng-container
          *ngIf="event.eventType === 'shot' && event.shotOutcome === 'goal'"
        >
          (<a [routerLink]="[]" [title]="formatPlayers(event.plus)"
            >+{{ event.plusCount }}</a
          >/<a [routerLink]="[]" [title]="formatPlayers(event.minus)"
            >-{{ event.minusCount }}</a
          >)
        </ng-container>
        <ng-container
          *ngIf="
            event.eventType === 'shot' &&
            event.shotOutcome === 'blocked' &&
            event.blocker
          "
        >
          by {{ event.blocker }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'shot' && event.shotScenario"
          >/ {{ event.shotScenario }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'shot' && event.shotType"
          >/ {{ event.shotType }}</ng-container
        >
        <ng-container
          *ngIf="event.eventType === 'shot' && event.shotOutcome !== 'blocked'"
          >/
          <ng-container *ngIf="event.xgValue"
            >{{ event.xgValue | percent : '1.2-2' }} xG
          </ng-container>
          <ng-container *ngIf="!event.xgValue">– xG</ng-container>
        </ng-container>

        <ng-container *ngIf="event.eventType === 'pass'"
          >/ {{ event.pass_type }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'videoTag'"
          >/ {{ event.videoTag }}
        </ng-container>
        <ng-container *ngIf="event.eventType === 'oddMenRush'"
          >/ {{ event.oddMenRushDetail }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'face_off'"
          >/ Team Outcome: {{ event.teamFaceOffOutcome }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'time_on_ice'"
          >/ {{ event.timeOnIceType }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'highlight'"
          >/ {{ event.highlightType }}</ng-container
        >
        <ng-container *ngIf="event.eventType === 'game_incident'"
          >/ {{ event.gameIncidentReason }}</ng-container
        >
      </td>
      <td
        *ngIf="
          game.isHighlightCollection &&
          (filter.eventType === '' || filter.eventType === 'highlight')
        "
        class="mdl-data-table__cell--non-numeric"
      >
        <app-star-rating
          [starCount]="5"
          [color]="'primary'"
          [ngModel]="event.highlightRating"
          (ngModelChange)="ratingEventChange($event, event)"
        ></app-star-rating>
      </td>
      <td class="mdl-data-table__cell--non-numeric">
        {{ event.strengthState }}
        <span *ngIf="event.isHomeTeamEmptyNet"
          >/ {{ game.homeTeam }} Empty Net</span
        >
        <span *ngIf="event.isAwayTeamEmptyNet"
          >/ {{ game.awayTeam }} Empty Net</span
        >
      </td>
      <td class="mdl-data-table__cell--non-numeric">{{ event.team }}</td>
      <td class="mdl-data-table__cell--non-numeric column-player">
        <div
          class="player"
          [title]="event.playerNumber + ' ' + (event.pass_receiver ?? '')"
        >
          {{ event.playerNumber }}

          <ng-container
            *ngIf="event.eventType === 'pass' && event.pass_receiver"
            >➡ {{ event.pass_receiver }}</ng-container
          >
        </div>
      </td>
      <td
        *ngIf="game.isLiveDraftEvents"
        class="mdl-data-table__cell--non-numeric column-player"
      >
        <div>
          <mat-icon *ngIf="event.draft">event_busy</mat-icon>
        </div>
      </td>
      <td class="mdl-data-table__cell--non-numeric" *ngIf="!event.updated">
        <ng-container *ngFor="let validation of event.validations">
          <mat-icon
            *ngIf="validation.status === 'success'"
            class="success"
            [title]="validation.message"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="validation.status === 'warning'"
            class="warning"
            [title]="validation.message"
            >warning</mat-icon
          >
          <mat-icon
            *ngIf="validation.status === 'error'"
            class="error"
            [title]="validation.message"
            >error</mat-icon
          >
        </ng-container>
      </td>
      <td class="mdl-data-table__cell--non-numeric" *ngIf="event.updated">
        <mat-icon
          style="cursor: pointer"
          matTooltip="Reload events to check validation"
          (click)="loadEvents()"
          >cached</mat-icon
        >
      </td>
      <td class="mdl-data-table__cell--non-numeric">
        <ng-container *ngIf="event.xPosition && event.yPosition">
          {{ event.xPosition | number : '0.1-1' }} /
          {{ event.yPosition | number : '0.1-1' }}</ng-container
        >
      </td>
      <td>
        <i
          class="material-icons"
          style="cursor: pointer"
          (click)="deleteEvent(event)"
          >delete</i
        >
        <i
          class="material-icons"
          style="cursor: pointer"
          (click)="editEvent(event)"
          >edit</i
        >

        <i
          class="material-icons"
          *ngIf="filter.changed"
          [class.invisible]="event.audit?.length <= 1"
          [matTooltip]="formatAuditChanges(event)"
          >change_history</i
        >
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="14" style="text-align: center">
        <pagination-controls
          (pageChange)="pageChanged($event)"
          id="events-pagination"
        ></pagination-controls>
      </th>
    </tr>
  </tfoot>
</table>
