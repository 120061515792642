<section [formGroup]="filter">
  <mat-form-field>
    <mat-select formControlName="season" placeholder="Season">
      <mat-option *ngFor="let season of seasons | async" [value]="season"
        >{{ season - 1 }}/{{ season }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="seasonType" placeholder="Season Type">
      <mat-option *ngFor="let seasonType of seasonTypes" [value]="seasonType">{{
        seasonType
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      formControlName="collectorTeam"
      placeholder="Collector Team"
    />
  </mat-form-field>

  <mat-checkbox formControlName="hideWithoutGames"
    >Hide without Games</mat-checkbox
  >
  <button
    mat-raised-button
    color="accent"
    style="float: right; margin: 4px"
    (click)="showCreateLeagueDialog(null)"
    >Add League</button
  >
</section>

<table class="mat-elevation-z0" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="imageUrl">
    <th *matHeaderCellDef mat-header-cell>Logo</th>
    <td mat-cell *matCellDef="let summary">
      <img
        [src]="summary.league?.imageUrl"
        style="height: 20px"
        *ngIf="summary.league?.imageUrl"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="shortName">
    <th *matHeaderCellDef mat-header-cell>League</th>
    <td mat-cell *matCellDef="let summary">
      <span>{{ summary.league?.shortName }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="statusNew">
    <th *matHeaderCellDef mat-header-cell>New</th>
    <td mat-cell *matCellDef="let summary"
      ><a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'new')"
        >{{ summary.byStatus['new'] ?? 0 }}</a
      ></td
    >
  </ng-container>

  <ng-container matColumnDef="statusInCollection">
    <th *matHeaderCellDef mat-header-cell>In Collection</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'in_collection')"
        >{{ summary.byStatus['in_collection'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="statusInExtendedCollection">
    <th *matHeaderCellDef mat-header-cell>In Extended Collection</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'in_extended_collection')"
        >{{ summary.byStatus['in_extended_collection'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="statusQualityControl">
    <th *matHeaderCellDef mat-header-cell>Quality Control</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'quality_control')"
        >{{ summary.byStatus['quality_control'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="statusComplete">
    <th *matHeaderCellDef mat-header-cell>Complete</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'complete')"
        >{{ summary.byStatus['complete'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="statusFailed">
    <th *matHeaderCellDef mat-header-cell>Failed</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'failed')"
        >{{ summary.byStatus['failed'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="statusAbandoned">
    <th *matHeaderCellDef mat-header-cell>Abandoned</th>
    <td mat-cell *matCellDef="let summary" class="col-status">
      <a
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id, 'abandoned')"
        >{{ summary.byStatus['abandoned'] ?? 0 }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let summary">
      <a
        mat-icon-button
        routerLink="/games"
        [queryParams]="getQueryParams(summary._id)"
        title="Find Games"
      >
        <mat-icon>search</mat-icon>
      </a>

      <a
        mat-icon-button
        [routerLink]="['./', summary._id, 'teams']"
        [queryParams]="teamsQueryParams()"
        title="Show Teams"
      >
        <mat-icon>list</mat-icon>
      </a>
      <button mat-icon-button (click)="deleteLeague(summary)"
        ><mat-icon>delete</mat-icon></button
      >
      <button mat-icon-button (click)="showCreateLeagueDialog(summary.league)"
        ><mat-icon>edit</mat-icon></button
      ></td
    >
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let game; columns: columns"></tr>
</table>

<mat-progress-bar
  *ngIf="loading"
  mode="indeterminate"
  color="primary"
  style="width: 100%"
>
</mat-progress-bar>
