<h2 mat-dialog-title
  >Adjust Lineup {{ game.homeTeam }} - {{ game.awayTeam }}</h2
>
<mat-dialog-content>
  <b
    >Adjusting the lineup modifies all previously collected game events for the
    respective player.</b
  >
  <div class="row" style="margin-top: 24px">
    <div class="col-2">Existing Player</div>
    <div class="col-10">
      <mat-select [(value)]="existingPlayer" name="season" placeholder="Player">
        <mat-option *ngFor="let player of players" [value]="player"
          >{{ player.playerNumber }} ({{ player.team }})</mat-option
        >
      </mat-select>
    </div>

    <div class="col-2"></div>
    <div class="col-10">
      {{ existingPlayer?.key }}: {{ existingPlayer?.playerNumber }}/{{
        existingPlayer?.playerId
      }}
    </div>

    <div class="col-2 mt-3">New Player</div>
    <div class="col-5 mt-3">
      <input [(ngModel)]="newPlayerName" placeholder="playerNumber" />
    </div>
    <div class="col-5 mt-3">
      <input [(ngModel)]="newPlayerId" placeholder="playerID" />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Cancel</button>
  <button mat-raised-button color="warn" (click)="applyChange()"
    >Apply Lineup Change</button
  >
</mat-dialog-actions>
