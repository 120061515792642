<div class="wrapper">
  <!-- If we change field picture please check validation -->
  <img
    (click)="setEventPosition($event)"
    src="/assets/sihf_ice_rink.svg"
    style="border-radius: 75px; cursor: crosshair"
  />

  <img
    *ngIf="
      !hasSourceAndTargetPosition() &&
      position?.x !== null &&
      position?.y !== null
    "
    class="cross"
    src="/assets/cross_black.png"
    [style.top.px]="position?.y - 8"
    [style.left.px]="position?.x - 8"
  />
  <img
    *ngIf="
      hasSourceAndTargetPosition() &&
      position?.x !== null &&
      position?.y !== null
    "
    class="cross"
    src="/assets/cross_green.png "
    [style.top.px]="position?.y - 8"
    [style.left.px]="position?.x - 8"
  />
  <img
    *ngIf="
      hasSourceAndTargetPosition() &&
      targetPosition?.x !== null &&
      targetPosition?.y !== null
    "
    class="cross"
    src="/assets/cross_red.png"
    [style.top.px]="targetPosition?.y - 8"
    [style.left.px]="targetPosition?.x - 8"
  />

  <ng-container *ngIf="showPlayerPosition">
    <div
      class="player"
      *ngFor="let p of playerCoordinates"
      [class.selected-player]="selectedPlayer && selectedPlayer === p.player"
      [class.possession-player]="
        puckPossessionPlayer && puckPossessionPlayer === p.player
      "
      [style.top.px]="round(p.y) - 8"
      [style.left.px]="round(p.x) - 12"
      [style.background-color]="teamColor(p.team)"
      (click)="selectPlayer(p)"
      >{{ jerseyNumber(p.player) || '?' }}</div
    >
  </ng-container>

  <div class="home-team">
    <span class="text">{{ game?.homeTeam }}</span>
  </div>
  <div class="away-team">
    <span class="text">{{ game?.awayTeam }}</span>
  </div>
</div>
<svg height="100%" width="100%" class="overlay">
  <defs>
    <marker
      id="arrow-head"
      viewBox="0 0 10 10"
      refX="12"
      refY="5"
      markerWidth="5"
      markerHeight="5"
      orient="auto"
      stroke="none"
    >
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>
  </defs>
  <line
    *ngIf="
      showTargetArrow &&
      hasSourceAndTargetPosition() &&
      position?.x &&
      targetPosition?.x
    "
    [attr.x1]="position?.x"
    [attr.y1]="position?.y"
    [attr.x2]="targetPosition?.x"
    [attr.y2]="targetPosition.y"
    marker-end="url(#arrow-head)"
  />
</svg>
