import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Game } from '../../domain/game';
import { Player } from '../../domain/player';
import { Team } from '../../domain/team';

@Component({
  selector: 'app-player-filter',
  templateUrl: './player-filter.component.html',
  styleUrls: ['./player-filter.component.css']
})
export class PlayerFilterComponent implements OnChanges {
  selectedPlayerId: string;
  homeTeamPlayers: Player[] = [];
  awayTeamPlayers: Player[] = [];

  @Input()
  game: Game;

  @Input()
  players: Player[];

  @Input()
  team: string;

  @Output()
  playerChange = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.players && this.game) {
      this.homeTeamPlayers = this.players
        .filter((p) => p.team === this.game.homeTeam)
        .sort((p1, p2) => p1.jerseyNumber - p2.jerseyNumber);
      this.awayTeamPlayers = this.players
        .filter((p) => p.team === this.game.awayTeam)
        .sort((p1, p2) => p1.jerseyNumber - p2.jerseyNumber);
    }
  }

  filterChanged(): void {
    this.playerChange.emit(this.selectedPlayerId);
  }
}
