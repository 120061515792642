import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { fromEvent } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';
import { NGRX_STORE } from '../reducers';

@Injectable()
export class LocalStorageEffects {
  storeId: string;
  constructor(private actions$: Actions) {
    this.storeId = self.crypto.randomUUID();
  }

  onStoreChange$ = createEffect(
    () =>
      this.actions$.pipe(
        filter((action) => action.type !== '@ngrx/effects/init'),
        filter((action) => !action.hasOwnProperty('storeId')),
        tap((action) => {
          let augmentedAction = action;
          augmentedAction = this.addStoreIdToAction(
            augmentedAction,
            this.storeId
          );
          localStorage.setItem(NGRX_STORE, JSON.stringify(augmentedAction));
        })
      ),
    { dispatch: false }
  );

  storageChangeListener$ = createEffect(() =>
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((evt) => evt.key === NGRX_STORE),
      filter((evt) => evt.newValue !== null),
      map((event) => {
        const action = JSON.parse(event.newValue);
        if (action.storeId === this.storeId) {
          // won't happen anymore
          return { type: '[Game Actions] Ignore Action' };
        }
        return action;
      })
    )
  );

  addStoreIdToAction(action, storeId) {
    return Object.assign({}, action, { storeId });
  }
}
