import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VideoPlayerTrimComponent } from '../video-player-trim.component';
import { Game, Video } from '../../domain/game';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-video-player-trim-dialog',
  templateUrl: './video-player-trim-dialog.component.html',
  styleUrls: ['./video-player-trim-dialog.component.scss']
})
export class VideoPlayerTrimDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VideoPlayerTrimDialogComponent>,
    private alertService: AlertService
  ) {}

  @ViewChild(VideoPlayerTrimComponent)
  videoPlayerTrim: VideoPlayerTrimComponent;

  game: Game;
  cameraIndex: number;

  src: string;
  startOfVideoClip: number;
  durationVideoClip: number;
  startOfOriginalVideoClip: number;
  durationOfOriginalVideoClip: number;
  durationOfFullVideo: number;

  ngOnInit() {
    this.game = this.data.game;
    this.cameraIndex = this.data.cameraIndex;
    this.src = this.data.src;
    this.startOfVideoClip = this.data.startOfVideoClip;
    this.durationVideoClip = this.data.durationVideoClip;
    this.startOfOriginalVideoClip = this.data.startOfOriginalVideoClip;
    this.durationOfOriginalVideoClip = this.data.durationOfOriginalVideoClip;
    this.durationOfFullVideo = this.data.durationOfFullVideo;
    this.validateData();
  }

  validateData() {
    if (
      this.durationVideoClip < 0 ||
      this.startOfOriginalVideoClip < 0 ||
      this.startOfVideoClip < 0
    ) {
      this.alertService.showWarning(
        'Video time malformed, please check your video time'
      );
    }
  }

  updateTrimVideo(event): void {
    this.data.startVideoTime = event.startTrimTime;
    this.data.endVideoTime = event.endTrimTime;
  }

  apply() {
    this.videoPlayerTrim.confirmTrim();
    this.dialogRef.close({
      startVideoTime: this.data.startVideoTime,
      endVideoTime: this.data.endVideoTime
    });
  }

  changeCameraAngle(video: Video) {
    this.dialogRef.close({
      cameraAngle: video.cameraAngle,
      format: video.format,
      variant: video.variant
    });
  }
}
