import { GameEventState, INITIAL_GAME_EVENT_STATE } from './game-event.reducer';
import { GameState, INITIAL_GAME_STATE } from './game.reducer';

export interface GlobalState {
  game: GameState;
  gameEvent: GameEventState;
}

const NEW_GLOBAL_STATE: GlobalState = {
  game: INITIAL_GAME_STATE,
  gameEvent: INITIAL_GAME_EVENT_STATE
};

export const INITIAL_GLOBAL_STATE_NGRX: GlobalState = { ...NEW_GLOBAL_STATE };

export const NGRX_STORE = 'NGRX_STORE';
