import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Game } from '../domain/game';
import { VisualTimeOnIceService } from '../visual-time-on-ice/visual-time-on-ice.service';

export interface QualityCheck {
  period: string;
  team: string;
  expected: number;
  actual: number;
  error: number;
}

@Component({
  selector: 'app-game-quality-dialog',
  templateUrl: './game-quality-dialog.component.html',
  styleUrls: ['./game-quality-dialog.component.css']
})
export class GameQualityDialogComponent implements OnInit {
  game: Game;
  qualityChecks: QualityCheck[];
  total: QualityCheck;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private visualTimeOnIceService: VisualTimeOnIceService,
    public dialogRef: MatDialogRef<GameQualityDialogComponent>,
    private router: Router
  ) {}

  async ngOnInit() {
    this.game = this.data.game;
    this.qualityChecks = this.data.report.qualityChecks;
    this.total = this.data.report.total;
    await this.visualTimeOnIceService.init(this.game);
  }

  async navigateToTimeline(period: string, team: string) {
    this.dialogRef.close();
    await this.router.navigate(['games', this.game._id, 'timeline'], {
      queryParams: { period, team }
    });
  }
}
