<h2 mat-dialog-title>Recalculate FOINT GameTime for {{ data.gameName }}</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="periods">
          <mat-option value="all">All Periods</mat-option>
          <mat-option value="1">Period 1</mat-option>
          <mat-option value="2">Period 2</mat-option>
          <mat-option value="3">Period 3</mat-option>
          <mat-option value="4">OT 1</mat-option>
          <mat-option value="5">OT 2</mat-option>
          <mat-option value="6">OT 3</mat-option>
          <mat-option value="7">OT 4</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="close-button"
    >Cancel</button
  >
  <button
    mat-raised-button
    color="warn"
    class="confirm-button"
    (click)="confirm()"
    >Recalculate FOINT</button
  >
</mat-dialog-actions>
