import { Pipe, PipeTransform } from '@angular/core';
import { GamePeriod } from '../../domain/game-event';

@Pipe({ name: 'countdown' })
export class CountdownTimePipe implements PipeTransform {
  constructor() {}

  transform(inputTime: number, period: GamePeriod | string) {
    if (inputTime === null || !period) {
      return '';
    }

    const startSec = this.getStartSec(period);
    const diffSec = startSec - inputTime;
    const s = (Math.ceil(diffSec) % 3600) % 60;
    const m = Math.ceil((diffSec - s) / 60);
    return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }

  private getStartSec(period: any): number {
    period = period.toString();
    const periodNum = parseInt(period, 10);
    return periodNum * 20 * 60;
  }
}
