<h2 mat-dialog-title>Validations {{ game.homeTeam }} - {{ game.awayTeam }}</h2>
<mat-dialog-content>
  <table>
    <tr *ngFor="let validation of validations">
      <td>{{ validation.message }}</td>
      <td style="width: 24px">
        <mat-icon *ngIf="validation.status === 'success'" class="success"
          >check_circle</mat-icon
        >
        <mat-icon *ngIf="validation.status === 'warning'" class="warning"
          >warning</mat-icon
        >
        <mat-icon *ngIf="validation.status === 'error'" class="error"
          >error</mat-icon
        >
      </td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
