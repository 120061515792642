<div class="reviews-container" style="padding: 25px">
  <h5>Review</h5>

  <div class="form-row">
    <div class="form-group col-md-2">
      <label for="49ingGameId">49ing Reviewed GameId</label>
      <input
        id="49ingGameId"
        class="form-control"
        [(ngModel)]="review.gameId"
      />
    </div>

    <div class="form-group col-md-2" *ngIf="review?.type === '49ing'">
      <label for="masterGameId">49ing Master GameId</label>
      <input
        id="masterGameId"
        class="form-control"
        [(ngModel)]="review.masterGameId"
      />
    </div>
  </div>

  <div class="form-group col-md-2" *ngIf="review?.type === '49ing'">
    <label for="eventType">Event Type</label>
    <select id="eventType" class="form-control" [(ngModel)]="review.eventType">
      <option value="shot">Shots</option>
      <option value="foint">FOINT</option>
      <option value="pass">Passes</option>
      <option value="puckPossession">Puck Possession</option>
      <option value="videoTag">Video Tags</option>
      <option value="oddMenRush">Odd Men Rush</option>
    </select>
  </div>

  <div class="form-row">
    <div class="form-group col-md-2">
      <label for="person">Reviewer</label>
      <input id="person" class="form-control" [(ngModel)]="review.person" />
    </div>

    <div class="form-group col-md-2">
      <label for="status">Status</label>
      <select id="status" class="form-control" [(ngModel)]="review.status">
        <option value="to_review">To Review</option>
        <option value="reviewing">Reviewing</option>
        <option value="checking">Checking</option>
        <option value="done">Done</option>
      </select>
    </div>
  </div>

  <button
    mat-raised-button
    color="primary"
    class="save-button"
    (click)="save()"
  >
    Save
  </button>
</div>
