import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  VgStates,
  VgApiService,
  VgControlsHiddenService
} from '@videogular/ngx-videogular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-live-scrub-bar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './video-live-scrub-bar.component.html',
  styleUrls: ['./video-live-scrub-bar.component.css']
})
export class VideoLiveScrubBarComponent implements OnInit, OnDestroy {
  @HostBinding('class.hide') hideScrubBar = false;

  @Input() vgFor: string;
  @Input() vgSlider = true;

  /**
   * If DVR is enabled live streams can be seeked.
   */
  @Input() dvr = false;

  /**
   * Seekable stream required for seeking
   */
  @Output() switchChannel: EventEmitter<number> = new EventEmitter();

  elem: HTMLElement;
  target: any;
  isSeeking = false;
  wasPlaying = false;

  subscriptions: Subscription[] = [];

  private componentDestroyed$: Subject<void> = new Subject();

  @Input() livePosition: number = 0;

  constructor(
    ref: ElementRef,
    public API: VgApiService,
    vgControlsHiddenState: VgControlsHiddenService
  ) {
    this.elem = ref.nativeElement;
    this.subscriptions.push(
      vgControlsHiddenState.isHidden.subscribe((hide) =>
        this.onHideScrubBar(hide)
      )
    );
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);
    this.target.subscriptions.loadedMetadata
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((e) => {
        // Set init seek back live duration
        this.target.capturedSeekBackLiveDuration = this.target.duration;
      });
  }

  protected seekStart(offset: number) {
    if (this.isWebRTC()) {
      const percentage = Math.max(
        Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
        0
      );
      this.target.pause();
      console.log(`switching to HLS...percentage ${percentage}`);
      this.switchChannel.emit(percentage);
      return;
    }

    if (this.target.canPlay) {
      this.isSeeking = true;
      if (this.target.state === VgStates.VG_PLAYING) {
        this.wasPlaying = true;
      }
      this.target.pause();
    }
  }

  private isWebRTC() {
    const target = this.API.getDefaultMedia();
    const video = document.getElementById(target.id) as HTMLVideoElement;
    return video.id.includes('video-webrtc-');
  }

  protected seekMove(offset: number) {
    if (this.isSeeking) {
      const percentage = Math.max(
        Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
        0
      );
      this.target.time.current = (percentage * this.getTotalTime()) / 100;
      this.seekTime(percentage);
    }
  }

  protected seekEnd(offset: number) {
    this.isSeeking = false;
    if (this.target.canPlay) {
      const percentage = Math.max(
        Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
        0
      );
      this.seekTime(percentage);
      if (this.wasPlaying) {
        this.wasPlaying = false;
        this.target.play();
      }
    }
  }

  protected touchEnd() {
    this.isSeeking = false;
    if (this.wasPlaying) {
      this.wasPlaying = false;
      this.target.play();
    }
  }

  protected getTouchOffset(event: any) {
    let offsetLeft = 0;
    let element: any = event.target;
    while (element) {
      offsetLeft += element.offsetLeft;
      element = element.offsetParent;
    }
    return event.touches[0].pageX - offsetLeft;
  }

  private isSeekable() {
    return !this.target.isLive || this.dvr;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDownScrubBar($event: any) {
    if (this.target) {
      if (this.isSeekable()) {
        if (!this.vgSlider) {
          this.seekEnd($event.offsetX);
        } else {
          this.seekStart($event.offsetX);
        }
      } else {
        this.captureSeekBackLiveDuration();
      }
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMoveScrubBar($event: any) {
    if (this.target) {
      if (this.target.isLive) {
        this.captureSeekBackLiveDuration();
      }
      if (this.vgSlider && this.isSeeking) {
        this.seekMove($event.offsetX);
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUpScrubBar($event: any) {
    if (this.target) {
      if (this.isSeekable() && this.vgSlider && this.isSeeking) {
        this.seekEnd($event.offsetX);
      } else {
        this.captureSeekBackLiveDuration();
      }
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStartScrubBar($event: any) {
    if (this.target) {
      if (this.isSeekable()) {
        if (!this.vgSlider) {
          this.seekEnd(this.getTouchOffset($event));
        } else {
          this.seekStart(this.getTouchOffset($event));
        }
      } else {
        this.captureSeekBackLiveDuration();
      }
    }
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMoveScrubBar($event: any) {
    if (this.target) {
      if (this.isSeekable() && this.vgSlider && this.isSeeking) {
        this.seekMove(this.getTouchOffset($event));
      } else {
        this.captureSeekBackLiveDuration();
      }
    }
  }
  // @ts-ignore
  @HostListener('document:touchcancel', ['$event']) onTouchCancelScrubBar(
    $event: any
  ) {
    if (this.target) {
      if (this.isSeekable() && this.vgSlider && this.isSeeking) {
        this.touchEnd();
      }
    }
  }
  // @ts-ignore
  @HostListener('document:touchend', ['$event']) onTouchEndScrubBar(
    $event: any
  ) {
    if (this.target) {
      if (this.isSeekable() && this.vgSlider && this.isSeeking) {
        this.touchEnd();
      }
    }
  }

  /** Original code have this function but there is no point to have something like this
   *  inside vg-scrub-bar for us since we manipulate seek time.
   *  Plus naming (arrowAdjustVolume) in library is completely wrong.
   *
   * @HostListener('keydown', ['$event'])
   * arrowAdjustVolume(event: KeyboardEvent) {
   *   if (this.target) {
   *     if (event.keyCode === 38 || event.keyCode === 39) {
   *       event.preventDefault();
   *      this.target.seekTime((this.target.time.current + 5000) / 1000, false);
   *    } else if (event.keyCode === 37 || event.keyCode === 40) {
   *       event.preventDefault();
   *       this.target.seekTime((this.target.time.current - 5000) / 1000, false);
   *     }
   *   }
   * }
   */

  getPercentage() {
    return this.target
      ? (this.target.time.current * 100) / this.getTotalTime() + '%'
      : '0%';
  }

  onHideScrubBar(hide: boolean) {
    this.hideScrubBar = hide;
  }

  protected seekTime(percentage: number): void {
    if (!this.target.isLive) {
      // Regular calculation
      this.target.seekTime(percentage, true);
    } else {
      if (this.isLiveTime()) {
        // Regular calculation
        this.target.seekTime(percentage, true);
      } else {
        /*
         * In live mode when we seek back we need to use captured
         * duration at that moment and do division with that duration time
         */
        const currentTime =
          percentage * this.target?.capturedSeekBackLiveDuration * 10;
        this.target.time.current = currentTime;
        this.target.seekTime(currentTime / 1000, false);
      }
    }
  }

  protected getTotalTime() {
    if (!this.target.isLive) {
      return this.target.time.total;
    } else {
      if (this.isLiveTime()) {
        /*
         * In live mode we need to check duration because
         * time total is not live updated
         */
        return this.target.duration * 1000;
      } else {
        /*
         * In live mode when we seek back we need to use captured
         * duration at that moment and do division with that duration time
         */
        return (
          (this.target?.capturedSeekBackLiveDuration ?? this.target.duration) *
          1000
        );
      }
    }
  }

  isLiveTime() {
    if (this.target && this.target.isLive) {
      return this.target.time.current >= this.livePosition * 1000;
    }
  }

  protected captureSeekBackLiveDuration() {
    if (this.isLiveTime()) {
      this.target.capturedSeekBackLiveDuration = this.target.duration;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.componentDestroyed$.next(null);
  }
}
