<ng-container *ngIf="game">
  <label>{{ positionName }}:</label>
  <input
    [id]="positionKey + '_name'"
    [name]="positionKey + '_name'"
    [(ngModel)]="game[positionKey]"
    validatePlayer
    #nameValue="ngModel"
    [class.error]="nameValue.invalid"
  />

  <label>{{ positionName }} ID:</label>
  <input
    [id]="positionKey + '_id'"
    [name]="positionKey + '_id'"
    [(ngModel)]="game[positionKey + 'ID']"
    validatePlayerId
    #idValue="ngModel"
    [class.error]="idValue.invalid"
  />

  <div
    *ngIf="nameValue.invalid && (nameValue.dirty || nameValue.touched)"
    class="alert alert-danger"
  >
    <div *ngIf="nameValue.errors.validatePlayer">
      Wrong Format: Number - LastName FirstName
    </div>
  </div>
  <div
    *ngIf="idValue.invalid && (idValue.dirty || idValue.touched)"
    class="alert alert-danger"
  >
    <div *ngIf="idValue.errors.validatePlayerId">
      Wrong Format: Numeric ID
    </div>
  </div>
</ng-container>
