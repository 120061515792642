import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadImageService } from '../../services/upload-image.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UppyFile } from '@uppy/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  disabledDragDrop: boolean = false;
  fileUUID: string;
  thumbnailUrl: SafeUrl = null;

  @Input() bucketFolder = null;
  @Input() imageUrl: string = null;
  @Output() fileUploadComplete = new EventEmitter();
  constructor(
    private uploadImageService: UploadImageService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.uploadImageService.initImageUploader(this.bucketFolder);
    this.uploadImageService.addListener('upload', () => {
      this.disabledDragDrop = true;
    });

    this.uploadImageService.addListener('complete', (result) => {
      const resultData = result?.successful[0];
      const data = {
        ...resultData?.meta,
        key: resultData?.meta?.name,
        uploadURL: resultData?.uploadURL
      };
      this.fileUploadComplete.emit(decodeURIComponent(data.uploadURL));
      this.thumbnailUrl = null;
      this.uploadImageService.cancelAll();
      this.uploadImageService.thumbnailImage.next(null);
    });
    this.uploadImageService.thumbnailImage.subscribe((tumb) => {
      this.thumbnailUrl = tumb
        ? this.domSanitizer.bypassSecurityTrustUrl(tumb)
        : null;
    });
    this.uploadImageService.addListener('cancel-all', () => {
      this.disabledDragDrop = false;
      console.log(`Upload image cancelled`);
    });

    this.uploadImageService.setOnBeforeFileAdded((file) =>
      this.fileAdded(file)
    );
  }
  private fileAdded(file: UppyFile) {
    const uuid = uuidv4() + '.' + file.name.split('.')[1];
    this.fileUUID = uuid;
    const originalName = file.name;
    const currentFile = {
      ...file,
      name: this.uploadImageService.getS3Key(uuid)
    };
    this.uploadImageService.setMeta({ uuid, originalName });
    return currentFile;
  }

  removeFile() {
    this.thumbnailUrl = null;
    this.uploadImageService.cancelAll();
  }

  initUpload() {
    this.thumbnailUrl = null;
    this.imageUrl = null;
    this.fileUploadComplete.emit(null);
    this.uploadImageService.thumbnailImage.next(null);
  }
}
