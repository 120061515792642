<p><b>Shift Details</b></p>

<div class="container no-gutters">
  <div [formGroup]="formGroup" class="row">
    <div class="col-4"><label>Player</label></div>
    <div class="col-8">
      <b>
        <mat-select formControlName="playerNumber" placeholder="Player">
          <mat-option></mat-option>
          <mat-option *ngFor="let p of players" [value]="p.playerNumber">{{
            p.playerNumber
          }}</mat-option>
        </mat-select>
      </b>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <label>Period</label>
    </div>
    <div class="col-8">{{ shift.onEvent.period }}</div>
  </div>
  <div class="row">
    <div class="col-4"><label>Game Time</label></div>
    <div class="col-8"
      >{{ shift.onEvent.gameTime | countdown : shift.onEvent.period }} -
      {{ shift.offEvent?.gameTime | countdown : shift.offEvent?.period }}</div
    >
  </div>
  <div class="row">
    <div class="col-4">
      <label>Duration</label>
    </div>
    <div class="col-8">{{ shift.duration | mmss }}</div>
  </div>
  <div class="row">
    <div class="col-4"><label>Video Time</label></div>
    <div class="col-8"
      ><a href="#" (click)="seekVideo(shift.onEvent.videoTime, $event)">{{
        shift.onEvent.videoTime | hhmmss
      }}</a>
      -
      <a href="#" (click)="seekVideo(shift.offEvent?.videoTime, $event)">{{
        shift.offEvent?.videoTime | hhmmss
      }}</a></div
    >
  </div>
  <div class="row">
    <div class="col-4">
      <label>Strength State</label>
    </div>
    <div class="col-8"
      >{{ shift.onEvent.strengthState }} /
      {{ shift.offEvent?.strengthState }}</div
    >
  </div>
  <div class="row">
    <div class="col-4">
      <label>Insert User</label>
    </div>
    <div class="col-8"
      >{{ shift.onEvent.insertUser }} / {{ shift.offEvent?.insertUser }}</div
    >
  </div>
  <div class="row">
    <div class="col-4">
      <label>Insert Time</label>
    </div>
    <div class="col-8"
      >{{ shift.onEvent.insertDate | date : 'HH:mm:ss' }} /
      {{ shift.offEvent?.insertDate | date : 'HH:mm:ss' }}</div
    >
  </div>
  <div class="row">
    <div class="col-4">
      <label>Shift Score</label>
    </div>
    <div class="col-8"
      >{{ shift.onEvent.score * 100 | number : '1.0-0' }} %</div
    >
  </div>

  <div class="buttons" style="margin-top: 16px">
    <button mat-button (click)="delete()" color="warn">Delete</button>
    <button mat-button (click)="cancel()" [disabled]="formGroup.pristine"
      >Reset</button
    >
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="formGroup.pristine"
      >Save Shift</button
    >
  </div>
</div>
