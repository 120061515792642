import { Injectable } from '@angular/core';
import moment from 'moment';
import { firstValueFrom, throwError } from 'rxjs';
import { TimelineItem } from 'vis-timeline';
import { Game } from '../domain/game';
import { GameEvent } from '../domain/game-event';
import { Shift } from '../domain/shift';
import { EventService } from '../services/event.service';
import { findIndexSorted } from '../services/find-index-sorted';
import { ShiftsService } from '../services/shifts.service';

@Injectable({
  providedIn: 'root'
})
export class VisualTimeOnIceService {
  private game: Game;
  fointEvents: GameEvent[];
  penaltyEvents: GameEvent[];

  constructor(
    private eventService: EventService,
    private shiftsService: ShiftsService
  ) {}

  async init(game: Game) {
    this.game = game;
    await this.loadFOINT(game._id);
  }

  async loadFOINT(gameId: string) {
    const interruptionResult = await firstValueFrom(
      this.eventService.getEvents(
        gameId,
        { eventType: 'FOINT' },
        false,
        0,
        1000
      )
    );
    this.fointEvents = interruptionResult[1];
    const penaltyResult = await firstValueFrom(
      this.eventService.getEvents(this.game._id, { eventType: 'penalty' }, true)
    );
    this.penaltyEvents = penaltyResult[1];
  }

  formatGameClock(gameTime: number, period: string) {
    const periodLength = ['1', '2', '3'].includes(period) ? 20 * 60 : 5 * 60;
    let countdown = periodLength - (gameTime % 1200);
    if (gameTime > 0 && gameTime % periodLength === 0) {
      countdown = 0;
    }

    const s = Math.abs((Math.ceil(countdown) % 3600) % 60);
    const m = Math.abs(Math.ceil((countdown - s) / 60));
    return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }

  formatDuration(gameTime: number) {
    const s = Math.abs((Math.ceil(gameTime) % 3600) % 60);
    const m = Math.abs(Math.ceil((gameTime - s) / 60));
    return (
      (Math.sign(gameTime) < 0 ? '-' : '') +
      ('0' + m).slice(-2) +
      ':' +
      ('0' + s).slice(-2)
    );
  }

  classForStrengthState(isHomeTeam: boolean, strengthState: string) {
    if (isHomeTeam) {
      if (['5-4', '5-3', '4-3'].includes(strengthState)) {
        return 'positive';
      } else if (['4-5', '3-5', '3-4'].includes(strengthState)) {
        return 'negative';
      } else {
        return 'equal';
      }
    } else {
      if (['4-5', '3-5', '3-4'].includes(strengthState)) {
        return 'positive';
      } else if (['5-4', '5-3', '4-3'].includes(strengthState)) {
        return 'negative';
      } else {
        return 'equal';
      }
    }
  }

  async createShift(
    start: Date,
    end: Date,
    period: string,
    team: string,
    playerNumber: string
  ): Promise<Shift> {
    const player = this.game
      ?.getAllPlayersObj()
      .find((p) => p.team === team && p.playerNumber === playerNumber);
    if (!player) {
      throw new Error('Could not find player: ' + playerNumber);
    }
    const baseEvent = {
      gameId: this.game._id,
      eventType: 'time_on_ice',
      team,
      teamId: player.teamId,
      playerNumber,
      playerId: player.playerId,
      period
    };

    const gameStart = moment(this.game.date).unix() * 1000;

    const onVideoTime = start.getTime() - gameStart;
    const onEvent = {
      ...baseEvent,
      timeOnIceType: 'on',
      videoTime: onVideoTime,
      gameTime: this.determineGameTime(onVideoTime),
      strengthState: this.inferStrengthState(onVideoTime)
    } as GameEvent;

    const offVideoTime = end.getTime() - gameStart;
    const offEvent = {
      ...baseEvent,
      timeOnIceType: 'off',
      videoTime: offVideoTime,
      gameTime: this.determineGameTime(offVideoTime),
      strengthState: this.inferStrengthState(offVideoTime)
    } as GameEvent;
    return await firstValueFrom(
      this.shiftsService.createShift({ onEvent, offEvent })
    );
  }

  inferStrengthState(videoTime: number) {
    const lastPenalty = this.penaltyEvents
      .filter((p) => p.videoTime <= videoTime)
      .pop();
    return lastPenalty?.strengthState ?? '5-5';
  }

  moveShift(
    shift: Shift,
    changes: {
      start: Date;
      end: Date;
      group: string;
    }
  ) {
    const gameStart = moment(this.game.date).unix() * 1000;
    const onEvent = {
      ...shift.onEvent,
      videoTime: changes.start.getTime() - gameStart,
      score: null
    };
    onEvent.gameTime = this.determineGameTime(onEvent.videoTime);
    const offEvent = {
      ...shift.offEvent,
      videoTime: changes.end.getTime() - gameStart,
      score: null
    };
    offEvent.gameTime = this.determineGameTime(offEvent.videoTime);

    const playerNumber = changes.group;
    const players = this.game
      ?.getAllPlayersObj()
      .filter((p) => p.team === shift.onEvent.team);
    const player = players.find((p) => p.playerNumber === playerNumber);
    if (onEvent.playerNumber !== playerNumber && player) {
      onEvent.playerNumber = player.playerNumber;
      onEvent.playerId = player.playerId;
    }
    if (offEvent.playerNumber !== playerNumber && player) {
      offEvent.playerNumber = player.playerNumber;
      offEvent.playerId = player.playerId;
    }
    return firstValueFrom(
      this.shiftsService.updateShift({ onEvent, offEvent })
    );
  }

  saveShift(
    shift: Shift,
    changes: {
      playerId?: string;
      playerNumber?: string;
      confirmed?: boolean;
    }
  ) {
    const onEvent = { ...shift.onEvent };
    const offEvent = { ...shift.offEvent };
    if (changes.playerId) {
      onEvent.playerId = changes.playerId;
      offEvent.playerId = changes.playerId;
    }
    if (changes.playerNumber) {
      onEvent.playerNumber = changes.playerNumber;
      offEvent.playerNumber = changes.playerNumber;
    }
    if (changes.confirmed) {
      onEvent.confirmed = changes.confirmed;
    }
    onEvent.score = null;
    offEvent.score = null;
    return firstValueFrom(
      this.shiftsService.updateShift({ onEvent, offEvent })
    );
  }

  moveShot(
    shot: GameEvent,
    content: string,
    changes: {
      start: Date;
      end: Date;
      group: string;
    }
  ) {
    const gameStart = moment(this.game.date).unix() * 1000;
    shot.videoTime = changes.start.getTime() - gameStart;
    shot.gameTime = this.determineGameTime(shot.videoTime);

    const playerNumber = changes.group;
    const players = this.game
      ?.getAllPlayersObj()
      .filter((p) => p.team === shot.team);
    const player = players.find((p) => p.playerNumber === playerNumber);

    if (
      (content === 'Shot' || content === 'Goal') &&
      shot.playerNumber !== playerNumber &&
      player
    ) {
      shot.playerNumber = player.playerNumber;
      shot.playerId = player.playerId;
    } else if (content === 'Block' && shot.blocker !== playerNumber && player) {
      shot.blocker = player.playerNumber;
      shot.blockerId = player.playerId;
    } else if (
      content === 'Deflection' &&
      shot.deflector !== playerNumber &&
      player
    ) {
      shot.deflector = player.playerNumber;
      shot.deflectorId = player.playerId;
    } else if (
      content === 'Net Traffic' &&
      shot.net_traffic_causer !== playerNumber &&
      player
    ) {
      shot.net_traffic_causer = player.playerNumber;
      shot.net_traffic_causerId = player.playerId;
    } else if (
      content === 'Screen' &&
      shot.screener !== playerNumber &&
      player
    ) {
      shot.screener = player.playerNumber;
      shot.screenerId = player.playerId;
    }
    return firstValueFrom(this.eventService.save(shot));
  }

  movePass(
    pass: GameEvent,
    content: string,
    changes: {
      start: Date;
      end: Date;
      group: string;
    }
  ) {
    const gameStart = moment(this.game.date).unix() * 1000;
    pass.videoTime = changes.start.getTime() - gameStart;
    pass.gameTime = this.determineGameTime(pass.videoTime);

    const playerNumber = changes.group;
    const players = this.game
      ?.getAllPlayersObj()
      .filter((p) => p.team === pass.team);
    const player = players.find((p) => p.playerNumber === playerNumber);

    if (content === 'Pass' && pass.playerNumber !== playerNumber && player) {
      pass.playerNumber = player.playerNumber;
      pass.playerId = player.playerId;
    } else if (
      content === 'Received' &&
      pass.pass_receiver !== playerNumber &&
      player
    ) {
      pass.pass_receiver = player.playerNumber;
      pass.pass_receiverId = player.playerId;
    }

    return firstValueFrom(this.eventService.save(pass));
  }

  determineGameTime(videoTime: number) {
    const timeSyncEvent = this.findLessEqual(
      this.fointEvents.filter((e) =>
        ['interruption', 'face_off'].includes(e.eventType)
      ),
      (e) => e.videoTime - videoTime
    );
    if (!timeSyncEvent) {
      return 0;
    }

    if (timeSyncEvent.eventType === 'face_off') {
      const videoTimeDiff = videoTime - timeSyncEvent.videoTime;
      return timeSyncEvent.gameTime + videoTimeDiff;
    } else if (timeSyncEvent.eventType === 'interruption') {
      return timeSyncEvent.gameTime;
    }
    return undefined;
  }

  private findLessEqual = (timeEvents, predicate) => {
    const j = findIndexSorted(timeEvents, predicate);
    if (j > -1) {
      return timeEvents[j];
    }
    return null;
  };

  deleteShift(shift: Shift) {
    return this.shiftsService.deleteShift(shift);
  }

  mergeShifts(shifts: Shift[]) {
    const period0 = shifts[0].offEvent.period;
    const period1 = shifts[1].offEvent.period;
    if (period0 !== period1) {
      return throwError(
        new Error(
          `Cannot join shifts with different periods (${period0}, ${period1})`
        )
      );
    }

    const player0 = shifts[0].offEvent.playerNumber;
    const player1 = shifts[1].offEvent.playerNumber;
    if (player0 !== player1) {
      return throwError(
        new Error(
          `Cannot join shifts with different players (${player0}, ${player1})`
        )
      );
    }

    const strengthState0 = shifts[0].offEvent.strengthState;
    const strengthState1 = shifts[1].offEvent.strengthState;
    if (strengthState0 !== strengthState1) {
      return throwError(
        new Error(
          `Cannot join shifts with different strength states (${strengthState0}, ${strengthState1})`
        )
      );
    }

    return this.shiftsService.mergeShifts(shifts[0], shifts[1]);
  }

  summarizeTimeOnIce(
    faceOff: GameEvent,
    interruption: GameEvent,
    team: string,
    shifts: Shift[]
  ) {
    if (!faceOff || !interruption) {
      return 0;
    }

    const teamPeriodShifts = shifts.filter(
      (s) => s.onEvent.team === team && s.onEvent.period === faceOff.period
    );

    const intersected = teamPeriodShifts.filter(
      (s) =>
        (s.onEvent.gameTime < faceOff.gameTime &&
          s.offEvent.gameTime >= faceOff?.gameTime) ||
        (s.onEvent.gameTime >= faceOff.gameTime &&
          s.offEvent.gameTime <= interruption?.gameTime) ||
        (s.onEvent.gameTime <= interruption.gameTime &&
          s.offEvent.gameTime > interruption?.gameTime)
    );

    return intersected
      .map((s) => {
        const start = Math.max(s.onEvent.gameTime, faceOff.gameTime);
        const end = Math.min(s.offEvent.gameTime, interruption.gameTime);
        return end - start;
      })
      .reduce((acc, d) => acc + d, 0);
  }

  expectedTimeOnIceForSegment(
    faceoff: GameEvent,
    interruption: GameEvent,
    penalties: GameEvent[],
    isHomeTeam: boolean
  ) {
    if (!interruption) {
      return 0;
    }
    const start = faceoff.gameTime;
    const end = interruption.gameTime;

    let total = 0;
    let currentGameTime = start;
    while (currentGameTime < end) {
      // find next strength state change
      const lastPenalty = penalties
        .filter((p) => p.gameTime <= currentGameTime && p.gameTime <= end)
        .pop();
      const nextPenalty = penalties.find(
        (p) => p.gameTime > currentGameTime && p.gameTime <= end
      );
      const [numHome, numAway] = this.parseStrengthState(
        lastPenalty?.strengthState ?? '5-5'
      );
      const numPlayers = (isHomeTeam ? numHome : numAway) + 1;
      const timePlayed = (nextPenalty?.gameTime ?? end) - currentGameTime;
      total += numPlayers * timePlayed;
      currentGameTime = nextPenalty?.gameTime ?? end;
    }
    return total;
  }

  private parseStrengthState(strengthState: string) {
    const [h, a] = strengthState.split('-');
    const homeCount = parseInt(h, 10);
    const awayCount = parseInt(a, 10);
    return [homeCount, awayCount];
  }

  periodEndTime(period: string) {
    return this.fointEvents.find(
      (e) =>
        e.eventType === 'interruption' &&
        e.interruption_type === 'period_end' &&
        e.period === period
    )?.videoTime;
  }

  periodStartTime(period: string) {
    return this.fointEvents.find(
      (e) =>
        e.eventType === 'interruption' &&
        e.interruption_type === 'period_start' &&
        e.period === period
    )?.videoTime;
  }

  initialStrengthState(period: string) {
    if (period === '1') {
      return '5-5';
    }

    const periodStartTime = this.periodStartTime(period);
    const previousPenalty = [...this.penaltyEvents]
      .reverse()
      .find((p) => p.videoTime <= periodStartTime);
    return previousPenalty?.strengthState ?? '5-5';
  }

  prepareStrengthStateItems(team: string, period: string, shifts: Shift[]) {
    const filteredPenalties = this.penaltyEvents.filter(
      (p) => p.period === period
    );
    const penalties = [
      {
        _id: 'p_period_start_' + period,
        strengthState: this.initialStrengthState(period),
        videoTime: this.periodStartTime(period)
      },
      ...filteredPenalties,
      {
        _id: 'p_period_end_' + period,
        strengthState: 'x-y',
        videoTime: this.periodEndTime(period)
      }
    ] as GameEvent[];
    const strengthStateItems: TimelineItem[] = [
      {
        id: penalties[0]._id,
        content: 'period start',
        start: moment(this.game.date).toDate(),
        end: moment(this.game.date).add(penalties[1].videoTime).toDate(),
        type: 'background',
        className: 'equal' // TODO: get strength state from end of previous period
      }
    ];
    let lastEvent = penalties[0];
    penalties.forEach((p) => {
      const isHomeTeam = team === this.game.homeTeam;
      strengthStateItems.push({
        id: p._id,
        content: isHomeTeam
          ? lastEvent.strengthState
          : this.reverse(lastEvent.strengthState),
        start: moment(this.game.date).add(lastEvent.videoTime).toDate(),
        end: moment(this.game.date).add(p.videoTime).toDate(),
        type: 'background',
        className: this.classForStrengthState(
          isHomeTeam,
          lastEvent.strengthState
        )
      });
      lastEvent = p;
    });

    this.fointEvents
      .filter(
        (e) =>
          e.eventType === 'face_off' &&
          e.teamFaceOffOutcome === 'win' &&
          e.period === period
      )
      .forEach((faceOff) => {
        const interruption = this.fointEvents.find(
          (e) =>
            e.eventType === 'interruption' &&
            e.period === period &&
            e.videoTime > faceOff.videoTime
        );
        if (!interruption) {
          return;
        }
        strengthStateItems.push({
          id: `${faceOff._id}_ss`,
          content: '',
          start: moment(this.game.date).add(faceOff.videoTime).toDate(),
          end: moment(this.game.date).add(interruption.videoTime).toDate(),
          type: 'background',
          className: 'play'
        });
      });

    shifts
      .filter(
        (s) => s.onEvent.isAwayTeamEmptyNet || s.onEvent.isHomeTeamEmptyNet
      )
      .forEach((s) => {
        strengthStateItems.push({
          id: s.onEvent._id + '_empty_net',
          content: s.onEvent.team + ' empty net',
          start: moment(this.game.date).add(s.onEvent.videoTime).toDate(),
          end: moment(this.game.date).add(s.offEvent.videoTime).toDate(),
          type: 'background',
          className: ''
        });
      });
    return strengthStateItems;
  }

  private reverse(value: string) {
    return value.split('').reverse().join('');
  }

  isShortShift(
    shift: Shift,
    shortShiftThreshold: number,
    fointEvents: GameEvent[]
  ) {
    const duration = Math.round(
      shift.offEvent.gameTime - shift.onEvent.gameTime
    );
    if (duration >= shortShiftThreshold) {
      return false;
    }

    const endMatchingInterruption = fointEvents.find(
      (e) =>
        e.eventType === 'interruption' &&
        ['unspecified', 'period_end'].includes(e.interruption_type) &&
        Math.abs(e.gameTime - shift.offEvent.gameTime) < 1
    );
    if (endMatchingInterruption) {
      return false;
    }

    const endMatchingPenaltyExpiration = fointEvents.find(
      (e) =>
        e.eventType === 'penalty' &&
        e.penaltyType === 'expiration' &&
        Math.abs(e.gameTime - shift.offEvent.gameTime) < 1
    );
    if (endMatchingPenaltyExpiration) {
      return false;
    }

    const startMatchingPenaltyExpiration = fointEvents.find(
      (e) =>
        e.eventType === 'penalty' &&
        e.penaltyType === 'expiration' &&
        Math.abs(e.gameTime - shift.onEvent.gameTime) < 1
    );
    if (startMatchingPenaltyExpiration) {
      return false;
    }

    return true;
  }
}
