<h2 mat-dialog-title
  >Sync to Cockpit {{ game.homeTeam }} - {{ game.awayTeam }}</h2
>
<mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="syncPeriod">
          <mat-option value="all">All Periods</mat-option>
          <mat-option value="1">Period 1</mat-option>
          <mat-option value="2">Period 1+2</mat-option>
          <mat-option value="3">Period 1+2+3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="close-button"
    >Cancel</button
  >
  <button
    mat-raised-button
    color="warn"
    class="sync-button"
    (click)="syncToCockpit()"
    >Sync to Cockpit</button
  >
</mat-dialog-actions>
