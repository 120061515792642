import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Game } from '../../domain/game';
import { GameEvent } from '../../domain/game-event';
import { Player } from '../../domain/player';

@Component({
  selector: 'app-events-overview',
  templateUrl: './events-overview.component.html',
  styleUrls: ['./events-overview.component.css']
})
export class EventsOverviewComponent implements OnInit {
  _game: Game;

  _team: string;
  _playerId: string;
  _eventType: string;
  _period: string;

  filterablePlayers: Player[] = [];

  allEvents: GameEvent[] = [];
  filteredEvents: GameEvent[] = [];

  @Input()
  set game(game: Game) {
    this._game = game;
    this.filterablePlayers = game.getAllPlayersObj();
  }

  get game() {
    return this._game;
  }

  set team(value: string) {
    this._team = value;
    this.filterChanged();
  }

  @Input()
  set events(events: GameEvent[]) {
    this.allEvents = events;
    this.filterChanged();
  }

  @Input()
  set playerId(playerId: string) {
    this._playerId = playerId;
    this.filterChanged();
  }

  @Input()
  set eventType(eventType: string) {
    this._eventType = eventType;
    this.filterChanged();
  }

  @Output()
  seek = new EventEmitter<GameEvent>();

  @Output()
  delete = new EventEmitter<GameEvent>();

  ngOnInit() {
    this.team = this.game?.homeTeam;
  }

  filterChanged() {
    this.filteredEvents = this.allEvents;

    if (this._team) {
      this.filteredEvents = this.filteredEvents.filter(
        (e) => e.team === this._team
      );
    }

    if (this._playerId) {
      this.filteredEvents = this.filteredEvents.filter(
        (s) => s.playerId === this._playerId
      );
    }

    if (this._period) {
      this.filteredEvents = this.filteredEvents.filter(
        (s) => s.period === this._period
      );
    }

    const timeOnIceEventType = 'time_on_ice';

    if (this._eventType === timeOnIceEventType) {
      this.filteredEvents = this.filteredEvents.filter(
        (s) => s.eventType === this._eventType
      );
    } else {
      this.filteredEvents = this.filteredEvents.filter(
        (s) => s.eventType !== timeOnIceEventType
      );
    }
  }

  playerChanged(playerId: string) {
    this._playerId = playerId;
    this.filterChanged();
  }

  seekEvent(onEvent: GameEvent, $event: MouseEvent): void {
    $event.preventDefault();
    this.seek.emit(onEvent);
  }

  deleteEvent(event: GameEvent): void {
    this.delete.emit(event);
  }

  deleteInterruptionEvent(event: GameEvent): void {
    this.delete.emit(event);
  }
}
